import React, { useEffect, useState } from 'react';
import { Button, Input, Modal, Spin } from 'antd';
import { connect } from 'react-redux';
import { DEFAULT_MODAL_WIDTH } from 'common/config';
import { useAutoFocus } from 'common/hooks/useAutoFocus';
import { RootState } from 'app/store';

type AllType = ReturnType<typeof mapState>;

const CommentModal: React.FC<AllType> = (props) => {
  const { commentModalParams, eventLoading, enrollmentLoading, patientLoading, patientTimerLoading } = props;
  const { open, externalId, defaultValue, handleSend, handleCancel } = commentModalParams;
  const [text, setText] = useState<string>('');
  const textareaRef = useAutoFocus();

  const onCancelClick = () => {
    handleCancel?.();
    setText('');
  };

  const onSendClick = () => {
    if (externalId) {
      handleSend?.(externalId, text);
      setText('');
    }
  };

  useEffect(() => {
    if (defaultValue) {
      setText(defaultValue);
    }
  }, [defaultValue]);

  return (
    <Modal title="Leave a comment" open={open} footer={false} onCancel={onCancelClick} width={DEFAULT_MODAL_WIDTH}>
      <Spin spinning={eventLoading || enrollmentLoading || patientLoading || patientTimerLoading}>
        <div className="modal__body">
          <Input.TextArea
            placeholder="Message"
            rows={6}
            value={text}
            onChange={(e) => setText(e.target.value)}
            ref={textareaRef}
            autoFocus
          />
        </div>

        <div className="modal__footer">
          <Button className="btn-primary" onClick={onSendClick} disabled={!text.length}>
            Send
          </Button>
          <Button onClick={onCancelClick}>Cancel</Button>
        </div>
      </Spin>
    </Modal>
  );
};

const mapState = (state: RootState) => ({
  commentModalParams: state.modals.commentModalParams,
  eventLoading: state.actionModel.loading,
  enrollmentLoading: state.enrollmentReviewHistoryModel.loading,
  patientLoading: state.patientReviewHistoryModel.loading,
  patientTimerLoading: state.patientTimerModel.loading,
});

export default connect(mapState)(CommentModal);
