import React from 'react';
import { connect } from 'react-redux';
import { Spin } from 'antd';
import { RootState } from 'app/store';
import { StatisticsItem } from 'entities/Statistics/components/StatisticsItem';

type AllType = ReturnType<typeof mapState>;

const EnrollmentsSubheaderComponent: React.FC<AllType> = (props) => {
  const { statistics, statisticsLoading, enrollmentsLoading } = props;

  return (
    <Spin spinning={enrollmentsLoading || statisticsLoading}>
      <div className="statistics__container">
        <StatisticsItem title="Pending" count={statistics?.pendingCount} />
        <StatisticsItem title="Completed" subtitle="for today" count={statistics?.completedTodayCount} />
      </div>
    </Spin>
  );
};

const mapState = (state: RootState) => ({
  statistics: state.statistics.enrollmentsStatisticsData,
  statisticsLoading: state.statistics.loading,
  enrollmentsLoading: state.enrollmentsCollection.loading,
});

export const EnrollmentsSubheader = connect(mapState)(EnrollmentsSubheaderComponent);
