import React from 'react';
import { v4 as uuid } from 'uuid';
import { CommonContentLayout } from 'common/components/CommonContentLayout';
import { NavigationSubheader } from 'common/components/NavigationSubheader';
import AuditBody from 'entities/Timings/components/AuditBody';
import TimingsHistoryModal from 'entities/Modals/components/TimingsHistoryModal';

const AuditComponent: React.FC = () => {
  const modals = [{ key: uuid(), modal: <TimingsHistoryModal /> }];

  return (
    <CommonContentLayout
      subheader={<NavigationSubheader link="CPT Codes and Timing" onClick={() => history.back()} />}
      body={<AuditBody />}
      modals={modals}
    />
  );
};

export const Audit = AuditComponent;
