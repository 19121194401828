import React, { useEffect, useRef, useState } from 'react';
import { Button } from 'antd';
import parse from 'html-react-parser';
import { ReactComponent as EditIcon } from 'app/assets/images/svg/editIcon.svg';

interface IComponentProps {
  description?: string;
  onAddClick: () => void;
  onEditClick: () => void;
}

export const CarePlan: React.FC<IComponentProps> = ({ description, onAddClick, onEditClick }) => {
  const [collapsible, setCollapsible] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const ref = useRef(null);

  const toggleDescription = () => {
    setIsVisible(!isVisible);
  };

  useEffect(() => {
    // @ts-ignore
    if (ref.current && ref.current.clientHeight > 112) {
      setCollapsible(true);
    } else {
      setCollapsible(false);
    }
  }, [description]);

  return (
    <div className="care-plan">
      <p className="subtitle care-plan__title">Care Plan</p>

      {description?.length ? (
        <div>
          <div className="care-plan__container">
            <div
              className={`care-plan__description ${collapsible ? 'care-plan__description-collapsible' : ''}
                ${isVisible ? 'care-plan__description-visible' : ''}`}
              ref={ref}
            >
              {parse(description)}
            </div>

            {collapsible && (
              <Button className="btn-link care-plan__btn-toggle" onClick={toggleDescription}>
                {isVisible ? 'Collapse' : 'Show more'}
              </Button>
            )}
          </div>

          <Button className="btn-link care-plan__btn-edit" icon={<EditIcon />} onClick={onEditClick}>
            Edit
          </Button>
        </div>
      ) : (
        <Button className="btn-primary" onClick={onAddClick}>
          Add Care Plan
        </Button>
      )}
    </div>
  );
};
