import React, { useEffect } from 'react';
import { Layout } from 'antd';
import { connect } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import Header from 'common/components/Header';
import { ERoutes } from 'common/const/enums.const';
import PrivateLayout from 'common/components/PrivateLayout';
import { RootDispatch } from 'app/store';
import LoginPage from 'entities/Auth/components/LoginPage';

type AllType = ReturnType<typeof mapDispatch>;

const App: React.FC<AllType> = (props) => {
  const { initAuthModel } = props;

  useEffect(() => {
    initAuthModel();
  }, []);

  return (
    <Layout>
      <Header />

      <Routes>
        <Route path={ERoutes.Login} element={<LoginPage />} />
        <Route path="*" element={<PrivateLayout />} />
      </Routes>
    </Layout>
  );
};

const mapDispatch = (dispatch: RootDispatch) => ({
  initAuthModel: dispatch.authModel.initAuthModel,
});

export default connect(null, mapDispatch)(App);
