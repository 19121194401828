import { createModel } from '@rematch/core';
import { IRootModel } from 'app/store';
import { IAssessmentHistoryCollection, IAssessmentHistoryParams } from 'entities/AssessmentHistory/AssessmentHistory.models';
import { assessmentHistoryTransport } from 'entities/AssessmentHistory/AssessmentHistory.transport';

export const assessmentHistoryCollection = createModel<IRootModel>()({
  state: {
    data: null as IAssessmentHistoryCollection | null,
    loading: false,
  },
  reducers: {
    setAssessmentHistoryCollection: (state, payload: IAssessmentHistoryCollection) => ({ ...state, data: payload }),
    setAssessmentHistoryCollectionLoading: (state, payload: boolean) => ({ ...state, loading: payload }),
    clearAssessmentHistoryCollection: (state) => ({ ...state, data: null }),
  },
  effects: (dispatch) => ({
    async getAssessmentHistoryCollection(params: IAssessmentHistoryParams) {
      dispatch.assessmentHistoryCollection.setAssessmentHistoryCollectionLoading(true);
      assessmentHistoryTransport
        .getAssessmentHistoryCollection(params)
        .then((response) => {
          dispatch.assessmentHistoryCollection.setAssessmentHistoryCollection(response);
        })
        .finally(() => {
          dispatch.assessmentHistoryCollection.setAssessmentHistoryCollectionLoading(false);
        });
    },
  }),
});
