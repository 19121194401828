import React from 'react';
import { CommonContentLayout } from 'common/components/CommonContentLayout';
import { CCMBillingBody } from 'entities/Billing/components/CCMBilling/CCMBillingBody';
import { CCMBillingSubheader } from 'entities/Billing/components/CCMBilling/CCMBillingSubheader';

export const CCMBilling: React.FC = () => {
  return (
    <CommonContentLayout
      header={<p className="title">Billing</p>}
      subheader={<CCMBillingSubheader />}
      body={<CCMBillingBody />}
    />
  );
};
