import React from 'react';
import { Layout, Form, Input, Button, Tooltip } from 'antd';
import { CheckOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { EDashboardTabs, EStatusCodes } from 'common/const/enums.const';
import { ErrorMessages } from 'common/const/error.const';
import { RootDispatch, RootState } from 'app/store';
import { getDashboardPathname } from 'entities/Dashboard/Dashboard.helper';

const { Content } = Layout;

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

const LoginPage: React.FC<AllType> = (props) => {
  const { statusCode, addAuthModel } = props;

  const renderSuffix = () => {
    switch (statusCode) {
      case EStatusCodes.Success: {
        return <CheckOutlined />;
      }
      case EStatusCodes.Unauthorized:
      case EStatusCodes.UnprocessableEntity: {
        return (
          <Tooltip title={ErrorMessages[statusCode]}>
            <InfoCircleOutlined />
          </Tooltip>
        );
      }
      default: {
        return undefined;
      }
    }
  };

  const validateStatus = () => {
    switch (statusCode) {
      case EStatusCodes.Success: {
        return 'success';
      }
      case EStatusCodes.Unauthorized:
      case EStatusCodes.UnprocessableEntity: {
        return 'error';
      }
      default: {
        return undefined;
      }
    }
  };

  if (statusCode === EStatusCodes.Success) {
    return <Navigate to={getDashboardPathname(EDashboardTabs.Events)} />;
  }

  return (
    <Content>
      <Form className="login-page__wrapper" onFinish={addAuthModel}>
        <p className="title">Login</p>
        <Form.Item name="email" rules={[{ required: true, message: 'required field' }]} validateStatus={validateStatus()}>
          <Input placeholder="E-mail" suffix={renderSuffix()} />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[{ required: true, message: 'required field' }]}
          validateStatus={statusCode !== EStatusCodes.UnprocessableEntity ? validateStatus() : undefined}
        >
          <Input
            placeholder="Password"
            type="password"
            suffix={statusCode !== EStatusCodes.UnprocessableEntity && renderSuffix()}
          />
        </Form.Item>
        <Button className="btn-primary" htmlType="submit">
          Login
        </Button>
      </Form>
    </Content>
  );
};

const mapState = (state: RootState) => ({ statusCode: state.authModel.statusCode });

const mapDispatch = (dispatch: RootDispatch) => ({ addAuthModel: dispatch.authModel.addAuthModel });

export default connect(mapState, mapDispatch)(LoginPage);
