import React, { useEffect, useState } from 'react';
import { useDebounce } from 'common/hooks/useDebounce';
import { DEFAULT_DEBOUNCE_DELAY } from 'common/config';
import { assessmentTransport } from 'entities/Assessment/Assessment.transport';
import { ECcmAssessmentAnswer } from 'entities/Assessment/enums';
import { ICcmAssessment } from 'entities/Assessment/Assessment.models';
import { AssessmentRadio } from 'entities/Assessment/components/AssessmentRadio';
import { AssessmentInput } from 'entities/Assessment/components/AssessmentInput';

interface IComponentProps {
  billingCycleId: string;
  assessment: ICcmAssessment;
  disabled: boolean;
}

export const Assessment: React.FC<IComponentProps> = ({ assessment, billingCycleId, disabled }) => {
  const [answers, setAnswers] = useState<ICcmAssessment>(assessment);

  const handleChange =
    (section: string, key: string, keysToClean: string[] = []) =>
    (e: any) => {
      // @ts-ignore
      keysToClean.forEach((key) => (answers[section][key] = undefined));

      setAnswers({
        ...answers,
        [section]: {
          // @ts-ignore
          ...answers[section],
          // @ts-ignore
          [key]: e.target.value,
        },
      });
    };

  const debouncedAnswers = useDebounce(JSON.stringify(answers), DEFAULT_DEBOUNCE_DELAY);

  useEffect(() => {
    assessmentTransport.updateAssessmentModel(billingCycleId, answers).catch();
  }, [debouncedAnswers]);

  return (
    <div className="assessment">
      <p className="subtitle assessment__title">Assessment</p>
      <p>General</p>
      <div className="patient-details">
        <AssessmentInput
          title="1. How is your pain today?"
          value={answers.general['1']}
          onChange={handleChange('general', '1')}
          disabled={disabled}
        />
      </div>
      <div className="patient-details">
        <AssessmentRadio
          title="2. Do you have a home exercise program?"
          value={answers.general['2']}
          onChange={handleChange('general', '2', ['2.1', '2.2', '2.3', '2.4', '2.5', '2.6', '2.7'])}
          disabled={disabled}
        />
        {answers?.general?.['2'] === ECcmAssessmentAnswer.Yes && (
          <AssessmentRadio
            title="2.1 Have you done your home exercise program today?"
            value={answers.general['2.1']}
            onChange={handleChange('general', '2.1', ['2.2', '2.3', '2.4'])}
            disabled={disabled}
          />
        )}
        {answers?.general?.['2.1'] === ECcmAssessmentAnswer.Yes && (
          <>
            <AssessmentInput
              title="2.2 What did you do for your exercise?"
              value={answers.general['2.2']}
              onChange={handleChange('general', '2.2')}
              disabled={disabled}
            />
            <AssessmentInput
              title="2.3 How long did you exercise?"
              value={answers.general['2.3']}
              onChange={handleChange('general', '2.3')}
              disabled={disabled}
            />
            <AssessmentInput
              title="2.4 How was that for you?"
              value={answers.general['2.4']}
              onChange={handleChange('general', '2.4')}
              disabled={disabled}
            />
          </>
        )}
        {answers?.general?.['2.1'] === ECcmAssessmentAnswer.No && (
          <>
            <AssessmentRadio
              title="2.5 Do you plan on doing it later today?"
              value={answers.general['2.5']}
              onChange={handleChange('general', '2.5')}
              disabled={disabled}
            />
            <AssessmentInput
              title="2.6 How can I motivate you to perform it?"
              value={answers.general['2.6']}
              onChange={handleChange('general', '2.6')}
              disabled={disabled}
            />
          </>
        )}
        {answers?.general?.['2'] === ECcmAssessmentAnswer.No && (
          <AssessmentRadio
            title="2.7 Would you like to schedule an appointment with a provider to come up with a home exercise for you?"
            value={answers.general['2.7']}
            onChange={handleChange('general', '2.7')}
            disabled={disabled}
          />
        )}
      </div>
      <div className="patient-details">
        <AssessmentRadio
          title="3. Do you have a stress reduction tool, something you use daily to help with reducing stress?"
          value={answers.general['3']}
          onChange={handleChange('general', '3', ['3.1', '3.2', '3.3', '3.4', '3.5', '3.6', '3.7'])}
          disabled={disabled}
        />
        {answers?.general?.['3'] === ECcmAssessmentAnswer.No && (
          <AssessmentRadio
            title="3.1 Would you like to learn some techniques to help reduce stress?"
            value={answers.general['3.1']}
            onChange={handleChange('general', '3.1')}
            disabled={disabled}
          />
        )}
        {answers?.general?.['3'] === ECcmAssessmentAnswer.Yes && (
          <>
            <AssessmentInput
              title="3.2 What do you do?"
              value={answers.general['3.2']}
              onChange={handleChange('general', '3.2')}
              disabled={disabled}
            />
            <AssessmentRadio
              title="3.3  Have you done it today?"
              value={answers.general['3.3']}
              onChange={handleChange('general', '3.3', ['3.4', '3.5', '3.6'])}
              disabled={disabled}
            />
          </>
        )}
        {answers?.general?.['3.3'] === ECcmAssessmentAnswer.Yes && (
          <AssessmentInput
            title="3.4 How was it for you?"
            value={answers.general['3.4']}
            onChange={handleChange('general', '3.4')}
            disabled={disabled}
          />
        )}
        {answers?.general?.['3.3'] === ECcmAssessmentAnswer.No && (
          <>
            <AssessmentRadio
              title="3.5 Do you plan on doing it later today?"
              value={answers.general['3.5']}
              onChange={handleChange('general', '3.5')}
              disabled={disabled}
            />
            <AssessmentInput
              title="3.6 How can I motivate you to perform it?"
              value={answers.general['3.6']}
              onChange={handleChange('general', '3.6')}
              disabled={disabled}
            />
          </>
        )}
        {answers?.general?.['3'] && answers.general['3'] !== ECcmAssessmentAnswer.NA && (
          <AssessmentRadio
            title="3.7 Would you like to learn other techniques? "
            value={answers.general['3.7']}
            onChange={handleChange('general', '3.7')}
            disabled={disabled}
          />
        )}
      </div>
      <div className="patient-details">
        <AssessmentRadio
          title="4. Have you been to the emergency room or urgent care clinic since your last visit, or hospitalized for any reason?"
          value={answers.general['4']}
          onChange={handleChange('general', '4', ['4.1', '4.2', '4.3', '4.4', '4.5'])}
          disabled={disabled}
        />
        {answers?.general?.['4'] === ECcmAssessmentAnswer.Yes && (
          <>
            <AssessmentInput
              title="4.1 What was the reason?"
              value={answers.general['4.1']}
              onChange={handleChange('general', '4.1')}
              disabled={disabled}
            />
            <AssessmentRadio
              title="4.2 Have you followed up with your primary care provider or appropriate specialist?"
              value={answers.general['4.2']}
              onChange={handleChange('general', '4.2', ['4.3', '4.4', '4.5'])}
              disabled={disabled}
            />
          </>
        )}

        {answers?.general?.['4.2'] === ECcmAssessmentAnswer.Yes && (
          <AssessmentRadio
            title="4.3 Are you following their treatment plan or recommendations?"
            value={answers.general['4.3']}
            onChange={handleChange('general', '4.3', ['4.4', '4.5'])}
            disabled={disabled}
          />
        )}

        {answers?.general?.['4.3'] === ECcmAssessmentAnswer.No && (
          <>
            <AssessmentInput
              title="4.4 Why not?"
              value={answers.general['4.4']}
              onChange={handleChange('general', '4.4')}
              disabled={disabled}
            />
            <AssessmentInput
              title="4.5 How can we help you to follow their treatment plan and recommendations?"
              value={answers.general['4.5']}
              onChange={handleChange('general', '4.5')}
              disabled={disabled}
            />
          </>
        )}

        {answers?.general?.['4.2'] === ECcmAssessmentAnswer.No && (
          <>
            <AssessmentInput
              title="4.6 Why not?"
              value={answers.general['4.6']}
              onChange={handleChange('general', '4.6')}
              disabled={disabled}
            />
            <AssessmentRadio
              title="4.7 Do you need help making the appropriate follow up appointments?"
              value={answers.general['4.7']}
              onChange={handleChange('general', '4.7')}
              disabled={disabled}
            />
          </>
        )}
      </div>
      <p>Medication</p>
      <div className="patient-details">
        <AssessmentRadio
          title="1. Do you take medications for pain?"
          value={answers.medication['1']}
          onChange={handleChange('medication', '1', ['1.1', '1.2', '1.4'])}
          disabled={disabled}
        />
        {answers?.medication?.['1'] === ECcmAssessmentAnswer.Yes && (
          <AssessmentRadio
            title="1.1 Have you taken your medication today?"
            value={answers.medication['1.1']}
            onChange={handleChange('medication', '1.1', ['1.2', '1.4'])}
            disabled={disabled}
          />
        )}
        {answers?.medication['1.1'] === ECcmAssessmentAnswer.Yes && (
          <AssessmentRadio
            title="1.2 Does your medication help?"
            value={answers.medication['1.2']}
            onChange={handleChange('medication', '1.2')}
            disabled={disabled}
          />
        )}
        {answers?.medication['1.2'] === ECcmAssessmentAnswer.No && (
          <AssessmentRadio
            title="1.3 Would you like to make an appointment with your provider to discuss medications?"
            value={answers.medication['1.3']}
            onChange={handleChange('medication', '1.3')}
            disabled={disabled}
          />
        )}
        {answers?.medication['1.1'] === ECcmAssessmentAnswer.No && (
          <AssessmentInput
            title="1.4 Why have you not taken it today?"
            value={answers.medication['1.4']}
            onChange={handleChange('medication', '1.4')}
            disabled={disabled}
          />
        )}
        {answers?.medication['1'] === ECcmAssessmentAnswer.No && (
          <AssessmentInput
            title="1.5 Why have you not taken it today?"
            value={answers.medication['1.5']}
            onChange={handleChange('medication', '1.5')}
            disabled={disabled}
          />
        )}
      </div>
      <p>Referrals</p>
      <div className="patient-details">
        <p>
          Your provider has referred you to ____________________. (PT, OT, consultation, psych, CBT, sleep class, stress class,
          PEP, FRP, etc).
        </p>
        <AssessmentRadio
          title="1. Have you made an appointment for that visit?"
          value={answers.referrals['1']}
          onChange={handleChange('referrals', '1', ['1.1', '1.2', '1.3', '1.4'])}
          disabled={disabled}
        />
        {answers?.referrals?.['1'] === ECcmAssessmentAnswer.No && (
          <AssessmentRadio
            title="1.1 Would you like help in scheduling that appointment?"
            value={answers.referrals['1.1']}
            onChange={handleChange('referrals', '1.1', ['1.2', '1.3', '1.4'])}
            disabled={disabled}
          />
        )}
        {answers?.referrals?.['1'] === ECcmAssessmentAnswer.Yes && (
          <AssessmentRadio
            title="1.2 Have you gone to the appointment or started the recommended therapy?"
            value={answers.referrals['1.2']}
            onChange={handleChange('referrals', '1.2', ['1.3', '1.4'])}
            disabled={disabled}
          />
        )}
        {answers?.referrals?.['1.2'] === ECcmAssessmentAnswer.Yes && (
          <AssessmentInput
            title="1.3 How is it going for you?"
            value={answers.referrals['1.3']}
            onChange={handleChange('referrals', '1.3')}
            disabled={disabled}
          />
        )}
        {answers?.referrals?.['1.2'] === ECcmAssessmentAnswer.No && (
          <AssessmentInput
            title="1.4 When is your appointment?"
            value={answers.referrals['1.4']}
            onChange={handleChange('referrals', '1.4')}
            disabled={disabled}
          />
        )}
      </div>
      <p>Injections/Procedures</p>
      <div className="patient-details">
        <p>
          Your provider has recommended the following procedure _______________. (Epidural, medical branch block, RFA, trigger
          point injection, SCS trial, etc.)
        </p>
        <AssessmentRadio
          title="1. Have you made an appointment for that procedure?"
          value={answers.procedures['1']}
          onChange={handleChange('procedures', '1', ['1.1', '1.2'])}
          disabled={disabled}
        />
        {answers?.procedures?.['1'] === ECcmAssessmentAnswer.No && (
          <AssessmentRadio
            title="1.1 Would you like help in scheduling that appointment?"
            value={answers.procedures['1.1']}
            onChange={handleChange('procedures', '1.1', ['1.2'])}
            disabled={disabled}
          />
        )}
        {answers?.procedures?.['1'] === ECcmAssessmentAnswer.Yes && (
          <AssessmentRadio
            title="1.2 Have you had the procedure already?"
            value={answers.procedures['1.2']}
            onChange={handleChange('procedures', '1.2')}
            disabled={disabled}
          />
        )}
        {answers?.procedures?.['1.2'] === ECcmAssessmentAnswer.Yes && (
          <AssessmentInput
            title="1.3 How did the procedure go?"
            value={answers.procedures['1.3']}
            onChange={handleChange('procedures', '1.3')}
            disabled={disabled}
          />
        )}
        {answers?.procedures?.['1.2'] === ECcmAssessmentAnswer.No && (
          <AssessmentInput
            title="1.4 When is the appointment?"
            value={answers.procedures['1.4']}
            onChange={handleChange('procedures', '1.4')}
            disabled={disabled}
          />
        )}
      </div>
    </div>
  );
};
