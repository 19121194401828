import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Row, Spin } from 'antd';
import { RootDispatch, RootState } from 'app/store';
import { PatientDetails } from 'entities/Patient/components/PatientDetails';
import { ICCMPatientModel } from 'entities/Patient/Patient.models';
import { PatientVerbalConsent } from 'entities/Patient/components/PatientVerbalConsent';
import { PatientHistory } from 'entities/Patient/components/PatientHistory';
import { Diagnoses } from 'entities/Diagnoses/components/Diagnoses';
import { IDiagnosesItem } from 'entities/Diagnoses/Diagnoses.models';

interface IComponentProps {
  ccmPatientModelData: ICCMPatientModel;
}

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch> & IComponentProps;

const EnrollmentPatientDetailsContainerComponent: React.FC<AllType> = (props) => {
  const {
    ccmPatientModelData: { id: patientId, name: patientName },
    patientCcdaModel: { data: patientCcdaData },
    getPatientCcdaModel,
    addCcmDiagnosesCollection,
    setMultiselectDiagnosesModalParams,
  } = props;

  const onSave = (diagnoses: IDiagnosesItem[]) => {
    addCcmDiagnosesCollection({
      patientId,
      diagnoses: diagnoses.map((item) => ({ diagnosis: item.displayName, code: item.code })),
      onSuccess: () => {
        setMultiselectDiagnosesModalParams({
          open: false,
          title: null,
          limit: null,
          defaultCcdaDiagnosesValue: null,
          defaultClinicalDiagnosesValue: null,
          defaultIsCcda: true,
          ccdaIsDisabled: false,
        });
      },
    });
  };

  useEffect(() => {
    getPatientCcdaModel(patientId);
  }, []);

  if (!patientCcdaData) {
    return (
      <Row justify="center" align="middle">
        <Spin size="large" />
      </Row>
    );
  }

  return (
    <div className="patient-details">
      <PatientDetails
        details={{
          gender: patientCcdaData.ccda?.gender,
          height: patientCcdaData.ccda?.height,
          weight: patientCcdaData.ccda?.weight,
          dob: patientCcdaData.ccda?.dob,
          ccdaLink: patientCcdaData.link,
        }}
      />

      <div className="patient-details__diagnosis-consent">
        <Diagnoses title="Add more than 1 diagnosis" limit={2} onSave={onSave} />

        <PatientVerbalConsent patientId={patientId} canEdit={true} />
      </div>

      <PatientHistory patientId={patientId} patientName={patientName} />
    </div>
  );
};

const mapState = (state: RootState) => ({
  patientCcdaModel: state.patientCcdaModel,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  getPatientCcdaModel: dispatch.patientCcdaModel.getPatientCcdaModel,
  addCcmDiagnosesCollection: dispatch.diagnosesCollection.addCcmDiagnosesCollection,
  setMultiselectDiagnosesModalParams: dispatch.modals.setMultiselectDiagnosesModalParams,
});

export const EnrollmentPatientDetailsContainer = connect(mapState, mapDispatch)(EnrollmentPatientDetailsContainerComponent);
