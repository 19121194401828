import { createModel } from '@rematch/core';
import { IRootModel } from 'app/store';
import { twilioTransport } from 'entities/Twilio/Twilio.transport';
import { ITwilioPayload } from 'entities/Twilio/Twilio.models';

export const twilioModel = createModel<IRootModel>()({
  state: {
    loading: false,
  },
  reducers: {
    setTwilioModelLoading: (state, payload: boolean) => ({ ...state, loading: payload }),
  },
  effects: (dispatch) => ({
    async getTwilioModel(payload: ITwilioPayload) {
      dispatch.twilioModel.setTwilioModelLoading(true);
      return await twilioTransport
        .getTwilioModel()
        .then((response) => {
          if (payload.onSuccess) {
            payload.onSuccess();
          }

          return response.token;
        })
        .finally(() => {
          dispatch.twilioModel.setTwilioModelLoading(false);
        });
    },
  }),
});
