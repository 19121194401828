import React, { useEffect, useState } from 'react';
import { Button, Checkbox, Pagination, Table } from 'antd';
import { SendOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { ESearchParams, EStatuses } from 'common/const/enums.const';
import { DEFAULT_PAGE_ID, DEFAULT_PAGE_SIZE } from 'common/config';
import { sort } from 'common/helpers/sorter.helper';
import { SearchInput } from 'common/components/SearchInput';
import { DatePicker } from 'common/components/DatePicker';
import { Menu } from 'common/components/Menu';
import { useSearchParams } from 'common/hooks/useSearchParams';
import { RootDispatch, RootState } from 'app/store';
import { IBillingCycleModel, IBillingCycleQueryParameters } from 'entities/Billing/Billing.models';
import { billingQueryParamsTo } from 'entities/Billing/Billing.mappers';
import { toDataSourseMapper } from 'entities/Dashboard/Dashboard.helper';
import { getBillingSearchParams, renderBillingRecords } from 'entities/Billing/Billing.helper';
import { billingMenuItems } from 'entities/Billing/Billing.const';

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

const BillingBody: React.FC<AllType> = (props) => {
  const {
    billingCollection,
    getBillingCollection,
    getBillingEventsByIdCollection,
    getBillingReport,
    sendBillingCycleIds,
    setBillingDetailsModalParams,
  } = props;
  const { searchParams, setSearchParam, removeSearchParam, setSearchParamWithCondition } = useSearchParams();
  const [pageId, setPageId] = useState<number>(DEFAULT_PAGE_ID);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [collectionParams, setCollectionParams] = useState<IBillingCycleQueryParameters>();
  const { data: billingData, loading } = billingCollection;

  const params = getBillingSearchParams(searchParams);
  const dataSource = toDataSourseMapper(billingData?.data);

  const { status, search, dateFrom, dateTo, orderField, orderDirection } = params;

  const searchValueChange = (value: string) => {
    setSearchParamWithCondition(ESearchParams.Search, value);
    setPageId(DEFAULT_PAGE_ID);
  };

  const dateRangeValueChange = (values: { [key: string]: string | undefined }[]) => {
    values.forEach((item) => {
      const [key, value] = Object.entries(item).flat() as [string, string | undefined];
      setSearchParamWithCondition(key, value);
    });
    setPageId(DEFAULT_PAGE_ID);
  };

  const menuTabChange = (value: string) => {
    removeSearchParam(ESearchParams.OrderField);
    removeSearchParam(ESearchParams.OrderDirection);
    setSearchParam(ESearchParams.Status, value);
    setPageId(DEFAULT_PAGE_ID);
  };

  const onExportClick = () => {
    const exportParams = billingQueryParamsTo(params);

    getBillingReport(exportParams);
  };

  const sendToEMR = async (billingCycleIds: number[]): Promise<unknown> => {
    return await sendBillingCycleIds({ billingCycleIds, params: collectionParams as IBillingCycleQueryParameters }).then(() => {
      setSelectedRowKeys([]);
    });
  };

  const onCell = (record: IBillingCycleModel) => {
    return {
      onClick: () => {
        getBillingEventsByIdCollection(record.id).then((response) => {
          setBillingDetailsModalParams({
            open: true,
            billingCycleModel: record,
            events: response,
            hasDiagnosis: record.hasDiagnosis,
            sendToEMR: Object.keys(EStatuses)[params.status as number] === EStatuses.Completed && sendToEMR,
          });
        });
      },
    };
  };

  const handleSort = (pagination: any, filters: any, sorter: any) => {
    sort(sorter, setSearchParam, removeSearchParam);
  };

  useEffect(() => {
    const queryParams = billingQueryParamsTo(params, pageId);

    setCollectionParams(queryParams);
    getBillingCollection(queryParams);
  }, [status, search, dateFrom, dateTo, orderField, orderDirection, pageId]);

  useEffect(() => {
    setSelectedRowKeys([]);
  }, [status]);

  return (
    <>
      <div>
        <div className="filter">
          <div className="filter__row">
            <SearchInput value={search} onChange={searchValueChange} placeholder="Search by patient / MRN" />

            <DatePicker dateFrom={dateFrom} dateTo={dateTo} onChange={dateRangeValueChange} />
          </div>

          <div className="filter__row">
            <Menu items={billingMenuItems} selectedKeys={[status.toString()]} onChange={menuTabChange} />

            {(Object.keys(EStatuses)[status] === EStatuses.Completed || Object.keys(EStatuses)[status] === EStatuses.Closed) && (
              <div className="filter__btn_wrapper">
                <Button onClick={onExportClick}>Export to CSV</Button>
              </div>
            )}
          </div>
        </div>

        <Table
          className="billing-body__table"
          rowSelection={{
            selectedRowKeys,
            onChange: (newSelectedRowKeys) => setSelectedRowKeys(newSelectedRowKeys),
            getCheckboxProps: (record) => ({
              disabled: !record.hasDiagnosis,
            }),
            renderCell: (checked, record, index, originNode) => {
              return status === Object.keys(EStatuses).indexOf(EStatuses.Completed) && originNode;
            },
            hideSelectAll: status === Object.keys(EStatuses).indexOf(EStatuses.Completed) ? false : true,
          }}
          columns={renderBillingRecords(sendToEMR, onCell, status)}
          dataSource={dataSource}
          pagination={false}
          size="small"
          loading={loading}
          onChange={handleSort}
        />

        <Pagination
          current={pageId}
          total={billingData?.meta.count}
          showSizeChanger={false}
          onChange={(page: number) => setPageId(page)}
          defaultPageSize={DEFAULT_PAGE_SIZE}
        />
      </div>

      {!!selectedRowKeys.length && (
        <div className="billing-body__footer">
          <div className="billing-body__footer_container">
            <Checkbox checked>Selected {selectedRowKeys.length} reports</Checkbox>
            <Button className="btn-primary" icon={<SendOutlined />} onClick={() => sendToEMR(selectedRowKeys as number[])}>
              Send selected to EMR
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

const mapState = (state: RootState) => ({
  billingCollection: state.billingCollection,
});

const mapDispatch = (dispatch: RootDispatch) => ({
  getBillingCollection: dispatch.billingCollection.getBillingCollection,
  getBillingEventsByIdCollection: dispatch.billingCollection.getBillingEventsByIdCollection,
  getBillingReport: dispatch.billingCollection.getBillingReport,
  sendBillingCycleIds: dispatch.billingCollection.sendBillingCycleIds,
  setBillingDetailsModalParams: dispatch.modals.setBillingDetailsModalParams,
});

export default connect(mapState, mapDispatch)(BillingBody);
