import React from 'react';
import { EnrollmentActionsCard } from 'entities/Actions/components/EnrollmentActionsCard';
import { EnrollmentReviewHistory } from 'entities/ReviewHistory/components/EnrollmentReviewHistory';

interface IComponentProps {
  enrollmentId?: string;
}

export const EnrollmentPanel: React.FC<IComponentProps> = ({ enrollmentId }) => {
  return (
    <>
      <EnrollmentActionsCard enrollmentId={enrollmentId} />

      <EnrollmentReviewHistory enrollmentId={enrollmentId} />
    </>
  );
};
