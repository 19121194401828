import { EEnrollmentsStatuses } from 'common/const/enums.const';

export const getEnrollmentAvailableOperations = (status?: string, currentUserName?: string, assignee?: string) => {
  let canReview = false;
  let canBeMovedToInbox = false;
  let canBeCompleted = false;
  let canBeCanceled = false;
  let canStart = false;
  let controlsAreVisible = false;
  let canCall = false;
  let canChangeTaskStatus = false;

  if (status === EEnrollmentsStatuses.Inbox) {
    canReview = true;
  }

  if (currentUserName === assignee && status === EEnrollmentsStatuses.UnderReview) {
    canBeMovedToInbox = true;
    canBeCompleted = true;
    canBeCanceled = true;
    canStart = true;
    controlsAreVisible = true;
    canCall = true;
    canChangeTaskStatus = true;
  }

  if (currentUserName !== assignee && status === EEnrollmentsStatuses.UnderReview) {
    controlsAreVisible = true;
    canCall = true;
  }

  if (status === EEnrollmentsStatuses.Completed) {
    controlsAreVisible = true;
    canCall = true;
  }

  return {
    canReview,
    canBeMovedToInbox,
    canBeCompleted,
    canBeCanceled,
    canStart,
    controlsAreVisible,
    canCall,
    canChangeTaskStatus,
  };
};
