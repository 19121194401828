import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Button, Modal, Spin } from 'antd';
import { Editor } from 'common/components/Editor';
import { RootState } from 'app/store';

type AllType = ReturnType<typeof mapState>;

const CarePlanModalComponent: React.FC<AllType> = ({ carePlanModalParams, loading }) => {
  const { open, title, description, onSaveClick, onCancelClick } = carePlanModalParams;
  const [value, setValue] = useState<string>('');

  const handleCancelClick = () => {
    setValue('');
    onCancelClick?.();
  };

  const handleSaveClick = () => {
    onSaveClick?.(value);
  };

  useEffect(() => {
    if (description) {
      setValue(description);
    }
  }, [description]);

  return (
    <Modal title={title} open={open} footer={false} onCancel={handleCancelClick} width={800}>
      <Spin spinning={loading}>
        <div className="modal__body">
          <Editor value={value} onChange={setValue} />
        </div>

        <div className="modal__footer">
          <Button className="btn-primary" onClick={handleSaveClick} disabled={!value.length}>
            Save
          </Button>

          <Button onClick={handleCancelClick}>Cancel</Button>
        </div>
      </Spin>
    </Modal>
  );
};

const mapState = (state: RootState) => ({
  carePlanModalParams: state.modals.carePlanModalParams,
  loading: state.carePlanModel.loading,
});

export const CarePlanModal = connect(mapState)(CarePlanModalComponent);
