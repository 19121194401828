import React from 'react';
import { Spin } from 'antd';

interface IComponentProps {
  loading?: boolean;
  children: React.ReactNode;
}

export const ReviewHistory: React.FC<IComponentProps> = ({ loading, children }) => {
  return (
    <div className="history-card">
      <p className="title history-card__title">Review history</p>

      <Spin spinning={loading}>
        <div className="history-card__wrapper">{children}</div>
      </Spin>
    </div>
  );
};
