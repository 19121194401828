import { createModel } from '@rematch/core';
import { EEventStatus } from 'common/const/enums.const';
import { IRootModel, history } from 'app/store';
import { actionsTransport } from 'entities/Actions/Actions.transport';
import { IActionParams, IActionRequestParams, ICCMActionPayload } from 'entities/Actions/Actions.models';
import { eventTasksToDataMapper } from 'entities/Tasks/Tasks.mapper';

export const actionModel = createModel<IRootModel>()({
  state: {
    actionParams: {
      type: '',
    } as IActionParams,
    loading: false,
  },
  reducers: {
    setActionParams: (state, payload: IActionParams) => ({ ...state, actionParams: payload }),
    setLoading: (state, paylod: boolean) => ({ ...state, loading: paylod }),
  },
  effects: (dispatch) => ({
    async reviewEvent(eventId: number) {
      dispatch.actionModel.setLoading(true);
      return actionsTransport
        .reviewEvent(eventId)
        .then((response) => {
          return dispatch.patientModel.getPatientModel(eventId).then(() => {
            dispatch.eventModel.setEventModel({ ...response, eventId: eventId, eventStatus: response.status as EEventStatus });
            dispatch.tasksCollection.setTasksCollection(eventTasksToDataMapper(response.tasks));
          });
        })
        .finally(() => {
          dispatch.actionModel.setLoading(false);
        });
    },
    async returnEvent(eventId: number) {
      dispatch.actionModel.setLoading(true);
      return actionsTransport.returnEvent(eventId).finally(() => {
        dispatch.actionModel.setLoading(false);
      });
    },
    async escalateEvent(params: IActionRequestParams) {
      dispatch.actionModel.setLoading(true);
      return actionsTransport.escalateEvent(params).finally(() => {
        dispatch.actionModel.setLoading(false);
      });
    },
    async commentEvent(params: IActionRequestParams) {
      dispatch.actionModel.setLoading(true);
      actionsTransport
        .commentEvent(params)
        .then(() => {
          dispatch.eventReviewHistoryModel.getEventReviewHistoryModel(params.eventId);
        })
        .finally(() => {
          dispatch.actionModel.setLoading(false);
        });
    },
    async completeEvent(params: IActionRequestParams) {
      dispatch.actionModel.setLoading(true);
      return actionsTransport.completeEvent(params).finally(() => {
        dispatch.actionModel.setLoading(false);
      });
    },
    async changeEnrollmentStatus(payload: ICCMActionPayload) {
      dispatch.actionModel.setLoading(true);
      return actionsTransport
        .changeEnrollmentStatus(payload)
        .then(() => {
          dispatch.enrollmentModel.clearPingEnrollmentModel();
        })
        .catch((error) => {
          if (error.status === 400) {
            history.back();
          }
        })
        .finally(() => {
          dispatch.actionModel.setLoading(false);
        });
    },
  }),
});
