import React from 'react';

interface IComponentProps {
  label: string;
  value?: string | number;
}

export const PatientDetailItem: React.FC<IComponentProps> = ({ label, value }) => (
  <span>
    <span className="patient-details__item">{label}:</span>
    <span>{value}</span>
  </span>
);
