import React, { useCallback } from 'react';
import { Button, Modal } from 'antd';
import { connect } from 'react-redux';
import { RouterPrompt } from 'common/components/RouterPrompt';
import { DEFAULT_MODAL_WIDTH } from 'common/config';
import { convertToTimerFormat } from 'common/helpers/time.helper';
import { ITransition } from 'common/models';
import { EActionTypes, EEventStatus, ERole } from 'common/const/enums.const';
import { useBeforeUnload } from 'common/hooks/useBeforeUnload';
import { useBlocker } from 'common/hooks/useBlocker';
import { RootDispatch, RootState } from 'app/store';

interface IComponentProps {
  eventId: number;
  eventStatus?: EEventStatus;
  blockRouter: boolean;
}

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch> & IComponentProps;

const EventRouterHandlerComponent: React.FC<AllType> = (props) => {
  const {
    eventId,
    eventStatus,
    blockRouter,
    authModel,
    eventModel,
    eventTimerModel,
    actionModel,
    stopEventTimer,
    deleteEventTimerModel,
    returnEvent,
    escalateEvent,
    completeEvent,
    setNotificationModalParams,
    showEventInactiveModal,
  } = props;
  const { data: authModelData } = authModel;
  const { isRunning, untrackedTime } = eventTimerModel;
  const { actionParams } = actionModel;

  const role = authModelData?.access?.role;
  const currentUserUid = authModelData?.access?.uid;
  const assigneeUid = eventModel?.assigneeUid;

  useBeforeUnload([isRunning], () => {
    isRunning && stopEventTimer(eventId);
  });

  const blocker = useCallback(
    (autoUnblockingTx: ITransition) => {
      const text = actionParams.text ? actionParams.text : '';

      switch (true) {
        case actionParams.type === EActionTypes.Move: {
          returnEvent(eventId).then(() => {
            autoUnblockingTx.retry();
          });
          break;
        }
        case actionParams.type === EActionTypes.Escalate: {
          escalateEvent({ eventId, text, physicianId: actionParams.physicianId }).then(() => {
            autoUnblockingTx.retry();
          });
          break;
        }
        case actionParams.type === EActionTypes.Complete: {
          completeEvent({ eventId, text }).then(() => {
            autoUnblockingTx.retry();
          });
          break;
        }
        case blockRouter && autoUnblockingTx.action === 'PUSH':
        case blockRouter && autoUnblockingTx.action === 'POP' && !actionParams.type:
        case blockRouter && actionParams.type === EActionTypes.Back: {
          showEventInactiveModal({ open: false, title: null, description: null });
          if (role === ERole.Staff) {
            if (eventStatus !== EEventStatus.UnderReview) {
              autoUnblockingTx.retry();
              break;
            }

            if (currentUserUid === assigneeUid) {
              returnEvent(eventId).then(() => {
                autoUnblockingTx.retry();
              });
            } else {
              escalateEvent({ eventId, text: '' }).then(() => {
                autoUnblockingTx.retry();
              });
            }
          }

          if (role === ERole.Physician) {
            escalateEvent({ eventId, text: '' }).then(() => {
              autoUnblockingTx.retry();
            });
          }
          break;
        }
        default: {
          autoUnblockingTx.retry();
          break;
        }
      }
    },
    [eventStatus, blockRouter, actionParams.type, actionParams.text, currentUserUid, assigneeUid]
  );

  useBlocker(blockRouter && !isRunning, blocker);

  const renderPrompt = (isActive: boolean, onConfirm?: (value: unknown) => void, onCancel?: (value: unknown) => void) => {
    setNotificationModalParams({ onCancel });

    return (
      isActive && (
        <Modal title="New Notification" open={true} footer={false} onCancel={onCancel} width={DEFAULT_MODAL_WIDTH}>
          <div className="modal__body">
            <p>
              From the review start you’ve spent {convertToTimerFormat(untrackedTime)}, but haven’t stopped the timer. Save time
              or delete?
            </p>
          </div>

          <div className="modal__footer">
            <Button
              className="btn-primary"
              onClick={() => {
                stopEventTimer(eventId).then(() => {
                  onConfirm?.(undefined);
                });
              }}
            >
              Save and Leave
            </Button>
            <Button
              className="btn-orange"
              onClick={() => {
                deleteEventTimerModel(eventId).then(() => {
                  onConfirm?.(undefined);
                });
              }}
            >
              Delete and Leave
            </Button>
            <Button onClick={onCancel}>Cancel</Button>
          </div>
        </Modal>
      )
    );
  };

  return <RouterPrompt when={isRunning} renderPrompt={renderPrompt} />;
};

const mapState = (state: RootState) => ({
  authModel: state.authModel,
  eventModel: state.eventModel,
  eventTimerModel: state.eventTimerModel,
  actionModel: state.actionModel,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  stopEventTimer: dispatch.eventTimerModel.stopEventTimer,
  deleteEventTimerModel: dispatch.eventTimerModel.deleteEventTimerModel,
  returnEvent: dispatch.actionModel.returnEvent,
  escalateEvent: dispatch.actionModel.escalateEvent,
  completeEvent: dispatch.actionModel.completeEvent,
  setNotificationModalParams: dispatch.modals.setNotificationModalParams,
  showEventInactiveModal: dispatch.modals.showEventInactiveModal,
});

export const EventRouterHandler = connect(mapState, mapDispatch)(EventRouterHandlerComponent);
