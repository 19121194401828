import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { v4 as uuid } from 'uuid';
import { useParams } from 'react-router-dom';
import { Row, Spin } from 'antd';
import moment from 'moment';
import { EActionTypes, EDateFormat, EEventType, ERoutes } from 'common/const/enums.const';
import { CommonContentLayout } from 'common/components/CommonContentLayout';
import { NavigationSubheader } from 'common/components/NavigationSubheader';
import { RootDispatch, RootState, history } from 'app/store';
import EventBody from 'entities/Event/components/EventBody';
import EventPanel from 'entities/Event/components/EventPanel';
import TimeEditorModal from 'entities/Modals/components/TimeEditorModal';
import ExportedEventModal from 'entities/Modals/components/ExportedEventModal';
import CallModal from 'entities/Modals/components/CallModal';
import EscalateModal from 'entities/Modals/components/EscalateModal';
import CommentModal from 'entities/Modals/components/CommentModal';
import EditPhysicianTimeModal from 'entities/Modals/components/EditPhysicianTimeModal';
import CompleteModal from 'entities/Modals/components/CompleteModal';
import { EventRouterHandler } from 'entities/Event/components/EventRouterHandler';
import PatientHistoryModal from 'entities/Modals/components/PatientHistoryModal';
import { InactiveModal } from 'entities/Modals/components/InactiveModal';
import { MultiselectDiagnosisModal } from 'entities/Modals/components/MultiselectDiagnosesModal';
import { getEMEventAvailableOperations } from 'entities/Event/Event.helper';
import { EmptyTimeRecordModal } from 'entities/Modals/components/EmptyTimeRecordModal';

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

const Event: React.FC<AllType> = (props) => {
  const { authModel, actionModel, eventModel, patientModel, getEventModel, clearModels, setActionParams } = props;
  const { loading: actionModelLoading } = actionModel;
  const currentUserId = authModel.data?.access?.userId;
  const reviewerId = eventModel?.reviewerId;
  const eventStatus = eventModel?.eventStatus;
  const eventIsBilled = eventModel?.isBilled;

  const { id } = useParams();
  const eventId = Number(id);
  const autoStartTimer = new URLSearchParams(window.location.search).get('autoStartTimer') === 'true';
  const isBillingSource = new URLSearchParams(window.location.search).get('source') === 'billing';
  const { blockRouter } = getEMEventAvailableOperations({ currentUserId, reviewerId, eventStatus, eventIsBilled });

  const modals = [
    { key: uuid(), modal: <InactiveModal /> },
    { key: uuid(), modal: <TimeEditorModal /> },
    { key: uuid(), modal: <ExportedEventModal /> },
    { key: uuid(), modal: <CallModal /> },
    { key: uuid(), modal: <EscalateModal /> },
    { key: uuid(), modal: <CommentModal /> },
    { key: uuid(), modal: <EditPhysicianTimeModal /> },
    { key: uuid(), modal: <CompleteModal /> },
    { key: uuid(), modal: <MultiselectDiagnosisModal /> },
    { key: uuid(), modal: <PatientHistoryModal /> },
    { key: uuid(), modal: <EmptyTimeRecordModal /> },
  ];

  const onBackClick = () => {
    setActionParams({ type: EActionTypes.Back });

    if (isBillingSource) {
      history.push(ERoutes.EMBilling);
    } else {
      history.back();
    }
  };

  useEffect(() => {
    if (!eventModel) {
      getEventModel(eventId);
    }
  }, [eventModel]);

  useEffect(() => {
    return () => {
      setActionParams({ type: '', text: undefined });
      clearModels();
    };
  }, []);

  if (!eventModel || !patientModel) {
    return (
      <Row justify="center" align="middle">
        <Spin size="large" />
      </Row>
    );
  }

  return (
    <Spin spinning={actionModelLoading}>
      <CommonContentLayout
        subheader={
          <NavigationSubheader
            link="Dashboard"
            description={`${EEventType.DiarySubmission} - ${moment(eventModel.date).format(EDateFormat.ShortYear12hm)}`}
            onClick={onBackClick}
          />
        }
        body={<EventBody eventModel={eventModel} patientModel={patientModel} eventStatus={eventStatus} />}
        panel={<EventPanel eventId={eventId} autoStartTimer={autoStartTimer} isBillingSource={isBillingSource} />}
        modals={modals}
      />

      <EventRouterHandler eventId={eventId} eventStatus={eventStatus} blockRouter={blockRouter} />
    </Spin>
  );
};

const mapState = (state: RootState) => ({
  authModel: state.authModel,
  actionModel: state.actionModel,
  eventModel: state.eventModel,
  patientModel: state.patientModel,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  getEventModel: dispatch.eventModel.getEventModel,
  clearModels: dispatch.eventModel.clearModels,
  setActionParams: dispatch.actionModel.setActionParams,
});

export default connect(mapState, mapDispatch)(Event);
