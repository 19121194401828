import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Table } from 'antd';
import { RootDispatch, RootState } from 'app/store';
import {
  convertToPromisDataSource,
  getPromisFunctionDomains,
  promisColumns,
  promisSymptomDomains,
} from 'entities/Promis/Promis.helper';

interface IComponentProps {
  patientId: string;
}

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch> & IComponentProps;

const PromisComponent: React.FC<AllType> = (props) => {
  const { patientId, promisCollection, getPromisCollection } = props;
  const { data } = promisCollection;

  const promisFunctionDomains = getPromisFunctionDomains(data?.testData);
  const promisSymptomDomainsData = convertToPromisDataSource(promisSymptomDomains, data?.testData);
  const promisFunctionDomainsData = convertToPromisDataSource(promisFunctionDomains, data?.testData);

  useEffect(() => {
    if (patientId) {
      getPromisCollection(patientId);
    }
  }, [patientId]);

  if (!data) {
    return null;
  }

  return (
    <div className="promis">
      <p className="subtitle">PROMIS symptom domains</p>
      <p className="promis__description">Lower scores/percentiles indicate less symptom severity</p>
      <Table columns={promisColumns} dataSource={promisSymptomDomainsData} pagination={false} />

      <p className="subtitle">PROMIS function domains</p>
      <p className="promis__description">Higher scores/percentiles indicate greater social participation/physical function</p>
      <Table columns={promisColumns} dataSource={promisFunctionDomainsData} pagination={false} />
    </div>
  );
};

const mapState = (state: RootState) => ({
  promisCollection: state.promisCollection,
});

const mapDispatch = (dispatch: RootDispatch) => ({
  getPromisCollection: dispatch.promisCollection.getPromisCollection,
});

export const Promis = connect(mapState, mapDispatch)(PromisComponent);
