import React, { useEffect, useState } from 'react';
import { Button, Modal, Radio, RadioChangeEvent, Select, Spin } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { DefaultOptionType } from 'antd/lib/select';
import { connect } from 'react-redux';
import { DEFAULT_MODAL_WIDTH } from 'common/config';
import { RootState } from 'app/store';
import { getDiagnosisName } from 'entities/Diagnoses/Diagnoses.helper';
import { IDiagnosesItem } from 'entities/Diagnoses/Diagnoses.models';

type AllType = ReturnType<typeof mapState>;

const MultiselectDiagnosisModalComponent: React.FC<AllType> = ({
  ccdaDiagnosesCollection,
  clinicalDiagnosesCollection,
  multiselectDiagnosesModalParams,
  loading,
}) => {
  const {
    open,
    title,
    limit,
    defaultCcdaDiagnosesValue,
    defaultClinicalDiagnosesValue,
    defaultIsCcda,
    ccdaIsDisabled,
    searchClinicalDiagnoses,
    onSave,
    onCancel,
  } = multiselectDiagnosesModalParams;
  const { data: ccdaDiagnosesData } = ccdaDiagnosesCollection;
  const { data: clinicalDiagnosesData, loading: clinicalDiagnosesLoading } = clinicalDiagnosesCollection;
  const [isCcda, setIsCcda] = useState<boolean>(true);
  const [selectedDiagnosis, setSelectedDiagnosis] = useState<IDiagnosesItem>();
  const [ccdaDiagnosesList, setCcdaDiagnosesList] = useState<IDiagnosesItem[]>([]);
  const [clinicalDiagnosesList, setClinicalDiagnosesList] = useState<IDiagnosesItem[]>([]);

  const handleDiagnosisTypeChange = (e: RadioChangeEvent) => {
    setIsCcda(e.target.value);
    setSelectedDiagnosis(undefined);
  };

  const onSearch = (value: string) => {
    if (!isCcda) {
      searchClinicalDiagnoses?.(value);
    }
  };

  const handleDiagnosisChange = (value: string) => {
    setSelectedDiagnosis(JSON.parse(value));
  };

  const filterOption = (input: string, option?: DefaultOptionType) => {
    if (isCcda && option?.value) {
      const value = getDiagnosisName(JSON.parse(option.value as string));
      return value.toLocaleString().includes(input.toLowerCase());
    } else {
      return true;
    }
  };

  const addDiagnosis = () => {
    if (!selectedDiagnosis) {
      return;
    }

    if (isCcda && ccdaDiagnosesList.every((item) => item.code !== selectedDiagnosis.code)) {
      setCcdaDiagnosesList([...ccdaDiagnosesList, selectedDiagnosis]);
    }

    if (!isCcda && clinicalDiagnosesList.every((item) => item.code !== selectedDiagnosis.code)) {
      setClinicalDiagnosesList([...clinicalDiagnosesList, selectedDiagnosis]);
    }

    setSelectedDiagnosis(undefined);
  };

  const removeDiagnosis = (value: IDiagnosesItem) => {
    const list = isCcda ? ccdaDiagnosesList : clinicalDiagnosesList;
    const filterdList = list.filter((item) => item.code !== value.code);
    isCcda ? setCcdaDiagnosesList(filterdList) : setClinicalDiagnosesList(filterdList);
  };

  const getOptions = () => {
    const items = isCcda ? ccdaDiagnosesData : clinicalDiagnosesData;
    return items.map((item) => ({ label: getDiagnosisName(item), value: JSON.stringify(item) }));
  };

  const getDiagnosesList = () => {
    const list = isCcda ? ccdaDiagnosesList : clinicalDiagnosesList;
    return list.map((item, index) => (
      <div key={index} className="patient-details__diagnosis-modal_diagnoses-list-item">
        {getDiagnosisName(item)}
        <Button
          className="patient-details__diagnosis-modal_remove-btn"
          onClick={() => removeDiagnosis(item)}
          icon={<PlusOutlined />}
        />
      </div>
    ));
  };

  const setDefaultState = () => {
    setIsCcda(defaultIsCcda);
    setCcdaDiagnosesList(defaultCcdaDiagnosesValue || []);
    setClinicalDiagnosesList(defaultClinicalDiagnosesValue || []);
  };

  const handleCancel = () => {
    setSelectedDiagnosis(undefined);
    setDefaultState();
    onCancel?.();
  };

  const handleSaveClick = () => {
    const diagnoses = ccdaDiagnosesList.concat(clinicalDiagnosesList);
    onSave?.(diagnoses);
  };

  const disableSave = () => {
    return ccdaDiagnosesList.concat(clinicalDiagnosesList).length < Number(limit);
  };

  useEffect(() => {
    setDefaultState();
  }, [defaultCcdaDiagnosesValue, defaultClinicalDiagnosesValue, defaultIsCcda]);

  return (
    <Modal
      className="patient-details__diagnosis-modal"
      title={title}
      open={open}
      footer={false}
      onCancel={handleCancel}
      width={DEFAULT_MODAL_WIDTH}
    >
      <Spin spinning={loading}>
        <div className="modal__body">
          <Radio.Group onChange={handleDiagnosisTypeChange} value={isCcda}>
            <Radio value={true} disabled={ccdaIsDisabled}>
              From patient’s CCDA
            </Radio>
            <Radio value={false}>Full diagnosis list</Radio>
          </Radio.Group>

          <div className="patient-details__diagnosis-modal_wrapper">
            <Select
              className="patient-details__diagnosis-modal_select"
              showSearch
              placeholder="Start typing to search"
              value={selectedDiagnosis && getDiagnosisName(selectedDiagnosis)}
              onSearch={onSearch}
              onChange={handleDiagnosisChange}
              filterOption={filterOption}
              loading={clinicalDiagnosesLoading}
              showArrow={false}
              options={getOptions()}
            />

            <Button className="patient-details__diagnosis-modal_add-btn" icon={<PlusOutlined />} onClick={addDiagnosis}>
              Add Below
            </Button>
          </div>

          <div className="patient-details__diagnosis-modal_diagnoses-list">{getDiagnosesList()}</div>
        </div>

        <div className="modal__footer">
          <Button className="btn-primary" onClick={handleSaveClick} disabled={disableSave()}>
            Save
          </Button>

          <Button onClick={handleCancel}>Cancel</Button>
        </div>
      </Spin>
    </Modal>
  );
};

const mapState = (state: RootState) => ({
  ccdaDiagnosesCollection: state.ccdaDiagnosesCollection,
  clinicalDiagnosesCollection: state.clinicalDiagnosesCollection,
  multiselectDiagnosesModalParams: state.modals.multiselectDiagnosesModalParams,
  loading: state.diagnosesCollection.loading,
});

export const MultiselectDiagnosisModal = connect(mapState)(MultiselectDiagnosisModalComponent);
