/* eslint-disable sonarjs/no-duplicate-string */

export enum ERoutes {
  Login = '/login',
  Dashboard = '/dashboard/:tab',
  EMBilling = '/billing/em',
  CCMBilling = '/billing/ccm',
  Operations = '/operations',
  Event = '/event/:id',
  Timings = '/timings/:type',
  Audit = '/audit/:type',
  Enrollment = '/enrollment/:id',
  Patient = '/patient/:id',
}

export enum ERole {
  Physician = 'physician',
  Staff = 'staff',
}

export enum ESort {
  Date = 'date',
  PatientName = 'patientName',
  Physician = 'physician',
  EventType = 'eventType',
  Age = 'age',
  Score = 'score',
  Description = 'description',
  Color = 'color',
  StartDate = 'startDate',
  EndDate = 'endDate',
  MRN = 'mrn',
  TimeSpent = 'timeSpent',
  EventCount = 'eventCount',
  ExportDate = 'exportDate',
  StaffName = 'staffName',
  CreatedAt = 'createdAt',
  CcmEnrollmentsCount = 'ccmEnrollmentsCount',
  CcmPatientsCount = 'ccmPatientsCount',
  CcmTimeSpent = 'ccmTimeSpent',
  TotalTimeSpent = 'totalTimeSpent',
}

export enum EStatusCodes {
  Success = 200,
  UnprocessableEntity = 422,
  Unauthorized = 401,
  NotFound = 404,
}

export enum ENavOptions {
  'Dashboard' = 'Dashboard',
  'Billing' = 'Billing',
  'EMBilling' = 'E&M Billing',
  'CCMBilling' = 'CCM Billing',
  'Operations' = 'Operations',
}

export enum EStatuses {
  Inbox = 'Inbox',
  'Under review' = 'Under review',
  Escalated = 'Escalated',
  Completed = 'Completed',
  Pending = 'Pending',
  All = 'All',
  Complete = 'Complete',
  'Not completed' = 'Not completed',
  'N/A' = 'N/A',
  Closed = 'Closed',
  Active = 'active',
  Cancelled = 'Cancelled',
}

export enum EReviewHistoryActionTypes {
  received = 'received',
  escalated = 'escalated',
  moved_back = 'moved_back',
  moved_back_to_staff = 'moved_back_to_staff',
  moved_back_to_physician = 'moved_back_to_physician',
  completed = 'completed',
  call = 'call',
  spent_time_record_is_added = 'spent_time_record_is_added',
  spent_time_record_is_edited = 'spent_time_record_is_edited',
  comment_is_left = 'comment_is_left',
  spent_time_cancelled = 'spent_time_cancelled',
  review = 'review',
  cancelled = 'cancelled',
}

export enum EReviewHistoryActionTitles {
  event_received = 'Incoming diary item for review',
  enrollment_received = 'Incoming ССM item for the review',
  escalated = 'Escalated to',
  moved_back = 'Moved back to Inbox',
  moved_back_to_staff = "Moved back to Staff's Inbox",
  moved_back_to_physician = "Moved back to Physician's Inbox",
  completed = 'Event is completed',
  call = 'Call to the patient',
  spent_time_record_is_added = 'Time spent',
  spent_time_record_is_edited = 'Time edited',
  comment_is_left = 'A comment is left',
  spent_time_cancelled = 'Time deleted',
  review = 'Review',
  cancelled = 'Cancelled',
}

export enum EHealthMetrics {
  Scores = 'scores',
  Sleep = 'sleep',
  Steps = 'steps',
  Heart = 'heart',
  Distance = 'distance',
  HeartMin = 'heart-min',
  HeartMax = 'heart-max',
}

export enum ESearchParams {
  Status = 'status',
  Red = 'red',
  isBilled = 'isBilled',
  Search = 'search',
  DateFrom = 'dateFrom',
  DateTo = 'dateTo',
  Sort = 'sort',
  OrderField = 'orderField',
  OrderDirection = 'orderDirection',
  CurrentPhysician = 'currentPhysician',
  HasReviewer = 'hasReviewer',
  HasAssignee = 'hasAssignee',
  DaysLeftLessThan = 'daysLeftLessThan',
  TimeLeftToNextThreshold = 'timeLeftToNextThreshold',
  SendDate = 'sendDate',
}

export enum EEventType {
  DiarySubmission = 'Diary submission',
  CcmEnrollment = 'CCM Enrollment',
}

export enum ETimingsTypes {
  From = 'from',
  To = 'to',
  IsBillable = 'isBillable',
  Code = 'code',
  Fee = 'fee',
  AddToNext = 'addToNext',
}

export enum EDateFormat {
  FullYear = 'MM/DD/YYYY',
  ShortYear = 'MM/DD/YY',
  ShortYear12hm = 'MM.DD.YY hh:mma',
  FullYear12hm = 'MM/DD/YYYY - hh:mma',
  ShortMonth12h = 'D MMM ha',
  FullTime24 = 'HH:mm:ss',
}

export enum EActionTypes {
  Move = 'move',
  Escalate = 'escalate',
  Complete = 'complete',
  Back = 'back',
  Cancel = 'cancel',
}

export enum EProgramType {
  ENM = 'enm',
  CCM = 'ccm',
}

export enum EDashboardTabs {
  Events = 'events',
  Enrollments = 'enrollments',
  Patients = 'patients',
}

export enum EEnrollmentsStatuses {
  Inbox = 'inbox',
  UnderReview = 'under_review',
  Completed = 'completed',
  Cancelled = 'cancelled',
}

export enum ETaskTypes {
  ChooseDiagnoses = 'choose_diagnoses',
  ObtainPatientConsent = 'obtain_patient_consent',
}

export enum ECCMTasksStatuses {
  Completed = 'completed',
  NotCompleted = 'not_completed',
}

export enum ETaskStatusTitles {
  Completed = 'Completed',
  NotCompleted = 'Not Completed',
  NA = 'N/A',
}

export enum ECCMBillingStatuses {
  Active = 'active',
  Completed = 'completed',
  Closed = 'closed',
  Rejected = 'rejected',
  All = 'all',
}

export enum EPatientHistoryType {
  CreatePatient = 'create_patient',
  DiagnosisUpdate = 'diagnosis_update',
  CcmEnrollment = 'ccm_enrollment',
  CcmPatientEnrolled = 'ccm_patient_enrolled',
  CcmPatientUnenrolled = 'ccm_patient_unenrolled',
  CcmDiagnosesUpdate = 'ccm_diagnoses_update',
  CcmObtainPatientConsent = 'ccm_obtain_patient_consent',
}

export enum EEventStatus {
  Inbox = 'inbox',
  UnderReview = 'under_review',
  Completed = 'completed',
  Cancelled = 'cancelled',
}

export enum EEventStatusTitles {
  inbox = 'Inbox',
  under_review = 'Under Review',
  completed = 'Completed',
  cancelled = 'Cancelled',
}

export enum EOrderDirection {
  Asc = 'ASC',
  Desc = 'DESC',
}

export enum ETimingItemType {
  Monthly = 'monthly',
  OneTime = 'one_time',
}

export enum EPromisName {
  PainInterference = 'PROMIS SF v1.0-Pain Interference 4a',
  Anxiety = 'PROMIS SF v1.0-Anxiety 4a',
  Depression = 'PROMIS SF v1.0-Depression 4a',
  Fatigue = 'PROMIS SF v1.0-Fatigue 4a',
  SleepDisturbance = 'PROMIS SF v1.0-Sleep Disturbance 4a',
  PhysicalFunction_en = 'PROMIS SF v1.0-Physical Function 4a',
  PhysicalFunction_es = 'PROMIS SF v2.0 - Physical Function 4a',
  SocialParticipation = 'PROMIS SF v2.0 - Ability to Participate Social 4a',
  GlobalPain = 'PROMIS-Global Pain',
  ImpactScore = 'Impact Score',
}

export enum EPromisLabel {
  'PROMIS SF v1.0-Pain Interference 4a' = 'Pain Interference',
  'PROMIS SF v1.0-Anxiety 4a' = 'Anxiety',
  'PROMIS SF v1.0-Depression 4a' = 'Depression',
  'PROMIS SF v1.0-Fatigue 4a' = 'Fatigue',
  'PROMIS SF v1.0-Sleep Disturbance 4a' = 'Sleep Disturbance',
  'PROMIS SF v1.0-Physical Function 4a' = 'Physical Function',
  'PROMIS SF v2.0 - Physical Function 4a' = 'Physical Function',
  'PROMIS SF v2.0 - Ability to Participate Social 4a' = 'Social Participation',
  'PROMIS-Global Pain' = 'Global Pain',
  'Impact Score' = 'Impact Score',
}

export enum EPromisSeverity {
  Normal = 'Normal',
  Mild = 'Mild',
  Moderate = 'Moderate',
  Severe = 'Severe',
  LowPain = 'Low Pain',
  ModeratePain = 'Moderate Pain',
  HighPain = 'High Pain',
  LowImpact = 'Low Impact',
  ModerateImpact = 'Moderate Impact',
  HighImpact = 'High Impact',
}
