import React from 'react';
import ReactRouterPrompt from 'react-router-prompt';

interface IComponentProps {
  when: boolean;
  renderPrompt: (isActive: boolean, onConfirm?: (value: unknown) => void, onCancel?: (value: unknown) => void) => React.ReactNode;
}

export const RouterPrompt: React.FC<IComponentProps> = ({ when, renderPrompt }) => (
  <ReactRouterPrompt when={when}>
    {({ isActive, onConfirm, onCancel }) => renderPrompt(isActive, onConfirm, onCancel)}
  </ReactRouterPrompt>
);
