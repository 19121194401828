import { EStatuses } from 'common/const/enums.const';
import { ICcmTaskItem, IEventTaskItem } from 'entities/Tasks/Tasks.models';
import { ccmTaskOptions, eventTasksOptions } from 'entities/Tasks/Tasks.const';

export const eventTasksToDataMapper = (tasks: IEventTaskItem[]) => {
  return tasks.map((task) => {
    const options = eventTasksOptions.filter((option) =>
      task.name === 'Choose the diagnosis' ? option.value !== EStatuses['N/A'] : option
    );

    return {
      taskId: task.id,
      name: task.name,
      status: task.status,
      options: options,
    };
  });
};

export const ccmTasksToDataMapper = (tasks: ICcmTaskItem[]) => {
  return tasks.map((task) => ({
    taskId: task.id,
    name: task.name,
    status: task.status,
    type: task.type,
    options: ccmTaskOptions,
  }));
};
