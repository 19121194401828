import React from 'react';
import { Button, Modal, Spin, Table } from 'antd';
import { connect } from 'react-redux';
import { RootDispatch, RootState } from 'app/store';
import { billingEventByIdRecords, convertDateFormat } from 'entities/Billing/Billing.helper';
import { billingEventToDataSourseMapper } from 'entities/Billing/Billing.mappers';

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

const BillingDetailsModal: React.FC<AllType> = (props) => {
  const { billingDetailsModalParams, loading, setBillingDetailsModalParams } = props;
  const { open, billingCycleModel, events, hasDiagnosis, sendToEMR } = billingDetailsModalParams;

  const onCloseClick = () => {
    setBillingDetailsModalParams({ open: false, billingCycleModel: null, events: [] });
  };

  const onSendClick = () => {
    billingCycleModel && sendToEMR?.([billingCycleModel.id]).then(() => onCloseClick());
  };

  return (
    <Modal
      className="billing-details-modal"
      title={billingCycleModel?.patientName}
      open={open}
      footer={false}
      closable={false}
      width={900}
    >
      <Spin spinning={loading}>
        <p className="billing-details-modal__description">
          <span>MRN: {billingCycleModel?.mrn}</span>
          <span>
            {convertDateFormat(billingCycleModel?.startDate)} - {convertDateFormat(billingCycleModel?.endDate)}
          </span>
        </p>

        <Table
          columns={billingEventByIdRecords}
          dataSource={billingEventToDataSourseMapper(events)}
          pagination={false}
          size="small"
        />

        <div className="billing-details-modal__footer">
          <Button className="btn-primary" onClick={onCloseClick}>
            Close
          </Button>

          {sendToEMR && hasDiagnosis && <Button onClick={onSendClick}>Send To EMR</Button>}
          {sendToEMR && !hasDiagnosis && <div>No Diagnosis</div>}
        </div>
      </Spin>
    </Modal>
  );
};

const mapState = (state: RootState) => ({
  billingDetailsModalParams: state.modals.billingDetailsModalParams,
  loading: state.billingCollection.loading,
});

const mapDispatch = (dispatch: RootDispatch) => ({
  setBillingDetailsModalParams: dispatch.modals.setBillingDetailsModalParams,
});

export default connect(mapState, mapDispatch)(BillingDetailsModal);
