import { createModel } from '@rematch/core';
import { EDashboardTabs, EStatusCodes } from 'common/const/enums.const';
import { IRootModel } from 'app/store';
import { enrollmentTransport } from 'entities/Enrollment/Enrollment.transport';
import { IEnrollmentModel, IEnrollmentModelQueryParams } from 'entities/Enrollment/Enrollment.models';

let timerId: NodeJS.Timer;

export const enrollmentModel = createModel<IRootModel>()({
  state: {
    data: null as IEnrollmentModel | null,
    loading: false,
  },
  reducers: {
    setEnrollmentModel: (state, payload: IEnrollmentModel) => ({ ...state, data: payload }),
    setEnrollmentModelLoading: (state, payload: boolean) => ({ ...state, loading: payload }),
    clearEnrollmentModel: (state) => ({ ...state, data: null }),
  },
  effects: (dispatch) => ({
    async getEnrollmentModel(enrollmentId: string) {
      dispatch.enrollmentModel.setEnrollmentModelLoading(true);

      try {
        const response = await enrollmentTransport.getEnrollmentModel(enrollmentId);
        await dispatch.ccmPatientModel.getEnrollmentPatientModel(enrollmentId);
        dispatch.enrollmentModel.setEnrollmentModel(response);
        return response;
      } catch (error) {
        // @ts-ignore
        if (error?.data?.statusCode === EStatusCodes.NotFound) {
          window.location.replace(`/dashboard/${EDashboardTabs.Enrollments}`);
        }
        return null;
      } finally {
        dispatch.enrollmentModel.setEnrollmentModelLoading(false);
      }
    },
    async pingEnrollmentModel(enrollmentId: string) {
      timerId = setInterval(() => {
        enrollmentTransport.pingEnrollmentModel(enrollmentId);
      }, 10000);
    },
    clearPingEnrollmentModel() {
      clearInterval(timerId);
    },
    async changeEnrollmentModelStatus(params: IEnrollmentModelQueryParams) {
      dispatch.enrollmentModel.setEnrollmentModelLoading(true);
      return enrollmentTransport
        .changeEnrollmentModelStatus(params)
        .then((response) => {
          dispatch.enrollmentModel.setEnrollmentModel(response);
        })
        .finally(() => {
          dispatch.enrollmentModel.setEnrollmentModelLoading(false);
        });
    },
    clearModels() {
      dispatch.modals.setInactiveModalParams({ open: false, title: null, description: null });
      dispatch.enrollmentModel.clearEnrollmentModel();
      dispatch.enrollmentModel.clearPingEnrollmentModel();
      dispatch.patientCcdaModel.clearPatientCcdaModel();
      dispatch.ccmPatientModel.clearCcmPatientModel();
      dispatch.diagnosesCollection.clearDiagnosesCollection();
      dispatch.ccdaDiagnosesCollection.clearCcdaDiagnosesCollection();
      dispatch.clinicalDiagnosesCollection.clearClinicalDiagnosesCollection();
      dispatch.ccmPatientConsentModel.clearCcmPatientConsentModel();
      dispatch.tasksCollection.clearTasksCollection();
      dispatch.enrollmentReviewHistoryModel.clearEnrollmentReviewHistoryModel();
      dispatch.patientHistoryCollection.clearPatientHistoryCollection();
    },
  }),
});
