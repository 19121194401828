import React from 'react';
import { connect } from 'react-redux';
import { v4 as uuid } from 'uuid';
import { useParams } from 'react-router-dom';
import { CommonContentLayout } from 'common/components/CommonContentLayout';
import { EDashboardTabs } from 'common/const/enums.const';
import { RootState } from 'app/store';
import CompleteModal from 'entities/Modals/components/CompleteModal';
import { EventsSubheader } from 'entities/Dashboard/components/Events/EventsSubheader';
import EventsBody from 'entities/Dashboard/components/Events/EventsBody';
import { EnrollmentsSubheader } from 'entities/Dashboard/components/Enrollments/EnrollmentsSubheader';
import { PatientsSubheader } from 'entities/Dashboard/components/Patients/PatientsSubheader';
import { EnrollmentsBody } from 'entities/Dashboard/components/Enrollments/EnrollmentsBody';
import { PatientsBody } from 'entities/Dashboard/components/Patients/PatientsBody';
import { DashboardHeader } from 'entities/Dashboard/components/DashboardHeader';

type AllType = ReturnType<typeof mapState>;

const Dashboard: React.FC<AllType> = ({ role }) => {
  const { tab } = useParams();

  const getSubheader = () => {
    switch (tab) {
      case EDashboardTabs.Enrollments: {
        return <EnrollmentsSubheader />;
      }
      case EDashboardTabs.Patients: {
        return <PatientsSubheader />;
      }
      case EDashboardTabs.Events:
      default: {
        return <EventsSubheader role={role} />;
      }
    }
  };

  const getBody = () => {
    switch (tab) {
      case EDashboardTabs.Enrollments: {
        return <EnrollmentsBody />;
      }
      case EDashboardTabs.Patients: {
        return <PatientsBody />;
      }
      case EDashboardTabs.Events:
      default: {
        return <EventsBody />;
      }
    }
  };

  const getModals = () => {
    if (tab === EDashboardTabs.Events) {
      return [{ key: uuid(), modal: <CompleteModal /> }];
    } else {
      return [];
    }
  };

  return (
    <CommonContentLayout
      header={<DashboardHeader activeTab={tab} />}
      subheader={getSubheader()}
      body={getBody()}
      modals={getModals()}
    />
  );
};

const mapState = (state: RootState) => ({
  role: state.authModel.data?.access?.role,
});

export default connect(mapState)(Dashboard);
