import React from 'react';
import { connect } from 'react-redux';
import { RootDispatch, RootState } from 'app/store';
import { Timer } from 'entities/Timer/components/Timer';
import { getBillingCyclePeriod, getBillingCycleSpentTime, getStopwatchOffset } from 'entities/Timer/Timer.helper';

interface IComponentProps {
  billingCycleId?: string;
  autoStartTimer: boolean;
  canStart: boolean;
  canEdit: boolean;
  billingCycleStartDate?: string;
  billingCycleEndDate?: string;
}

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch> & IComponentProps;

const PatientTimerComponent: React.FC<AllType> = (props) => {
  const {
    billingCycleId,
    autoStartTimer,
    canStart,
    canEdit,
    billingCycleStartDate,
    billingCycleEndDate,
    patientTimerModel,
    getPatientTimerModel,
    setPatientTimerModelUntrackedTime,
    startPatientTimer,
    stopPatientTimer,
    updatePatientTimerModel,
    setTimeEditorModalParams,
    setCommentModalParams,
    setExportedEventModalParams,
  } = props;
  const { data } = patientTimerModel;

  const getTimerModel = () => {
    if (billingCycleId) {
      return getPatientTimerModel(billingCycleId);
    }

    return Promise.resolve(undefined);
  };

  const onStartClick = () => {
    if (billingCycleId) {
      startPatientTimer(billingCycleId);
    }
  };

  const handleCommentModalSend = (billingCycleId: string, comment: string) => {
    stopPatientTimer({ billingCycleId, comment }).then(() => {
      setCommentModalParams({ open: false, externalId: null });
    });
  };

  const handleCommentModalCancel = () => {
    setCommentModalParams({ open: false, externalId: null });
  };

  const onStopClick = () => {
    if (billingCycleId) {
      setCommentModalParams({
        open: true,
        externalId: billingCycleId,
        handleSend: handleCommentModalSend,
        handleCancel: handleCommentModalCancel,
      });
    }
  };

  const onCancelEditClick = () => {
    setTimeEditorModalParams({ open: false, externalId: null, timeResultId: null, hours: null, minutes: null, seconds: null });
  };

  const onSaveEditClick = (
    milliseconds: number,
    reset: (offsetTimestamp?: Date | undefined, autoStart?: boolean | undefined) => void,
    comment: string
  ) => {
    if (billingCycleId) {
      updatePatientTimerModel({ billingCycleId, time: milliseconds, comment }).then(() => {
        reset(getStopwatchOffset(milliseconds), false);
        onCancelEditClick();
      });
    }
  };

  const onEditClick = (hours: number, minutes: number, seconds: number, reset: any, timeResultId?: string | number) => {
    if (!canEdit) {
      setExportedEventModalParams({ open: true });
    } else {
      setTimeEditorModalParams({
        open: true,
        timeResultId,
        hours,
        minutes,
        seconds,
        reset,
        onSaveEditClick,
        onCancelEditClick,
      });
    }
  };

  return (
    <Timer
      autoStartTimer={autoStartTimer}
      canStart={canStart}
      timerModel={patientTimerModel}
      spentTimeForLastDays={getBillingCycleSpentTime(billingCycleStartDate, data?.commonResults)}
      billingPeriod={getBillingCyclePeriod(billingCycleStartDate, billingCycleEndDate)}
      getTimerModel={getTimerModel}
      setTimerModelUntrackedTime={setPatientTimerModelUntrackedTime}
      onStartClick={onStartClick}
      onStopClick={onStopClick}
      onEditClick={onEditClick}
    />
  );
};

const mapState = (state: RootState) => ({
  patientTimerModel: state.patientTimerModel,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  getPatientTimerModel: dispatch.patientTimerModel.getPatientTimerModel,
  setPatientTimerModelUntrackedTime: dispatch.patientTimerModel.setPatientTimerModelUntrackedTime,
  startPatientTimer: dispatch.patientTimerModel.startPatientTimer,
  stopPatientTimer: dispatch.patientTimerModel.stopPatientTimer,
  updatePatientTimerModel: dispatch.patientTimerModel.updatePatientTimerModel,
  setTimeEditorModalParams: dispatch.modals.setTimeEditorModalParams,
  setCommentModalParams: dispatch.modals.setCommentModalParams,
  setExportedEventModalParams: dispatch.modals.setExportedEventModalParams,
});

export const PatientTimer = connect(mapState, mapDispatch)(PatientTimerComponent);
