import React, { useEffect, useState } from 'react';
import { Button, Checkbox, Pagination, Radio, RadioChangeEvent, Table } from 'antd';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { EEventStatus, ERole, ESearchParams } from 'common/const/enums.const';
import { DEFAULT_PAGE_ID, DEFAULT_PAGE_SIZE } from 'common/config';
import { sort } from 'common/helpers/sorter.helper';
import { useSocket } from 'common/hooks/useSocket';
import { SearchInput } from 'common/components/SearchInput';
import { DatePicker } from 'common/components/DatePicker';
import { Menu } from 'common/components/Menu';
import { useSearchParams } from 'common/hooks/useSearchParams';
import { history, RootDispatch, RootState } from 'app/store';
import {
  convertStatusToTab,
  convertTabToStatus,
  getEventsSearchParams,
  renderDashboardRecords,
  toDataSourseMapper,
} from 'entities/Dashboard/Dashboard.helper';
import { EDashboardTableTabs, eventsMenuItemsPhysician, eventsMenuItemsStaff } from 'entities/Dashboard/Dashboard.const';

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

const EventsBody: React.FC<AllType> = (props) => {
  const { role, eventsCollection, getEventsCollection, getEventById, reviewEvent, completeEvent, setCompleteModalParams } = props;
  const { searchParams, setSearchParam, removeSearchParam, setSearchParamWithCondition } = useSearchParams();
  const [pageId, setPageId] = useState<number>(DEFAULT_PAGE_ID);
  const navigate = useNavigate();
  const { data: eventsData, loading } = eventsCollection;

  const params = getEventsSearchParams(searchParams, role);
  const [currentTab, setCurrentTab] = useState<EDashboardTableTabs>(convertStatusToTab(params.status, params.hasAssignee));
  const dataSource = toDataSourseMapper(eventsData?.events);
  const columns = renderDashboardRecords(role, currentTab);
  const paramsRef = React.useRef({ ...params, pageId: pageId - 1 });

  const { status, red, search, dateFrom, dateTo, orderField, orderDirection, currentPhysician } = params;

  useSocket('events', () => {
    getEventsCollection(paramsRef.current);
  });

  const searchValueChange = (value: string) => {
    setSearchParamWithCondition(ESearchParams.Search, value);
    setPageId(DEFAULT_PAGE_ID);
  };

  const dateRangeValueChange = (values: { [key: string]: string | undefined }[]) => {
    values.forEach((item) => {
      const [key, value] = Object.entries(item).flat() as [string, string | undefined];
      setSearchParamWithCondition(key, value);
    });
    setPageId(DEFAULT_PAGE_ID);
  };

  const menuTabChange = (tab: EDashboardTableTabs) => {
    const status = convertTabToStatus(tab);
    removeSearchParam(ESearchParams.OrderField);
    removeSearchParam(ESearchParams.OrderDirection);
    setSearchParam(ESearchParams.Status, status);

    if (tab === EDashboardTableTabs.Inbox) {
      setSearchParam(ESearchParams.HasAssignee, role === ERole.Staff ? 'false' : 'true');
    } else if (tab === EDashboardTableTabs.Escalated) {
      setSearchParam(ESearchParams.HasAssignee, 'true');
    } else {
      removeSearchParam(ESearchParams.HasAssignee);
    }

    setPageId(DEFAULT_PAGE_ID);
    setCurrentTab(tab);
  };

  const handleRedChange = (e: RadioChangeEvent) => {
    setSearchParam(ESearchParams.Red, e.target.value);
    setPageId(DEFAULT_PAGE_ID);
  };

  const handleCurrentPhysicianChange = (e: CheckboxChangeEvent) => {
    setSearchParam(ESearchParams.CurrentPhysician, e.target.checked.toString());
    setPageId(DEFAULT_PAGE_ID);
  };

  const handleSort = (pagination: any, filters: any, sorter: any) => {
    sort(sorter, setSearchParam, removeSearchParam);
  };

  const onReviewClick = (eventId: number) => {
    history.push(`${history.location.pathname}${history.location.search}`);

    getEventById(eventId).then((isBilled) => {
      if (!isBilled) {
        reviewEvent(eventId).then(() => {
          navigate(`/event/${eventId}?autoStartTimer=true`, { replace: true });
        });
      } else {
        navigate(`/event/${eventId}`, { replace: true });
      }
    });
  };

  const onViewClick = (eventId: number) => {
    history.push(`${history.location.pathname}${history.location.search}`);
    navigate(`/event/${eventId}`, { replace: true });
  };

  const handleCompleteModalCompleteClick = (eventId: number, text: string) => {
    completeEvent({ eventId, text }).then(() => {
      setCompleteModalParams({ open: false, externalId: null });
    });
  };

  const onCompleteClick = (eventId: number) => {
    setCompleteModalParams({ open: true, externalId: eventId, onCompleteClick: handleCompleteModalCompleteClick });
  };

  columns.push({
    key: 'action',
    render: (_, record) => {
      if (status === EEventStatus.Inbox) {
        return (
          <div className="dashboard__events_actions">
            <Button className="btn-link" onClick={() => onReviewClick(record.id)}>
              Review
            </Button>
            {role === ERole.Staff && !record.hasUncompletedTasks && (
              <Button className="btn-link" onClick={() => onCompleteClick(record.id)}>
                Complete
              </Button>
            )}
          </div>
        );
      }

      return (
        <div className="dashboard__events_actions">
          <Button className="btn-link" onClick={() => onViewClick(record.id)}>
            View
          </Button>
        </div>
      );
    },
  });

  useEffect(() => {
    getEventsCollection({ ...params, pageId: pageId - 1 });
  }, [status, red, search, dateFrom, dateTo, orderField, orderDirection, currentPhysician, pageId, currentTab]);

  useEffect(() => {
    paramsRef.current = { ...params, pageId: pageId - 1 };
  });

  return (
    <>
      <div className="filter">
        <div className="filter__row">
          <SearchInput
            value={search}
            onChange={searchValueChange}
            placeholder={
              role === ERole.Physician ? 'Search by patient / description' : 'Search by patient / physician / description'
            }
          />

          <DatePicker dateFrom={dateFrom} dateTo={dateTo} onChange={dateRangeValueChange} />
        </div>

        <div className="filter__row">
          <Menu
            items={role === ERole.Physician ? eventsMenuItemsPhysician : eventsMenuItemsStaff}
            selectedKeys={[currentTab]}
            onChange={menuTabChange}
          />

          <Radio.Group value={red} onChange={handleRedChange}>
            <Radio value={false}>All</Radio>
            <Radio value={true}>Show red only</Radio>
          </Radio.Group>

          {role === ERole.Physician && (
            <Checkbox checked={currentPhysician} onChange={handleCurrentPhysicianChange}>
              My Tasks
            </Checkbox>
          )}
        </div>
      </div>

      <Table columns={columns} dataSource={dataSource} pagination={false} size="small" loading={loading} onChange={handleSort} />

      <Pagination
        current={Number(pageId)}
        total={eventsData?.count}
        showSizeChanger={false}
        onChange={(pageId: number) => setPageId(pageId)}
        defaultPageSize={DEFAULT_PAGE_SIZE}
      />
    </>
  );
};

const mapState = (state: RootState) => ({
  role: state.authModel.data?.access?.role,
  eventsCollection: state.eventsCollection,
});

const mapDispatch = (dispatch: RootDispatch) => ({
  getEventsCollection: dispatch.eventsCollection.getEventsCollection,
  getEventById: dispatch.eventsCollection.getEventById,
  reviewEvent: dispatch.actionModel.reviewEvent,
  completeEvent: dispatch.actionModel.completeEvent,
  setCompleteModalParams: dispatch.modals.setCompleteModalParams,
});

export default connect(mapState, mapDispatch)(EventsBody);
