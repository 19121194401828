import { createModel } from '@rematch/core';
import { IRootModel } from 'app/store';
import { statisticsTransport } from 'entities/Statistics/Statistics.transport';
import {
  IBillingCycleStatistic,
  ICcmBillingCycleStatistic,
  IDashboardStatisticsModel,
  IEnrollmentsStatistic,
  IOperationsStatistic,
  IPatientsStatistic,
} from 'entities/Statistics/Statistics.models';

export const statistics = createModel<IRootModel>()({
  state: {
    dashboardStatisticsData: null as IDashboardStatisticsModel | null,
    billingCyclesStatisticsData: null as IBillingCycleStatistic | null,
    enrollmentsStatisticsData: null as IEnrollmentsStatistic | null,
    patientsStatisticsData: null as IPatientsStatistic | null,
    ccmBillingCyclesStatisticsData: null as ICcmBillingCycleStatistic | null,
    operationsStatisticsData: null as IOperationsStatistic | null,
    loading: false,
  },
  reducers: {
    setDashboardStatisticsData: (state, payload: IDashboardStatisticsModel) => ({ ...state, dashboardStatisticsData: payload }),
    setBillingCyclesStatisticsData: (state, payload: IBillingCycleStatistic) => ({
      ...state,
      billingCyclesStatisticsData: payload,
    }),
    setEnrollmentsStatisticsData: (state, payload: IEnrollmentsStatistic) => ({ ...state, enrollmentsStatisticsData: payload }),
    setPatientsStatisticsData: (state, payload: IPatientsStatistic) => ({ ...state, patientsStatisticsData: payload }),
    setCcmBillingCyclesStatisticsData: (state, payload: ICcmBillingCycleStatistic) => ({
      ...state,
      ccmBillingCyclesStatisticsData: payload,
    }),
    setOperationsStatisticsData: (state, payload: IOperationsStatistic) => ({
      ...state,
      operationsStatisticsData: payload,
    }),
    setStatisticsLoading: (state, payload: boolean) => ({ ...state, loading: payload }),
  },
  effects: (dispatch) => ({
    async getDashboardStatistics(role: string) {
      dispatch.statistics.setStatisticsLoading(true);
      statisticsTransport
        .getDashboardStatistics(role)
        .then((response) => {
          dispatch.statistics.setDashboardStatisticsData(response);
        })
        .finally(() => dispatch.statistics.setStatisticsLoading(false));
    },
    async getBillingCyclesStatistics() {
      dispatch.statistics.setStatisticsLoading(true);
      statisticsTransport
        .getBillingCyclesStatistics()
        .then((response) => {
          dispatch.statistics.setBillingCyclesStatisticsData(response);
        })
        .finally(() => dispatch.statistics.setStatisticsLoading(false));
    },
    async getEnrollmentsStatistics() {
      dispatch.statistics.setStatisticsLoading(true);
      statisticsTransport
        .getEnrollmentsStatistics()
        .then((response) => {
          dispatch.statistics.setEnrollmentsStatisticsData(response);
        })
        .finally(() => dispatch.statistics.setStatisticsLoading(false));
    },
    async getPatientsStatistics() {
      dispatch.statistics.setStatisticsLoading(true);
      statisticsTransport
        .getPatientsStatistics()
        .then((response) => {
          dispatch.statistics.setPatientsStatisticsData(response);
        })
        .finally(() => dispatch.statistics.setStatisticsLoading(false));
    },
    async getCcmBillingCyclesStatistics() {
      dispatch.statistics.setStatisticsLoading(true);
      statisticsTransport
        .getCcmBillingCyclesStatistics()
        .then((response) => {
          dispatch.statistics.setCcmBillingCyclesStatisticsData(response);
        })
        .finally(() => dispatch.statistics.setStatisticsLoading(false));
    },
    async getOperationsStatistics() {
      dispatch.statistics.setStatisticsLoading(true);
      statisticsTransport
        .getOperationsStatistics()
        .then((response) => {
          dispatch.statistics.setOperationsStatisticsData(response);
        })
        .finally(() => dispatch.statistics.setStatisticsLoading(false));
    },
  }),
});
