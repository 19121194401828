import React from 'react';
import { connect } from 'react-redux';
import { Spin } from 'antd';
import { convertToTimerFormat } from 'common/helpers/time.helper';
import { RootState } from 'app/store';
import { StatisticsItem } from 'entities/Statistics/components/StatisticsItem';

type AllType = ReturnType<typeof mapState>;

const PatientsSubheaderComponent: React.FC<AllType> = (props) => {
  const { statistics, statisticsLoading, patientsLoading } = props;

  return (
    <Spin spinning={patientsLoading || statisticsLoading}>
      <div className="statistics__container">
        <StatisticsItem title="CCM Patients" count={statistics?.patientsCount} />
        <StatisticsItem title="Patients" subtitle="Served Today" count={statistics?.patientsTodayCount} />
        <StatisticsItem
          title="Total review time for"
          subtitle="today"
          count={convertToTimerFormat(statistics?.reviewTodayTime)}
          isPrimary
        />
      </div>
    </Spin>
  );
};

const mapState = (state: RootState) => ({
  statistics: state.statistics.patientsStatisticsData,
  statisticsLoading: state.statistics.loading,
  patientsLoading: state.patientsCollection.loading,
});

export const PatientsSubheader = connect(mapState)(PatientsSubheaderComponent);
