import React from 'react';
import moment from 'moment';
import { Button, Checkbox, Input, InputNumber, TimePicker } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { v4 as uuid } from 'uuid';
import { EDateFormat, ETimingItemType, ETimingsTypes } from 'common/const/enums.const';
import { ReactComponent as TrashIcon } from 'app/assets/images/svg/trashIcon.svg';
import {
  ITimingItem,
  ITimingItemChangeCodePayload,
  ITimingItemChangeFeePayload,
  ITimingModel,
  ITimingModelState,
  ITimingsChangeValuesParams,
} from 'entities/Timings/Timings.models';

export const timingItemTimeLimit = 86399000;

export const filterTimingItems = (items: ITimingItem[]) => {
  const convertedItems = items.map((item) => ({ ...item, id: uuid(), fee: item.fee && item.fee / 100 }));

  const monthlyItems = convertedItems.filter((item) => item.type === ETimingItemType.Monthly);
  const oneTimeItems = convertedItems.filter((item) => item.type === ETimingItemType.OneTime);

  return { monthlyItems, oneTimeItems };
};

export const mapMonthlyItems = (data: ITimingItem[]) => {
  const from = data.findIndex((item) => item.isBillable);
  // @ts-ignore
  const to = data.findLastIndex((item) => item.isBillable);

  return data.map((item, index, array) => {
    const newItem: ITimingItem = { ...item, key: index, showSuperseded: true };

    if (newItem.key === 0) {
      newItem.canDelete = false;
    } else {
      newItem.canDelete = true;
    }

    if ((newItem.key !== 0 && newItem.from >= timingItemTimeLimit) || Number(newItem.to) - newItem.from <= 1000) {
      newItem.breakdownIsDisabled = true;
    } else {
      newItem.breakdownIsDisabled = false;
    }

    if (newItem.key === from || newItem.key === to) {
      newItem.isBillableDisabled = false;
    }

    if (Number(newItem.key) > from && Number(newItem.key) < to) {
      newItem.isBillable = true;
      newItem.isBillableDisabled = true;
      newItem.code = item.code || '';
      newItem.fee = item.fee || 0;
    }

    if (newItem.key === data.length - 1 || !newItem.isBillable) {
      newItem.showSuperseded = false;
      newItem.addToNext = undefined;
    } else {
      newItem.addToNext = newItem.addToNext !== undefined ? newItem.addToNext : false;
    }

    const itemsWithSameCode = array.filter((el) => el.code && el.code === newItem.code);

    if (itemsWithSameCode.length > 1) {
      const [initialItem, ...restItems] = itemsWithSameCode;

      restItems.forEach((el) => {
        el.fee = initialItem.fee;
        el.feeError = false;
        el.feeIsDuplicated = true;
      });

      if (newItem.key === initialItem.key) {
        newItem.fee = newItem.fee ? newItem.fee : 0;
        newItem.feeIsDuplicated = false;
      }
    } else if (newItem.feeIsDuplicated) {
      newItem.fee = 0;
      newItem.feeIsDuplicated = false;
    }

    return newItem;
  });
};

export const mapOneTimeItems = (items: ITimingItem[]) => {
  return items.map((item, index, array) => {
    const itemsWithSameCode = array.filter((i) => i.code === item.code);
    const feeIsDuplicated = itemsWithSameCode.length > 1 && item.id !== itemsWithSameCode[0].id;

    return { ...item, key: index, feeIsDuplicated };
  });
};

export const getMomentFromMilliseconds = (milliseconds: number) =>
  moment().set('hours', 0).set('minutes', 0).set('seconds', 0).add(milliseconds, 'milliseconds');

export const renderMonthlyItemsRecords = (
  disabled?: boolean,
  handleValuesChange?: (params: ITimingsChangeValuesParams) => void
): ColumnsType<ITimingItem> => {
  return [
    {
      title: 'From',
      dataIndex: ETimingsTypes.From,
      key: ETimingsTypes.From,
      render: (_, record) => {
        return (
          <TimePicker
            className={`timings-body__timepicker ${record.timeError && 'timings-body__timepicker-error'}`}
            value={getMomentFromMilliseconds(record.from)}
            onChange={(value) => handleValuesChange?.({ timingItem: record, key: ETimingsTypes.From, value })}
            suffixIcon="min"
            clearIcon={false}
            placeholder={undefined}
            format={EDateFormat.FullTime24}
            disabled={disabled || record.key === 0}
          />
        );
      },
      width: '150px',
    },
    {
      render: () => '-',
      width: '1%',
    },
    {
      title: 'To (excluding)',
      dataIndex: ETimingsTypes.To,
      key: ETimingsTypes.To,
      render: (_, record) => {
        if (!record.to) {
          return <Input className="timings-body__code" type="text" value="Infinite" disabled />;
        }

        return (
          <TimePicker
            className="timings-body__timepicker"
            value={getMomentFromMilliseconds(record.to)}
            suffixIcon="min"
            clearIcon={false}
            placeholder={undefined}
            format={EDateFormat.FullTime24}
            disabled
          />
        );
      },
      width: '150px',
    },
    {
      title: 'Is Billable',
      dataIndex: ETimingsTypes.IsBillable,
      key: ETimingsTypes.IsBillable,
      render: (_, record) => {
        return (
          <Checkbox
            className="timings-body__checkbox"
            checked={record.isBillable}
            onChange={(e) => handleValuesChange?.({ timingItem: record, key: ETimingsTypes.IsBillable, value: e.target.checked })}
            disabled={disabled || record.isBillableDisabled}
          />
        );
      },
    },
    {
      title: 'Is superseded by next code',
      dataIndex: ETimingsTypes.AddToNext,
      key: ETimingsTypes.AddToNext,
      render: (_, record) => {
        if (record.showSuperseded) {
          return (
            <Checkbox
              className="timings-body__checkbox"
              checked={!record.addToNext}
              onChange={(e) =>
                handleValuesChange?.({ timingItem: record, key: ETimingsTypes.AddToNext, value: !e.target.checked })
              }
              disabled={disabled}
            />
          );
        } else {
          return null;
        }
      },
    },
    {
      title: 'CPT Code',
      dataIndex: ETimingsTypes.Code,
      key: ETimingsTypes.Code,
      render: (_, record) => (
        <Input
          className={`timings-body__code ${record.codeError && 'timings-body__code-error'}`}
          value={record.isBillable ? record.code : undefined}
          onChange={(e) => handleValuesChange?.({ timingItem: record, key: ETimingsTypes.Code, value: e.target.value })}
          disabled={disabled || record.code === undefined}
        />
      ),
      width: '12%',
    },
    {
      title: 'Fee',
      dataIndex: ETimingsTypes.Fee,
      key: ETimingsTypes.Fee,
      render: (_, record) => {
        return (
          !record.feeIsDuplicated && (
            <InputNumber
              className={`timings-body__fee ${record.feeError && 'timings-body__fee-error'}`}
              value={record.isBillable ? Number(record.fee) : undefined}
              onChange={(value) => handleValuesChange?.({ timingItem: record, key: ETimingsTypes.Fee, value })}
              prefix="$"
              min={0}
              step="0.01"
              disabled={disabled || record.fee === undefined}
            />
          )
        );
      },
      width: '12%',
    },
  ];
};

export const renderOneTimeItemsRecords = (
  changeCode?: (payload: ITimingItemChangeCodePayload) => void,
  changeFee?: (payload: ITimingItemChangeFeePayload) => void,
  deleteItem?: (id: string) => void
) => {
  return [
    {
      title: 'CPT Code',
      dataIndex: ETimingsTypes.Code,
      key: ETimingsTypes.Code,
      render: (_, record) => (
        <Input
          className={`timings-body__code ${record.codeError && 'timings-body__code-error'}`}
          value={record.code}
          onChange={(e) => changeCode?.({ id: record.id as string, value: e.target.value })}
          disabled={!changeCode}
        />
      ),
      width: '21%',
    },
    {
      title: 'Fee',
      dataIndex: ETimingsTypes.Fee,
      key: ETimingsTypes.Fee,
      render: (_, record) => {
        return record.feeIsDuplicated ? null : (
          <InputNumber
            className={`timings-body__fee ${record.feeError && 'timings-body__fee-error'}`}
            value={record.fee}
            onChange={(value) => changeFee?.({ id: record.id as string, value })}
            prefix="$"
            min={0}
            step="0.01"
            disabled={!changeFee}
          />
        );
      },
      width: '21%',
    },
    {
      key: '-',
    },
    {
      render: (_, record) => {
        return deleteItem ? (
          <Button className="timings-body__delete-btn" icon={<TrashIcon />} onClick={() => deleteItem?.(record.id as string)} />
        ) : null;
      },
      width: '1%',
    },
  ] as ColumnsType<ITimingItem>;
};

export const updateTimingModel = {
  setTimingModelLoading: (state: ITimingModelState, payload: boolean) => ({ ...state, loading: payload }),
  setTimingModelCreatedAt: (state: ITimingModelState, payload: string) => ({ ...state, createdAt: payload }),
  setMonthlyItems: (state: ITimingModelState, payload: ITimingItem[]) => ({ ...state, monthlyItems: payload }),
  setOneTimeItems: (state: ITimingModelState, payload: ITimingItem[]) => ({ ...state, oneTimeItems: payload }),
  addOneTimeItem: (state: ITimingModelState) => {
    return {
      ...state,
      oneTimeItems: [
        ...state.oneTimeItems,
        {
          id: uuid(),
          type: ETimingItemType.OneTime,
          from: 0,
          fee: 0,
          isBillable: true,
        },
      ],
    };
  },
  changeOneTimeItemCode: (state: ITimingModelState, payload: ITimingItemChangeCodePayload) => {
    const { id, value } = payload;

    return {
      ...state,
      oneTimeItems: state.oneTimeItems
        .map((item) => (item.id === id ? { ...item, code: value, codeError: !value.length } : item))
        .map((item, _, array) => {
          const itemsWithSameCode = array.filter((i) => i.code === item.code);
          const feeIsDuplicated = itemsWithSameCode.length > 1 && item.id !== itemsWithSameCode[0].id;
          const fee = feeIsDuplicated ? itemsWithSameCode[0].fee : item.feeIsDuplicated ? 0 : item.fee;

          return { ...item, feeIsDuplicated, fee };
        }),
    };
  },
  changeOneTimeItemFee: (state: ITimingModelState, payload: ITimingItemChangeFeePayload) => {
    const { id, value } = payload;
    const currentItem = state.oneTimeItems.find((item) => item.id === id);

    return {
      ...state,
      oneTimeItems: state.oneTimeItems.map((item) =>
        item.code === currentItem?.code ? { ...item, fee: value || 0, feeError: !value } : item
      ),
    };
  },
  deleteOneTimeItem: (state: ITimingModelState, payload: string) => {
    return {
      ...state,
      oneTimeItems: state.oneTimeItems.filter((item) => item.id !== payload),
    };
  },
};

export const findTimingItemsErrors = (items: ITimingItem[]) => {
  return items.some((item) => item.timeError || item.codeError || item.feeError);
};

export const renderTimingsHistoryRecord = (): ColumnsType<ITimingModel> => {
  return [
    {
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (text) => <p className="audit-body__date">{moment(text).format(EDateFormat.ShortYear12hm)}</p>,
      width: '160px',
    },
    {
      dataIndex: 'userName',
      key: 'userName',
      render: (value) => value,
    },
  ];
};
