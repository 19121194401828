import { EEventStatus } from 'common/const/enums.const';
import { IEventTimeModel } from 'entities/Timer/Timer.models';

interface IGetEMEventAvailableOperationsParams {
  currentUserId?: string;
  reviewerId?: string;
  eventStatus?: EEventStatus;
  eventIsBilled?: boolean;
  currentUid?: number;
  assigneeUid?: number;
  timeResults?: IEventTimeModel[];
}

export const getEMEventAvailableOperations = ({
  currentUserId,
  reviewerId,
  eventStatus,
  eventIsBilled,
  currentUid,
  assigneeUid,
  timeResults,
}: IGetEMEventAvailableOperationsParams) => {
  const timeResultsUserIds = timeResults?.map((item) => item.userUid);

  let canBeEscalated = false;
  let canBeMovedToInbox = false;
  let canBeCompleted = false;
  let canStart = false;
  let canEditTimer = false;
  let canCall = false;
  let blockRouter = false;
  let phisicianNotTrackTime = false;

  if (eventStatus === EEventStatus.UnderReview && currentUserId === reviewerId) {
    canBeEscalated = true;
    canBeMovedToInbox = true;
    canBeCompleted = true;
    canStart = true;
    blockRouter = true;
  }

  if (eventStatus === EEventStatus.Inbox) {
    canBeEscalated = true;
    canBeMovedToInbox = true;
    canBeCompleted = true;
    blockRouter = true;
  }

  if (!eventIsBilled) {
    canEditTimer = true;
    canCall = true;
  }

  if (assigneeUid && currentUid !== assigneeUid && !timeResultsUserIds?.includes(assigneeUid)) {
    phisicianNotTrackTime = true;
  }

  return {
    canBeEscalated,
    canBeMovedToInbox,
    canBeCompleted,
    canStart,
    canEditTimer,
    canCall,
    blockRouter,
    phisicianNotTrackTime,
  };
};
