import { DEFAULT_PAGE_SIZE } from 'common/config';
import { IPatientHistoryItem, IPatientHistoryQueryParams } from 'entities/Patient/Patient.models';

export const diagnosisAuditQueryParamsTo = (params: IPatientHistoryQueryParams, pageId: number) => {
  return {
    ...params,
    offset: DEFAULT_PAGE_SIZE * (pageId - 1),
    limit: DEFAULT_PAGE_SIZE,
  };
};

export const patientHistoryDataToDataSource = (data?: IPatientHistoryItem[]) => {
  return data?.map((item) => ({
    key: item.id,
    ...item,
  }));
};
