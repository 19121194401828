import React, { useEffect, useState } from 'react';
import { Button } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { EActionTypes, ERole, ERoutes, EStatuses } from 'common/const/enums.const';
import { IdleTimer } from 'common/components/IdleTimer';
import { EVENT_IDLE_LIMIT_MS } from 'common/config';
import { RootDispatch, RootState, history } from 'app/store';
import { Phone } from 'entities/Twilio/components/Phone';
import { EventTimer } from 'entities/Timer/components/EventTimer';
import { getEMEventAvailableOperations } from 'entities/Event/Event.helper';
import { IEventTimerModelUpdateParams } from 'entities/Timer/Timer.models';

interface IComponentProps {
  eventId: number;
  autoStartTimer: boolean;
  isBillingSource: boolean;
}

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch> & IComponentProps;

const EventActionsCard: React.FC<AllType> = (props) => {
  const {
    eventId,
    autoStartTimer,
    isBillingSource,
    authModel: { data: authModelData },
    eventTimerModel,
    eventModel,
    patientModel,
    tasksCollection,
    physiciansCollection,
    commentEvent,
    setActionParams,
    setEscalateModalParams,
    setCommentModalParams,
    setEditPhysicianTimeModalParams,
    setCompleteModalParams,
    setExportedEventModalParams,
    setCallModalParams,
    showEventInactiveModal,
    setInactiveModalParams,
    setEmptyTimeRecordModalParams,
    startEventTimer,
    stopEventTimer,
    updateEventTimer,
    getTwilioModel,
    postEventCallHistory,
    getPhysiciansCollection,
  } = props;
  const [onCall, setOnCall] = useState<boolean>(false);
  const { data: eventTimerModelData, isRunning } = eventTimerModel;
  const { data: physiciansCollectionData } = physiciansCollection;
  const currentUserId = authModelData?.access?.userId;
  const currentUid = authModelData?.access?.uid;
  const role = authModelData?.access?.role;
  const assignee = eventModel?.assignee;
  const assigneeUid = eventModel?.assigneeUid;
  const reviewerId = eventModel?.reviewerId;
  const physician = eventModel?.physician;
  const patientName = eventModel?.patientName;
  const eventStatus = eventModel?.eventStatus;
  const eventIsBilled = eventModel?.isBilled;
  const timeResults = eventTimerModelData?.commonResults;
  const phone = patientModel?.phone || '';
  const { canBeEscalated, canBeMovedToInbox, canBeCompleted, canStart, canEditTimer, canCall, phisicianNotTrackTime } =
    getEMEventAvailableOperations({
      currentUserId,
      reviewerId,
      eventStatus,
      eventIsBilled,
      currentUid,
      assigneeUid,
      timeResults,
    });

  const handleEscalateModalSend = (eventId: number, text: string, physicianId: number) => {
    setActionParams({ type: EActionTypes.Escalate, text, physicianId });

    if (isRunning) {
      stopEventTimer(eventId).then(() => {
        history.back();
      });
    } else {
      if (isBillingSource) {
        history.push(ERoutes.EMBilling);
      } else {
        history.back();
      }
    }
  };

  const handleEscalateClick = () => {
    setEscalateModalParams({
      open: true,
      eventId,
      physician,
      physiciansCollection: physiciansCollectionData,
      handleSend: handleEscalateModalSend,
    });
  };

  const onEscalateClick = () => {
    if (phisicianNotTrackTime) {
      setEmptyTimeRecordModalParams({ open: true, physicianName: assignee, onLeaveClick: handleEscalateClick });
    } else {
      handleEscalateClick();
    }
  };

  const handleCommentModalSend = (eventId: number, text: string) => {
    commentEvent({ eventId, text }).then(() => {
      setCommentModalParams({ open: false, externalId: null });
    });
  };

  const handleEditTimeSpentModalCancel = () => {
    setEditPhysicianTimeModalParams({ open: false, eventId: null });
  };

  const handleEditTimeSpentSend = (params: IEventTimerModelUpdateParams) => {
    updateEventTimer(params).then(() => {
      setEditPhysicianTimeModalParams({ open: false, eventId: null });
    });
  };

  const handleCommentModalCancel = () => {
    setCommentModalParams({ open: false, externalId: null });
  };

  const onCommentClick = () => {
    setCommentModalParams({
      open: true,
      externalId: eventId,
      handleSend: handleCommentModalSend,
      handleCancel: handleCommentModalCancel,
    });
  };

  const onEditTimeSpentClick = () => {
    setEditPhysicianTimeModalParams({
      open: true,
      eventId,
      physician,
      physiciansCollection: physiciansCollectionData,
      handleSend: handleEditTimeSpentSend,
      handleCancel: handleEditTimeSpentModalCancel,
    });
  };

  const handleMoveClick = () => {
    setActionParams({ type: EActionTypes.Move });
    history.back();
  };

  const onMoveClick = () => {
    if (phisicianNotTrackTime) {
      setEmptyTimeRecordModalParams({ open: true, physicianName: assignee, onLeaveClick: handleMoveClick });
    } else {
      handleMoveClick();
    }
  };

  const handleCompleteModalCompleteClick = (eventId: number, text: string) => {
    setActionParams({ type: EActionTypes.Complete, text });

    if (isRunning) {
      stopEventTimer(eventId).then(() => {
        history.back();
      });
    } else {
      if (isBillingSource) {
        history.push(ERoutes.EMBilling);
      } else {
        history.back();
      }
    }
  };

  const handleCompleteClick = () => {
    setCompleteModalParams({ open: true, externalId: eventId, onCompleteClick: handleCompleteModalCompleteClick });
  };

  const onCompleteClick = () => {
    if (phisicianNotTrackTime) {
      setEmptyTimeRecordModalParams({ open: true, physicianName: assignee, onLeaveClick: handleCompleteClick });
    } else {
      handleCompleteClick();
    }
  };

  const makeCall = () => {
    getTwilioModel({
      onSuccess: () => postEventCallHistory(eventId),
    }).then((token) => setCallModalParams({ open: true, token, phone, patientName, setOnCall }));
  };

  const onCallClick = () => {
    if (canCall) {
      if (canStart && !isRunning) {
        startEventTimer(eventId).then(() => {
          makeCall();
        });
      } else {
        makeCall();
      }
    } else {
      setExportedEventModalParams({ open: true });
    }
  };

  const onPauseClick = () => {
    setInactiveModalParams({ open: false, title: null, description: null });
    setActionParams({ type: '', text: undefined });
  };

  const onResumeClick = () => {
    startEventTimer(eventId).then(() => {
      onPauseClick();
    });
  };

  const onInactiveModalMoveClick = () => {
    history.back();
  };

  const disableComplete = () => {
    return tasksCollection.data.some((task) => {
      if (task.name === 'Choose the diagnosis' && task.status === EStatuses['N/A']) {
        return true;
      }

      return task.status === EStatuses['Not completed'];
    });
  };

  useEffect(() => {
    getPhysiciansCollection();
  }, []);

  return (
    <div className="actions-card">
      <div className="actions-card__item">
        <EventTimer
          eventId={eventId}
          autoStartTimer={autoStartTimer}
          canStart={canStart}
          canEdit={canEditTimer}
          billingCycleStartDate={eventModel?.startDate}
          billingCycleEndDate={eventModel?.endDate}
        />
      </div>

      <div className="actions-card__item">
        <Phone phone={phone} onCallClick={onCallClick} />
      </div>

      <div className="actions-card__item">
        <div className="actions-card__actions">
          {role === ERole.Staff && (
            <Button className="btn-primary" onClick={onEscalateClick} disabled={!canBeEscalated}>
              Escalate
            </Button>
          )}

          <Button icon={<div className="icon actions-card__comment-icon" />} onClick={onCommentClick}>
            Leave a comment
          </Button>

          {role === ERole.Staff && (
            <Button
              icon={<div className="icon actions-card__timer-icon" />}
              disabled={!canEditTimer}
              onClick={onEditTimeSpentClick}
            >
              Edit Physician Time Spent
            </Button>
          )}

          <Button onClick={onMoveClick} disabled={!canBeMovedToInbox}>
            {role === ERole.Staff ? 'Move to Inbox' : "Move to Staff's Inbox"}
          </Button>

          <Button
            className="btn-green"
            icon={<CheckCircleOutlined />}
            onClick={onCompleteClick}
            disabled={disableComplete() || !canBeCompleted}
          >
            Complete
          </Button>
        </div>
      </div>

      {isRunning && !onCall && (
        <IdleTimer
          timeout={EVENT_IDLE_LIMIT_MS}
          onIdle={() => {
            stopEventTimer(eventId).then(() => {
              showEventInactiveModal({
                open: true,
                title: 'The timer is stopped',
                description: (
                  <>
                    <span>We stopped the timer due to non interaction.</span>
                    <span>Are you still reviewing?</span>
                  </>
                ),
                onResumeClick,
                onPauseClick,
                onMoveClick: onInactiveModalMoveClick,
              });
            });
          }}
        />
      )}
    </div>
  );
};

const mapState = (state: RootState) => ({
  authModel: state.authModel,
  eventTimerModel: state.eventTimerModel,
  eventModel: state.eventModel,
  patientModel: state.patientModel,
  tasksCollection: state.tasksCollection,
  physiciansCollection: state.physiciansCollection,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  commentEvent: dispatch.actionModel.commentEvent,
  setActionParams: dispatch.actionModel.setActionParams,
  setEscalateModalParams: dispatch.modals.setEscalateModalParams,
  setCommentModalParams: dispatch.modals.setCommentModalParams,
  setEditPhysicianTimeModalParams: dispatch.modals.setEditPhysicianTimeModalParams,
  setCompleteModalParams: dispatch.modals.setCompleteModalParams,
  setExportedEventModalParams: dispatch.modals.setExportedEventModalParams,
  setCallModalParams: dispatch.modals.setCallModalParams,
  showEventInactiveModal: dispatch.modals.showEventInactiveModal,
  setInactiveModalParams: dispatch.modals.setInactiveModalParams,
  setEmptyTimeRecordModalParams: dispatch.modals.setEmptyTimeRecordModalParams,
  startEventTimer: dispatch.eventTimerModel.startEventTimer,
  stopEventTimer: dispatch.eventTimerModel.stopEventTimer,
  updateEventTimer: dispatch.eventTimerModel.updateEventTimerModel,
  getTwilioModel: dispatch.twilioModel.getTwilioModel,
  postEventCallHistory: dispatch.eventReviewHistoryModel.postEventCallHistory,
  getPhysiciansCollection: dispatch.physiciansCollection.getPhysiciansCollection,
});

export default connect(mapState, mapDispatch)(EventActionsCard);
