import moment from 'moment';
import { EDateFormat } from 'common/const/enums.const';
import { convertToTimerFormat } from 'common/helpers/time.helper';
import { IEventTimeModel, IPatientTimeModel } from 'entities/Timer/Timer.models';

export const getStopwatchOffset = (milliseconds: number) => {
  const stopwatchOffset = new Date();
  stopwatchOffset.setMilliseconds(stopwatchOffset.getMilliseconds() + milliseconds);
  return stopwatchOffset;
};

export const getBillingCycleSpentTime = (startDate?: string, times?: IEventTimeModel[] | IPatientTimeModel[]) => {
  // @ts-ignore
  // eslint-disable-next-line
  const milliseconds = times?.reduce((previous, current) => previous + current.time, 0);

  return `Total Time Spent on this Event: ${convertToTimerFormat(milliseconds)}`;
};

export const getBillingCyclePeriod = (startDate?: string, endDate?: string) => {
  if (!startDate) {
    return 'Billing Period: Not Yet Established';
  }

  return `Billing Period: ${moment(startDate).startOf('day').format(EDateFormat.ShortYear)} - ${moment(endDate)
    .endOf('day')
    .format(EDateFormat.ShortYear)}`;
};
