import axios from 'axios';
import {
  IBillingCycleStatistic,
  ICcmBillingCycleStatistic,
  IDashboardStatisticsModel,
  IEnrollmentsStatistic,
  IOperationsStatistic,
  IPatientsStatistic,
} from 'entities/Statistics/Statistics.models';

const basePath = 'statistics';
const billingBasePath = 'billing-cycles/statistic';
const ccmPath = 'ccm';
const operationsPath = 'operations';

export const statisticsTransport = {
  getDashboardStatistics: (role: string): Promise<IDashboardStatisticsModel> => axios.get(`${basePath}/${role}`),
  getBillingCyclesStatistics: (): Promise<IBillingCycleStatistic> => axios.get(`${billingBasePath}`),
  getEnrollmentsStatistics: (): Promise<IEnrollmentsStatistic> => axios.get(`${ccmPath}/enrollments/statistic`),
  getPatientsStatistics: (): Promise<IPatientsStatistic> => axios.get(`${ccmPath}/billing-cycles/statistic`),
  getCcmBillingCyclesStatistics: (): Promise<ICcmBillingCycleStatistic> => axios.get(`${ccmPath}/billing-cycles/statistic/month`),
  getOperationsStatistics: (): Promise<IOperationsStatistic> => axios.get(`${operationsPath}/statistic`),
};
