import { createModel } from '@rematch/core';
import { IRootModel } from 'app/store';
import { IPromisCollection } from 'entities/Promis/Promis.models';
import { promisTransport } from 'entities/Promis/Promis.transport';

export const promisCollection = createModel<IRootModel>()({
  state: {
    data: null as IPromisCollection | null,
    loading: false,
  },
  reducers: {
    setPromisCollection: (state, payload: IPromisCollection) => ({ ...state, data: payload }),
    setPromisCollectionLoading: (state, payload: boolean) => ({ ...state, loading: payload }),
    clearPromisCollection: (state) => ({ ...state, data: null }),
  },
  effects: (dispatch) => ({
    async getPromisCollection(patientId: string) {
      dispatch.promisCollection.setPromisCollectionLoading(true);
      promisTransport
        .getPromisCollection(patientId)
        .then((response) => {
          response && dispatch.promisCollection.setPromisCollection(response);
        })
        .finally(() => {
          dispatch.promisCollection.setPromisCollectionLoading(false);
        });
    },
  }),
});
