import { createModel } from '@rematch/core';
import { IRootModel } from 'app/store';
import { ICarePlanModel, ICarePlanModelPayload } from 'entities/CarePlan/CarePlan.models';
import { carePlanTransport } from 'entities/CarePlan/CarePlan.transport';

export const carePlanModel = createModel<IRootModel>()({
  state: {
    data: null as ICarePlanModel | null,
    loading: false,
  },
  reducers: {
    setCarePlanModel: (state, payload: ICarePlanModel) => ({ ...state, data: payload }),
    setCarePlanModelLoading: (state, payload: boolean) => ({ ...state, loading: payload }),
    clearCarePlanModel: (state) => ({ ...state, data: null }),
  },
  effects: (dispatch) => ({
    async updateCarePlanModel(payload: ICarePlanModelPayload) {
      dispatch.carePlanModel.setCarePlanModelLoading(true);
      carePlanTransport
        .updateCarePlanModel(payload)
        .then((response) => {
          dispatch.carePlanModel.setCarePlanModel(response);

          if (payload.onSuccess) {
            payload.onSuccess();
          }
        })
        .finally(() => {
          dispatch.carePlanModel.setCarePlanModelLoading(false);
        });
    },
  }),
});
