import { EHealthMetrics } from 'common/const/enums.const';
import { IChartDataSource, IHealthMetricsModel, IHealthMetricsScoresModel } from 'entities/HealthMetrics/HealthMetrics.models';

export const sortHeartScores = (
  initialData: IHealthMetricsModel[],
  data: IHealthMetricsModel[],
  metric: string
): IChartDataSource[] => {
  if (!data.length) {
    return initialData
      .map((item, index, arr) => index % 2 === 0 && { date: new Date(item.end), min: item.data, max: arr[index + 1].data })
      .filter((item) => !!item) as IChartDataSource[];
  } else {
    const dataItem = data.filter((item) => item.metric === metric)[0];
    const filteredData = data.filter((item) => item !== dataItem);

    return sortHeartScores(
      [...initialData, dataItem],
      filteredData,
      metric === EHealthMetrics.HeartMin ? EHealthMetrics.HeartMax : EHealthMetrics.HeartMin
    );
  }
};

export const healthMetricsResponseToDataMapper = (
  metric: string,
  response: IHealthMetricsScoresModel[] | IHealthMetricsModel[]
) => {
  let dataSource: IChartDataSource[] = [];
  let color: string = '#226ddd';
  let min: number = 0;
  let max: number = 40;

  if (metric === EHealthMetrics.Scores) {
    dataSource = (response as IHealthMetricsScoresModel[]).map((item) => ({ date: new Date(item.date), score: item.score }));
  }

  if (metric === EHealthMetrics.Sleep) {
    const data = (response as IHealthMetricsModel[]).map((item) => ({
      date: new Date(item.end),
      score: Math.round(item.data / 3600),
    }));

    dataSource = data;
    color = '#18212d';
    min = Math.min(...data.map((item) => item.score));
    max = Math.max(...data.map((item) => item.score));
  }

  if (metric === EHealthMetrics.Steps) {
    dataSource = (response as IHealthMetricsModel[]).map((item) => ({ date: new Date(item.end), score: item.data }));
    color = '#f97316';
    min = Math.min(...(response as IHealthMetricsModel[]).map((item) => item.data));
    max = Math.max(...(response as IHealthMetricsModel[]).map((item) => item.data));
  }

  if (metric === EHealthMetrics.Heart) {
    const filteredResponse = (response as IHealthMetricsModel[]).filter(
      // @ts-ignore
      (item) => item.metric === EHealthMetrics.HeartMin || item.metric === EHealthMetrics.HeartMax
    );
    const data = sortHeartScores([], filteredResponse, EHealthMetrics.HeartMin);

    dataSource = data;
    color = '#1f6df2';
    min = Math.min(...dataSource.map((item: any) => item.min as number));
    max = Math.max(...dataSource.map((item: any) => item.max as number));
  }

  return { dataSource, metric, color, min, max };
};
