import React from 'react';
import { connect } from 'react-redux';
import { Tabs } from 'antd';
import { useNavigate } from 'react-router-dom';
import { RootState } from 'app/store';
import { getDashboardPathname, getDashboardTabs } from 'entities/Dashboard/Dashboard.helper';

interface IComponentProps {
  activeTab?: string;
}

type AllType = ReturnType<typeof mapState> & IComponentProps;

const DashboardHeaderComponent: React.FC<AllType> = ({ activeTab, dashboardInfoModel }) => {
  const { data: dashboardInfoData } = dashboardInfoModel;
  const navigate = useNavigate();
  const tabs = getDashboardTabs(dashboardInfoData);

  const handleTabChange = (key: string) => {
    navigate(getDashboardPathname(key), { replace: true });
  };

  return <Tabs activeKey={activeTab} className="tabs" items={tabs} onChange={handleTabChange} />;
};

const mapState = (state: RootState) => ({
  dashboardInfoModel: state.dashboardInfoModel,
});

export const DashboardHeader = connect(mapState)(DashboardHeaderComponent);
