import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Row, Spin } from 'antd';
import moment from 'moment';
import { v4 as uuid } from 'uuid';
import { CommonContentLayout } from 'common/components/CommonContentLayout';
import { NavigationSubheader } from 'common/components/NavigationSubheader';
import { EActionTypes, EDateFormat, EEventType } from 'common/const/enums.const';
import { history, RootDispatch, RootState } from 'app/store';
import { EnrollmentBody } from 'entities/Enrollment/components/EnrollmentBody';
import { MultiselectDiagnosisModal } from 'entities/Modals/components/MultiselectDiagnosesModal';
import { VerbalConsentModal } from 'entities/Modals/components/VerbalConsentModal';
import { EnrollmentPanel } from 'entities/Enrollment/components/EnrollmentPanel';
import CallModal from 'entities/Modals/components/CallModal';
import { InactiveModal } from 'entities/Modals/components/InactiveModal';
import CommentModal from 'entities/Modals/components/CommentModal';
import PatientHistoryModal from 'entities/Modals/components/PatientHistoryModal';
import { EnrollmentRouterHandler } from 'entities/Enrollment/components/EnrollmentRouterHandler';
import { CancelModal } from 'entities/Modals/components/CancelModal';
import { getEnrollmentAvailableOperations } from 'entities/Enrollment/Enrollment.helper';

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

const EnrollmentComponent: React.FC<AllType> = (props) => {
  const { authModel, enrollmentModel, ccmPatientModel, getEnrollmentModel, pingEnrollmentModel, clearModels, setActionParams } =
    props;
  const { data: enrollmentModelData, loading } = enrollmentModel;
  const { data: ccmPatientModelData } = ccmPatientModel;
  const currentUserName = authModel.data?.access?.id;
  const enrollmentStatus = enrollmentModelData?.status as string;
  const assignee = enrollmentModelData?.assignee.name;
  const { canStart } = getEnrollmentAvailableOperations(enrollmentStatus, currentUserName, assignee);

  const { id: enrollmentId } = useParams();

  const modals = [
    { key: uuid(), modal: <MultiselectDiagnosisModal /> },
    { key: uuid(), modal: <VerbalConsentModal /> },
    { key: uuid(), modal: <CallModal /> },
    { key: uuid(), modal: <InactiveModal /> },
    { key: uuid(), modal: <CommentModal /> },
    { key: uuid(), modal: <PatientHistoryModal /> },
    { key: uuid(), modal: <CancelModal /> },
  ];

  const onBackClick = () => {
    setActionParams({ type: EActionTypes.Back });
    history.back();
  };

  useEffect(() => {
    if (!enrollmentModelData && enrollmentId) {
      getEnrollmentModel(enrollmentId);
    }
  }, [enrollmentModelData]);

  useEffect(() => {
    if (enrollmentId && canStart) {
      pingEnrollmentModel(enrollmentId);
    }
  }, [canStart]);

  useEffect(() => {
    return () => {
      setActionParams({ type: '' });
      clearModels();
    };
  }, []);

  if (!enrollmentModelData || !ccmPatientModelData || loading) {
    return (
      <Row justify="center" align="middle" style={{ height: '50vh' }}>
        <Spin size="large" />
      </Row>
    );
  }

  return (
    <>
      <CommonContentLayout
        subheader={
          <NavigationSubheader
            link="Dashboard"
            description={`${EEventType.CcmEnrollment} - ${moment(enrollmentModelData.createdAt).format(
              EDateFormat.ShortYear12hm
            )}`}
            onClick={onBackClick}
          />
        }
        body={
          <EnrollmentBody
            enrollmentModelData={enrollmentModelData}
            ccmPatientModelData={ccmPatientModelData}
            enrollmentStatus={enrollmentStatus}
          />
        }
        panel={<EnrollmentPanel enrollmentId={enrollmentId} />}
        modals={modals}
      />

      <EnrollmentRouterHandler enrollmentId={enrollmentId} canStart={canStart} />
    </>
  );
};

const mapState = (state: RootState) => ({
  authModel: state.authModel,
  enrollmentModel: state.enrollmentModel,
  ccmPatientModel: state.ccmPatientModel,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  getEnrollmentModel: dispatch.enrollmentModel.getEnrollmentModel,
  pingEnrollmentModel: dispatch.enrollmentModel.pingEnrollmentModel,
  clearModels: dispatch.enrollmentModel.clearModels,
  setActionParams: dispatch.actionModel.setActionParams,
});

export const Enrollment = connect(mapState, mapDispatch)(EnrollmentComponent);
