import React from 'react';
import { ICommonContentModalItem } from 'common/models';

interface IComponentProps {
  header?: React.ReactNode;
  subheader?: React.ReactNode;
  body: React.ReactNode;
  panel?: React.ReactNode;
  modals?: ICommonContentModalItem[];
}

export const CommonContentLayout: React.FC<IComponentProps> = ({ header, subheader, body, panel, modals }) => {
  return (
    <div className="common-content-layout__container">
      {header && <div className="common-content-layout__header">{header}</div>}
      {subheader && <div className="common-content-layout__subheader">{subheader}</div>}
      <div className="common-content-layout__wrapper">
        {body && <div className="common-content-layout__body">{body}</div>}
        {panel && <div className="common-content-layout__panel">{panel}</div>}
      </div>
      {!!modals?.length && modals.map((item) => <div key={item.key}>{item.modal}</div>)}
    </div>
  );
};
