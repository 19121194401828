import React from 'react';
import { v4 as uuid } from 'uuid';
import { CommonContentLayout } from 'common/components/CommonContentLayout';
import TimingsHeader from 'entities/Timings/components/TimingsHeader';
import TimingsBody from 'entities/Timings/components/TimingsBody';
import TimingsSaveModal from 'entities/Modals/components/TimingsSaveModal';
import TimingsCancelModal from 'entities/Modals/components/TimingsCancelModal';

const TimingsComponent: React.FC = () => {
  const modals = [
    { key: uuid(), modal: <TimingsSaveModal /> },
    { key: uuid(), modal: <TimingsCancelModal /> },
  ];

  return <CommonContentLayout header={<TimingsHeader />} body={<TimingsBody />} modals={modals} />;
};

export const Timings = TimingsComponent;
