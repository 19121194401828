import { ColumnsType } from 'antd/lib/table';
import { EPromisLabel, EPromisName } from 'common/const/enums.const';
import { getPromisColor } from 'common/helpers/color.helper';
import { IPromiseItem, IPromisModel } from 'entities/Promis/Promis.models';

export const promisSymptomDomains = [
  EPromisName.PainInterference,
  EPromisName.Anxiety,
  EPromisName.Depression,
  EPromisName.Fatigue,
  EPromisName.SleepDisturbance,
  EPromisName.GlobalPain,
  EPromisName.ImpactScore,
];

export const getPromisFunctionDomains = (data?: IPromisModel[]) => {
  const domains = [];

  if (data?.some((item) => item.name === EPromisName.PhysicalFunction_en)) {
    domains.push(EPromisName.PhysicalFunction_en);
  } else {
    domains.push(EPromisName.PhysicalFunction_es);
  }

  domains.push(EPromisName.SocialParticipation);

  return domains;
};

export const convertToPromisDataSource = (names: EPromisName[], data?: IPromisModel[]): IPromiseItem[] => {
  return names.map((name, index) => {
    const item = data?.find((i) => i.name === name);

    if (!item) {
      return { key: index, name, severity: '' };
    }

    return {
      key: index,
      name: EPromisLabel[item.name as EPromisName],
      score: item.tScore !== undefined ? item.tScore : item.score,
      severity: item.severity,
      percentile: item.percentile,
    };
  });
};

export const promisColumns: ColumnsType<IPromiseItem> = [
  {
    title: 'Instrument',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Score',
    dataIndex: 'score',
    key: 'score',
    render: (text) => (text !== undefined ? Math.round(text) : null),
  },
  {
    title: 'Severity',
    dataIndex: 'severity',
    key: 'severity',
    onCell: (record) => ({
      className: `color__${getPromisColor(record.severity)}`,
    }),
  },
  {
    title: 'Percentile',
    dataIndex: 'percentile',
    key: 'percentile',
    render: (text) => text && `${Math.round(text)}%`,
    onCell: (record) => ({
      className: `color__${getPromisColor(record.severity)}`,
    }),
  },
];
