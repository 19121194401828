import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'antd';
import { connect } from 'react-redux';
import { UserOutlined } from '@ant-design/icons';
import { Device } from 'twilio-client';
import { RootDispatch, RootState } from 'app/store';
import { ReactComponent as Phone } from 'app/assets/images/svg/phone-icon.svg';

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

const CallModal: React.FC<AllType> = (props) => {
  const { callModalParams, setCallModalParams } = props;
  const { open, token, phone, patientName, setOnCall } = callModalParams;
  const [device, setDevice] = useState<Device | null>(null);

  const onHungupClick = () => {
    setCallModalParams({ open: false, token: null, phone: null, patientName: null });
    device?.disconnectAll();
    setOnCall?.(false);
  };

  useEffect(() => {
    const device = new Device();

    if (token) {
      device.setup(token);

      device.on('ready', () => {
        setDevice(device);
        device.connect({ number: phone as string });
        setOnCall?.(true);
      });

      device.on('disconnect', () => {
        onHungupClick();
      });
    }

    return () => {
      device.destroy();
      setDevice(null);
    };
  }, [token]);

  return (
    <Modal className="call-modal" open={open} footer={false} closable={false}>
      <div className="call-modal__container">
        <div className="call-modal__user-icon">
          <UserOutlined />
        </div>
        <p className="call-modal__user-name">{patientName}</p>
      </div>

      <div className="call-modal__container">
        <Button className="call-modal__hung-up" icon={<Phone />} onClick={onHungupClick} />
        <p className="call-modal__hung-up_label">End the call</p>
      </div>
    </Modal>
  );
};

const mapState = (state: RootState) => ({
  callModalParams: state.modals.callModalParams,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  setCallModalParams: dispatch.modals.setCallModalParams,
});

export default connect(mapState, mapDispatch)(CallModal);
