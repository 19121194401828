import React from 'react';
import { connect } from 'react-redux';
import { Button, Modal } from 'antd';
import { DEFAULT_MODAL_WIDTH } from 'common/config';
import { RootDispatch, RootState, history } from 'app/store';

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

const TimingsCancelModal: React.FC<AllType> = ({ timingsCancelModalParams, setTimingsCancelModalParams }) => {
  const { open, setFormChanged } = timingsCancelModalParams;

  const toggleModal = () => setTimingsCancelModalParams({ open: false });

  const onOkClick = () => {
    setFormChanged?.(false);
    toggleModal();
    history.back();
  };

  return (
    <Modal open={open} footer={false} onCancel={toggleModal} width={DEFAULT_MODAL_WIDTH}>
      <div className="modal__body">
        <p>The changes are not saved and will be lost. Are you sure?</p>
      </div>

      <div className="modal__footer">
        <Button className="btn-primary" onClick={onOkClick}>
          Ok
        </Button>

        <Button onClick={toggleModal}>Cancel</Button>
      </div>
    </Modal>
  );
};

const mapState = (state: RootState) => ({
  timingsCancelModalParams: state.modals.timingsCancelModalParams,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  setTimingsCancelModalParams: dispatch.modals.setTimingsCancelModalParams,
});

export default connect(mapState, mapDispatch)(TimingsCancelModal);
