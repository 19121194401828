import React from 'react';
import { Button, Modal } from 'antd';
import { connect } from 'react-redux';
import { RouterPrompt } from 'common/components/RouterPrompt';
import { DEFAULT_MODAL_WIDTH } from 'common/config';
import { convertToTimerFormat } from 'common/helpers/time.helper';
import { useBeforeUnload } from 'common/hooks/useBeforeUnload';
import { RootDispatch, RootState } from 'app/store';
import { noCommentProvidedMessage } from 'entities/ReviewHistory/ReviewHistory.const';

interface IComponentProps {
  billingCycleId?: string;
}

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch> & IComponentProps;

const PatientRouterHandlerComponent: React.FC<AllType> = (props) => {
  const { billingCycleId, isRunning, untrackedTime, stopPatientTimer, deletePatientTimerModel, setNotificationModalParams } =
    props;

  useBeforeUnload([isRunning], () => {
    isRunning && billingCycleId && stopPatientTimer({ billingCycleId, comment: noCommentProvidedMessage });
  });

  const renderPrompt = (isActive: boolean, onConfirm?: (value: unknown) => void, onCancel?: (value: unknown) => void) => {
    setNotificationModalParams({ onCancel });

    return (
      isActive && (
        <Modal title="New Notification" open={true} footer={false} onCancel={onCancel} width={DEFAULT_MODAL_WIDTH}>
          <div className="modal__body">
            <p>
              From the review start you’ve spent {convertToTimerFormat(untrackedTime)}, but haven’t stopped the timer. Save time
              or delete?
            </p>
          </div>

          <div className="modal__footer">
            <Button
              className="btn-primary"
              onClick={() => {
                if (billingCycleId) {
                  stopPatientTimer({ billingCycleId, comment: noCommentProvidedMessage }).then(() => {
                    onConfirm?.(undefined);
                  });
                }
              }}
            >
              Save and Leave
            </Button>
            <Button
              className="btn-orange"
              onClick={() => {
                if (billingCycleId) {
                  deletePatientTimerModel({ billingCycleId, comment: noCommentProvidedMessage }).then(() => {
                    onConfirm?.(undefined);
                  });
                }
              }}
            >
              Delete and Leave
            </Button>
            <Button onClick={onCancel}>Cancel</Button>
          </div>
        </Modal>
      )
    );
  };

  return <RouterPrompt when={isRunning} renderPrompt={renderPrompt} />;
};

const mapState = (state: RootState) => ({
  isRunning: state.patientTimerModel.isRunning,
  untrackedTime: state.patientTimerModel.untrackedTime,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  stopPatientTimer: dispatch.patientTimerModel.stopPatientTimer,
  deletePatientTimerModel: dispatch.patientTimerModel.deletePatientTimerModel,
  setNotificationModalParams: dispatch.modals.setNotificationModalParams,
});

export const PatientRouterHandler = connect(mapState, mapDispatch)(PatientRouterHandlerComponent);
