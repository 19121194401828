import React from 'react';
import moment from 'moment';
import { EDateFormat } from 'common/const/enums.const';
import { PatientDetailItem } from 'entities/Patient/components/PatientDetailsItem';
import { IPatientDetails } from 'entities/Patient/Patient.models';

interface IComponentProps {
  details: IPatientDetails;
}

export const PatientDetails: React.FC<IComponentProps> = ({ details }) => {
  const { gender, height, weight, dob, ccdaLink } = details;
  const age = moment().diff(moment(dob).format(EDateFormat.FullYear), 'years');

  return (
    <div className="patient-details__common">
      <div className="patient-details__info">
        <PatientDetailItem label="Gender" value={gender} />
        <PatientDetailItem label="Age" value={age} />
        <PatientDetailItem label="Height" value={height} />
        <PatientDetailItem label="Weight" value={weight} />
        <PatientDetailItem label="DOB" value={moment(dob).format(EDateFormat.FullYear)} />
      </div>

      {ccdaLink && (
        <a className="patient-details__ccda" onClick={() => window.open(ccdaLink, '_blank')}>
          View CCDA
        </a>
      )}
    </div>
  );
};
