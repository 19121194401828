import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { RootDispatch, RootState } from 'app/store';
import { IEventModel } from 'entities/Event/Event.models';
import { IPatientModel } from 'entities/Patient/Patient.models';
import { PatientDetails } from 'entities/Patient/components/PatientDetails';
import { PatientHistory } from 'entities/Patient/components/PatientHistory';
import { Diagnoses } from 'entities/Diagnoses/components/Diagnoses';
import { IDiagnosesItem } from 'entities/Diagnoses/Diagnoses.models';

interface IComponentProps {
  eventModel: IEventModel;
  patientModel: IPatientModel;
}

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch> & IComponentProps;

const EventPatientDetailsContainer: React.FC<AllType> = (props) => {
  const {
    eventModel: { patientName },
    patientModel: { id: patientId },
    patientCcdaModel: { data: patientCcdaData },
    getPatientCcdaModel,
    addEventDiagnosesCollection,
    setMultiselectDiagnosesModalParams,
  } = props;

  const onSave = (diagnoses: IDiagnosesItem[]) => {
    addEventDiagnosesCollection({
      patientId,
      diagnoses: diagnoses.map((item) => ({ diagnosis: item.displayName, code: item.code })),
      onSuccess: () => {
        setMultiselectDiagnosesModalParams({
          open: false,
          title: null,
          limit: null,
          defaultCcdaDiagnosesValue: null,
          defaultClinicalDiagnosesValue: null,
          defaultIsCcda: true,
          ccdaIsDisabled: false,
        });
      },
    });
  };

  useEffect(() => {
    getPatientCcdaModel(patientId);
  }, []);

  if (!patientCcdaData) {
    return null;
  }

  return (
    <div className="patient-details">
      <PatientDetails
        details={{
          gender: patientCcdaData.ccda?.gender,
          height: patientCcdaData.ccda?.height,
          weight: patientCcdaData.ccda?.weight,
          dob: patientCcdaData.ccda?.dob,
          ccdaLink: patientCcdaData.link,
        }}
      />

      <Diagnoses title="Choose the diagnosis" limit={1} onSave={onSave} />

      <PatientHistory patientId={patientId} patientName={patientName} />
    </div>
  );
};

const mapState = (state: RootState) => ({
  patientCcdaModel: state.patientCcdaModel,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  getPatientCcdaModel: dispatch.patientCcdaModel.getPatientCcdaModel,
  addEventDiagnosesCollection: dispatch.diagnosesCollection.addEventDiagnosesCollection,
  setMultiselectDiagnosesModalParams: dispatch.modals.setMultiselectDiagnosesModalParams,
});

export default connect(mapState, mapDispatch)(EventPatientDetailsContainer);
