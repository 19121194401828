import React from 'react';
import { CommonContentLayout } from 'common/components/CommonContentLayout';
import OperationsBody from 'entities/Operations/components/OperationsBody';
import { OperationsSubheader } from 'entities/Operations/components/OperationsSubheader';

const Operations: React.FC = () => {
  return (
    <CommonContentLayout
      header={<p className="title">Operations</p>}
      subheader={<OperationsSubheader />}
      body={<OperationsBody />}
    />
  );
};

export default Operations;
