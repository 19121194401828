import React from 'react';
import { Radio, Spin } from 'antd';
import { ITaskItem } from 'entities/Tasks/Tasks.models';

interface IComponentProps {
  tasks: ITaskItem[];
  loading?: boolean;
  disabled?: boolean;
  onChange: (task: ITaskItem, status: string) => void;
}

export const Tasks: React.FC<IComponentProps> = ({ tasks, loading, disabled, onChange }) => {
  return (
    <div className="tasks">
      <p className="subtitle">Tasks</p>

      <Spin spinning={loading}>
        <div>
          {tasks.map((task, index) => {
            return (
              <div key={index} className="tasks__item">
                <span>{task.name}:</span>
                <Radio.Group onChange={(e) => onChange(task, e.target.value)} value={task.status} disabled={disabled}>
                  {task.options?.map((option, indx) => {
                    return (
                      <Radio key={indx} value={option.value}>
                        {option.label}
                      </Radio>
                    );
                  })}
                </Radio.Group>
              </div>
            );
          })}
        </div>
      </Spin>
    </div>
  );
};
