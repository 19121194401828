import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { formats, modules } from 'common/const/editor.const';

interface IComponentProps {
  value: string;
  onChange: (value: string) => void;
}

export const Editor: React.FC<IComponentProps> = ({ value, onChange }) => {
  return <ReactQuill className="editor" theme="snow" value={value} onChange={onChange} modules={modules} formats={formats} />;
};
