import React from 'react';
import { connect } from 'react-redux';
import { Button, Modal } from 'antd';
import { DEFAULT_MODAL_WIDTH } from 'common/config';
import { RootState } from 'app/store';

type AllType = ReturnType<typeof mapState>;

const UnenrollPatientModalComponent: React.FC<AllType> = ({ unenrollPatientModalParams }) => {
  const { open, onUnenrollClick, onCancelClick } = unenrollPatientModalParams;

  return (
    <Modal
      title="Are you sure you want to unenroll the patient?"
      open={open}
      footer={false}
      onCancel={onCancelClick}
      width={DEFAULT_MODAL_WIDTH}
    >
      <div className="modal__body">
        <p>
          You will not longer be able to track the service time for this patient. The current billing cycle will be available for
          sending to EMR. You will be able to subscribe the patient again from the Celeri dashboard.
        </p>
      </div>

      <div className="modal__footer">
        <Button className="btn-red" onClick={onUnenrollClick}>
          Unenroll
        </Button>

        <Button onClick={onCancelClick}>Cancel</Button>
      </div>
    </Modal>
  );
};

const mapState = (state: RootState) => ({
  unenrollPatientModalParams: state.modals.unenrollPatientModalParams,
});

export const UnenrollPatientModal = connect(mapState)(UnenrollPatientModalComponent);
