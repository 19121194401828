import React from 'react';
import { connect } from 'react-redux';
import { Button } from 'antd';
import moment from 'moment';
import { EDateFormat } from 'common/const/enums.const';
import { RootDispatch, RootState } from 'app/store';
import { ReactComponent as EditIcon } from 'app/assets/images/svg/editIcon.svg';

interface IComponentProps {
  patientId: string;
  canEdit: string;
  onUnenrollClick?: () => void;
}

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch> & IComponentProps;

const PatientVerbalConsentComponent: React.FC<AllType> = (props) => {
  const {
    patientId,
    canEdit,
    ccmPatientConsentModel,
    onUnenrollClick,
    addCcmPatientConsentModel,
    setCcmPatientVerbalConsentModalParams,
  } = props;
  const { data: consentData, error } = ccmPatientConsentModel;

  const onCancel = () => {
    setCcmPatientVerbalConsentModalParams({ open: false, defaultConsentDate: null });
  };

  const onSave = (date: string) => {
    addCcmPatientConsentModel({ patientId, date, onSuccess: onCancel });
  };

  const handleConsentDateChange = () => {
    setCcmPatientVerbalConsentModalParams({ open: true, defaultConsentDate: consentData?.date, onSave, onCancel });
  };

  return (
    <div>
      {consentData ? (
        <div className="patient-details__consent-wrapper">
          <div className="patient-details__consent-data">
            <span>Verbal Consent Obtained:</span>
            <span>{moment(consentData.date).format(EDateFormat.FullYear)}</span>
          </div>

          {canEdit && (
            <Button className="btn-view-link patient-details__edit-btn" icon={<EditIcon />} onClick={handleConsentDateChange}>
              Update
            </Button>
          )}

          {onUnenrollClick && canEdit && (
            <Button className="btn-red-link" onClick={onUnenrollClick}>
              Unenroll
            </Button>
          )}
        </div>
      ) : (
        <Button
          className={`patient-details__choose-btn ${error && 'patient-details__choose-btn--error'}`}
          onClick={handleConsentDateChange}
        >
          Specify Consent Obtained Date
        </Button>
      )}
    </div>
  );
};

const mapState = (state: RootState) => ({
  ccmPatientConsentModel: state.ccmPatientConsentModel,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  addCcmPatientConsentModel: dispatch.ccmPatientConsentModel.addCcmPatientConsentModel,
  setCcmPatientVerbalConsentModalParams: dispatch.modals.setCcmPatientVerbalConsentModalParams,
});

export const PatientVerbalConsent = connect(mapState, mapDispatch)(PatientVerbalConsentComponent);
