import React from 'react';
import { Input } from 'antd';
import { padTo2Digits } from 'common/helpers/time.helper';

interface IComponentProps {
  value: number;
  setValue: (value: number) => void;
  hours?: boolean;
}

export const TimeEditorItem: React.FC<IComponentProps> = (props) => {
  const { value, setValue, hours } = props;

  const changeCount = (number: number) => {
    if (!hours && number >= 60) {
      return;
    }
    setValue(number);
  };

  return (
    <div className="time-editor">
      <div
        className="time-editor__btn_increase"
        onClick={() => {
          if (value >= 59) {
            changeCount(0);
          } else {
            changeCount(value + 1);
          }
        }}
      />

      <Input
        className="time-editor__input"
        type="number"
        value={padTo2Digits(value)}
        onChange={(e) => changeCount(Number(e.target.value))}
      />

      <div
        className="time-editor__btn_decrease"
        onClick={() => {
          if (value <= 0) {
            changeCount(59);
          } else {
            changeCount(value - 1);
          }
        }}
      />
    </div>
  );
};
