import React from 'react';
import { connect } from 'react-redux';
import { Spin } from 'antd';
import { ERole } from 'common/const/enums.const';
import { convertToTimerFormat } from 'common/helpers/time.helper';
import { RootState } from 'app/store';
import { StatisticsItem } from 'entities/Statistics/components/StatisticsItem';

interface IComponentProps {
  role?: string;
}

type AllType = ReturnType<typeof mapState> & IComponentProps;

const EventsSubheaderComponent: React.FC<AllType> = ({ role, statistics, statisticsLoading }) => {
  return (
    <Spin spinning={!statistics || statisticsLoading}>
      <div className="statistics__container">
        {role === ERole.Staff && <StatisticsItem title="Escalated" subtitle="to teammates" count={statistics?.escalatedCount} />}
        <StatisticsItem
          title={role === ERole.Staff ? 'Pending for team' : 'My pending events'}
          count={statistics?.pendingCount}
          subtitle={statistics?.urgentCount ? `(${statistics?.urgentCount} urgent)` : undefined}
          highlighter="red"
        />
        <StatisticsItem title="Completed" subtitle="for today" count={statistics?.completedTodayCount} />
        <StatisticsItem
          title="Total review time for"
          subtitle="today"
          count={convertToTimerFormat(statistics?.totalTimeToday)}
          isPrimary
        />
      </div>
    </Spin>
  );
};

const mapState = (state: RootState) => ({
  statistics: state.statistics.dashboardStatisticsData,
  statisticsLoading: state.statistics.loading,
});

export const EventsSubheader = connect(mapState)(EventsSubheaderComponent);
