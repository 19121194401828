import React, { useState } from 'react';
import { Button } from 'antd';
import { connect } from 'react-redux';
import { IdleTimer } from 'common/components/IdleTimer';
import { EVENT_IDLE_LIMIT_MS } from 'common/config';
import { RootDispatch, RootState, history } from 'app/store';
import { Phone } from 'entities/Twilio/components/Phone';
import { PatientTimer } from 'entities/Timer/components/PatientTimer';
import { noCommentProvidedMessage } from 'entities/ReviewHistory/ReviewHistory.const';
import { getCCMBillingCycleAvailableOperations } from 'entities/Billing/Billing.helper';

interface IComponentProps {
  billingCycleId?: string;
  autoStartTimer: boolean;
}

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch> & IComponentProps;

const PatientActionsCardComponent: React.FC<AllType> = (props) => {
  const {
    billingCycleId,
    autoStartTimer,
    ccmBillingCycleModel,
    ccmPatientModel,
    isRunning,
    getTwilioModel,
    addPatientCommentHistory,
    addPatientCallHistory,
    setCallModalParams,
    setCommentModalParams,
    showPatientInactiveModal,
    setInactiveModalParams,
    setExportedEventModalParams,
    startPatientTimer,
    stopPatientTimer,
  } = props;
  const [onCall, setOnCall] = useState<boolean>(false);
  const { data: billingCycleModelData } = ccmBillingCycleModel;
  const { data: patientModelData } = ccmPatientModel;
  const patientEnrolled = patientModelData?.isCCMEnrolled;
  const patientName = patientModelData?.name;
  const phone = patientModelData?.phone || '';
  const { canStartTimer, canEditTimer, canCall } = getCCMBillingCycleAvailableOperations(billingCycleModelData?.status);

  const handleCommentModalSend = (billingCycleId: string, comment: string) => {
    addPatientCommentHistory({ id: billingCycleId, comment }).then(() => {
      setCommentModalParams({ open: false, externalId: null });
    });
  };

  const handleCommentModalCancel = () => {
    setCommentModalParams({ open: false, externalId: null });
  };

  const onCommentClick = () => {
    if (billingCycleId) {
      setCommentModalParams({
        open: true,
        externalId: billingCycleId,
        handleSend: handleCommentModalSend,
        handleCancel: handleCommentModalCancel,
      });
    }
  };

  const makeCall = () => {
    getTwilioModel({
      onSuccess: () => {
        if (billingCycleId) {
          addPatientCallHistory(billingCycleId);
        }
      },
    }).then((token) => setCallModalParams({ open: true, token, phone, patientName, setOnCall }));
  };

  const onCallClick = () => {
    if (canCall) {
      if (canStartTimer && !isRunning) {
        if (billingCycleId) {
          startPatientTimer(billingCycleId).then(() => {
            makeCall();
          });
        }
      } else {
        makeCall();
      }
    } else {
      setExportedEventModalParams({ open: true });
    }
  };

  const onPauseClick = () => {
    setInactiveModalParams({ open: false, title: null, description: null });
  };

  const onResumeClick = () => {
    if (billingCycleId) {
      startPatientTimer(billingCycleId).then(() => {
        onPauseClick();
      });
    }
  };

  const onMoveClick = () => {
    history.back();
  };

  return (
    <div className="actions-card">
      <div className="actions-card__item">
        <PatientTimer
          billingCycleId={billingCycleId}
          autoStartTimer={autoStartTimer}
          canStart={canStartTimer && patientEnrolled}
          canEdit={canEditTimer}
          billingCycleStartDate={billingCycleModelData?.startDate}
          billingCycleEndDate={billingCycleModelData?.endDate}
        />
      </div>

      <div className="actions-card__item">
        <Phone phone={phone} onCallClick={onCallClick} />
      </div>

      <div className="actions-card__item">
        <div className="actions-card__actions">
          <Button icon={<div className="icon actions-card__comment-icon" />} onClick={onCommentClick}>
            Leave a comment
          </Button>
        </div>
      </div>

      {isRunning && !onCall && (
        <IdleTimer
          timeout={EVENT_IDLE_LIMIT_MS}
          onIdle={() => {
            if (billingCycleId) {
              stopPatientTimer({ billingCycleId, comment: noCommentProvidedMessage }).then(() => {
                showPatientInactiveModal({
                  open: true,
                  title: 'The timer is stopped',
                  description: (
                    <>
                      <span>We stopped the timer due to non interaction.</span>
                      <span>Are you still reviewing?</span>
                    </>
                  ),
                  onResumeClick,
                  onPauseClick,
                  onMoveClick,
                });
              });
            }
          }}
        />
      )}
    </div>
  );
};

const mapState = (state: RootState) => ({
  ccmBillingCycleModel: state.ccmBillingCycleModel,
  ccmPatientModel: state.ccmPatientModel,
  isRunning: state.patientTimerModel.isRunning,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  getTwilioModel: dispatch.twilioModel.getTwilioModel,
  addPatientCommentHistory: dispatch.patientReviewHistoryModel.addPatientCommentHistory,
  addPatientCallHistory: dispatch.patientReviewHistoryModel.addPatientCallHistory,
  setCallModalParams: dispatch.modals.setCallModalParams,
  setCommentModalParams: dispatch.modals.setCommentModalParams,
  showPatientInactiveModal: dispatch.modals.showPatientInactiveModal,
  setInactiveModalParams: dispatch.modals.setInactiveModalParams,
  setExportedEventModalParams: dispatch.modals.setExportedEventModalParams,
  startPatientTimer: dispatch.patientTimerModel.startPatientTimer,
  stopPatientTimer: dispatch.patientTimerModel.stopPatientTimer,
});

export const PatientActionsCard = connect(mapState, mapDispatch)(PatientActionsCardComponent);
