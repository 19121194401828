import { DEFAULT_PAGE_SIZE } from 'common/config';
import { ECCMBillingStatuses, EStatuses } from 'common/const/enums.const';
import {
  BillingCycleOrderFieldType,
  CcmBillingCycleOrderFieldType,
  IBillingCycleEvent,
  IBillingCycleParams,
  IBillingCycleQueryParameters,
  IBillingCycleQueryStatuses,
  ICcmBillingCycleCollectionQueryParams,
} from 'entities/Billing/Billing.models';

export const billingEventToDataSourseMapper = (data?: IBillingCycleEvent[]) => {
  return data
    ?.map((event) =>
      event.details.map((item, index) => ({
        key: `${event.eventId}${index}`,
        eventId: event.eventId,
        eventType: event.eventType,
        eventDate: event.startedDate,
        staffMember: item.staffName,
        minutes: item.spentTime,
        comment: item.comments,
      }))
    )
    .flat();
};

export const billingQueryParamsTo = (params: IBillingCycleParams, pageId?: number): IBillingCycleQueryParameters => {
  const { status, ...restParams } = params;
  const eventState = Object.keys(EStatuses)[status as number];
  let isCompleted;
  let isBilled;
  let isBillable;

  if (eventState === EStatuses.Completed) {
    isCompleted = true;
    isBilled = false;
    isBillable = true;
  }

  if (eventState === EStatuses.Closed) {
    isCompleted = true;
    isBilled = true;
  }

  if (eventState === EStatuses.Pending) {
    isCompleted = false;
    isBilled = false;
  }

  return {
    ...restParams,
    orderField: params.orderField as BillingCycleOrderFieldType,
    orderDirection: params.orderDirection as 'ASC' | 'DESC',
    isCompleted,
    isBilled,
    isBillable,
    offset: pageId && DEFAULT_PAGE_SIZE * (pageId - 1),
    limit: pageId && DEFAULT_PAGE_SIZE,
  };
};

export const ccmBillingQueryParamsTo = (params: IBillingCycleParams, pageId?: number): ICcmBillingCycleCollectionQueryParams => {
  return {
    ...params,
    status: params.status !== ECCMBillingStatuses.All ? (params.status as IBillingCycleQueryStatuses) : undefined,
    isBillable: params.status === ECCMBillingStatuses.Completed ? true : undefined,
    searchWithPhysician: false,
    orderField: params.orderField as CcmBillingCycleOrderFieldType,
    orderDirection: params.orderDirection as 'ASC' | 'DESC',
    offset: pageId && DEFAULT_PAGE_SIZE * (pageId - 1),
    limit: pageId && DEFAULT_PAGE_SIZE,
  };
};
