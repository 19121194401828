import axios from 'axios';
import { IAuthModel, IAuthModelRequestParams } from 'entities/Auth/Auth.models';

const basePath = 'auth';

export const authTransport = {
  addAuthModel: (params: IAuthModelRequestParams): Promise<IAuthModel> =>
    axios.post(`${basePath}`, { email: params.email, password: params.password }),
  refreshToken: (refreshToken: string): Promise<IAuthModel> => axios.put(`${basePath}`, { refreshToken }),
  deleteAuthModel: (): Promise<void> => axios.delete(`${basePath}`),
};
