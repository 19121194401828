// According to https://dev.celerihealth.com/home/resources/assessment-types?assessment-types_page=1
export const assessmentNames: { [key: number]: string } = {
  1: 'PROMIS-29',
  2: 'ORT',
  3: 'ZCQ',
  4: 'ODI',
  5: 'mJOA',
  6: 'NDI',
  10: 'SIC',
  12: 'NPP',
  13: 'PPMM',
  14: 'VAS',
  15: 'BM',
  16: 'PGIC',
  17: 'PI',
  18: 'COVID19',
  19: 'BMNRS',
  20: 'PGICPPR',
  21: 'SAP',
  22: 'PCL-C',
  23: 'CNR-NRS',
  24: 'CNR-ZCQPS',
  25: 'PANRS',
  26: 'PCS',
  27: 'PGICPPRG',
  28: 'ZCQPFSS',
  29: 'ZCQFULL',
  30: 'VASB',
  31: 'VASL',
  32: 'RMDQ',
  34: 'DPE',
  35: 'DSE',
  36: 'PHQ',
  37: 'GAD',
  38: 'ZCQPS',
  39: 'PNPC',
  40: 'PCLPM',
  41: 'PCLPW',
  51: 'AUDIT',
  52: 'ADAM',
  53: 'RBA',
  54: 'BMA',
  55: 'BMPH',
  56: 'BMM',
  57: 'DU',
  58: 'CBCQ',
  59: 'CFCQ',
  62: 'BMPHFU',
  63: 'TPSGOALS',
  64: 'TPSMEDS',
  65: 'TPSCF',
  66: 'TVHCF',
  67: 'TPSDR',
  68: 'hrsnst',
  69: 'WCS',
  70: 'em billing',
  71: 'evoke',
  72: 'saluda_pgic',
  73: 'p29_2',
  74: 'rbintake',
  75: 'ssa',
  76: 'ssafu',
  77: 'CISM',
  78: 'CISV',
  79: 'DT',
  80: 'DT_FOLLOWUP',
  81: 'CCM BILLING',
  82: 'RMFU',
  83: 'RNRSPAFU',
  84: 'RES',
  85: 'RFBQ',
  86: 'RDQ',
  87: 'SPARTAN_NRS',
  88: 'QBPDS',
  89: 'pain_rtw_disability',
  90: 'phq2',
  91: 'gad2',
  92: 'cbrief',
  93: 'barc10',
  94: 'pss',
  95: 'dast10',
  96: 'patient_transcription_consent',
  97: 'MM',
  98: 'grmcdu',
  99: 'madrs',
  100: 'bpeq',
  101: 'nrs_scs',
  102: 'pgic_prospera',
  103: 'ppr_prospera',
  104: 'bmsa',
};

export enum EAssessmentType {
  diagnostic = 'diagnostic', // 1
  screening = 'screening', // 2
  zcq = 'zcq', // 3
  odi = 'odi', // 4
  mjoa = 'mjoa', // 5
  ndi = 'ndi', // 6
  sic = 'sic', // 10
  npp = 'npp', // 12
  ppmm = 'ppmm', // 13
  vas = 'vas', // 14
  bm = 'bm', // 15
  pgic = 'pgic', // 16
  pi = 'pi', // 17
  covid19 = 'covid19', // 18
  bmnrs = 'bmnrs', // 19
  pgicppr = 'pgicppr', // 20
  sap = 'sap', // 21
  pclc = 'pclc', // 22
  cnrnrs = 'cnrnrs', // 23
  cnrzcqps = 'cnrzcqps', // 24
  panrs = 'panrs', // 25
  pcs = 'pcs', // 26
  pgicpprg = 'pgicpprg', // 27
  zcqpfss = 'zcqpfss', // 28
  zcqfull = 'zcqfull', // 29
  vasb = 'vasb', // 30
  vasl = 'vasl', // 31
  rmdq = 'rmdq', // 32
  csi = 'csi', // 33
  dpe = 'dpe', // 34
  dse = 'dse', // 35
  phq = 'phq', // 36
  gad = 'gad', // 37
  zcqps = 'zcqps', // 38
  pnpc = 'pnpc', // 39
  pclpm = 'pclpm', // 40
  pclpw = 'pclpw', // 41
  audit = 'audit', // 51
  adam = 'adam', // 52
  rba = 'rba', // 53
  bma = 'bma', // 54
  bmph = 'bmph', // 55
  bmm = 'bmm', // 56
  du = 'du', // 57
  cbcq = 'cbcq', // 58
  cfcq = 'cfcq', // 59
  bmphfu = 'bmphfu', // 62
  tpsgoals = 'tpsgoals', // 63
  tpsmeds = 'tpsmeds', // 64
  tpscf = 'tpscf', // 65
  tvhcf = 'tvhcf', // 66
  tpsdr = 'tpsdr', // 67
  hrsn_screening_tool = 'hrsn_screening_tool', // 68
  wcs = 'wcs', // 69
  embilling = 'embilling', // 70
  evoke = 'evoke', // 71
  saluda_pgic = 'saluda_pgic', // 72
  p29_2 = 'p29_2', // 73
  rbintake = 'rbintake', // 74
  ssa = 'ssa', // 75
  ssafu = 'ssafu', // 76
  cism = 'cism', // 77
  cisv = 'cisv', // 78
  dt = 'dt', // 79
  dt_followup = 'dt_followup', // 80
  ccmbilling = 'ccmbilling', // 81
  rmfu = 'rmfu', // 82
  rnrspafu = 'rnrspafu', // 83
  res = 'res', // 84
  rfbq = 'rfbq', // 85
  rdq = 'rdq', // 86
  spartan_nrs = 'spartan_nrs', // 87
  qbpds = 'qbpds', // 88
  pain_rtw_disability = 'pain_rtw_disability', // 89
  phq2 = 'phq2', // 90
  gad2 = 'gad2', // 91
  columbia_brief = 'columbia_brief', // 92
  brief_assessment_of_recovery_capital = 'brief_assessment_of_recovery_capital', // 93
  perceived_stress_scale = 'perceived_stress_scale', // 94
  drug_abuse_screening_test_dast_10 = 'drug_abuse_screening_test_dast_10', // 95
  patient_transcription_consent = 'patient_transcription_consent', // 96
  manifesto_mindset = 'manifesto_mindset', // 97
  grmcdu = 'grmcdu', // 98
  MADRS = 'MADRS', // 99
  bpeq = 'bpeq', // 100
  nrs_scs = 'nrs_scs', // 101
  pgic_prospera = 'pgic_prospera', // 102
  ppr_prospera = 'ppr_prospera', // 103
  body_map_single = 'body_map_single', // 104
}
