import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { useBlocker } from 'common/hooks/useBlocker';
import { ITransition } from 'common/models';
import { EActionTypes, EEnrollmentsStatuses } from 'common/const/enums.const';
import { RootDispatch, RootState } from 'app/store';

interface IComponentProps {
  enrollmentId?: string;
  canStart: boolean;
}

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch> & IComponentProps;

const EnrollmentRouterHandlerComponent: React.FC<AllType> = (props) => {
  const { enrollmentId, canStart, actionParams, changeEnrollmentStatus, setInactiveModalParams, setCancelModalParams } = props;

  const blocker = useCallback(
    (autoUnblockingTx: ITransition) => {
      switch (true) {
        case actionParams.type === EActionTypes.Complete: {
          if (enrollmentId) {
            changeEnrollmentStatus({ enrollmentId, status: EEnrollmentsStatuses.Completed }).then(() => {
              autoUnblockingTx.retry();
            });
          }
          break;
        }
        case actionParams.type === EActionTypes.Cancel: {
          if (enrollmentId) {
            changeEnrollmentStatus({ enrollmentId, status: EEnrollmentsStatuses.Cancelled }).then(() => {
              setCancelModalParams({ open: false, title: null, description: null });
              autoUnblockingTx.retry();
            });
          }
          break;
        }
        case actionParams.type === EActionTypes.Move:
        case canStart && autoUnblockingTx.action === 'PUSH':
        case canStart && autoUnblockingTx.action === 'POP' && !actionParams.type:
        case canStart && actionParams.type === EActionTypes.Back: {
          if (enrollmentId) {
            changeEnrollmentStatus({ enrollmentId, status: EEnrollmentsStatuses.Inbox }).then(() => {
              setInactiveModalParams({ open: false, title: null, description: null });
              autoUnblockingTx.retry();
            });
          }
          break;
        }
        default: {
          autoUnblockingTx.retry();
          break;
        }
      }
    },
    [enrollmentId, actionParams.type]
  );

  useBlocker(true, blocker);

  return null;
};

const mapState = (state: RootState) => ({
  actionParams: state.actionModel.actionParams,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  changeEnrollmentStatus: dispatch.actionModel.changeEnrollmentStatus,
  setInactiveModalParams: dispatch.modals.setInactiveModalParams,
  setCancelModalParams: dispatch.modals.setCancelModalParams,
});

export const EnrollmentRouterHandler = connect(mapState, mapDispatch)(EnrollmentRouterHandlerComponent);
