import React from 'react';
import { connect } from 'react-redux';
import { Button, Modal } from 'antd';
import { DEFAULT_MODAL_WIDTH } from 'common/config';
import { RootDispatch, RootState } from 'app/store';

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

const TimingsSaveModal: React.FC<AllType> = ({ timingsSaveModalParams, setTimingsSaveModalParams, createTimingModel }) => {
  const { open, type, items, setFormChanged } = timingsSaveModalParams;

  const toggleModal = () => setTimingsSaveModalParams({ open: false, type: null, items: null });

  const onSaveClick = () => {
    if (type && items) {
      createTimingModel({ type, items }).then(() => {
        setFormChanged?.(false);
        toggleModal();
      });
    }
  };

  return (
    <Modal open={open} footer={false} onCancel={toggleModal} width={DEFAULT_MODAL_WIDTH}>
      <div className="modal__body">
        <p>
          You are updating the CPT codes tables. New changes will be applied to all not closed billing cycles along all future
          billings. Are you sure?
        </p>
      </div>

      <div className="modal__footer">
        <Button className="btn-primary" onClick={onSaveClick}>
          Save
        </Button>

        <Button onClick={toggleModal}>Cancel</Button>
      </div>
    </Modal>
  );
};

const mapState = (state: RootState) => ({
  timingsSaveModalParams: state.modals.timingsSaveModalParams,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  setTimingsSaveModalParams: dispatch.modals.setTimingsSaveModalParams,
  createTimingModel: dispatch.timingModel.createTimingModel,
});

export default connect(mapState, mapDispatch)(TimingsSaveModal);
