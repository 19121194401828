import axios from 'axios';
import { IActionRequestParams, ICCMActionPayload } from 'entities/Actions/Actions.models';
import { IEventModel } from 'entities/Event/Event.models';

const basePath = 'action';
const ccmPath = 'ccm/enrollments/status';

export const actionsTransport = {
  reviewEvent: (eventId: number): Promise<IEventModel> => axios.patch(`${basePath}/review/${eventId}`),
  completeEvent: ({ eventId, text }: IActionRequestParams): Promise<void> =>
    axios.patch(`${basePath}/complete/${eventId}`, { text }),
  escalateEvent: ({ eventId, ...restParams }: IActionRequestParams): Promise<void> =>
    axios.patch(`${basePath}/escalate/${eventId}`, { ...restParams }),
  commentEvent: ({ eventId, text }: IActionRequestParams): Promise<void> =>
    axios.patch(`${basePath}/comment/${eventId}`, { text }),
  returnEvent: (eventId: number): Promise<void> => axios.patch(`${basePath}/return/${eventId}`),
  changeEnrollmentStatus: (payload: ICCMActionPayload): Promise<void> => axios.patch(`${ccmPath}`, payload),
};
