import React, { useEffect, useState } from 'react';
import { Button, Input, Modal, Select } from 'antd';
import { connect } from 'react-redux';
import { DEFAULT_MODAL_WIDTH } from 'common/config';
import { useAutoFocus } from 'common/hooks/useAutoFocus';
import { RootDispatch, RootState } from 'app/store';

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

const EscalateModal: React.FC<AllType> = React.memo(({ escalateModalParams, setEscalateModalParams }) => {
  const { open, eventId, physician, physiciansCollection, handleSend } = escalateModalParams;
  const [text, setText] = useState<string>('');
  const [selectedPhysician, setSelectedPhysician] = useState<string>();
  const textareaRef = useAutoFocus();

  const onCancelClick = () => {
    setText('');
    setEscalateModalParams({ open: false, eventId: null, physician: null, physiciansCollection: null });
  };

  const onSendClick = () => {
    const physician = physiciansCollection?.find((item) => item.name === selectedPhysician);

    if (eventId && physician) {
      handleSend?.(eventId, text, physician.id);
      setText('');
      setEscalateModalParams({ open: false, eventId: null, physician: null, physiciansCollection: null });
    }
  };

  const handlePhysicianChange = (value: string) => {
    setSelectedPhysician(value);
  };

  useEffect(() => {
    if (physician) {
      setSelectedPhysician(physician);
    }
  }, [physician]);

  return (
    <Modal title="Escalate to a physician" open={open} footer={false} onCancel={onCancelClick} width={DEFAULT_MODAL_WIDTH}>
      <div className="modal__body">
        <Select
          className="modal__select"
          placeholder="Select physician"
          value={selectedPhysician}
          onSelect={handlePhysicianChange}
        >
          {physiciansCollection?.map((item) => {
            return (
              <Select.Option key={item.id} value={item.name}>
                {item.name}
              </Select.Option>
            );
          })}
        </Select>

        <Input.TextArea
          placeholder="Message"
          rows={6}
          value={text}
          onChange={(e) => setText(e.target.value)}
          ref={textareaRef}
          autoFocus
        />
      </div>

      <div className="modal__footer">
        <Button className="btn-primary" onClick={onSendClick} disabled={!text.length}>
          Send
        </Button>
        <Button onClick={onCancelClick}>Cancel</Button>
      </div>
    </Modal>
  );
});

const mapState = (state: RootState) => ({
  escalateModalParams: state.modals.escalateModalParams,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  setEscalateModalParams: dispatch.modals.setEscalateModalParams,
});

export default connect(mapState, mapDispatch)(EscalateModal);
