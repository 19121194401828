import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Button } from 'antd';
import { EDateFormat } from 'common/const/enums.const';
import { RootDispatch, RootState } from 'app/store';

interface IComponentProps {
  patientId: string;
  patientName: string;
}

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch> & IComponentProps;

const PatientHistoryComponent: React.FC<AllType> = (props) => {
  const { patientId, patientName, patientHistoryCollection, getPatientHistoryCollection, setPatientHistoryModalParams } = props;
  const { data: patientHistoryData } = patientHistoryCollection;

  const onSeeHistoryClick = () => {
    setPatientHistoryModalParams({ open: true, patientId, patientName });
  };

  useEffect(() => {
    getPatientHistoryCollection({ patientId, limit: 1 });
  }, []);

  if (!patientHistoryData || !patientHistoryData.data.length) {
    return null;
  }

  return (
    <div className="patient-details__history_container">
      <span className="patient-details__history_date">
        Last updated:
        <span>{moment(patientHistoryData.data[0].createdAt).format(EDateFormat.ShortYear12hm)}</span>
      </span>

      <Button className="btn-link patient-details__history_link" onClick={onSeeHistoryClick}>
        See history
      </Button>
    </div>
  );
};

const mapState = (state: RootState) => ({
  patientHistoryCollection: state.patientHistoryCollection,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  getPatientHistoryCollection: dispatch.patientHistoryCollection.getPatientHistoryCollection,
  setPatientHistoryModalParams: dispatch.modals.setPatientHistoryModalParams,
});

export const PatientHistory = connect(mapState, mapDispatch)(PatientHistoryComponent);
