import React, { ChangeEvent, useState } from 'react';
import { Input } from 'antd';

interface IComponentProps {
  value?: string;
  placeholder: string;
  onChange: (value: string) => void;
}

export const SearchInput: React.FC<IComponentProps> = ({ value = '', placeholder, onChange }) => {
  const [searchValue, setSearchValue] = useState<string>(value);

  const handleValueChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  const handlePressEnter = () => {
    onChange(searchValue);
  };

  return (
    <Input
      placeholder={placeholder}
      suffix={<div className="icon search-input-suffix" />}
      value={searchValue}
      onChange={handleValueChange}
      onPressEnter={handlePressEnter}
    />
  );
};
