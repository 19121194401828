import React from 'react';
import { connect } from 'react-redux';
import { Button, Modal, Table } from 'antd';
import moment from 'moment';
import { EDateFormat, EProgramType } from 'common/const/enums.const';
import { RootDispatch, RootState } from 'app/store';
import {
  filterTimingItems,
  mapMonthlyItems,
  mapOneTimeItems,
  renderMonthlyItemsRecords,
  renderOneTimeItemsRecords,
} from 'entities/Timings/Timings.helper';

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

const TimingsHistoryModal: React.FC<AllType> = ({ timingsHistoryModalParams, setTimingsHistoryModalParams }) => {
  const { open, type, timingModel } = timingsHistoryModalParams;

  const toggleModal = () => setTimingsHistoryModalParams({ open: false, type: null, timingModel: null });

  if (!timingModel) {
    return null;
  }

  const { createdAt, userName, items } = timingModel;
  const disabled = true;
  const { monthlyItems, oneTimeItems } = filterTimingItems(items);
  const mappedMonthlyItems = mapMonthlyItems(monthlyItems);
  const mappedOneTimeItems = mapOneTimeItems(oneTimeItems);
  const oneTimeItemsColumns = renderOneTimeItemsRecords();
  const monthlyItemsColumns = renderMonthlyItemsRecords(disabled);

  return (
    <Modal
      title={`${userName}: ${moment(createdAt).format(EDateFormat.ShortYear12hm)}`}
      open={open}
      footer={false}
      onCancel={toggleModal}
      width={893}
    >
      <div className="timings-body" style={{ marginTop: 20 }}>
        {type === EProgramType.CCM && (
          <div>
            <div className="timings-body__label">Fixed Fees</div>

            <Table dataSource={mappedOneTimeItems} columns={oneTimeItemsColumns} pagination={false} />
          </div>
        )}

        <div>
          <div className="timings-body__label">Hourly Rates</div>

          <Table dataSource={mappedMonthlyItems} columns={monthlyItemsColumns} pagination={false} />
        </div>
      </div>

      <Button onClick={toggleModal}>Cancel</Button>
    </Modal>
  );
};

const mapState = (state: RootState) => ({
  timingsHistoryModalParams: state.modals.timingsHistoryModalParams,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  setTimingsHistoryModalParams: dispatch.modals.setTimingsHistoryModalParams,
});

export default connect(mapState, mapDispatch)(TimingsHistoryModal);
