import React, { useEffect } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { EReviewHistoryActionTypes, EReviewHistoryActionTitles, EDateFormat } from 'common/const/enums.const';
import { RootDispatch, RootState } from 'app/store';
import { IEnrollmentHistoryItem } from 'entities/ReviewHistory/ReviewHistory.models';
import { ReviewHistory } from 'entities/ReviewHistory/components/ReviewHistory';

interface IComponentProps {
  enrollmentId: string;
}

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch> & IComponentProps;

const EnrollmentReviewHistoryComponent: React.FC<AllType> = (props) => {
  const { enrollmentId, getEnrollmentReviewHistoryModel, enrollmentReviewHistoryModel } = props;
  const { data: reviewHistoryData, loading } = enrollmentReviewHistoryModel;

  const renderHistoryDescription = (item: IEnrollmentHistoryItem) => {
    if (item.type === EReviewHistoryActionTypes.comment_is_left) {
      return <div className="history-card__item_description history-card__item_comment">{item.comment}</div>;
    }

    if (item.type === EReviewHistoryActionTypes.received) {
      return (
        <div className="history-card__item_description history-card__item_comment">
          {EReviewHistoryActionTitles[`enrollment_${item.type}`]}
        </div>
      );
    }

    return (
      <div className="history-card__item_description">
        <div>{EReviewHistoryActionTitles[item.type]}</div>
        {item.comment && <div className="history-card__item_comment">{item.comment}</div>}
      </div>
    );
  };

  useEffect(() => {
    getEnrollmentReviewHistoryModel(enrollmentId);
  }, []);

  if (!reviewHistoryData) {
    return null;
  }

  return (
    <ReviewHistory loading={loading}>
      {reviewHistoryData?.data.map((item) => {
        return (
          <div key={item.id} className="history-card__item">
            <div className="history-card__item_header">
              <span>{moment(item.createdAt).format(EDateFormat.FullYear12hm)}</span>
              {item.type !== EReviewHistoryActionTypes.received && <span>by: {item.user?.name}</span>}
            </div>

            {renderHistoryDescription(item)}
          </div>
        );
      })}
    </ReviewHistory>
  );
};

const mapState = (state: RootState) => ({
  enrollmentReviewHistoryModel: state.enrollmentReviewHistoryModel,
});

const mapDispatch = (dispatch: RootDispatch) => ({
  getEnrollmentReviewHistoryModel: dispatch.enrollmentReviewHistoryModel.getEnrollmentReviewHistoryModel,
});

export const EnrollmentReviewHistory = connect(mapState, mapDispatch)(EnrollmentReviewHistoryComponent);
