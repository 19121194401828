import React from 'react';
import { Radio, RadioChangeEvent } from 'antd';
import { ECcmAssessmentAnswer } from 'entities/Assessment/enums';

interface IComponentProps {
  title: string;
  disabled: boolean;
  onChange: (e: RadioChangeEvent) => void;
  value?: string;
}

export const AssessmentRadio: React.FC<IComponentProps> = ({ title, value, onChange, disabled }) => {
  return (
    <>
      <p className="assessment__question_title">{title}</p>
      <Radio.Group
        className="assessment__answer_radio"
        value={value || ECcmAssessmentAnswer.NA}
        disabled={disabled}
        onChange={onChange}
      >
        <Radio value={ECcmAssessmentAnswer.Yes}>Yes</Radio>
        <Radio value={ECcmAssessmentAnswer.No}>No</Radio>
        <Radio value={ECcmAssessmentAnswer.NA}>N/A</Radio>
      </Radio.Group>
    </>
  );
};
