import axios from 'axios';
import {
  IBillingCycleCollection,
  IBillingCycleEvent,
  IBillingCycleQueryParameters,
  ICcmBillingCycleCollection,
  ICcmBillingCycleCollectionQueryParams,
  ICcmBillingCycleModel,
} from 'entities/Billing/Billing.models';

const basePath = 'billing-cycles';
const ccmPath = 'ccm/billing-cycles';

export const billingTransport = {
  getBillingCollection: (params: IBillingCycleQueryParameters): Promise<IBillingCycleCollection> =>
    axios.get(`${basePath}`, { params }),
  getBillingEventsByIdCollection: (cycleId: number): Promise<IBillingCycleEvent[]> => axios.get(`${basePath}/${cycleId}/events`),
  getBillingReport: (params: IBillingCycleQueryParameters): Promise<File> => axios.get(`${basePath}/export`, { params }),
  sendBillingCycleIds: (billingCycleIds: number[]): Promise<void> => axios.post(`${basePath}/send`, { billingCycleIds }),
  getCcmBillingCollection: (params: ICcmBillingCycleCollectionQueryParams): Promise<ICcmBillingCycleCollection> =>
    axios.get(`${ccmPath}`, { params }),
  getCcmBillingReport: (params: ICcmBillingCycleCollectionQueryParams): Promise<File> =>
    axios.get(`${ccmPath}/export`, { params }),
  sendCcmBillingCycleIds: (billingCycleIds: string[]): Promise<void> => axios.post(`${ccmPath}/send`, { billingCycleIds }),
  getCcmBillingCycleById: (billingCycleId: string): Promise<ICcmBillingCycleModel> => axios.get(`${ccmPath}/${billingCycleId}`),
  rejectCcmBillingCycleIds: (billingCycleIds: string[]): Promise<void> => axios.post(`${ccmPath}/reject`, { billingCycleIds }),
};
