import axios from 'axios';
import {
  IPatientsCollection,
  IPatientsCollectionQueryParams,
  IEnrollmentCollection,
  IEnrollmentCollectionQueryParams,
  IEventsCollection,
  IEventsQueryParameters,
  IDashboardInfoModel,
  IEventByIdModel,
} from 'entities/Dashboard/Dashboard.models';

const basePath = 'events';
const ccmPath = 'ccm';

export const dashboardTransport = {
  getEventsCollection: (params: IEventsQueryParameters): Promise<IEventsCollection> => axios.get(`${basePath}`, { params }),
  getEnrollmentsCollection: (params: IEnrollmentCollectionQueryParams): Promise<IEnrollmentCollection> =>
    axios.get(`${ccmPath}/enrollments`, { params }),
  getPatientsCollection: (params: IPatientsCollectionQueryParams): Promise<IPatientsCollection> =>
    axios.get(`${ccmPath}/billing-cycles`, { params }),
  getDashboardInfo: (): Promise<IDashboardInfoModel> => axios.get(`dashboard/info`),
  getEventById: (eventId: number): Promise<IEventByIdModel> => axios.get(`${basePath}/review/${eventId}`),
};
