import { useEffect } from 'react';

export const useBeforeUnload = (dependencies: any[], callback: () => void) => {
  useEffect(() => {
    window.addEventListener('beforeunload', callback);

    return () => {
      window.removeEventListener('beforeunload', callback);
    };
  }, [...dependencies]);
};
