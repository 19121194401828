import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Button, Pagination, Table } from 'antd';
import { useParams } from 'react-router-dom';
import { DEFAULT_PAGE_ID, DEFAULT_PAGE_SIZE } from 'common/config';
import { RootDispatch, RootState } from 'app/store';
import { renderTimingsHistoryRecord } from 'entities/Timings/Timings.helper';
import { ITimingModel } from 'entities/Timings/Timings.models';

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

const AuditBody: React.FC<AllType> = ({ timingsHistory, getTimingHistory, setTimingsHistoryModalParams }) => {
  const { loading, data: timingsHistoryData } = timingsHistory;
  const [pageId, setPageId] = useState<number>(DEFAULT_PAGE_ID);
  const { type } = useParams();

  const columns = renderTimingsHistoryRecord();

  const onSeeThisVersionClick = (timingModel: ITimingModel) => {
    setTimingsHistoryModalParams({ open: true, type, timingModel });
  };

  columns.push({
    render: (_, record) => {
      return (
        <Button className="audit-body__action" onClick={() => onSeeThisVersionClick(record)}>
          See this version
        </Button>
      );
    },
  });

  useEffect(() => {
    if (type) {
      getTimingHistory({ type, offset: pageId - 1, limit: 10 });
    }
  }, [type, pageId]);

  return (
    <>
      <p className="title">Audit trail</p>

      <Table
        dataSource={timingsHistoryData?.data.map((item, index) => ({ ...item, key: index }))}
        columns={columns}
        loading={loading}
        pagination={false}
      />

      <Pagination
        current={pageId}
        total={timingsHistoryData?.meta.count}
        showSizeChanger={false}
        onChange={(pageId: number) => setPageId(pageId)}
        defaultPageSize={DEFAULT_PAGE_SIZE}
      />
    </>
  );
};

const mapState = (state: RootState) => ({
  timingsHistory: state.timingsHistory,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  getTimingHistory: dispatch.timingsHistory.getTimingHistory,
  setTimingsHistoryModalParams: dispatch.modals.setTimingsHistoryModalParams,
});

export default connect(mapState, mapDispatch)(AuditBody);
