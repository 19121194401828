import { ECCMTasksStatuses, EStatuses, ETaskStatusTitles } from 'common/const/enums.const';

export const eventTasksOptions = [
  {
    value: EStatuses.Complete,
    label: ETaskStatusTitles.Completed,
  },
  {
    value: EStatuses['Not completed'],
    label: ETaskStatusTitles.NotCompleted,
  },
  {
    value: EStatuses['N/A'],
    label: ETaskStatusTitles.NA,
  },
];

export const ccmTaskOptions = [
  {
    value: ECCMTasksStatuses.Completed,
    label: ETaskStatusTitles.Completed,
  },
  {
    value: ECCMTasksStatuses.NotCompleted,
    label: ETaskStatusTitles.NotCompleted,
  },
];
