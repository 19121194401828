export const envConfig = (window as any).env as {
  EVENT_IDLE_LIMIT_MS: string;
  ENROLLMENT_IDLE_LIMIT_MS: string;
  SENTRY_DSN: string;
  NODE_ENV: string;
};
export const DEFAULT_PAGE_SIZE = 10;
export const NO_INTERACTION_TIMEOUT_MIN = 60000;
export const EVENT_IDLE_LIMIT_MS = envConfig.EVENT_IDLE_LIMIT_MS ? Number(envConfig.EVENT_IDLE_LIMIT_MS) : 120000;
export const ENROLLMENT_IDLE_LIMIT_MS = envConfig.ENROLLMENT_IDLE_LIMIT_MS ? Number(envConfig.ENROLLMENT_IDLE_LIMIT_MS) : 300000;
export const DEFAULT_PAGE_ID = 1;
export const DEFAULT_MODAL_WIDTH = 600;
export const DEFAULT_DEBOUNCE_DELAY = 500;
