import React from 'react';
import moment from 'moment';
import { ColumnsType } from 'antd/lib/table';
import { Button, Tooltip } from 'antd';
import { ECCMBillingStatuses, EDateFormat, EEventType, ESearchParams, ESort, EStatuses } from 'common/const/enums.const';
import { getStatusColor } from 'common/helpers/color.helper';
import { convertToMinutes } from 'common/helpers/time.helper';
import { IBillingCycleEventColumnsType, IBillingCycleModel, ICcmBillingCycleItem } from 'entities/Billing/Billing.models';

export const getBillingSearchParams = (searchParams: URLSearchParams) => {
  const status = searchParams.get(ESearchParams.Status);
  const search = searchParams.get(ESearchParams.Search);
  const dateFrom = searchParams.get(ESearchParams.DateFrom);
  const dateTo = searchParams.get(ESearchParams.DateTo);
  const orderField = searchParams.get(ESearchParams.OrderField);
  const orderDirection = searchParams.get(ESearchParams.OrderDirection);

  return {
    status: status ? Number(status) : Object.keys(EStatuses).indexOf(EStatuses.Completed),
    search: search ? search : undefined,
    dateFrom: dateFrom ? dateFrom : undefined,
    dateTo: dateTo ? dateTo : undefined,
    orderField: orderField ? orderField : ESort.StartDate,
    orderDirection: orderDirection ? orderDirection : 'DESC',
  };
};

export const convertDateFormat = (date?: string) => moment(date).format(EDateFormat.ShortMonth12h);

export const renderBillingRecords = (
  sendToEMR: (billingCycleIds: number[]) => void,
  onCell: (record: IBillingCycleModel) => { onClick: () => void },
  status?: string | number
) => {
  const tab = Object.keys(EStatuses)[status as number];

  return [
    {
      title: 'MRN',
      dataIndex: 'mrn',
      key: 'mrn',
      sorter: true,
      onCell,
    },
    {
      title: 'Patient Name',
      dataIndex: 'patientName',
      key: 'patientName',
      sorter: true,
      onCell,
    },
    {
      title: 'Start date',
      dataIndex: 'startDate',
      key: 'startDate',
      sorter: true,
      render: (text) => (text ? convertDateFormat(text) : '-'),
      onCell,
    },
    {
      title: 'End date',
      dataIndex: 'endDate',
      key: 'endDate',
      sorter: true,
      render: (text) => (text ? convertDateFormat(text) : '-'),
      onCell,
    },
    {
      title: 'Total minutes spent',
      dataIndex: 'timeSpent',
      key: 'timeSpent',
      sorter: true,
      render: (text) => convertToMinutes(text),
      onCell,
    },
    {
      title: 'Events',
      dataIndex: 'eventCount',
      key: 'eventCount',
      sorter: true,
      onCell,
    },
    {
      title: 'CPT code',
      dataIndex: 'cptCode',
      key: 'cptCode',
      render: (_, record) => record.code || '-',
      onCell,
    },
    {
      title: 'Fee',
      dataIndex: 'fee',
      key: 'fee',
      render: (_, record) => (record.fee ? `${record.fee / 100} $` : '-'),
      onCell,
    },
    {
      title: 'Status',
      dataIndex: 'isCompleted',
      key: 'isCompleted',
      render: (text, record) => {
        return record.isCompleted && record.isBilled ? (
          <span className={`color__${getStatusColor(EStatuses.Closed)}`}>{EStatuses.Closed}</span>
        ) : (
          <span className={`color__${getStatusColor(text ? EStatuses.Completed : EStatuses.Pending)}`}>
            {text ? EStatuses.Completed : EStatuses.Pending}
          </span>
        );
      },
      onCell,
    },
    {
      title: tab === EStatuses.Closed ? 'Sent to EMR' : '',
      dataIndex: 'sendDate',
      key: 'sendDate',
      sorter: tab === EStatuses.Closed,
      render: (text, record) => {
        if (tab === EStatuses.Closed) {
          return convertDateFormat(text);
        }

        if (tab === EStatuses.Completed) {
          if (record.hasDiagnosis) {
            return (
              <Button className="btn-link" onClick={() => sendToEMR([record.id])}>
                Send to EMR
              </Button>
            );
          } else {
            return 'No Diagnosis';
          }
        }

        return null;
      },
    },
  ] as ColumnsType<IBillingCycleModel>;
};

export const billingEventByIdRecords: ColumnsType<IBillingCycleEventColumnsType> = [
  {
    title: 'Event type',
    dataIndex: 'eventType',
    key: 'eventType',
    render: (_, record) => (
      <Button
        className="btn-link billing-details-modal__link"
        onClick={() => window.open(`/event/${record.eventId}?source=billing`, '_blank')}
      >
        {EEventType.DiarySubmission}
      </Button>
    ),
  },
  {
    title: 'Event Date',
    dataIndex: 'eventDate',
    key: 'eventDate',
    render: (text) => convertDateFormat(text),
  },
  {
    title: 'Staff member',
    dataIndex: 'staffMember',
    key: 'staffMember',
  },
  {
    title: 'Minutes',
    dataIndex: 'minutes',
    key: 'minutes',
    render: (text) => convertToMinutes(text),
  },
  {
    title: 'Comment',
    dataIndex: 'comment',
    key: 'comment',
    render: (comments) => {
      return comments.length ? (
        <Tooltip
          title={comments.map((comment: string, index: number) => (
            <p key={index}>{comment}</p>
          ))}
        >
          <span>{comments[comments.length - 1]}</span>
        </Tooltip>
      ) : (
        <span>-</span>
      );
    },
  },
];

export const getCcmBillingSearchParams = (searchParams: URLSearchParams) => {
  const status = searchParams.get(ESearchParams.Status);
  const search = searchParams.get(ESearchParams.Search);
  const dateFrom = searchParams.get(ESearchParams.DateFrom);
  const dateTo = searchParams.get(ESearchParams.DateTo);
  const orderField = searchParams.get(ESearchParams.OrderField);
  const orderDirection = searchParams.get(ESearchParams.OrderDirection);
  const sendDate = searchParams.get(ESearchParams.SendDate);

  return {
    status: status ? status : ECCMBillingStatuses.Completed,
    search: search ? search : undefined,
    dateFrom: dateFrom ? dateFrom : undefined,
    dateTo: dateTo ? dateTo : undefined,
    sendDate: sendDate ? sendDate : undefined,
    orderField: orderField ? orderField : ESort.StartDate,
    orderDirection: orderDirection ? orderDirection : 'DESC',
  };
};

const renderCcmBillingCell = (record: ICcmBillingCycleItem, text: string) => {
  return (
    <span className={record.isCompletedOnUnenrollment && !record.isPatientCcmEnrolled ? 'billing-body__unenrolled-item' : ''}>
      {text}
    </span>
  );
};

export const renderCcmBillingRecords = (onCell: (record: ICcmBillingCycleItem) => { onClick: () => void }) => {
  return [
    {
      title: 'MRN',
      dataIndex: 'patientMrn',
      key: 'patientMrn',
      onCell,
    },
    {
      title: 'Patient Name',
      dataIndex: 'patientName',
      key: 'patientName',
      render: (_, record) => renderCcmBillingCell(record, record.patientName),
      onCell,
    },
    {
      title: 'Start date',
      dataIndex: 'startDate',
      key: 'startDate',
      sorter: true,
      render: (_, record) => renderCcmBillingCell(record, record.startDate ? convertDateFormat(record.startDate) : '-'),
      onCell,
    },
    {
      title: 'End date',
      dataIndex: 'endDate',
      key: 'endDate',
      sorter: true,
      render: (_, record) => renderCcmBillingCell(record, record.endDate ? convertDateFormat(record.endDate) : '-'),
      onCell,
    },
    {
      title: 'Total minutes spent',
      dataIndex: 'totalTimeSpent',
      key: 'totalTimeSpent',
      render: (text) => convertToMinutes(text),
      onCell,
    },
    {
      title: 'CPT code',
      dataIndex: 'cpt',
      key: 'cpt',
      render: (_, record) => record.cpt || '-',
      onCell,
    },
    {
      title: 'Fee',
      dataIndex: 'fee',
      key: 'fee',
      render: (_, record) => (record.fee ? `${record.fee / 100} $` : '-'),
      onCell,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (_, record) => {
        return (
          <span className={`color__${getStatusColor(record.status)}`} style={{ textTransform: 'capitalize' }}>
            {record.status === ECCMBillingStatuses.Active ? EStatuses.Pending : record.status}
          </span>
        );
      },
      onCell,
    },
  ] as ColumnsType<ICcmBillingCycleItem>;
};

export const getCCMBillingCycleAvailableOperations = (status: 'active' | 'completed' | 'closed' | 'rejected' | undefined) => {
  let canStartTimer = false;
  let canEditTimer = false;
  let canCall = false;

  if (status === ECCMBillingStatuses.Active) {
    canStartTimer = true;
    canEditTimer = true;
    canCall = true;
  }

  if (status === ECCMBillingStatuses.Completed) {
    canEditTimer = true;
  }

  return { canStartTimer, canEditTimer, canCall };
};
