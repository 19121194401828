import { createModel } from '@rematch/core';
import { IRootModel } from 'app/store';
import { tasksTransport } from 'entities/Tasks/Tasks.transport';
import { ICCMTasksCollectionQueryParams, ITaskItem, ITaskPayload } from 'entities/Tasks/Tasks.models';
import { ccmTasksToDataMapper } from 'entities/Tasks/Tasks.mapper';

export const tasksCollection = createModel<IRootModel>()({
  state: {
    data: [] as ITaskItem[],
    loading: false,
  },
  reducers: {
    setTasksCollection: (state, payload: ITaskItem[]) => ({ ...state, data: payload }),
    setTasksCollectionLoading: (state, payload: boolean) => ({ ...state, loading: payload }),
    clearTasksCollection: (state) => ({ ...state, data: [] }),
  },
  effects: (dispatch) => ({
    async changeEventTaskStatus(payload: ITaskPayload, models) {
      dispatch.tasksCollection.setTasksCollectionLoading(true);
      await tasksTransport
        .changeEventTaskStatus({ taskId: payload.taskId, status: encodeURIComponent(payload.status) })
        .then(() => {
          const task = models.tasksCollection.data.find((item) => item.taskId === payload.taskId);

          if (task) {
            task.status = payload.status;
            dispatch.tasksCollection.setTasksCollection(models.tasksCollection.data);
          }
        })
        .finally(() => {
          dispatch.tasksCollection.setTasksCollectionLoading(false);
        });
    },
    async getCcmTasksCollection(params: ICCMTasksCollectionQueryParams) {
      tasksTransport.getCcmTasksCollection(params).then((response) => {
        dispatch.tasksCollection.setTasksCollection(ccmTasksToDataMapper(response.data));
      });
    },
    async changeCcmTaskStatus(payload: ITaskPayload, models) {
      dispatch.tasksCollection.setTasksCollectionLoading(true);
      await tasksTransport
        .changeCcmTaskStatus(payload)
        .then((response) => {
          const task = models.tasksCollection.data.find((item) => item.taskId === response.id);

          if (task) {
            task.status = response.status;
            dispatch.tasksCollection.setTasksCollection(models.tasksCollection.data);
          }
        })
        .finally(() => {
          dispatch.tasksCollection.setTasksCollectionLoading(false);
        });
    },
  }),
});
