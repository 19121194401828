import React, { useEffect, useState } from 'react';
import { Button, DatePicker, Modal, Spin } from 'antd';
import { connect } from 'react-redux';
import moment, { Moment } from 'moment';
import { DEFAULT_MODAL_WIDTH } from 'common/config';
import { EDateFormat } from 'common/const/enums.const';
import { RootState } from 'app/store';

type AllType = ReturnType<typeof mapState>;

const VerbalConsentModalComponent: React.FC<AllType> = ({ ccmPatientVerbalConsentModalParams, loading }) => {
  const { open, defaultConsentDate, onSave, onCancel } = ccmPatientVerbalConsentModalParams;
  const [value, setValue] = useState<Moment | null>(null);

  const handleSaveClick = () => {
    value && onSave?.(moment(value).toISOString());
  };

  const onChange = (value: Moment | null) => {
    setValue(value);
  };

  useEffect(() => {
    if (defaultConsentDate) {
      setValue(moment(defaultConsentDate));
    }
  }, [defaultConsentDate]);

  return (
    <Modal
      className="patient-details__verbal-consent-modal"
      title="Specify consent obtained date"
      open={open}
      footer={false}
      onCancel={onCancel}
      width={DEFAULT_MODAL_WIDTH}
    >
      <Spin spinning={loading}>
        <div className="modal__body">
          <DatePicker value={value} onChange={onChange} format={EDateFormat.FullYear} />
        </div>

        <div className="modal__footer">
          <Button className="btn-primary" onClick={handleSaveClick} disabled={!value}>
            Save
          </Button>

          <Button onClick={onCancel}>Cancel</Button>
        </div>
      </Spin>
    </Modal>
  );
};

const mapState = (state: RootState) => ({
  ccmPatientVerbalConsentModalParams: state.modals.ccmPatientVerbalConsentModalParams,
  loading: state.ccmPatientConsentModel.loading,
});

export const VerbalConsentModal = connect(mapState)(VerbalConsentModalComponent);
