import React, { useState } from 'react';
import { Button } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { EActionTypes, ECCMTasksStatuses } from 'common/const/enums.const';
import { IdleTimer } from 'common/components/IdleTimer';
import { ENROLLMENT_IDLE_LIMIT_MS } from 'common/config';
import { RootDispatch, RootState, history } from 'app/store';
import { Phone } from 'entities/Twilio/components/Phone';
import { getEnrollmentAvailableOperations } from 'entities/Enrollment/Enrollment.helper';

interface IComponentProps {
  enrollmentId?: string;
}

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch> & IComponentProps;

const EnrollmentActionsCardComponent: React.FC<AllType> = (props) => {
  const {
    enrollmentId,
    authModel,
    enrollmentModel,
    ccmPatientModel,
    tasksCollection,
    getTwilioModel,
    setActionParams,
    addEnrollmentCommentHistory,
    addEnrollmentCallHistory,
    setCallModalParams,
    setCommentModalParams,
    showEnrollmentInactiveModal,
    setInactiveModalParams,
    setCancelModalParams,
  } = props;
  const [onCall, setOnCall] = useState<boolean>(false);
  const { data: authModelData } = authModel;
  const { data: enrollmentModelData } = enrollmentModel;
  const { data: patientModelData } = ccmPatientModel;
  const currentUserName = authModelData?.access?.id;
  const enrollmentStatus = enrollmentModelData?.status;
  const assignee = enrollmentModelData?.assignee.name;
  const patientName = patientModelData?.name;
  const phone = patientModelData?.phone || '';
  const { canBeMovedToInbox, canBeCompleted, canBeCanceled, canStart, controlsAreVisible, canCall } =
    getEnrollmentAvailableOperations(enrollmentStatus, currentUserName, assignee);

  const handleCommentModalSend = (enrollmentId: string, comment: string) => {
    addEnrollmentCommentHistory({ id: enrollmentId, comment }).then(() => {
      setCommentModalParams({ open: false, externalId: null });
    });
  };

  const handleCommentModalCancel = () => {
    setCommentModalParams({ open: false, externalId: null });
  };

  const onCommentClick = () => {
    if (enrollmentId) {
      setCommentModalParams({
        open: true,
        externalId: enrollmentId,
        handleSend: handleCommentModalSend,
        handleCancel: handleCommentModalCancel,
      });
    }
  };

  const handleMoveClick = () => {
    setActionParams({ type: EActionTypes.Move });
    history.back();
  };

  const onCompleteClick = () => {
    setActionParams({ type: EActionTypes.Complete });
    history.back();
  };

  const onCallClick = () => {
    getTwilioModel({
      onSuccess: () => {
        if (enrollmentId) {
          addEnrollmentCallHistory(enrollmentId);
        }
      },
    }).then((token) => setCallModalParams({ open: true, token, phone, patientName, setOnCall }));
  };

  const onResumeClick = () => {
    setInactiveModalParams({ open: false, title: null, description: null });
    setActionParams({ type: '', text: undefined });
  };

  const onMoveClick = () => {
    history.back();
  };

  const onNoClick = () => {
    setCancelModalParams({ open: false, title: null, description: null });
  };

  const onYesClick = () => {
    setActionParams({ type: EActionTypes.Cancel });
    history.back();
  };

  const onCancelEnrollmentClick = () => {
    setCancelModalParams({
      open: true,
      title: 'Cancel Enrollment',
      description: 'Are you sure you want to cancel the enrollment?',
      onYesClick,
      onNoClick,
    });
  };

  return (
    <div className="actions-card">
      <div className="actions-card__item">
        <Phone phone={phone} disabled={!canCall} onCallClick={onCallClick} />
      </div>

      <div className="actions-card__item">
        <div className="actions-card__actions">
          <Button icon={<div className="icon actions-card__comment-icon" />} onClick={onCommentClick}>
            Leave a comment
          </Button>

          {controlsAreVisible && (
            <>
              <Button onClick={handleMoveClick} disabled={!canBeMovedToInbox}>
                Move to Inbox
              </Button>

              <div className="actions-card__btns-wrapper">
                <Button
                  className="btn-green"
                  icon={<CheckCircleOutlined />}
                  onClick={onCompleteClick}
                  disabled={tasksCollection.data.some((task) => task.status !== ECCMTasksStatuses.Completed) || !canBeCompleted}
                >
                  Complete
                </Button>

                <Button className="btn-red" onClick={onCancelEnrollmentClick} disabled={!canBeCanceled}>
                  Cancel Enrollment
                </Button>
              </div>
            </>
          )}
        </div>
      </div>

      {canStart && !onCall && (
        <IdleTimer
          timeout={ENROLLMENT_IDLE_LIMIT_MS}
          onIdle={() => {
            if (enrollmentId) {
              showEnrollmentInactiveModal({
                open: true,
                title: 'Review inactivity was detected',
                description: (
                  <>
                    <span>We noticed a lack of review acitvity due to non interaction.</span>
                    <span>Are you still reviewing?</span>
                  </>
                ),
                onResumeClick,
                onMoveClick,
              });
            }
          }}
        />
      )}
    </div>
  );
};

const mapState = (state: RootState) => ({
  authModel: state.authModel,
  enrollmentModel: state.enrollmentModel,
  ccmPatientModel: state.ccmPatientModel,
  tasksCollection: state.tasksCollection,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  getTwilioModel: dispatch.twilioModel.getTwilioModel,
  setActionParams: dispatch.actionModel.setActionParams,
  addEnrollmentCommentHistory: dispatch.enrollmentReviewHistoryModel.addEnrollmentCommentHistory,
  addEnrollmentCallHistory: dispatch.enrollmentReviewHistoryModel.addEnrollmentCallHistory,
  setCallModalParams: dispatch.modals.setCallModalParams,
  setCommentModalParams: dispatch.modals.setCommentModalParams,
  showEnrollmentInactiveModal: dispatch.modals.showEnrollmentInactiveModal,
  setInactiveModalParams: dispatch.modals.setInactiveModalParams,
  setCancelModalParams: dispatch.modals.setCancelModalParams,
});

export const EnrollmentActionsCard = connect(mapState, mapDispatch)(EnrollmentActionsCardComponent);
