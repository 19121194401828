import { EEnrollmentsStatuses, EStatuses } from 'common/const/enums.const';

const underReview = EStatuses['Under review'];

export enum EDashboardTableTabs {
  Inbox = 'Inbox',
  UnderReview = 'Under review',
  Escalated = 'Escalated',
  Completed = 'Completed',
}

export enum EEventSeverity {
  None = 'none',
  Mild = 'mild',
  Moderate = 'moderate',
  Severe = 'severe',
}

export const eventsMenuItemsStaff = [
  {
    label: EDashboardTableTabs.Inbox,
    key: EDashboardTableTabs.Inbox,
  },
  {
    label: EDashboardTableTabs.UnderReview,
    key: EDashboardTableTabs.UnderReview,
  },
  {
    label: EDashboardTableTabs.Escalated,
    key: EDashboardTableTabs.Escalated,
  },
  {
    label: EDashboardTableTabs.Completed,
    key: EDashboardTableTabs.Completed,
  },
];

export const eventsMenuItemsPhysician = [
  {
    label: EDashboardTableTabs.Inbox,
    key: EDashboardTableTabs.Inbox,
  },
  {
    label: EDashboardTableTabs.UnderReview,
    key: EDashboardTableTabs.UnderReview,
  },
  {
    label: EDashboardTableTabs.Completed,
    key: EDashboardTableTabs.Completed,
  },
];

export const enrollmentsMenuItems = [
  {
    label: EStatuses.Inbox,
    key: EEnrollmentsStatuses.Inbox,
  },
  {
    label: underReview,
    key: EEnrollmentsStatuses.UnderReview,
  },
  {
    label: EStatuses.Completed,
    key: EEnrollmentsStatuses.Completed,
  },
  {
    label: EStatuses.Cancelled,
    key: EEnrollmentsStatuses.Cancelled,
  },
];
