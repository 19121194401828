import React from 'react';
import { getColor } from 'common/helpers/color.helper';
import { EEventSeverity } from 'entities/Dashboard/Dashboard.const';

interface IComponentProps {
  dots: EEventSeverity[];
}

export const DiaryDots: React.FC<IComponentProps> = (props) => {
  const { dots } = props;
  return (
    <div className="diary-dots">
      {dots?.map((severity: EEventSeverity, index: number) => (
        <div key={index} className={`diary-dots__dot bg__${getColor.bySeverity(severity)}`} />
      ))}
    </div>
  );
};
