import React from 'react';
import { Input } from 'antd';

interface IComponentProps {
  title: string;
  disabled: boolean;
  value?: string;
  onChange(e: any): void;
}

export const AssessmentInput: React.FC<IComponentProps> = ({ title, value, onChange, disabled }) => {
  return (
    <>
      <p>{title}</p>
      <div className="assessment__answer_input">
        <Input value={value} placeholder="Record the response" disabled={disabled} onChange={onChange} />
      </div>
    </>
  );
};
