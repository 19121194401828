import React, { useEffect, useState } from 'react';
import { Button, Dropdown, InputNumber, Badge } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { ESearchParams } from 'common/const/enums.const';
import { ReactComponent as TuneIcon } from 'app/assets/images/svg/tuneIcon.svg';

interface IComponentProps {
  daysLeftLessThan: number | undefined;
  timeLeftToNextThreshold: number | undefined;
  onChange: (values: { [key: string]: string | undefined }[]) => void;
}

export const PatientsFilter: React.FC<IComponentProps> = (props) => {
  const { daysLeftLessThan, timeLeftToNextThreshold, onChange } = props;
  const [open, setOpen] = useState<boolean>(false);
  const [daysLeftLessThanValue, setDaysLeftLessThanValue] = useState<number | undefined>(daysLeftLessThan);
  const [timeLeftToNextThresholdValue, setTimeLeftToNextThresholdValue] = useState<number | undefined>(timeLeftToNextThreshold);
  const [filtersCount, setFiltersCount] = useState<number>(0);

  const toggleDropdown = (value: boolean) => setOpen(value);

  const handleDaysChange = (value: number | null) => {
    value !== null ? setDaysLeftLessThanValue(value) : setDaysLeftLessThanValue(undefined);
  };

  const handleTimeChange = (value: number | null) => {
    value !== null ? setTimeLeftToNextThresholdValue(value) : setTimeLeftToNextThresholdValue(undefined);
  };

  const onApplyClick = () => {
    toggleDropdown(false);
    onChange([
      { [ESearchParams.DaysLeftLessThan]: daysLeftLessThanValue?.toString() },
      { [ESearchParams.TimeLeftToNextThreshold]: timeLeftToNextThresholdValue?.toString() },
    ]);
  };

  const onCancelClick = () => {
    toggleDropdown(false);
    setDaysLeftLessThanValue(daysLeftLessThan);
    setTimeLeftToNextThresholdValue(timeLeftToNextThreshold);
  };

  useEffect(() => {
    const newFiltersCount = [daysLeftLessThan, timeLeftToNextThreshold].filter((item) => !!item).length;
    setFiltersCount(newFiltersCount);
  }, [daysLeftLessThan, timeLeftToNextThreshold]);

  const overlay = (
    <div className="patients-filter__wrapper">
      <div className="patients-filter__entry">
        <div>Days left less than:</div>
        <InputNumber
          className="patients-filter__input"
          placeholder="Days"
          value={daysLeftLessThanValue}
          onChange={handleDaysChange}
          controls={false}
        />
      </div>
      <div className="patients-filter__entry">
        <div>Time left to get the next threshold:</div>
        <InputNumber
          className="patients-filter__input"
          placeholder="Minutes"
          value={timeLeftToNextThresholdValue}
          onChange={handleTimeChange}
          controls={false}
        />
      </div>
      <div className="patients-filter__actions">
        <Button className="btn-primary" onClick={onApplyClick}>
          Apply
        </Button>
        <Button onClick={onCancelClick}>Cancel</Button>
      </div>
    </div>
  );

  return (
    <Dropdown overlay={overlay} trigger={['click']} open={open} onOpenChange={toggleDropdown}>
      <Button className="patients-filter__btn" icon={<TuneIcon />}>
        Filter
        {filtersCount ? <Badge style={{ background: '#1f6df2', color: '#ffffff' }} count={filtersCount} /> : <DownOutlined />}
      </Button>
    </Dropdown>
  );
};
