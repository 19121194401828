import React from 'react';
import { Button, Modal } from 'antd';
import { DEFAULT_MODAL_WIDTH } from 'common/config';

interface IComponentProps {
  onQuit: () => void;
  onCancel?: (value: unknown) => void;
}

export const TimingsUnsavedModal: React.FC<IComponentProps> = ({ onQuit, onCancel }) => (
  <Modal open={true} footer={false} onCancel={onCancel} width={DEFAULT_MODAL_WIDTH}>
    <div className="modal__body">
      <p>The changes are not saved and will be lost. Are you sure you want to leave the page?</p>
    </div>

    <div className="modal__footer">
      <Button className="btn-primary" onClick={onCancel}>
        Cancel & Go Back
      </Button>

      <Button className="btn-red" onClick={onQuit}>
        Don’t Save and Quit
      </Button>
    </div>
  </Modal>
);
