import React, { useEffect, useState } from 'react';
import { Pagination, Table } from 'antd';
import { connect } from 'react-redux';
import { DEFAULT_PAGE_ID, DEFAULT_PAGE_SIZE } from 'common/config';
import { RootDispatch, RootState } from 'app/store';
import { toDataSourseMapper } from 'entities/Dashboard/Dashboard.helper';
import { assessmentHistoryColumns } from 'entities/AssessmentHistory/AssessmentHistory.helper';

interface IComponentProps {
  patientId: string;
}

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch> & IComponentProps;

const AssessmentHistoryComponent: React.FC<AllType> = (props) => {
  const { patientId, assessmentHistoryCollection, getAssessmentHistoryCollection } = props;
  const { data } = assessmentHistoryCollection;
  const [page, setPage] = useState<number>(DEFAULT_PAGE_ID);

  useEffect(() => {
    if (patientId) {
      getAssessmentHistoryCollection({ patientId, page });
    }
  }, [patientId, page]);

  if (!data) {
    return null;
  }

  return (
    <div className="assessment-history">
      <p className="subtitle">Assessment History</p>
      <Table columns={assessmentHistoryColumns} dataSource={toDataSourseMapper(data.data)} pagination={false} />

      <Pagination
        current={page}
        total={data.meta.lastPage * DEFAULT_PAGE_SIZE}
        showSizeChanger={false}
        onChange={(page: number) => setPage(page)}
        defaultPageSize={DEFAULT_PAGE_SIZE}
      />
    </div>
  );
};

const mapState = (state: RootState) => ({
  assessmentHistoryCollection: state.assessmentHistoryCollection,
});

const mapDispatch = (dispatch: RootDispatch) => ({
  getAssessmentHistoryCollection: dispatch.assessmentHistoryCollection.getAssessmentHistoryCollection,
});

export const AssessmentHistory = connect(mapState, mapDispatch)(AssessmentHistoryComponent);
