import React from 'react';
import { Menu as AntdMenu } from 'antd';
import type { MenuProps } from 'antd';
import { IMenuItem } from 'common/models';
import { EDashboardTableTabs } from 'entities/Dashboard/Dashboard.const';

interface IComponentProps {
  items: IMenuItem[];
  selectedKeys: string[];
  onChange: (key: EDashboardTableTabs) => void;
}

export const Menu: React.FC<IComponentProps> = ({ items, selectedKeys, onChange }) => {
  const handleMenuClick: MenuProps['onClick'] = (e) => {
    onChange(e.key as EDashboardTableTabs);
  };

  return (
    <AntdMenu className="filter__menu" items={items} selectedKeys={selectedKeys} onClick={handleMenuClick} mode="horizontal" />
  );
};
