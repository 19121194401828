import io, { Socket } from 'socket.io-client';

export class SocketIOService {
  private static socket?: Socket;
  private static connecting: boolean = false;

  public static connect(token: string) {
    this.disconnect();
    if (this.connecting) {
      return;
    }

    if ((!this.socket || !this.socket.connected) && !this.connecting) {
      this.connecting = true;
      this.socket = io({
        extraHeaders: {
          Authorization: `Bearer ${token}`,
        },
        transports: ['polling'],
      });

      // eslint-disable-next-line
      this.socket.on('connect', () => {});
      // eslint-disable-next-line
      this.socket.on('exception', () => {});
      // eslint-disable-next-line
      this.socket.on('disconnect', () => {});
      this.socket.on('reconnect', () => {
        this.connect(token);
      });
    }
  }

  public static disconnect() {
    if (this.socket) {
      this.socket.removeAllListeners();
      this.socket.close();
      this.connecting = false;
    }
  }

  public static getSocket() {
    return this.socket;
  }
}
