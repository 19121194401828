import React from 'react';
import { getStatusColor } from 'common/helpers/color.helper';
import { EEnrollmentsStatuses } from 'common/const/enums.const';
import { IEnrollmentModel } from 'entities/Enrollment/Enrollment.models';
import { ICCMPatientModel } from 'entities/Patient/Patient.models';
import { CCMTasks } from 'entities/Tasks/components/CCMTasks';
import { EnrollmentPatientDetailsContainer } from 'entities/Patient/components/EnrollmentPatientDetailsContainer';

interface IComponentProps {
  enrollmentModelData: IEnrollmentModel;
  ccmPatientModelData: ICCMPatientModel;
  enrollmentStatus: string;
}

export const EnrollmentBody: React.FC<IComponentProps> = ({ enrollmentModelData, ccmPatientModelData, enrollmentStatus }) => {
  return (
    <div className="enrollment-body">
      <div className="enrollment-body__header">
        <p className="title">{enrollmentModelData.patientName}</p>
        <p className="enrollment-body__header_status">
          <span>Status: </span>
          <span className={`color__${getStatusColor(enrollmentStatus)}`}>
            {/* TODO */}
            {enrollmentStatus === EEnrollmentsStatuses.UnderReview ? 'Under Review' : enrollmentStatus}
          </span>
        </p>
      </div>

      <EnrollmentPatientDetailsContainer ccmPatientModelData={ccmPatientModelData} />

      <CCMTasks enrollmentId={enrollmentModelData.id} enrollmentStatus={enrollmentStatus} />
    </div>
  );
};
