import React from 'react';
import { ECCMBillingStatuses } from 'common/const/enums.const';
import { ICcmAssessment } from 'entities/Assessment/Assessment.models';
import { ICcmBillingCycleModel } from 'entities/Billing/Billing.models';
import { Assessment } from 'entities/Assessment/components/Assessment';
import { ICCMPatientModel } from 'entities/Patient/Patient.models';
import { CCMPatientDetailsContainer } from 'entities/Patient/components/CCMPatientDetailsContainer';
import { HealthMetrics } from 'entities/HealthMetrics/components/HealthMetrics';
import { Promis } from 'entities/Promis/components/Promis';
import { AssessmentHistory } from 'entities/AssessmentHistory/components/AssessmentHistory';
import { PatientCarePlan } from 'entities/CarePlan/components/PatientCarePlan';

interface IComponentProps {
  billingCycle: ICcmBillingCycleModel;
  ccmPatientModelData: ICCMPatientModel;
  patientEnrolled: boolean;
}

export const CCMPatientBody: React.FC<IComponentProps> = (props) => {
  const { billingCycle, ccmPatientModelData, patientEnrolled } = props;

  return (
    <div className="patient-body">
      <div className={`patient-body__header title ${!patientEnrolled ? 'patient-body__header-unenrolled' : ''}`}>
        <span>{ccmPatientModelData.name}</span>
        {!patientEnrolled && <span>(Unenrolled)</span>}
      </div>

      <CCMPatientDetailsContainer
        billingCycleId={billingCycle.id}
        ccmPatientModelData={ccmPatientModelData}
        patientEnrolled={patientEnrolled}
      />

      <PatientCarePlan patientId={ccmPatientModelData.id} />

      <Assessment
        billingCycleId={billingCycle.id}
        disabled={billingCycle.status !== ECCMBillingStatuses.Active}
        assessment={billingCycle.assessment as ICcmAssessment}
      />

      <HealthMetrics patientId={ccmPatientModelData.id} />

      <Promis patientId={ccmPatientModelData.id} />

      <AssessmentHistory patientId={ccmPatientModelData.id} />
    </div>
  );
};
