import React from 'react';
import { Chart as ChartComponent } from 'devextreme-react/chart';
import {
  ArgumentAxis,
  Label,
  Legend,
  Series,
  Tick,
  Tooltip,
  ValueAxis,
  ZoomAndPan,
  CommonSeriesSettings,
} from 'devextreme-react/chart';
import 'devextreme/dist/css/dx.light.css';
import { EHealthMetrics } from 'common/const/enums.const';
import { IHealthMetricsCollection } from 'entities/HealthMetrics/HealthMetrics.models';

interface IComponentProps {
  data: IHealthMetricsCollection | null;
}

export const Chart: React.FC<IComponentProps> = ({ data }) => {
  const renderArgumentLabel = (date: string) =>
    date.includes('AM') || date.includes('PM') ? date.substring(0, date.indexOf('M') + 1) : date;

  return (
    <div className="chart-component">
      <ChartComponent className="chart-component__chart" dataSource={data?.dataSource}>
        {data?.metric === EHealthMetrics.Heart && <CommonSeriesSettings argumentField="date" type="rangebar" minBarSize={2} />}
        {data?.metric === EHealthMetrics.Heart && (
          <Series rangeValue1Field="min" rangeValue2Field="max" name="ANS West Coast" color="#1f6df2" />
        )}
        {data?.metric !== EHealthMetrics.Heart && <Series argumentField="date" valueField="score" color={data?.color} />}

        <ArgumentAxis visible={false} grid={{ visible: true }}>
          <Tick visible={false} />
          <Label render={(e) => <text className="chart-component__argument-label">{renderArgumentLabel(e.valueText)}</text>} />
        </ArgumentAxis>
        <ValueAxis grid={{ visible: false }} color="#d3d3d3" visualRange={[data?.min, data?.max]}>
          <Tick visible={false} />
          <Label />
        </ValueAxis>
        <ValueAxis position="right" grid={{ visible: false }} color="#d3d3d3">
          <Tick visible={false} />
        </ValueAxis>
        <Legend visible={false} />
        <Tooltip enabled={true} />
        <ZoomAndPan argumentAxis="both" />
      </ChartComponent>
    </div>
  );
};
