import React from 'react';
import { Button } from 'antd';
import { connect } from 'react-redux';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { EDateFormat, EProgramType } from 'common/const/enums.const';
import { history, RootState } from 'app/store';

type AllType = ReturnType<typeof mapState>;

const TimingsHeader: React.FC<AllType> = ({ timingModel: { createdAt } }) => {
  const { type } = useParams();

  return (
    <div className="timings-header">
      <p className="title">{`CPT Codes and Timing: ${type === EProgramType.ENM ? 'E&M' : 'CCM'}`}</p>

      <div className="timings-header__description">
        {!!createdAt.length && (
          <span className="timings-header__date">
            Last updated:
            <span>{moment(createdAt).format(EDateFormat.ShortYear12hm)}</span>
          </span>
        )}

        <Button className="btn-link timings-header__link" onClick={() => history.push(`/audit/${type}`)}>
          See history
        </Button>
      </div>
    </div>
  );
};

const mapState = (dispatch: RootState) => ({
  timingModel: dispatch.timingModel,
});

export default connect(mapState)(TimingsHeader);
