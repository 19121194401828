import React from 'react';
import { v4 as uuid } from 'uuid';
import { CommonContentLayout } from 'common/components/CommonContentLayout';
import { BillingSubheader } from 'entities/Billing/components/EMBilling/BillingSubheader';
import BillingBody from 'entities/Billing/components/EMBilling/BillingBody';
import BillingDetailsModal from 'entities/Modals/components/BillingDetailsModal';

const Billing: React.FC = () => {
  const modals = [{ key: uuid(), modal: <BillingDetailsModal key={Symbol} /> }];

  return (
    <CommonContentLayout
      header={<p className="title">Billing</p>}
      subheader={<BillingSubheader />}
      body={<BillingBody />}
      modals={modals}
    />
  );
};

export default Billing;
