import React from 'react';
import { getStatusColor } from 'common/helpers/color.helper';
import { EEventStatus, EEventStatusTitles } from 'common/const/enums.const';
import { EventTasks } from 'entities/Tasks/components/EventTasks';
import { EventDiary } from 'entities/Event/components/EventDiary';
import { HealthMetrics } from 'entities/HealthMetrics/components/HealthMetrics';
import { Promis } from 'entities/Promis/components/Promis';
import { AssessmentHistory } from 'entities/AssessmentHistory/components/AssessmentHistory';
import { IEventModel } from 'entities/Event/Event.models';
import { IPatientModel } from 'entities/Patient/Patient.models';
import EventPatientDetailsContainer from 'entities/Patient/components/EventPatientDetailsContainer';

interface IComponentProps {
  eventModel: IEventModel;
  patientModel: IPatientModel;
  eventStatus?: string;
}

const EventBody: React.FC<IComponentProps> = ({ eventModel, patientModel, eventStatus }) => {
  return (
    <div className="event-body">
      <div className="event-body__header">
        <p className="title">{eventModel.patientName}</p>
        <p className="event-body__header_status">
          <span>Status: </span>
          <span className={`color__${getStatusColor(eventStatus as string)}`}>
            {EEventStatusTitles[eventStatus as EEventStatus]}
          </span>
        </p>
      </div>

      <EventPatientDetailsContainer eventModel={eventModel} patientModel={patientModel} />

      <EventTasks eventStatus={eventStatus} />

      <EventDiary
        assessmentType={eventModel.assessmentType}
        diary={eventModel.diary}
        date={eventModel.date}
        score={eventModel.score}
      />

      <HealthMetrics patientId={patientModel.id} />

      <Promis patientId={patientModel.id} />

      <AssessmentHistory patientId={patientModel.id} />
    </div>
  );
};

export default EventBody;
