import React, { useEffect, useState } from 'react';
import { Button, Modal, Pagination, Table } from 'antd';
import { connect } from 'react-redux';
import { DEFAULT_PAGE_ID, DEFAULT_PAGE_SIZE } from 'common/config';
import { RootDispatch, RootState } from 'app/store';
import { diagnosisAuditQueryParamsTo, patientHistoryDataToDataSource } from 'entities/Patient/Patient.mappers';
import { patientHistoryRecords } from 'entities/Patient/Patient.helper';

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

const PatientHistoryModal: React.FC<AllType> = (props) => {
  const { patientHistoryModalParams, patientHistoryCollection, setPatientHistoryModalParams, getPatientHistoryCollection } =
    props;
  const { data: historyData, loading } = patientHistoryCollection;
  const { open, patientId, patientName } = patientHistoryModalParams;
  const [orderField, setOrderField] = useState<'createdAt'>('createdAt');
  const [orderDirection, setOrderDirection] = useState<'ASC' | 'DESC'>('DESC');
  const [pageId, setPageId] = useState<number>(DEFAULT_PAGE_ID);
  const dataSource = patientHistoryDataToDataSource(historyData?.data);

  const onCloseClick = () => {
    setPatientHistoryModalParams({ open: false, patientId: null });
    setOrderDirection('DESC');
  };

  const handleSort = (pagination: any, filters: any, sorter: any) => {
    setOrderField(sorter.field);
    setOrderDirection(sorter.order === 'ascend' ? 'ASC' : 'DESC');
  };

  useEffect(() => {
    if (patientId) {
      const params = diagnosisAuditQueryParamsTo({ patientId, orderField, orderDirection }, pageId);

      getPatientHistoryCollection(params);
    }
  }, [patientId, orderDirection, pageId]);

  return (
    <Modal title={`${patientName} - Audit trail`} open={open} footer={false} closable={false} width={1320}>
      <>
        <div className="modal__body modal__patient-history_body">
          <Table
            columns={patientHistoryRecords}
            dataSource={dataSource}
            pagination={false}
            size="small"
            loading={loading}
            onChange={handleSort}
          />

          <Pagination
            current={pageId}
            total={historyData?.meta?.count}
            showSizeChanger={false}
            onChange={(page: number) => setPageId(page)}
            defaultPageSize={DEFAULT_PAGE_SIZE}
          />
        </div>

        <div className="modal__footer">
          <Button className="btn-primary" onClick={onCloseClick}>
            Close
          </Button>
        </div>
      </>
    </Modal>
  );
};

const mapState = (state: RootState) => ({
  patientHistoryModalParams: state.modals.patientHistoryModalParams,
  patientHistoryCollection: state.patientHistoryCollection,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  setPatientHistoryModalParams: dispatch.modals.setPatientHistoryModalParams,
  getPatientHistoryCollection: dispatch.patientHistoryCollection.getPatientHistoryCollection,
});

export default connect(mapState, mapDispatch)(PatientHistoryModal);
