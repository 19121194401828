import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { EHealthMetrics } from 'common/const/enums.const';
import { RootDispatch, RootState } from 'app/store';
import { Chart } from 'entities/HealthMetrics/components/Chart';

interface IComponentProps {
  patientId: string;
}

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch> & IComponentProps;

const HealthMetricsComponent: React.FC<AllType> = (props) => {
  const { patientId, scores, sleep, steps, heart, getHealthMetrics } = props;
  const { data: scoresData } = scores;
  const { data: sleepData } = sleep;
  const { data: stepsData } = steps;
  const { data: heartData } = heart;

  useEffect(() => {
    getHealthMetrics(patientId);
  }, []);

  return (
    <div className="event-charts">
      <p className="subtitle">Recent activity</p>

      <p className="event-charts__subtitle">Diary submissions</p>

      <div className="event-charts__diary-submissions">
        <Chart data={scoresData} />
        <div className="event-charts__label">Daily diary score</div>
      </div>

      <p className="event-charts__subtitle">Health Metrics</p>

      <div className="event-charts__health-metrics">
        <div className={`event-charts__health-metrics_item ${EHealthMetrics.Sleep}`}>
          <Chart data={sleepData} />
          <div className="event-charts__label">{EHealthMetrics.Sleep}</div>
        </div>

        <div className={`event-charts__health-metrics_item ${EHealthMetrics.Steps}`}>
          <Chart data={stepsData} />
          <div className="event-charts__label">{EHealthMetrics.Steps}</div>
        </div>
      </div>

      <div className="event-charts__diary-submissions">
        <Chart data={heartData} />
        <div className="event-charts__label">Heart rate</div>
      </div>
    </div>
  );
};

const mapState = (state: RootState) => ({
  scores: state.healthMetricsCollection.scores,
  sleep: state.healthMetricsCollection.sleep,
  steps: state.healthMetricsCollection.steps,
  heart: state.healthMetricsCollection.heart,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  getHealthMetrics: dispatch.healthMetricsCollection.getHealthMetrics,
});

export const HealthMetrics = connect(mapState, mapDispatch)(HealthMetricsComponent);
