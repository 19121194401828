import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { v4 as uuid } from 'uuid';
import { useParams } from 'react-router-dom';
import { Row, Spin } from 'antd';
import moment from 'moment';
import { EDateFormat, ERoutes } from 'common/const/enums.const';
import { CommonContentLayout } from 'common/components/CommonContentLayout';
import { NavigationSubheader } from 'common/components/NavigationSubheader';
import { RootDispatch, RootState, history } from 'app/store';
import { CCMPatientBody } from 'entities/Patient/components/CCMPatientBody';
import { CCMPatientPanel } from 'entities/Patient/components/CCMPatientPanel';
import CallModal from 'entities/Modals/components/CallModal';
import CommentModal from 'entities/Modals/components/CommentModal';
import { InactiveModal } from 'entities/Modals/components/InactiveModal';
import { MultiselectDiagnosisModal } from 'entities/Modals/components/MultiselectDiagnosesModal';
import { VerbalConsentModal } from 'entities/Modals/components/VerbalConsentModal';
import TimeEditorModal from 'entities/Modals/components/TimeEditorModal';
import { UnenrollPatientModal } from 'entities/Modals/components/UnenrollPatientModal';
import { CarePlanModal } from 'entities/Modals/components/CarePlanModal';
import PatientHistoryModal from 'entities/Modals/components/PatientHistoryModal';
import { PatientRouterHandler } from 'entities/Patient/components/PatientRouterHandler';
import ExportedEventModal from 'entities/Modals/components/ExportedEventModal';

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

const CCMPatientComponent: React.FC<AllType> = (props) => {
  const { ccmBillingCycleModel, ccmPatientModel, getCcmBillingCycleById, clearModels } = props;
  const { data: ccmBillingCycleModelData } = ccmBillingCycleModel;
  const { data: ccmPatientModelData } = ccmPatientModel;

  const { id: billingCycleId } = useParams();
  const autoStartTimer = new URLSearchParams(window.location.search).get('autoStartTimer') === 'true';
  const isBillingSource = new URLSearchParams(window.location.search).get('source') === 'billing';

  const modals = [
    { key: uuid(), modal: <MultiselectDiagnosisModal /> },
    { key: uuid(), modal: <VerbalConsentModal /> },
    { key: uuid(), modal: <CallModal /> },
    { key: uuid(), modal: <CommentModal /> },
    { key: uuid(), modal: <InactiveModal /> },
    { key: uuid(), modal: <TimeEditorModal withComment /> },
    { key: uuid(), modal: <UnenrollPatientModal /> },
    { key: uuid(), modal: <CarePlanModal /> },
    { key: uuid(), modal: <PatientHistoryModal /> },
    { key: uuid(), modal: <ExportedEventModal /> },
  ];

  const onBackClick = () => {
    if (isBillingSource) {
      history.push(ERoutes.CCMBilling);
    } else {
      history.back();
    }
  };

  useEffect(() => {
    if (billingCycleId) {
      getCcmBillingCycleById(billingCycleId);
    }

    return () => {
      clearModels();
    };
  }, [billingCycleId]);

  useEffect(() => {
    return () => {
      clearModels();
    };
  }, []);

  if (!ccmBillingCycleModelData || !ccmPatientModelData) {
    return (
      <Row justify="center" align="middle">
        <Spin size="large" />
      </Row>
    );
  }

  return (
    <>
      <CommonContentLayout
        subheader={
          <NavigationSubheader
            link={isBillingSource ? 'Billing' : 'Dashboard'}
            description={`${ccmPatientModelData.name} - ${moment(ccmPatientModelData.createdAt).format(
              EDateFormat.ShortYear12hm
            )}`}
            onClick={onBackClick}
          />
        }
        body={
          <CCMPatientBody
            billingCycle={ccmBillingCycleModelData}
            ccmPatientModelData={ccmPatientModelData}
            patientEnrolled={ccmPatientModelData.isCCMEnrolled}
          />
        }
        panel={<CCMPatientPanel billingCycleId={billingCycleId} autoStartTimer={autoStartTimer} />}
        modals={modals}
      />

      <PatientRouterHandler billingCycleId={billingCycleId} />
    </>
  );
};

const mapState = (state: RootState) => ({
  ccmBillingCycleModel: state.ccmBillingCycleModel,
  ccmPatientModel: state.ccmPatientModel,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  getCcmBillingCycleById: dispatch.ccmBillingCycleModel.getCcmBillingCycleById,
  clearModels: dispatch.ccmPatientModel.clearModels,
});

export const CCMPatient = connect(mapState, mapDispatch)(CCMPatientComponent);
