import React from 'react';
import { connect } from 'react-redux';
import { EEventStatus } from 'common/const/enums.const';
import { RootDispatch, RootState } from 'app/store';
import { Tasks } from 'entities/Tasks/components/Tasks';
import { ITaskItem } from 'entities/Tasks/Tasks.models';

interface IComponentProps {
  eventStatus: string;
}

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch> & IComponentProps;

const EventTasksComponent: React.FC<AllType> = (props) => {
  const { eventStatus, tasksCollection, diagnosesCollection, changeEventTaskStatus, setDiagnosesCollectionError } = props;
  const { data: tasksData, loading } = tasksCollection;
  const { data: diagnosesData } = diagnosesCollection;

  const onChange = (task: ITaskItem, status: string) => {
    if (task.name === 'Choose the diagnosis' && status === EEventStatus.Completed && (!diagnosesData || !diagnosesData?.length)) {
      setDiagnosesCollectionError(true);
    } else {
      setDiagnosesCollectionError(false);
      changeEventTaskStatus({ taskId: task.taskId as number, status });
    }
  };

  if (!tasksData.length) {
    return null;
  }

  return <Tasks tasks={tasksData} loading={loading} onChange={onChange} disabled={eventStatus === EEventStatus.Completed} />;
};

const mapState = (state: RootState) => ({
  tasksCollection: state.tasksCollection,
  diagnosesCollection: state.diagnosesCollection,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  changeEventTaskStatus: dispatch.tasksCollection.changeEventTaskStatus,
  setDiagnosesCollectionError: dispatch.diagnosesCollection.setDiagnosesCollectionError,
});

export const EventTasks = connect(mapState, mapDispatch)(EventTasksComponent);
