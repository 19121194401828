import { IFilterParams } from 'common/models';
import { ESearchParams, ESort } from 'common/const/enums.const';

export const getNonEmptyParams = (params: IFilterParams) => {
  return (
    Object.entries(params)
      .map(([key, value]) => [key, value?.toString()])
      // eslint-disable-next-line
      .filter(([key, value]) => value !== undefined && (value as string).length !== 0)
  );
};

export const sort = (
  sorter: any,
  setSearchParam: (key: ESearchParams, value: string) => void,
  removeSearchParam: (key: ESearchParams) => void
) => {
  if (sorter.order) {
    setSearchParam(ESearchParams.OrderField, sorter.field === 'timer' ? ESort.TimeSpent : sorter.field);
    setSearchParam(ESearchParams.OrderDirection, sorter.order === 'ascend' ? 'ASC' : 'DESC');
  } else {
    removeSearchParam(ESearchParams.OrderField);
    removeSearchParam(ESearchParams.OrderDirection);
  }
};
