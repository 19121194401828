import React from 'react';

interface IComponentProps {
  link: string;
  description?: string;
  onClick: () => void;
}

export const NavigationSubheader: React.FC<IComponentProps> = ({ link, description, onClick }) => {
  return (
    <div className="navigation-subheader">
      <button className="navigation-subheader__back" onClick={onClick}>
        <p>{link}</p>
      </button>
      {description && <p className="navigation-subheader__description">{description}</p>}
    </div>
  );
};
