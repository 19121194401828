import { createModel } from '@rematch/core';
import { IRootModel } from 'app/store';
import {
  IDashboardInfoModel,
  IEnrollmentCollection,
  IEnrollmentCollectionQueryParams,
  IEventsCollection,
  IEventsQueryParameters,
  IPatientsCollection,
  IPatientsCollectionQueryParams,
} from 'entities/Dashboard/Dashboard.models';
import { dashboardTransport } from 'entities/Dashboard/Dashboard.transport';

export const eventsCollection = createModel<IRootModel>()({
  state: {
    data: null as IEventsCollection | null,
    loading: false,
  },
  reducers: {
    setEventsCollection: (state, payload: IEventsCollection) => ({ ...state, data: payload }),
    setEventsCollectionLoading: (state, payload: boolean) => ({ ...state, loading: payload }),
  },
  effects: (dispatch) => ({
    async getEventsCollection(params: IEventsQueryParameters, models) {
      dispatch.eventsCollection.setEventsCollectionLoading(true);
      return dashboardTransport
        .getEventsCollection(params)
        .then((response) => {
          dispatch.eventsCollection.setEventsCollection(response);
          dispatch.dashboardInfoModel.getDashboardInfo().then(() => {
            const authModelData = models.authModel.data;

            if (authModelData && authModelData.access) {
              dispatch.statistics.getDashboardStatistics(authModelData.access.role);
            }
          });
        })
        .finally(() => {
          dispatch.eventsCollection.setEventsCollectionLoading(false);
        });
    },
    async getEventById(eventId: number) {
      dispatch.eventsCollection.setEventsCollectionLoading(true);
      return dashboardTransport
        .getEventById(eventId)
        .then((response) => {
          return response.isBilled;
        })
        .finally(() => {
          dispatch.eventsCollection.setEventsCollectionLoading(false);
        });
    },
  }),
});

export const enrollmentsCollection = createModel<IRootModel>()({
  state: {
    data: null as IEnrollmentCollection | null,
    loading: false,
  },
  reducers: {
    setEnrollmentsCollection: (state, payload: IEnrollmentCollection) => ({ ...state, data: payload }),
    setEnrollmentsLoading: (state, payload: boolean) => ({ ...state, loading: payload }),
  },
  effects: (dispatch) => ({
    async getEnrollmentsCollection(params: IEnrollmentCollectionQueryParams) {
      dispatch.enrollmentsCollection.setEnrollmentsLoading(true);
      dashboardTransport
        .getEnrollmentsCollection(params)
        .then((response) => {
          dispatch.enrollmentsCollection.setEnrollmentsCollection(response);
          dispatch.dashboardInfoModel.getDashboardInfo().then(() => {
            dispatch.statistics.getEnrollmentsStatistics();
          });
        })
        .finally(() => {
          dispatch.enrollmentsCollection.setEnrollmentsLoading(false);
        });
    },
  }),
});

export const patientsCollection = createModel<IRootModel>()({
  state: {
    data: null as IPatientsCollection | null,
    loading: false,
  },
  reducers: {
    setPatientsCollection: (state, payload: IPatientsCollection) => ({ ...state, data: payload }),
    setPatientsLoading: (state, payload: boolean) => ({ ...state, loading: payload }),
  },
  effects: (dispatch) => ({
    async getPatientsCollection(params: IPatientsCollectionQueryParams) {
      dispatch.patientsCollection.setPatientsLoading(true);
      dashboardTransport
        .getPatientsCollection(params)
        .then((response) => {
          dispatch.patientsCollection.setPatientsCollection(response);
          dispatch.dashboardInfoModel.getDashboardInfo().then(() => {
            dispatch.statistics.getPatientsStatistics();
          });
        })
        .finally(() => {
          dispatch.patientsCollection.setPatientsLoading(false);
        });
    },
  }),
});

export const dashboardInfoModel = createModel<IRootModel>()({
  state: {
    data: null as IDashboardInfoModel | null,
    loading: false,
  },
  reducers: {
    setDashboardInfoModel: (state, payload: IDashboardInfoModel) => ({ ...state, data: payload }),
    setDashboardInfoModelLoading: (state, payload: boolean) => ({ ...state, loading: payload }),
  },
  effects: (dispatch) => ({
    async getDashboardInfo() {
      dispatch.dashboardInfoModel.setDashboardInfoModelLoading(true);
      return dashboardTransport
        .getDashboardInfo()
        .then((response) => {
          dispatch.dashboardInfoModel.setDashboardInfoModel(response);
        })
        .finally(() => {
          dispatch.dashboardInfoModel.setDashboardInfoModelLoading(false);
        });
    },
  }),
});
