import React from 'react';
import { connect } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';
import { Layout } from 'antd';
import { EDashboardTabs, ERoutes } from 'common/const/enums.const';
import { RootState } from 'app/store';
import Dashboard from 'entities/Dashboard/components/Dashboard';
import Billing from 'entities/Billing/components/EMBilling/Billing';
import Operations from 'entities/Operations/components/Operations';
import Event from 'entities/Event/components/Event';
import { Timings } from 'entities/Timings/components/Timings';
import { Audit } from 'entities/Timings/components/Audit';
import { getDashboardPathname } from 'entities/Dashboard/Dashboard.helper';
import { Enrollment } from 'entities/Enrollment/components/Enrollment';
import { CCMBilling } from 'entities/Billing/components/CCMBilling/CCMBilling';
import { CCMPatient } from 'entities/Patient/components/CCMPatient';

const { Content } = Layout;

type AllType = ReturnType<typeof mapState>;

const PrivateLayout: React.FC<AllType> = ({ authModel }) => {
  const { data, loading } = authModel;
  const authorized = data?.access?.token;

  if (!authorized && !loading) {
    return <Navigate to={ERoutes.Login} />;
  }

  if (!authorized && loading) {
    return null;
  }

  return (
    <Content>
      <Routes>
        <Route path={ERoutes.Dashboard} element={<Dashboard />} />
        <Route path={ERoutes.EMBilling} element={<Billing />} />
        <Route path={ERoutes.Operations} element={<Operations />} />
        <Route path={ERoutes.Event} element={<Event />} />
        <Route path={ERoutes.Timings} element={<Timings />} />
        <Route path={ERoutes.Audit} element={<Audit />} />
        <Route path={ERoutes.Enrollment} element={<Enrollment />} />
        <Route path={ERoutes.CCMBilling} element={<CCMBilling />} />
        <Route path={ERoutes.Patient} element={<CCMPatient />} />
        <Route path="*" element={<Navigate to={getDashboardPathname(EDashboardTabs.Events)} replace />} />
      </Routes>
    </Content>
  );
};

const mapState = (state: RootState) => ({
  authModel: state.authModel,
});

export default connect(mapState)(PrivateLayout);
