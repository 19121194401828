import React, { useState } from 'react';
import { Button, Input, Modal, Radio, Select } from 'antd';
import { connect } from 'react-redux';
import moment from 'moment/moment';
import { DEFAULT_MODAL_WIDTH } from 'common/config';
import { RootState } from 'app/store';
import { TimeEditorItem } from 'entities/Timer/components/TimeEditorItem';

type AllType = ReturnType<typeof mapState>;

const EditPhysicianTimeModal: React.FC<AllType> = (props) => {
  const { editPhysicianTimeModalParams, eventTimerModel } = props;
  const { open, physiciansCollection, eventId, time = 0, handleSend, handleCancel } = editPhysicianTimeModalParams;
  const [selectedPhysician, setSelectedPhysician] = useState<number>();
  const [hours, setHoursValue] = useState<number>(moment.duration(time).hours());
  const [minutes, setMinutesValue] = useState<number>(moment.duration(time).minutes());
  const [seconds, setSecondsValue] = useState<number>(moment.duration(time).seconds());
  const [comment, setComment] = useState<string>('');
  const [isAddTimeRecordType, setIsAddTimeRecordType] = useState<boolean>(true);

  const commonResults = eventTimerModel?.data?.commonResults || [];
  const disabled = !selectedPhysician || !comment;

  const setTime = (time?: number) => {
    if (!time) {
      setHoursValue(0);
      setMinutesValue(0);
      setSecondsValue(0);
    } else {
      setHoursValue(moment.duration(time).hours());
      setMinutesValue(moment.duration(time).minutes());
      setSecondsValue(moment.duration(time).seconds());
    }
  };

  const handleCancelClick = () => {
    handleCancel?.();
    setComment('');
  };

  const handleSaveClick = () => {
    const milliseconds = (hours * 3600 + minutes * 60 + seconds) * 1000;

    if (eventId) {
      handleSend?.({
        eventId,
        time: milliseconds,
        comment,
        userUid: selectedPhysician,
        addTime: isAddTimeRecordType,
      });
    }

    setComment('');
  };

  const handlePhysicianChange = (value: number) => {
    const result = commonResults.find(({ userUid }) => userUid === value);

    if (result?.time && !isAddTimeRecordType) {
      setTime(result.time);
    } else {
      setTime(0);
    }

    setSelectedPhysician(value);
  };

  const handleTimeRecordTypeChange = (isAddTimeRecordType: boolean) => {
    if (isAddTimeRecordType) {
      setTime(0);
    } else {
      const result = commonResults.find(({ userUid }) => userUid === selectedPhysician);

      setTime(result?.time);
    }

    setIsAddTimeRecordType(isAddTimeRecordType);
  };

  return (
    <Modal title="Edit Physician Time Spent" open={open} footer={false} onCancel={handleCancelClick} width={DEFAULT_MODAL_WIDTH}>
      <div className="modal__body">
        <Select className="modal__select" placeholder="Select user" value={selectedPhysician} onSelect={handlePhysicianChange}>
          {physiciansCollection?.map((item) => {
            return (
              <Select.Option key={item.id} value={item.id}>
                {item.name}
              </Select.Option>
            );
          })}
        </Select>
        <Radio.Group value={isAddTimeRecordType} onChange={(e) => handleTimeRecordTypeChange(e.target.value)}>
          <Radio value={true}>Add time record</Radio>
          <Radio value={false}>Edit time record</Radio>
        </Radio.Group>
        <div className="timer__editor">
          <TimeEditorItem value={hours} setValue={setHoursValue} hours />

          <span>:</span>

          <TimeEditorItem value={minutes} setValue={setMinutesValue} />

          <span>:</span>

          <TimeEditorItem value={seconds} setValue={setSecondsValue} />
        </div>
        <Input.TextArea placeholder="Comment" rows={6} value={comment} onChange={(e) => setComment(e.target.value)} autoFocus />
      </div>

      <div className="modal__footer">
        <Button className="btn-primary" onClick={handleSaveClick} disabled={disabled}>
          Save
        </Button>
        <Button onClick={handleCancelClick}>Cancel</Button>
      </div>
    </Modal>
  );
};

const mapState = (state: RootState) => ({
  editPhysicianTimeModalParams: state.modals.editPhysicianTimeModalParams,
  eventTimerModel: state.eventTimerModel,
  eventLoading: state.actionModel.loading,
  enrollmentLoading: state.enrollmentReviewHistoryModel.loading,
  patientLoading: state.patientReviewHistoryModel.loading,
  patientTimerLoading: state.patientTimerModel.loading,
});

export default connect(mapState)(EditPhysicianTimeModal);
