import { createModel } from '@rematch/core';
import { IRootModel } from 'app/store';
import { timerTransport } from 'entities/Timer/Timer.transport';
import {
  IEventTimerModel,
  IEventTimerModelUpdateParams,
  IPatientTimerModel,
  IPatientTimerPayload,
  IPatientTimerUpdatePayload,
} from 'entities/Timer/Timer.models';
import { noCommentProvidedMessage } from 'entities/ReviewHistory/ReviewHistory.const';

let timerId: NodeJS.Timer;

export const eventTimerModel = createModel<IRootModel>()({
  state: {
    data: null as IEventTimerModel | null,
    isRunning: false,
    untrackedTime: 0,
    loading: false,
  },
  reducers: {
    setEventTimerModel: (state, payload: IEventTimerModel) => ({ ...state, data: payload }),
    setEventTimerModelIsRunning: (state, isRunning: boolean) => ({ ...state, isRunning }),
    setEventTimerModelUntrackedTime: (state, untrackedTime: number) => ({ ...state, untrackedTime }),
    clearEventTimerModel: () => ({ data: null, isRunning: false, untrackedTime: 0, loading: false }),
    setEventTimerModelLoading: (state, loading: boolean) => ({ ...state, loading }),
  },
  effects: (dispatch) => ({
    async getEventTimerModel(eventId: number) {
      dispatch.eventTimerModel.setEventTimerModelLoading(true);
      return await timerTransport
        .getEventTimerModel(eventId)
        .then((response) => {
          dispatch.eventTimerModel.setEventTimerModel(response);
          return response?.time?.time;
        })
        .finally(() => {
          dispatch.eventTimerModel.setEventTimerModelLoading(false);
        });
    },
    async startEventTimer(eventId: number) {
      dispatch.eventTimerModel.setEventTimerModelLoading(true);
      await timerTransport
        .startEventTimer(eventId)
        .then(() => {
          dispatch.eventTimerModel.setEventTimerModelIsRunning(true);
          dispatch.eventTimerModel.pingEventTimer(eventId);
        })
        .catch((error) => {
          if (error.status === 409) {
            dispatch.eventTimerModel.stopEventTimer(eventId).then(() => {
              dispatch.eventTimerModel.startEventTimer(eventId);
            });
          }
        })
        .finally(() => {
          dispatch.eventTimerModel.setEventTimerModelLoading(false);
        });
    },
    async stopEventTimer(eventId: number) {
      dispatch.eventTimerModel.setEventTimerModelLoading(true);
      return await timerTransport
        .stopEventTimer(eventId)
        .then((response) => {
          dispatch.eventTimerModel.clearPingEventTimer();
          dispatch.eventTimerModel.setEventTimerModelIsRunning(false);
          dispatch.eventReviewHistoryModel.getEventReviewHistoryModel(eventId);
          return response.id;
        })
        .catch(() => {
          dispatch.eventTimerModel.setEventTimerModelIsRunning(false);
        })
        .finally(() => {
          dispatch.eventTimerModel.setEventTimerModelLoading(false);
        });
    },
    async updateEventTimerModel(params: IEventTimerModelUpdateParams) {
      dispatch.eventTimerModel.setEventTimerModelLoading(true);
      await timerTransport
        .updateEventTimerModel(params)
        .then(() => {
          dispatch.eventReviewHistoryModel.getEventReviewHistoryModel(params.eventId);
          timerTransport.getEventTimerModel(params.eventId).then((response) => {
            dispatch.eventTimerModel.setEventTimerModel(response);
          });
        })
        .finally(() => {
          dispatch.eventTimerModel.setEventTimerModelLoading(false);
        });
    },
    async pingEventTimer(eventId: number) {
      clearInterval(timerId);
      timerId = setInterval(() => {
        timerTransport.pingEventTimer(eventId);
      }, 10000);
    },
    clearPingEventTimer() {
      clearInterval(timerId);
    },
    async deleteEventTimerModel(eventId: number) {
      dispatch.eventTimerModel.setEventTimerModelLoading(true);
      await timerTransport
        .deleteEventTimerModel(eventId)
        .then(() => {
          dispatch.eventTimerModel.setEventTimerModelIsRunning(false);
        })
        .finally(() => {
          dispatch.eventTimerModel.setEventTimerModelLoading(false);
        });
    },
  }),
});

export const patientTimerModel = createModel<IRootModel>()({
  state: {
    data: null as IPatientTimerModel | null,
    isRunning: false,
    untrackedTime: 0,
    loading: false,
  },
  reducers: {
    setPatientTimerModel: (state, payload: IPatientTimerModel) => ({ ...state, data: payload }),
    setPatientTimerModelIsRunning: (state, isRunning: boolean) => ({ ...state, isRunning }),
    setPatientTimerModelUntrackedTime: (state, untrackedTime: number) => ({ ...state, untrackedTime }),
    setPatientTimerModelLoading: (state, loading: boolean) => ({ ...state, loading }),
    clearPatientTimerModel: () => ({ data: null, isRunning: false, untrackedTime: 0, loading: false }),
  },
  effects: (dispatch) => ({
    async getPatientTimerModel(billingCycleId: string) {
      dispatch.patientTimerModel.setPatientTimerModelLoading(true);
      return await timerTransport
        .getPatientTimerModel(billingCycleId)
        .then((response) => {
          dispatch.patientTimerModel.setPatientTimerModel(response);
          return response?.time?.time;
        })
        .finally(() => {
          dispatch.patientTimerModel.setPatientTimerModelLoading(false);
        });
    },
    async startPatientTimer(billingCycleId: string) {
      dispatch.patientTimerModel.setPatientTimerModelLoading(true);
      await timerTransport
        .startPatientTimer(billingCycleId)
        .then(() => {
          dispatch.patientTimerModel.setPatientTimerModelIsRunning(true);
          dispatch.patientTimerModel.pingPatientTimer(billingCycleId);
        })
        .catch((error) => {
          if (error.status === 409) {
            dispatch.patientTimerModel.stopPatientTimer({ billingCycleId, comment: noCommentProvidedMessage }).then(() => {
              dispatch.patientTimerModel.startPatientTimer(billingCycleId);
            });
          }
        })
        .finally(() => {
          dispatch.patientTimerModel.setPatientTimerModelLoading(false);
        });
    },
    async stopPatientTimer(payload: IPatientTimerPayload) {
      dispatch.patientTimerModel.setPatientTimerModelLoading(true);
      return await timerTransport
        .stopPatientTimer(payload)
        .then((response) => {
          dispatch.patientTimerModel.setPatientTimerModelIsRunning(false);
          dispatch.patientTimerModel.clearPingPatientTimer();
          dispatch.patientReviewHistoryModel.getPatientReviewHistoryModel(payload.billingCycleId);
          return response;
        })
        .finally(() => {
          dispatch.patientTimerModel.setPatientTimerModelLoading(false);
        });
    },
    async pingPatientTimer(billingCycleId: string) {
      clearInterval(timerId);
      timerId = setInterval(() => {
        timerTransport.pingPatientTimer(billingCycleId);
      }, 10000);
    },
    clearPingPatientTimer() {
      clearInterval(timerId);
    },
    async updatePatientTimerModel(payload: IPatientTimerUpdatePayload) {
      dispatch.patientTimerModel.setPatientTimerModelLoading(true);
      await timerTransport
        .updatePatientTimerModel(payload)
        .then(() => {
          dispatch.patientReviewHistoryModel.getPatientReviewHistoryModel(payload.billingCycleId);
          dispatch.patientTimerModel.getPatientTimerModel(payload.billingCycleId);
        })
        .finally(() => {
          dispatch.patientTimerModel.setPatientTimerModelLoading(false);
        });
    },
    async deletePatientTimerModel(payload: IPatientTimerPayload) {
      dispatch.patientTimerModel.setPatientTimerModelLoading(true);
      return await timerTransport
        .deletePatientTimerModel(payload)
        .then(() => {
          dispatch.patientTimerModel.setPatientTimerModelIsRunning(false);
        })
        .finally(() => {
          dispatch.patientTimerModel.setPatientTimerModelLoading(false);
        });
    },
  }),
});
