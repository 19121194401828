import { createModel, RematchDispatch } from '@rematch/core';
import { IRootModel } from 'app/store';
import {
  ICcmDiagnosesCollection,
  IDiagnosesCollectionPayload,
  IDiagnosesItem,
  IEventDiagnosesCollection,
} from 'entities/Diagnoses/Diagnoses.models';
import { diagnosesTransport } from 'entities/Diagnoses/Diagnoses.transport';

const postSuccess = (
  dispatch: RematchDispatch<IRootModel>,
  response: IEventDiagnosesCollection | ICcmDiagnosesCollection,
  payload: IDiagnosesCollectionPayload
) => {
  const diagnosesData = response.diagnoses.map((item) => ({ displayName: item.diagnosis, code: item.code }));
  dispatch.diagnosesCollection.setDiagnosesCollection(diagnosesData);

  if (payload.onSuccess) {
    payload.onSuccess();
  }
};

export const diagnosesCollection = createModel<IRootModel>()({
  state: {
    data: [] as IDiagnosesItem[],
    loading: false,
    error: false,
  },
  reducers: {
    setDiagnosesCollection: (state, payload: IDiagnosesItem[]) => ({ ...state, data: payload }),
    setDiagnosesCollectionLoading: (state, payload: boolean) => ({ ...state, loading: payload }),
    setDiagnosesCollectionError: (state, payload: boolean) => ({ ...state, error: payload }),
    clearDiagnosesCollection: (state) => ({ ...state, data: [], error: false }),
  },
  effects: (dispatch) => ({
    async addEventDiagnosesCollection(payload: IDiagnosesCollectionPayload) {
      dispatch.diagnosesCollection.setDiagnosesCollectionLoading(true);
      diagnosesTransport
        .addEventDiagnosesCollection(payload)
        .then((response) => {
          postSuccess(dispatch, response, payload);
        })
        .finally(() => {
          dispatch.diagnosesCollection.setDiagnosesCollectionLoading(false);
        });
    },
    async addCcmDiagnosesCollection(payload: IDiagnosesCollectionPayload) {
      dispatch.diagnosesCollection.setDiagnosesCollectionLoading(true);
      diagnosesTransport
        .addCcmDiagnosesCollection(payload)
        .then((response) => {
          postSuccess(dispatch, response, payload);
        })
        .finally(() => {
          dispatch.diagnosesCollection.setDiagnosesCollectionLoading(false);
        });
    },
  }),
});

export const ccdaDiagnosesCollection = createModel<IRootModel>()({
  state: {
    data: [] as IDiagnosesItem[],
  },
  reducers: {
    setCcdaDiagnosesCollection: (state, payload: IDiagnosesItem[]) => ({ ...state, data: payload }),
    clearCcdaDiagnosesCollection: (state) => ({ ...state, data: [] }),
  },
});

export const clinicalDiagnosesCollection = createModel<IRootModel>()({
  state: {
    data: [] as IDiagnosesItem[],
    loading: false,
  },
  reducers: {
    setClinicalDiagnosesCollection: (state, payload: IDiagnosesItem[]) => ({ ...state, data: payload }),
    setClinicalDiagnosesCollectionLoading: (state, payload: boolean) => ({ ...state, loading: payload }),
    clearClinicalDiagnosesCollection: (state) => ({ ...state, data: [] }),
  },
  effects: (dispatch) => ({
    async getClinicalDiagnosesCollection(value: string) {
      dispatch.clinicalDiagnosesCollection.setClinicalDiagnosesCollectionLoading(true);
      return diagnosesTransport
        .getClinicalDiagnosesCollection(value)
        .then((response) => {
          // Сomplete data on diagnoses is the third element of the array
          if (response[3]) {
            const diagnosesList = (response[3] as string[]).map((item) => ({ displayName: item[1], code: item[0] }));
            dispatch.clinicalDiagnosesCollection.setClinicalDiagnosesCollection(diagnosesList);
            return diagnosesList;
          }

          return [];
        })
        .finally(() => {
          dispatch.clinicalDiagnosesCollection.setClinicalDiagnosesCollectionLoading(false);
        });
    },
  }),
});
