import { useSearchParams as useSearchParamsHook } from 'react-router-dom';
import { ESearchParams } from 'common/const/enums.const';

export const useSearchParams = () => {
  const [params, setParams] = useSearchParamsHook();

  const setSearchParam = (key: ESearchParams, value: string) => {
    setParams((params) => {
      params.set(key, value);
      return params;
    });
  };

  const removeSearchParam = (key: ESearchParams) => {
    setParams((params) => {
      params.delete(key);
      return params;
    });
  };

  const setSearchParamWithCondition = (key: ESearchParams | string, value: string | undefined) => {
    setParams((params) => {
      value ? params.set(key, value) : params.delete(key);
      return params;
    });
  };

  return { searchParams: params, setSearchParam, removeSearchParam, setSearchParamWithCondition };
};
