import React from 'react';
import { Button } from 'antd';
import { MobileOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { RootState } from 'app/store';

interface IComponentProps {
  phone: string;
  disabled?: boolean;
  onCallClick: () => void;
}

type AllType = ReturnType<typeof mapState> & IComponentProps;

export const PhoneComponent: React.FC<AllType> = (props) => {
  const { phone, disabled, onCallClick, loading } = props;

  return (
    <div className="phone">
      <p className="phone__number">Phone: {phone}</p>
      <Button icon={<MobileOutlined />} onClick={onCallClick} loading={loading} disabled={disabled}>
        Call
      </Button>
    </div>
  );
};

const mapState = (state: RootState) => ({
  loading: state.twilioModel.loading,
});

export const Phone = connect(mapState)(PhoneComponent);
