import { clearCreds, getCreds, saveCreds } from '@axmit/axios-patch-jwt';
import { createModel } from '@rematch/core';
import { SocketIOService } from 'common/SocketIoService';
import { ERole, EStatusCodes } from 'common/const/enums.const';
import { IRootModel } from 'app/store';
import { IAuthModelRequestParams, ITokenModel } from 'entities/Auth/Auth.models';
import { authTransport } from 'entities/Auth/Auth.transport';

export default createModel<IRootModel>()({
  state: {
    data: null as ITokenModel | null,
    loading: true,
    statusCode: undefined as number | undefined,
  },
  reducers: {
    login: (state, payload: { data: ITokenModel | null; statusCode?: number }) => ({
      ...state,
      data: payload.data,
      statusCode: payload.statusCode,
    }),
    logout: (state) => ({ ...state, data: null, loading: false, statusCode: undefined }),
    setAuthLoading: (state, payload: boolean) => ({ ...state, loading: payload }),
  },
  effects: (dispatch) => ({
    async initAuthModel() {
      dispatch.authModel.setAuthLoading(true);
      await getCreds()
        .then((response: ITokenModel) => {
          if (Object.values(response).length) {
            dispatch.authModel.login({ data: response });
            SocketIOService.connect(response.access?.token as string);
          } else {
            dispatch.authModel.logout();
          }
        })
        .finally(() => {
          dispatch.authModel.setAuthLoading(false);
        });
    },
    async addAuthModel(payload: IAuthModelRequestParams) {
      dispatch.authModel.setAuthLoading(true);
      await authTransport
        .addAuthModel(payload)
        .then((response) => {
          const creds = {
            access: {
              id: response.name,
              token: response.access_token,
              userId: response.id,
              issuedAt: Number(response.expires_in),
              expiredAt: Number(response.expires_in),
              role: response.rights[0] as ERole,
              uid: response.uid,
            },
            refresh: {
              id: response.name,
              token: String(response.refresh_token),
              userId: response.id,
              issuedAt: Number(response.expires_in),
              expiredAt: Number(response.expires_in),
            },
          };

          saveCreds(creds);
          dispatch.authModel.login({ data: creds, statusCode: EStatusCodes.Success });
          SocketIOService.connect(creds.access.token);
        })
        .catch((response) => {
          console.error(response);
          dispatch.authModel.login({ data: null, statusCode: response.status });
        })
        .finally(() => {
          dispatch.authModel.setAuthLoading(false);
        });
    },
    async refreshTokenModel() {
      return await getCreds().then(async (response) => {
        return await authTransport.refreshToken(response.refresh.token).then((res) => {
          if (res && res.access_token) {
            const newCreds = {
              access: {
                ...response.access,
                token: res.access_token,
              },
              refresh: {
                ...response.refresh,
                token: res.refresh_token,
              },
            };

            saveCreds(newCreds);
            return newCreds;
          } else {
            clearCreds();
            dispatch.authModel.logout();
            SocketIOService.disconnect();
            return undefined;
          }
        });
      });
    },
    deleteAuthModel() {
      dispatch.authModel.setAuthLoading(true);
      authTransport
        .deleteAuthModel()
        .then(() => {
          clearCreds();
          dispatch.authModel.logout();
          SocketIOService.disconnect();
        })
        .finally(() => {
          dispatch.authModel.setAuthLoading(false);
        });
    },
  }),
});
