import { createModel } from '@rematch/core';
import { EDashboardTabs, EEventStatus, EStatusCodes } from 'common/const/enums.const';
import { IRootModel } from 'app/store';
import { IEventModel } from 'entities/Event/Event.models';
import { eventTransport } from 'entities/Event/Event.transport';
import { eventTasksToDataMapper } from 'entities/Tasks/Tasks.mapper';

type State = IEventModel;

export const eventModel = createModel<IRootModel>()({
  state: null as State | null,
  reducers: {
    setEventModel: (state, payload: State) => ({ ...state, ...payload }),
    clearEventModel: () => null,
  },
  effects: (dispatch) => ({
    async getEventModel(id: number) {
      try {
        const response = await eventTransport.getEventModel(id);
        await dispatch.patientModel.getPatientModel(id);
        dispatch.eventModel.setEventModel({ ...response, eventId: id, eventStatus: response.status as EEventStatus });
        dispatch.tasksCollection.setTasksCollection(eventTasksToDataMapper(response.tasks));
      } catch (error) {
        // @ts-ignore
        if (error?.data?.statusCode === EStatusCodes.NotFound) {
          window.location.replace(`/dashboard/${EDashboardTabs.Events}`);
        }
      }
    },
    clearModels() {
      dispatch.eventModel.clearEventModel();
      dispatch.patientModel.clearPatientModel();
      dispatch.promisCollection.clearPromisCollection();
      dispatch.assessmentHistoryCollection.clearAssessmentHistoryCollection();
      dispatch.eventReviewHistoryModel.clearEventReviewHistoryModel();
      dispatch.eventTimerModel.clearEventTimerModel();
      dispatch.modals.setInactiveModalParams({ open: false, title: null, description: null });
      dispatch.diagnosesCollection.clearDiagnosesCollection();
      dispatch.ccdaDiagnosesCollection.clearCcdaDiagnosesCollection();
      dispatch.clinicalDiagnosesCollection.clearClinicalDiagnosesCollection();
      dispatch.patientHistoryCollection.clearPatientHistoryCollection();
      dispatch.physiciansCollection.clearPhysiciansCollection();
    },
  }),
});
