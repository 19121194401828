import { createModel } from '@rematch/core';
import { IRootModel } from 'app/store';
import { IPhysician } from 'entities/Physicians/Physicians.models';
import { physiciansTransport } from 'entities/Physicians/Physicians.transport';

export const physiciansCollection = createModel<IRootModel>()({
  state: {
    data: null as IPhysician[] | null,
    loading: false,
  },
  reducers: {
    setPhysiciansCollection: (state, payload: IPhysician[]) => ({ ...state, data: payload }),
    setPhysiciansCollectionLoading: (state, payload: boolean) => ({ ...state, loading: payload }),
    clearPhysiciansCollection: (state) => ({ ...state, data: null }),
  },
  effects: (dispatch) => ({
    async getPhysiciansCollection() {
      dispatch.physiciansCollection.setPhysiciansCollectionLoading(true);
      await physiciansTransport
        .getPhysiciansCollection()
        .then((response) => {
          dispatch.physiciansCollection.setPhysiciansCollection(response);
        })
        .finally(() => {
          dispatch.physiciansCollection.setPhysiciansCollectionLoading(false);
        });
    },
  }),
});
