import React, { useEffect, useMemo, useState } from 'react';
import moment, { Moment } from 'moment';
import { Button, Table } from 'antd';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { EProgramType, ETimingsTypes } from 'common/const/enums.const';
import { RouterPrompt } from 'common/components/RouterPrompt';
import { ReactComponent as BreakdownIcon } from 'app/assets/images/svg/breakdownIcon.svg';
import { ReactComponent as TrashIcon } from 'app/assets/images/svg/trashIcon.svg';
import { RootDispatch, RootState, history } from 'app/store';
import {
  findTimingItemsErrors,
  mapMonthlyItems,
  renderOneTimeItemsRecords,
  renderMonthlyItemsRecords,
  timingItemTimeLimit,
} from 'entities/Timings/Timings.helper';
import {
  ITimingItem,
  ITimingItemChangeCodePayload,
  ITimingItemChangeFeePayload,
  ITimingsChangeValuesParams,
} from 'entities/Timings/Timings.models';
import { TimingsUnsavedModal } from 'entities/Modals/components/TimingsUnsavedModal';

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

const TimingsBody: React.FC<AllType> = (props) => {
  const {
    timingModel,
    getTimingModel,
    setMonthlyItems,
    setTimingsSaveModalParams,
    setTimingsCancelModalParams,
    setOneTimeItems,
    addOneTimeItem,
    changeOneTimeItemCode,
    changeOneTimeItemFee,
    deleteOneTimeItem,
  } = props;
  const { monthlyItems, oneTimeItems, loading } = timingModel;
  const [formChanged, setFormChanged] = useState<boolean>(false);
  const { type } = useParams();

  const oneTimeItemsDataSource = useMemo(() => {
    return oneTimeItems.map((item, index) => ({ ...item, key: index }));
  }, [oneTimeItems]);
  const oneTimeItemsColumns = renderOneTimeItemsRecords(
    handleChangeOneTimeItemCode,
    handleChangeOneTimeItemFee,
    handleDeleteOneTimeItem
  );
  const monthlyItemsError = findTimingItemsErrors(monthlyItems);
  const oneTimeItemsError = findTimingItemsErrors(oneTimeItems);
  const submitDisabled = !formChanged || monthlyItemsError || oneTimeItemsError;

  const handleAddOneTimeItem = () => {
    setFormChanged(true);
    addOneTimeItem();
  };

  function handleChangeOneTimeItemCode(payload: ITimingItemChangeCodePayload) {
    setFormChanged(true);
    changeOneTimeItemCode(payload);
  }

  function handleChangeOneTimeItemFee(payload: ITimingItemChangeFeePayload) {
    setFormChanged(true);
    changeOneTimeItemFee(payload);
  }

  function handleDeleteOneTimeItem(id: string) {
    setFormChanged(true);
    deleteOneTimeItem(id);
  }

  const onBreakdownClick = (timingItem: ITimingItem) => {
    setFormChanged(true);

    const newTimingItem = { ...timingItem };

    if (timingItem.key === 0 && monthlyItems.length < 2) {
      const newToValue = timingItem.from + 300000;

      timingItem.to = newToValue;
      newTimingItem.from = newToValue;
    } else if (timingItem.key === monthlyItems.length - 1) {
      const newToValue = timingItem.from + 300000;

      if (newToValue >= timingItemTimeLimit) {
        timingItem.to = timingItemTimeLimit;
        newTimingItem.from = timingItemTimeLimit;
        newTimingItem.breakdownIsDisabled = true;
      } else {
        timingItem.to = newToValue;
        newTimingItem.from = newToValue;
      }
    } else {
      const newToValue = Number(timingItem.to);

      timingItem.to = (timingItem.from + newToValue) / 2;
      newTimingItem.from = timingItem.to;
      newTimingItem.to = newToValue;
    }

    monthlyItems.splice(Number(timingItem.key) + 1, 0, newTimingItem);

    const newItems = mapMonthlyItems(monthlyItems);
    setMonthlyItems(newItems);
  };

  const onDeleteClick = (timingItem: ITimingItem) => {
    setFormChanged(true);

    const filteredItems = monthlyItems
      .map((item, index, array) => {
        if (item.key === timingItem.key) {
          // At the time of pressing the delete button, the array has two elements
          if (array.length < 3) {
            array[index - 1].to = undefined;
          }

          if (array.length > 2) {
            if (item.key === array.length - 1) {
              array[index - 1].to = undefined;
            } else {
              array[index + 1].from = item.from;
            }
          }
        }

        return item;
      })
      .filter((item) => item.key !== timingItem.key);

    const newItems = mapMonthlyItems(filteredItems);
    setMonthlyItems(newItems);
  };

  const handleTimeChange = (timingItem: ITimingItem, value: Moment) => {
    const hours = moment(value).hours();
    const minutes = moment(value).minutes();
    const seconds = moment(value).seconds();
    const milliseconds = (hours * 3600 + minutes * 60 + seconds) * 1000;
    const prevTimingItem = monthlyItems.find((_, index) => index === Number(timingItem.key) - 1);
    const nextimingItem = monthlyItems.find((_, index) => index === Number(timingItem.key) + 1);

    timingItem.from = milliseconds;

    if (prevTimingItem) {
      prevTimingItem.to = milliseconds;

      if ((nextimingItem && timingItem.from >= nextimingItem.from) || timingItem.from <= prevTimingItem.from) {
        timingItem.timeError = true;
      } else {
        timingItem.timeError = false;
      }
    }
  };

  const handleBillableChange = (timingItem: ITimingItem, value: boolean) => {
    timingItem.isBillable = value;

    if (value) {
      timingItem.code = '';
      timingItem.fee = 0;
    } else {
      timingItem.code = undefined;
      timingItem.fee = undefined;
      timingItem.codeError = false;
      timingItem.feeError = false;
    }
  };

  const handleCodeChange = (timingItem: ITimingItem, value: string) => {
    timingItem.code = value;
    timingItem.codeError = !value.length;
  };

  const handleFeeChange = (timingItem: ITimingItem, value: number) => {
    timingItem.fee = value;
    timingItem.feeError = value === 0;
  };

  const handleAddToNextChange = (timingItem: ITimingItem, value: boolean) => {
    timingItem.addToNext = value;
  };

  const handleValuesChange = (params: ITimingsChangeValuesParams) => {
    setFormChanged(true);
    const { timingItem, key, value } = params;

    switch (key) {
      case ETimingsTypes.From: {
        handleTimeChange(timingItem, value as Moment);
        break;
      }
      case ETimingsTypes.IsBillable:
        handleBillableChange(timingItem, value as boolean);
        break;
      case ETimingsTypes.Code:
        handleCodeChange(timingItem, value as string);
        break;
      case ETimingsTypes.Fee: {
        handleFeeChange(timingItem, Number(value));
        break;
      }
      case ETimingsTypes.AddToNext:
        handleAddToNextChange(timingItem, value as boolean);
        break;
    }

    const newItems = mapMonthlyItems(monthlyItems);
    setMonthlyItems(newItems);
  };

  const onSubmit = () => {
    const mappedOneTimeItems = oneTimeItems.map((item) => ({
      ...item,
      codeError: !item.code?.length,
      feeError: !item.fee,
    }));

    if (findTimingItemsErrors(mappedOneTimeItems)) {
      setOneTimeItems(mappedOneTimeItems);
      return;
    }

    if (monthlyItems.some((item) => item.code?.length === 0 || (item.fee === 0 && !item.feeIsDuplicated))) {
      const newItems = monthlyItems.map((item) => ({
        ...item,
        codeError: item.isBillable && !item.code?.length,
        feeError: item.isBillable && item.fee === 0,
      }));

      setMonthlyItems(newItems);
      return;
    }

    const oneTimeItemsPayload = oneTimeItems.map((item) => ({
      from: item.from,
      isBillable: item.isBillable,
      type: item.type,
      code: item.code,
      // Due to the peculiarities of javascript
      fee: Math.trunc((item.fee as number) * 10 * 10),
    }));

    const monthlyItemsPayload = monthlyItems.map((item) => ({
      from: item.from,
      to: item.to,
      isBillable: item.isBillable,
      code: item.code,
      // Due to the peculiarities of javascript
      fee: item.fee ? Math.trunc(item.fee * 10 * 10) : item.fee,
      addToNext: item.addToNext,
      type: item.type,
    }));

    setTimingsSaveModalParams({ open: true, type, items: [...oneTimeItemsPayload, ...monthlyItemsPayload], setFormChanged });
  };

  const onCancelClick = () => {
    if (formChanged) {
      setTimingsCancelModalParams({ open: true, setFormChanged });
    } else {
      history.back();
    }
  };

  const columns = renderMonthlyItemsRecords(undefined, handleValuesChange);

  columns.push(
    {
      render: (_, record) => {
        return (
          <Button
            className="timings-body__add-btn"
            icon={<BreakdownIcon />}
            onClick={() => onBreakdownClick(record)}
            disabled={record.breakdownIsDisabled}
          >
            Breakdown
          </Button>
        );
      },
    },
    {
      render: (_, record) => {
        return (
          record.canDelete && (
            <Button className="timings-body__delete-btn" icon={<TrashIcon />} onClick={() => onDeleteClick(record)} />
          )
        );
      },
      width: '1%',
    }
  );

  const renderPrompt = (isActive: boolean, onConfirm?: (value: unknown) => void, onCancel?: (value: unknown) => void) => {
    return (
      isActive && (
        <TimingsUnsavedModal
          onQuit={() => {
            setFormChanged(false);
            onConfirm?.(undefined);
          }}
          onCancel={onCancel}
        />
      )
    );
  };

  useEffect(() => {
    if (type) {
      getTimingModel({ type });
    }
  }, [type]);

  return (
    <div className="timings-body">
      {type === EProgramType.CCM && (
        <div>
          <div className="timings-body__header">
            <div className="timings-body__label">Fixed Fees</div>

            <Button onClick={handleAddOneTimeItem}>Add Fee</Button>
          </div>

          <Table
            className="timings-body__one-time-items-table"
            dataSource={oneTimeItemsDataSource}
            columns={oneTimeItemsColumns}
            loading={loading}
            pagination={false}
          />
        </div>
      )}

      <div>
        <div className="timings-body__label">Hourly Rates</div>

        <Table
          className="timings-body__monthly-items-table"
          dataSource={monthlyItems}
          columns={columns}
          loading={loading}
          pagination={false}
        />
      </div>

      <div className="timings-body__btns">
        <Button className="btn-primary" onClick={onSubmit} disabled={submitDisabled}>
          Submit
        </Button>

        <Button onClick={onCancelClick}>Cancel</Button>
      </div>

      <RouterPrompt when={formChanged} renderPrompt={renderPrompt} />
    </div>
  );
};

const mapState = (dispatch: RootState) => ({
  timingModel: dispatch.timingModel,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  getTimingModel: dispatch.timingModel.getTimingModel,
  setMonthlyItems: dispatch.timingModel.setMonthlyItems,
  setTimingsSaveModalParams: dispatch.modals.setTimingsSaveModalParams,
  setTimingsCancelModalParams: dispatch.modals.setTimingsCancelModalParams,
  setOneTimeItems: dispatch.timingModel.setOneTimeItems,
  addOneTimeItem: dispatch.timingModel.addOneTimeItem,
  changeOneTimeItemCode: dispatch.timingModel.changeOneTimeItemCode,
  changeOneTimeItemFee: dispatch.timingModel.changeOneTimeItemFee,
  deleteOneTimeItem: dispatch.timingModel.deleteOneTimeItem,
});

export default connect(mapState, mapDispatch)(TimingsBody);
