import { createModel } from '@rematch/core';
import { IRootModel } from 'app/store';
import {
  IBillingDetailsModalParams,
  ICallModalParams,
  ICommentModalParams,
  ICommonModalParams,
  ICompleteModalParams,
  IEscalateModalParams,
  INotificationModalParams,
  IPatientHistoryModalParams,
  ITimeEditorModalParams,
  ITimingsCancelModalParams,
  ITimingsHistoryModalParams,
  ITimingsSaveModalParams,
  IMultiselectDiagnosesModalParams,
  ICCMPatientVerbalConsentModalParams,
  IInactiveModalParams,
  IUnenrollPatientModalParams,
  ICarePlanModalParams,
  ICancelModalParams,
  IEmptyTimeRecordModalParams,
  IEditPhysicianTimeModalParams,
} from 'entities/Modals/Modals.models';

interface IState {
  completeModalParams: ICompleteModalParams;
  billingDetailsModalParams: IBillingDetailsModalParams;
  timeEditorModalParams: ITimeEditorModalParams;
  exportedEventModalParams: ICommonModalParams;
  callModalParams: ICallModalParams;
  escalateModalParams: IEscalateModalParams;
  commentModalParams: ICommentModalParams;
  editPhysicianTimeModalParams: IEditPhysicianTimeModalParams;
  timingsHistoryModalParams: ITimingsHistoryModalParams;
  timingsSaveModalParams: ITimingsSaveModalParams;
  timingsCancelModalParams: ITimingsCancelModalParams;
  notificationModalParams: INotificationModalParams;
  patientHistoryModalParams: IPatientHistoryModalParams;
  multiselectDiagnosesModalParams: IMultiselectDiagnosesModalParams;
  ccmPatientVerbalConsentModalParams: ICCMPatientVerbalConsentModalParams;
  inactiveModalParams: IInactiveModalParams;
  unenrollPatientModalParams: IUnenrollPatientModalParams;
  carePlanModalParams: ICarePlanModalParams;
  cancelModalParams: ICancelModalParams;
  emptyTimeRecordModalParams: IEmptyTimeRecordModalParams;
}

export const modals = createModel<IRootModel>()({
  state: {
    completeModalParams: {
      open: false,
      externalId: null,
    },
    billingDetailsModalParams: {
      open: false,
      billingCycleModel: null,
      events: [],
      hasDiagnosis: false,
    },
    timeEditorModalParams: {
      open: false,
      eventId: null,
      hours: null,
      minutes: null,
      seconds: null,
    },
    exportedEventModalParams: {
      open: false,
    },
    callModalParams: {
      open: false,
      token: null,
      phone: null,
      patientName: null,
    },
    escalateModalParams: {
      open: false,
      eventId: null,
      physician: null,
      physiciansCollection: null,
    },
    commentModalParams: {
      open: false,
      externalId: null,
    },
    editPhysicianTimeModalParams: {
      open: false,
      eventId: null,
      physician: null,
      physiciansCollection: [],
      time: 0,
    },
    timingsHistoryModalParams: {
      open: false,
      type: null,
      timingModel: null,
    },
    timingsSaveModalParams: {
      open: false,
      type: null,
      items: null,
    },
    timingsCancelModalParams: {
      open: false,
    },
    notificationModalParams: {},
    patientHistoryModalParams: {
      open: false,
      patientId: null,
      patientName: null,
    },
    multiselectDiagnosesModalParams: {
      open: false,
      title: null,
      limit: null,
      defaultCcdaDiagnosesValue: null,
      defaultClinicalDiagnosesValue: null,
      defaultIsCcda: true,
      ccdaIsDisabled: false,
    },
    ccmPatientVerbalConsentModalParams: {
      open: false,
      defaultConsentDate: null,
    },
    inactiveModalParams: {
      open: false,
      title: null,
      description: null,
    },
    unenrollPatientModalParams: {
      open: false,
    },
    carePlanModalParams: {
      open: false,
      title: null,
      description: null,
    },
    cancelModalParams: {
      open: false,
      title: null,
      description: null,
    },
    emptyTimeRecordModalParams: {
      open: false,
      physicianName: null,
    },
  } as IState,
  reducers: {
    setCompleteModalParams: (state, payload) => ({ ...state, completeModalParams: payload }),
    setBillingDetailsModalParams: (state, payload) => ({ ...state, billingDetailsModalParams: payload }),
    setTimeEditorModalParams: (state, payload) => ({ ...state, timeEditorModalParams: payload }),
    setExportedEventModalParams: (state, payload) => ({ ...state, exportedEventModalParams: payload }),
    setCallModalParams: (state, payload) => ({ ...state, callModalParams: payload }),
    setEscalateModalParams: (state, payload) => ({ ...state, escalateModalParams: payload }),
    setCommentModalParams: (state, payload) => ({ ...state, commentModalParams: payload }),
    setEditPhysicianTimeModalParams: (state, payload) => ({ ...state, editPhysicianTimeModalParams: payload }),
    setNotificationModalParams: (state, payload) => ({ ...state, notificationModalParams: payload }),
    setTimingsHistoryModalParams: (state, payload) => ({ ...state, timingsHistoryModalParams: payload }),
    setTimingsSaveModalParams: (state, payload) => ({ ...state, timingsSaveModalParams: payload }),
    setTimingsCancelModalParams: (state, payload) => ({ ...state, timingsCancelModalParams: payload }),
    setPatientHistoryModalParams: (state, payload) => ({ ...state, patientHistoryModalParams: payload }),
    setMultiselectDiagnosesModalParams: (state, payload) => ({ ...state, multiselectDiagnosesModalParams: payload }),
    setCcmPatientVerbalConsentModalParams: (state, payload) => ({ ...state, ccmPatientVerbalConsentModalParams: payload }),
    setInactiveModalParams: (state, payload) => ({ ...state, inactiveModalParams: payload }),
    setUnenrollPatientModalParams: (state, payload) => ({ ...state, unenrollPatientModalParams: payload }),
    setCarePlanModalParams: (state, payload) => ({ ...state, carePlanModalParams: payload }),
    setCancelModalParams: (state, payload) => ({ ...state, cancelModalParams: payload }),
    setEmptyTimeRecordModalParams: (state, payload) => ({ ...state, emptyTimeRecordModalParams: payload }),
  },
  effects: (dispatch) => ({
    showEventInactiveModal(payload: IInactiveModalParams, state) {
      state.modals.notificationModalParams.onCancel?.();
      dispatch.modals.setEscalateModalParams({ open: false, eventId: null, physician: null, physiciansCollection: null });
      dispatch.modals.setCommentModalParams({ open: false, externalId: null });
      dispatch.modals.setEditPhysicianTimeModalParams({
        open: false,
        eventId: null,
        physician: null,
        physiciansCollection: null,
      });
      dispatch.modals.setCompleteModalParams({ open: false, externalId: null });
      dispatch.modals.setPatientHistoryModalParams({ open: false, patientId: null, patientName: null });
      dispatch.modals.setMultiselectDiagnosesModalParams({
        open: false,
        title: null,
        limit: null,
        defaultCcdaDiagnosesValue: null,
        defaultClinicalDiagnosesValue: null,
        defaultIsCcda: true,
        ccdaIsDisabled: false,
      });
      dispatch.modals.setTimeEditorModalParams({
        open: false,
        eventId: null,
        hours: null,
        minutes: null,
        seconds: null,
      });
      dispatch.modals.setEmptyTimeRecordModalParams({ open: false, physicianName: null });
      dispatch.modals.setInactiveModalParams(payload);
    },
    showEnrollmentInactiveModal(payload: IInactiveModalParams) {
      dispatch.modals.setMultiselectDiagnosesModalParams({
        open: false,
        title: null,
        limit: null,
        defaultCcdaDiagnosesValue: null,
        defaultClinicalDiagnosesValue: null,
        defaultIsCcda: true,
        ccdaIsDisabled: false,
      });
      dispatch.modals.setCcmPatientVerbalConsentModalParams({ open: false, defaultConsentDate: null });
      dispatch.modals.setCommentModalParams({ open: false, externalId: null });
      dispatch.modals.setPatientHistoryModalParams({ open: false, patientId: null, patientName: null });
      dispatch.modals.setCancelModalParams({ open: false, title: null, description: null });
      dispatch.modals.setInactiveModalParams(payload);
    },
    showPatientInactiveModal(payload: IInactiveModalParams, state) {
      state.modals.notificationModalParams.onCancel?.();
      dispatch.modals.setMultiselectDiagnosesModalParams({
        open: false,
        title: null,
        limit: null,
        defaultCcdaDiagnosesValue: null,
        defaultClinicalDiagnosesValue: null,
        defaultIsCcda: true,
        ccdaIsDisabled: false,
      });
      dispatch.modals.setCcmPatientVerbalConsentModalParams({ open: false, defaultConsentDate: null });
      dispatch.modals.setCommentModalParams({ open: false, externalId: null });
      dispatch.modals.setEditPhysicianTimeModalParams({
        open: false,
        eventId: null,
        physician: null,
        physiciansCollection: null,
      });
      dispatch.modals.setTimeEditorModalParams({
        open: false,
        eventId: null,
        hours: null,
        minutes: null,
        seconds: null,
      });
      dispatch.modals.setUnenrollPatientModalParams({ open: false });
      dispatch.modals.setCarePlanModalParams({ open: false, title: null, description: null });
      dispatch.modals.setPatientHistoryModalParams({ open: false, patientId: null, patientName: null });
      dispatch.modals.setInactiveModalParams(payload);
    },
  }),
});
