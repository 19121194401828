import { createModel } from '@rematch/core';
import { exportCSVFile } from 'common/helpers/csv.helper';
import { EDashboardTabs, EStatusCodes } from 'common/const/enums.const';
import { IRootModel } from 'app/store';
import {
  IBillingCycleCollection,
  IBillingCycleQueryParameters,
  IBillingCycleSendParams,
  ICcmBillingCycleCollection,
  ICcmBillingCycleCollectionQueryParams,
  ICcmBillingCycleModel,
  ICcmBillingCycleRejectPayload,
  ICcmBillingCycleSendParams,
} from 'entities/Billing/Billing.models';
import { billingTransport } from 'entities/Billing/Billing.transport';

export const billingCollection = createModel<IRootModel>()({
  state: {
    data: null as IBillingCycleCollection | null,
    loading: false,
  },
  reducers: {
    setBillingCollection: (state, payload: IBillingCycleCollection) => ({ ...state, data: payload }),
    setBillingCollectionLoading: (state, payload: boolean) => ({ ...state, loading: payload }),
  },
  effects: (dispatch) => ({
    async getBillingCollection(params: IBillingCycleQueryParameters) {
      dispatch.billingCollection.setBillingCollectionLoading(true);
      billingTransport
        .getBillingCollection(params)
        .then((response) => {
          dispatch.billingCollection.setBillingCollection(response);
          dispatch.statistics.getBillingCyclesStatistics();
        })
        .finally(() => {
          dispatch.billingCollection.setBillingCollectionLoading(false);
        });
    },
    async getBillingEventsByIdCollection(cycleId: number) {
      dispatch.billingCollection.setBillingCollectionLoading(true);
      return billingTransport
        .getBillingEventsByIdCollection(cycleId)
        .then((response) => {
          return response;
        })
        .finally(() => {
          dispatch.billingCollection.setBillingCollectionLoading(false);
        });
    },
    async getBillingReport(params: IBillingCycleQueryParameters) {
      dispatch.billingCollection.setBillingCollectionLoading(true);
      billingTransport
        .getBillingReport(params)
        .then((response) => exportCSVFile(response, 'billing'))
        .finally(() => {
          dispatch.billingCollection.setBillingCollectionLoading(false);
        });
    },
    async sendBillingCycleIds(params: IBillingCycleSendParams) {
      dispatch.billingCollection.setBillingCollectionLoading(true);
      return billingTransport
        .sendBillingCycleIds(params.billingCycleIds)
        .then(() => {
          dispatch.billingCollection.getBillingCollection(params.params);
        })
        .finally(() => {
          dispatch.billingCollection.setBillingCollectionLoading(false);
        });
    },
  }),
});

export const ccmBillingCollection = createModel<IRootModel>()({
  state: {
    data: null as ICcmBillingCycleCollection | null,
    loading: false,
  },
  reducers: {
    setCcmBillingCollection: (state, payload: ICcmBillingCycleCollection) => ({ ...state, data: payload }),
    setCcmBillingCollectionLoading: (state, payload: boolean) => ({ ...state, loading: payload }),
  },
  effects: (dispatch) => ({
    async getCcmBillingCollection(params: ICcmBillingCycleCollectionQueryParams) {
      dispatch.ccmBillingCollection.setCcmBillingCollectionLoading(true);
      billingTransport
        .getCcmBillingCollection(params)
        .then((response) => {
          dispatch.ccmBillingCollection.setCcmBillingCollection(response);
          dispatch.statistics.getCcmBillingCyclesStatistics();
        })
        .finally(() => {
          dispatch.ccmBillingCollection.setCcmBillingCollectionLoading(false);
        });
    },
    async getCcmBillingReport(params: ICcmBillingCycleCollectionQueryParams) {
      dispatch.ccmBillingCollection.setCcmBillingCollectionLoading(true);
      billingTransport
        .getCcmBillingReport(params)
        .then((response) => exportCSVFile(response, 'billing'))
        .finally(() => {
          dispatch.ccmBillingCollection.setCcmBillingCollectionLoading(false);
        });
    },
    async sendCcmBillingCycleIds(params: ICcmBillingCycleSendParams) {
      dispatch.ccmBillingCollection.setCcmBillingCollectionLoading(true);
      return billingTransport
        .sendCcmBillingCycleIds(params.billingCycleIds)
        .then(() => {
          dispatch.ccmBillingCollection.getCcmBillingCollection(params.params);
        })
        .finally(() => {
          dispatch.ccmBillingCollection.setCcmBillingCollectionLoading(false);
        });
    },
    async rejectCcmBillingCycleIds(payload: ICcmBillingCycleRejectPayload) {
      dispatch.ccmBillingCollection.setCcmBillingCollectionLoading(true);
      return billingTransport
        .rejectCcmBillingCycleIds(payload.billingCycleIds)
        .then(() => {
          dispatch.ccmBillingCollection.getCcmBillingCollection(payload.params);
        })
        .finally(() => {
          dispatch.ccmBillingCollection.setCcmBillingCollectionLoading(false);
        });
    },
  }),
});

export const ccmBillingCycleModel = createModel<IRootModel>()({
  state: {
    data: null as ICcmBillingCycleModel | null,
    loading: false,
  },
  reducers: {
    setCcmBillingCycleModel: (state, payload: ICcmBillingCycleModel) => ({ ...state, data: payload }),
    setCcmBillingCycleModelLoading: (state, payload: boolean) => ({ ...state, loading: payload }),
    clearCcmBillingCycleModel: (state) => ({ ...state, data: null }),
  },
  effects: (dispatch) => ({
    async getCcmBillingCycleById(billingCycleId: string) {
      dispatch.ccmBillingCycleModel.setCcmBillingCycleModelLoading(true);

      try {
        const response = await billingTransport.getCcmBillingCycleById(billingCycleId);
        await dispatch.ccmPatientModel.getBillingCyclesPatientModel(billingCycleId);
        dispatch.ccmBillingCycleModel.setCcmBillingCycleModel(response);
      } catch (error) {
        // @ts-ignore
        if (error?.data?.statusCode === EStatusCodes.NotFound) {
          window.location.replace(`/dashboard/${EDashboardTabs.Patients}`);
        }
      } finally {
        dispatch.ccmBillingCycleModel.setCcmBillingCycleModelLoading(false);
      }
    },
  }),
});
