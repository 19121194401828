import { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import { EDateFormat, EPatientHistoryType } from 'common/const/enums.const';
import { IPatientChangedDiagnoses, IPatientHistoryItem } from 'entities/Patient/Patient.models';

const getPatientHistoryDescription = (record: IPatientHistoryItem) => {
  switch (record.type) {
    case EPatientHistoryType.CreatePatient: {
      return 'New patient created';
    }
    case EPatientHistoryType.DiagnosisUpdate: {
      // @ts-ignore
      const changedTo = record.changedTo as IPatientChangedDiagnoses;
      const diagnoses: string[] = [];

      changedTo.diagnoses.forEach((item) => {
        diagnoses.push(`[${item.code}] ${item.diagnosis}`);
      });

      return `Diagnosis updated to ${diagnoses.join(', ')}`;
    }
    case EPatientHistoryType.CcmEnrollment: {
      return 'CCM Enrollment received';
    }
    case EPatientHistoryType.CcmPatientEnrolled: {
      return 'Patient successfully enrolled in the CCM program';
    }
    case EPatientHistoryType.CcmPatientUnenrolled: {
      return 'Patient unenrolled from the CCM program';
    }
    case EPatientHistoryType.CcmDiagnosesUpdate: {
      // @ts-ignore
      const changedTo = record.changedTo as IPatientChangedDiagnoses;
      const diagnoses: string[] = [];

      changedTo.diagnoses.forEach((item) => {
        diagnoses.push(`[${item.code}] ${item.diagnosis}`);
      });

      return `CCM Diagnosis updated to ${diagnoses.join(', ')}`;
    }
    case EPatientHistoryType.CcmObtainPatientConsent: {
      return 'Verbal consent obtained';
    }
    default: {
      return null;
    }
  }
};

export const patientHistoryRecords: ColumnsType<IPatientHistoryItem> = [
  {
    title: 'Date',
    dataIndex: 'createdAt',
    key: 'createdAt',
    sorter: true,
    render: (text) => moment(text).format(EDateFormat.ShortYear12hm),
    width: '200px',
  },
  {
    title: 'Initiator',
    dataIndex: 'initiator',
    key: 'initiator',
    render: (_, record) => {
      if (record.changedBy) {
        return record.changedBy.name;
      } else {
        return 'System';
      }
    },
  },
  {
    title: 'Events',
    dataIndex: 'events',
    key: 'events',
    render: (_, record) => getPatientHistoryDescription(record),
  },
];
