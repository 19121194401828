import axios from 'axios';
import { IEnrollmentModel, IEnrollmentModelQueryParams } from 'entities/Enrollment/Enrollment.models';

const basePath = 'ccm';

export const enrollmentTransport = {
  getEnrollmentModel: (enrollmentId: string): Promise<IEnrollmentModel> => axios.get(`${basePath}/enrollments/${enrollmentId}`),
  pingEnrollmentModel: (enrollmentId: string): Promise<void> => axios.patch(`${basePath}/enrollments/ping`, { enrollmentId }),
  changeEnrollmentModelStatus: (params: IEnrollmentModelQueryParams): Promise<IEnrollmentModel> =>
    axios.patch(`${basePath}/enrollments/status`, params),
};
