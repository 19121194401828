import React from 'react';
import { connect } from 'react-redux';
import { RootDispatch, RootState } from 'app/store';
import { CarePlan } from 'entities/CarePlan/components/CarePlan';

interface IComponentProps {
  patientId: string;
}

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch> & IComponentProps;

const PatientCarePlanComponent: React.FC<AllType> = (props) => {
  const { patientId, carePlanModel, setCarePlanModalParams, updateCarePlanModel } = props;

  const onCancelClick = () => {
    setCarePlanModalParams({ open: false, title: null, description: null });
  };

  const onSaveClick = (description: string) => {
    updateCarePlanModel({ patientId, description, onSuccess: onCancelClick });
  };

  const onAddClick = () => {
    setCarePlanModalParams({ open: true, title: 'Add Care Plan', onSaveClick, onCancelClick });
  };

  const onEditClick = () => {
    setCarePlanModalParams({
      open: true,
      title: 'Edit Plan',
      description: carePlanModel.data?.description,
      onSaveClick,
      onCancelClick,
    });
  };

  return <CarePlan description={carePlanModel.data?.description} onAddClick={onAddClick} onEditClick={onEditClick} />;
};

const mapState = (state: RootState) => ({
  carePlanModel: state.carePlanModel,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  setCarePlanModalParams: dispatch.modals.setCarePlanModalParams,
  updateCarePlanModel: dispatch.carePlanModel.updateCarePlanModel,
});

export const PatientCarePlan = connect(mapState, mapDispatch)(PatientCarePlanComponent);
