import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Button } from 'antd';
import { useDebounce } from 'common/hooks/useDebounce';
import { DEFAULT_DEBOUNCE_DELAY } from 'common/config';
import { RootDispatch, RootState } from 'app/store';
import { ReactComponent as EditIcon } from 'app/assets/images/svg/editIcon.svg';
import { IDiagnosesItem } from 'entities/Diagnoses/Diagnoses.models';
import { getDiagnosisName } from 'entities/Diagnoses/Diagnoses.helper';

interface IComponentProps {
  title: string;
  limit: number;
  onSave: (diagnoses: IDiagnosesItem[]) => void;
}

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch> & IComponentProps;

const DiagnosesComponent: React.FC<AllType> = (props) => {
  const {
    title,
    limit,
    onSave,
    diagnosesCollection: { data: diagnosesData, loading, error },
    ccdaDiagnosesCollection: { data: ccdaDiagnosesData },
    setDiagnosesCollectionError,
    getClinicalDiagnosesCollection,
    setClinicalDiagnosesCollection,
    setMultiselectDiagnosesModalParams,
  } = props;
  const [defaultCcdaDiagnosesValue, setDefaultCcdaDiagnosesValue] = useState<IDiagnosesItem[]>([]);
  const [defaultClinicalDiagnosesValue, setDefaultClinicalDiagnosesValue] = useState<IDiagnosesItem[]>([]);
  const [defaultIsCcda, setDefaultIsCcda] = useState<boolean>(true);
  const [ccdaIsDisabled, setCcdaIsDisabled] = useState<boolean>(false);
  const [clinicalDiagnosesSearchValue, setClinicalDiagnosesSearchValue] = useState<string>('');

  const debouncedSearchValue = useDebounce(clinicalDiagnosesSearchValue, DEFAULT_DEBOUNCE_DELAY);

  const onCancel = () => {
    setMultiselectDiagnosesModalParams({
      open: false,
      title: null,
      limit: null,
      defaultCcdaDiagnosesValue: null,
      defaultClinicalDiagnosesValue: null,
      defaultIsCcda: true,
      ccdaIsDisabled: false,
    });
  };

  const searchClinicalDiagnoses = (value: string) => {
    setClinicalDiagnosesSearchValue(value);
  };

  const handleChooseDiagnosis = () => {
    setDiagnosesCollectionError(false);
    setMultiselectDiagnosesModalParams({
      open: true,
      title,
      limit,
      defaultCcdaDiagnosesValue,
      defaultClinicalDiagnosesValue,
      defaultIsCcda,
      ccdaIsDisabled,
      searchClinicalDiagnoses,
      onSave,
      onCancel,
    });
  };

  const setDefaultDiagnosesParams = () => {
    if (!diagnosesData.length) {
      if (!ccdaDiagnosesData.length) {
        setDefaultIsCcda(false);
        setCcdaIsDisabled(true);
      }
    } else {
      if (!ccdaDiagnosesData.length) {
        setDefaultIsCcda(false);
        setCcdaIsDisabled(true);
        setDefaultClinicalDiagnosesValue(diagnosesData);
      } else {
        const diagnosesFromCcda: IDiagnosesItem[] = [];
        const diagnosesFromClinical: IDiagnosesItem[] = [];

        diagnosesData.forEach((item) => {
          if (ccdaDiagnosesData.some((diagnosis) => diagnosis.code === item.code)) {
            diagnosesFromCcda.push(item);
          } else {
            diagnosesFromClinical.push(item);
          }
        });

        if (!diagnosesFromCcda.length) {
          setDefaultIsCcda(false);
        } else {
          setDefaultIsCcda(true);
        }

        setDefaultCcdaDiagnosesValue(diagnosesFromCcda);
        setDefaultClinicalDiagnosesValue(diagnosesFromClinical);
        setCcdaIsDisabled(false);
      }
    }
  };

  useEffect(() => {
    setDefaultDiagnosesParams();
  }, [ccdaDiagnosesData, diagnosesData]);

  useEffect(() => {
    if (clinicalDiagnosesSearchValue.length) {
      getClinicalDiagnosesCollection(clinicalDiagnosesSearchValue);
    } else {
      setClinicalDiagnosesCollection([]);
    }
  }, [debouncedSearchValue]);

  if (loading) {
    return null;
  }

  return (
    <div>
      {diagnosesData?.length ? (
        <>
          <div className="patient-details__diagnosis">
            <div className="patient-details__item">Diagnosis</div>

            <Button className="btn-view-link patient-details__edit-btn" icon={<EditIcon />} onClick={handleChooseDiagnosis}>
              Update
            </Button>
          </div>
          <div>
            {diagnosesData.map((item, index) => {
              return <div key={index}>{getDiagnosisName(item)}</div>;
            })}
          </div>
        </>
      ) : (
        <Button
          className={`patient-details__choose-btn ${error && 'patient-details__choose-btn--error'}`}
          onClick={handleChooseDiagnosis}
        >
          Choose the diagnosis
        </Button>
      )}
    </div>
  );
};

const mapState = (state: RootState) => ({
  diagnosesCollection: state.diagnosesCollection,
  ccdaDiagnosesCollection: state.ccdaDiagnosesCollection,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  setDiagnosesCollectionError: dispatch.diagnosesCollection.setDiagnosesCollectionError,
  getClinicalDiagnosesCollection: dispatch.clinicalDiagnosesCollection.getClinicalDiagnosesCollection,
  setClinicalDiagnosesCollection: dispatch.clinicalDiagnosesCollection.setClinicalDiagnosesCollection,
  setMultiselectDiagnosesModalParams: dispatch.modals.setMultiselectDiagnosesModalParams,
});

export const Diagnoses = connect(mapState, mapDispatch)(DiagnosesComponent);
