import React from 'react';

interface IComponentProps {
  title: string;
  subtitle?: string;
  count?: number | string;
  highlighter?: string;
  isPrimary?: boolean;
}

export const StatisticsItem: React.FC<IComponentProps> = (props) => {
  const { title, subtitle, count, highlighter, isPrimary } = props;

  return (
    <div className={`statistics__item ${isPrimary && 'statistics__item_primary'}`}>
      <div>
        <p className="statistics__item_description">{title}</p>
        <p className={`statistics__item_description ${highlighter}`}>{subtitle}</p>
      </div>
      <div className="statistics__item_count">{count}</div>
    </div>
  );
};
