import { createModel } from '@rematch/core';
import { EHealthMetrics } from 'common/const/enums.const';
import { IRootModel } from 'app/store';
import { healthMetricsTransport } from 'entities/HealthMetrics/HealthMetrics.transport';
import { healthMetricsResponseToDataMapper } from 'entities/HealthMetrics/HealthMetrics.helper';
import { IHealthMetricsCollection } from 'entities/HealthMetrics/HealthMetrics.models';

export const healthMetricsCollection = createModel<IRootModel>()({
  state: {
    scores: {
      data: null as IHealthMetricsCollection | null,
      loading: false,
    },
    sleep: {
      data: null as IHealthMetricsCollection | null,
      loading: false,
    },
    steps: {
      data: null as IHealthMetricsCollection | null,
      loading: false,
    },
    heart: {
      data: null as IHealthMetricsCollection | null,
      loading: false,
    },
  },
  reducers: {
    setHealthMetricsScores: (state, payload: IHealthMetricsCollection) => ({
      ...state,
      scores: { ...state.scores, data: payload },
    }),
    setHealthMetricsScoresLoading: (state, payload: boolean) => ({
      ...state,
      scores: { ...state.scores, loading: payload },
    }),
    setHealthMetricsSleep: (state, payload: IHealthMetricsCollection) => ({
      ...state,
      sleep: { ...state.sleep, data: payload },
    }),
    setHealthMetricsSleepLoading: (state, payload: boolean) => ({
      ...state,
      sleep: { ...state.sleep, loading: payload },
    }),
    setHealthMetricsSteps: (state, payload: IHealthMetricsCollection) => ({
      ...state,
      steps: { ...state.steps, data: payload },
    }),
    setHealthMetricsStepsLoading: (state, payload: boolean) => ({
      ...state,
      steps: { ...state.steps, loading: payload },
    }),
    setHealthMetricsHeart: (state, payload: IHealthMetricsCollection) => ({
      ...state,
      heart: { ...state.heart, data: payload },
    }),
    setHealthMetricsHeartLoading: (state, payload: boolean) => ({
      ...state,
      heart: { ...state.heart, loading: payload },
    }),
  },
  effects: (dispatch) => ({
    getHealthMetrics(patientId: string) {
      dispatch.healthMetricsCollection.getHealthMetricsScores(patientId);
      dispatch.healthMetricsCollection.getHealthMetricsSleep(patientId);
      dispatch.healthMetricsCollection.getHealthMetricsSteps(patientId);
      dispatch.healthMetricsCollection.getHealthMetricsHeart(patientId);
    },
    async getHealthMetricsScores(patientId: string) {
      dispatch.healthMetricsCollection.setHealthMetricsScoresLoading(true);
      return healthMetricsTransport
        .getHealthMetricsScores(patientId)
        .then((response) => {
          const data = healthMetricsResponseToDataMapper(EHealthMetrics.Scores, response);
          dispatch.healthMetricsCollection.setHealthMetricsScores(data);
        })
        .finally(() => {
          dispatch.healthMetricsCollection.setHealthMetricsScoresLoading(false);
        });
    },
    async getHealthMetricsSleep(patientId: string) {
      dispatch.healthMetricsCollection.setHealthMetricsSleepLoading(true);
      return healthMetricsTransport
        .getHealthMetrics({ patientId, metric: EHealthMetrics.Sleep })
        .then((response) => {
          const data = healthMetricsResponseToDataMapper(EHealthMetrics.Sleep, response);
          dispatch.healthMetricsCollection.setHealthMetricsSleep(data);
        })
        .finally(() => {
          dispatch.healthMetricsCollection.setHealthMetricsSleepLoading(false);
        });
    },
    async getHealthMetricsSteps(patientId: string) {
      dispatch.healthMetricsCollection.setHealthMetricsStepsLoading(true);
      return healthMetricsTransport
        .getHealthMetrics({ patientId, metric: EHealthMetrics.Steps })
        .then((response) => {
          const data = healthMetricsResponseToDataMapper(EHealthMetrics.Steps, response);
          dispatch.healthMetricsCollection.setHealthMetricsSteps(data);
        })
        .finally(() => {
          dispatch.healthMetricsCollection.setHealthMetricsStepsLoading(false);
        });
    },
    async getHealthMetricsHeart(patientId: string) {
      dispatch.healthMetricsCollection.setHealthMetricsHeartLoading(true);
      return healthMetricsTransport
        .getHealthMetrics({ patientId })
        .then((response) => {
          const data = healthMetricsResponseToDataMapper(EHealthMetrics.Heart, response);
          dispatch.healthMetricsCollection.setHealthMetricsHeart(data);
        })
        .finally(() => {
          dispatch.healthMetricsCollection.setHealthMetricsHeartLoading(false);
        });
    },
  }),
});
