import axios from 'axios';
import {
  ITimingCollection,
  ITimingCollectionQueryParams,
  ITimingModel,
  ITimingModelCreateQueryParams,
  ITimingModelQueryParams,
} from 'entities/Timings/Timings.models';

const basePath = 'timings';

export const timingsTransport = {
  getTimingModel: (params: ITimingModelQueryParams): Promise<ITimingModel> => axios.get(`${basePath}`, { params }),
  createTimingModel: (params: ITimingModelCreateQueryParams): Promise<void> => axios.put(`${basePath}`, params),
  getTimingHistory: (params: ITimingCollectionQueryParams): Promise<ITimingCollection> =>
    axios.get(`${basePath}/history`, { params }),
};
