import React, { useEffect } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { EReviewHistoryActionTypes, EReviewHistoryActionTitles, EDateFormat } from 'common/const/enums.const';
import { convertToTimerFormat } from 'common/helpers/time.helper';
import { RootDispatch, RootState } from 'app/store';
import { IEventHistoryComment, IEventHistoryModel, IEventHistoryTime } from 'entities/ReviewHistory/ReviewHistory.models';
import { ReviewHistory } from 'entities/ReviewHistory/components/ReviewHistory';

interface IComponentProps {
  eventId: number;
}

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch> & IComponentProps;

const EventReviewHistoryComponent: React.FC<AllType> = (props) => {
  const { physiciansCollection, eventId, getEventReviewHistoryModel, eventReviewHistoryModel } = props;
  const { data: reviewHistoryData, loading } = eventReviewHistoryModel;

  const renderHistoryDescription = (item: IEventHistoryModel) => {
    switch (item.actionType) {
      case EReviewHistoryActionTypes.spent_time_record_is_added:
      case EReviewHistoryActionTypes.spent_time_record_is_edited:
      case EReviewHistoryActionTypes.spent_time_cancelled: {
        const data = item.data as IEventHistoryTime;
        const physician = data.userUid && physiciansCollection?.data?.find((item) => item.id === data.userUid)?.name;
        let title: string = EReviewHistoryActionTitles[item.actionType];

        if (physician && item.actionType === EReviewHistoryActionTypes.spent_time_record_is_added) {
          title = 'Time added';
        }

        return (
          <div className="history-card__item_description">
            {title} {physician && ` for ${physician}`}: {convertToTimerFormat(data.time)}
            {data.comment && <div className="history-card__item_comment">{data.comment}</div>}
          </div>
        );
      }
      case EReviewHistoryActionTypes.escalated: {
        return (
          <div className="history-card__item_description">
            <div>
              {/* @ts-ignore */}
              {item.user?.name === (item.data as IEventHistoryComment).assignee
                ? EReviewHistoryActionTitles.moved_back_to_physician
                : `${EReviewHistoryActionTitles[item.actionType]} ${(item.data as IEventHistoryComment).assignee}`}
            </div>
            {item.data && <div className="history-card__item_comment">{(item.data as IEventHistoryComment).comment}</div>}
          </div>
        );
      }
      case EReviewHistoryActionTypes.moved_back: {
        return (
          <div className="history-card__item_description">
            <div>
              {/* @ts-ignore */}
              {item.user?.name === item.event.physician
                ? EReviewHistoryActionTitles.moved_back_to_staff
                : EReviewHistoryActionTitles.moved_back}
            </div>
            {item.data && <div className="history-card__item_comment">{(item.data as IEventHistoryComment).comment}</div>}
          </div>
        );
      }
      case EReviewHistoryActionTypes.completed: {
        return (
          <div className="history-card__item_description">
            <div>{EReviewHistoryActionTitles[item.actionType]}</div>
            {item.data && <div className="history-card__item_comment">{(item.data as IEventHistoryComment).comment}</div>}
          </div>
        );
      }
      case EReviewHistoryActionTypes.comment_is_left: {
        return (
          <div className="history-card__item_description history-card__item_comment">
            {(item.data as IEventHistoryComment).comment}
          </div>
        );
      }
      case EReviewHistoryActionTypes.review: {
        return <div className="history-card__item_description">{EReviewHistoryActionTitles[item.actionType]}</div>;
      }
      default: {
        if (item.actionType === EReviewHistoryActionTypes.received) {
          return (
            <div className="history-card__item_description history-card__item_comment">
              {EReviewHistoryActionTitles[`event_${item.actionType}`]}
            </div>
          );
        } else {
          return (
            <div className="history-card__item_description history-card__item_comment">
              {EReviewHistoryActionTitles[item.actionType]}
            </div>
          );
        }
      }
    }
  };

  useEffect(() => {
    getEventReviewHistoryModel(eventId);
  }, []);

  if (!reviewHistoryData) {
    return null;
  }

  return (
    <ReviewHistory loading={loading}>
      {reviewHistoryData?.data.map((item) => {
        return (
          <div key={item.id} className="history-card__item">
            <div className="history-card__item_header">
              <span>{moment(item.createdAt).format(EDateFormat.FullYear12hm)}</span>
              {/* @ts-ignore */}
              {item.actionType !== EReviewHistoryActionTypes.received && <span>by: {item.user?.name}</span>}
            </div>

            {renderHistoryDescription(item)}
          </div>
        );
      })}
    </ReviewHistory>
  );
};

const mapState = (state: RootState) => ({
  eventReviewHistoryModel: state.eventReviewHistoryModel,
  physiciansCollection: state.physiciansCollection,
});

const mapDispatch = (dispatch: RootDispatch) => ({
  getEventReviewHistoryModel: dispatch.eventReviewHistoryModel.getEventReviewHistoryModel,
});

export const EventReviewHistory = connect(mapState, mapDispatch)(EventReviewHistoryComponent);
