import React from 'react';
import moment from 'moment';
import { EDateFormat } from 'common/const/enums.const';
import { getColor } from 'common/helpers/color.helper';
import { EAssessmentType } from 'common/const/assessment.const';
import { IEventDiary } from 'entities/Event/Event.models';

interface IComponentProps {
  assessmentType: string;
  diary: IEventDiary[];
  date: string;
  score: number;
}

export const EventDiary: React.FC<IComponentProps> = ({ assessmentType, diary, date, score }) => (
  <div className="event-diary">
    <div className="event-diary__header">
      <div className="subtitle">
        <span>Diary</span>
        <span className="event-diary__date">({moment(date).format(EDateFormat.FullYear)})</span>
      </div>

      <div className="subtitle event-diary__subtitle">
        <span>Overall Score:</span>
        <span className={`color__${getColor.byScore(assessmentType, score)}`}>{score}</span>
      </div>
    </div>

    <div>
      {diary.map((item, index) => {
        return (
          <div key={index} className="event-diary__item">
            <p className="event-diary__item_question">{item.question}</p>

            {assessmentType === EAssessmentType.grmcdu ? (
              <pre className={`color__${getColor.byScore(assessmentType, item.score)}`}>{item.answer}</pre>
            ) : (
              <div className={`color__${item.score && item.score > 0 ? 'red' : 'green'}`}>
                {/* eslint-disable-next-line */}
                {`${item.answer} ${item.score > 0 ? `(+${item.score})` : `${item.score}`}`}
              </div>
            )}
          </div>
        );
      })}
    </div>
  </div>
);
