import axios from 'axios';
import { IOperationCollection, IOperationsQueryParameters } from 'entities/Operations/Operations.models';

const basePath = 'operations';

export const operationsTransport = {
  getOperationsCollection: (params: IOperationsQueryParameters): Promise<IOperationCollection> =>
    axios.get(`${basePath}`, { params }),
  getOperationsReport: (params: IOperationsQueryParameters): Promise<File> => axios.get(`${basePath}/csv/download`, { params }),
};
