import { init, RematchRootState, RematchDispatch, Models } from '@rematch/core';
import { createBrowserHistory } from 'history';
import { createReduxHistoryContext } from 'redux-first-history';
import authModel from 'entities/Auth/Auth.communication';
import {
  eventsCollection,
  enrollmentsCollection,
  patientsCollection,
  dashboardInfoModel,
} from 'entities/Dashboard/Dashboard.communication';
import { statistics } from 'entities/Statistics/Statistics.communication';
import { actionModel } from 'entities/Actions/Actions.communication';
import { eventTimerModel, patientTimerModel } from 'entities/Timer/Timer.communication';
import { eventModel } from 'entities/Event/Event.communication';
import {
  eventReviewHistoryModel,
  enrollmentReviewHistoryModel,
  patientReviewHistoryModel,
} from 'entities/ReviewHistory/ReviewHistory.communication';
import { twilioModel } from 'entities/Twilio/Twilio.communication';
import {
  patientModel,
  patientCcdaModel,
  patientHistoryCollection,
  ccmPatientModel,
  ccmPatientConsentModel,
} from 'entities/Patient/Patient.communication';
import { billingCollection, ccmBillingCollection, ccmBillingCycleModel } from 'entities/Billing/Billing.communication';
import { operationsCollection } from 'entities/Operations/Operations.communication';
import { modals } from 'entities/Modals/Modals.communication';
import { timingModel, timingsHistory } from 'entities/Timings/Timings.communication';
import { enrollmentModel } from 'entities/Enrollment/Enrollment.communication';
import {
  diagnosesCollection,
  ccdaDiagnosesCollection,
  clinicalDiagnosesCollection,
} from 'entities/Diagnoses/Diagnoses.communication';
import { tasksCollection } from 'entities/Tasks/Tasks.communication';
import { healthMetricsCollection } from 'entities/HealthMetrics/HealthMetrics.communication';
import { promisCollection } from 'entities/Promis/Promis.communication';
import { assessmentHistoryCollection } from 'entities/AssessmentHistory/AssessmentHistory.communication';
import { carePlanModel } from 'entities/CarePlan/CarePlan.communication';
import { physiciansCollection } from 'entities/Physicians/Physicians.communication';

const { createReduxHistory, routerMiddleware, routerReducer } = createReduxHistoryContext({
  history: createBrowserHistory(),
});

const reducers = { router: routerReducer };

export interface IRootModel extends Models<IRootModel> {
  authModel: typeof authModel;
  eventsCollection: typeof eventsCollection;
  statistics: typeof statistics;
  actionModel: typeof actionModel;
  eventTimerModel: typeof eventTimerModel;
  eventModel: typeof eventModel;
  eventReviewHistoryModel: typeof eventReviewHistoryModel;
  twilioModel: typeof twilioModel;
  patientModel: typeof patientModel;
  patientCcdaModel: typeof patientCcdaModel;
  billingCollection: typeof billingCollection;
  operationsCollection: typeof operationsCollection;
  promisCollection: typeof promisCollection;
  assessmentHistoryCollection: typeof assessmentHistoryCollection;
  modals: typeof modals;
  timingModel: typeof timingModel;
  timingsHistory: typeof timingsHistory;
  patientHistoryCollection: typeof patientHistoryCollection;
  enrollmentsCollection: typeof enrollmentsCollection;
  patientsCollection: typeof patientsCollection;
  enrollmentModel: typeof enrollmentModel;
  ccmPatientModel: typeof ccmPatientModel;
  diagnosesCollection: typeof diagnosesCollection;
  ccdaDiagnosesCollection: typeof ccdaDiagnosesCollection;
  clinicalDiagnosesCollection: typeof clinicalDiagnosesCollection;
  ccmPatientConsentModel: typeof ccmPatientConsentModel;
  tasksCollection: typeof tasksCollection;
  enrollmentReviewHistoryModel: typeof enrollmentReviewHistoryModel;
  ccmBillingCollection: typeof ccmBillingCollection;
  healthMetricsCollection: typeof healthMetricsCollection;
  patientReviewHistoryModel: typeof patientReviewHistoryModel;
  patientTimerModel: typeof patientTimerModel;
  carePlanModel: typeof carePlanModel;
  dashboardInfoModel: typeof dashboardInfoModel;
  ccmBillingCycleModel: typeof ccmBillingCycleModel;
  physiciansCollection: typeof physiciansCollection;
}

const models = {
  authModel,
  eventsCollection,
  statistics,
  actionModel,
  eventTimerModel,
  eventModel,
  twilioModel,
  eventReviewHistoryModel,
  patientModel,
  patientCcdaModel,
  billingCollection,
  operationsCollection,
  promisCollection,
  assessmentHistoryCollection,
  modals,
  timingModel,
  timingsHistory,
  patientHistoryCollection,
  enrollmentsCollection,
  patientsCollection,
  enrollmentModel,
  ccmPatientModel,
  diagnosesCollection,
  ccdaDiagnosesCollection,
  clinicalDiagnosesCollection,
  ccmPatientConsentModel,
  tasksCollection,
  enrollmentReviewHistoryModel,
  ccmBillingCollection,
  healthMetricsCollection,
  patientReviewHistoryModel,
  patientTimerModel,
  carePlanModel,
  dashboardInfoModel,
  ccmBillingCycleModel,
  physiciansCollection,
} as IRootModel;

const store = init<IRootModel>({
  redux: {
    reducers,
    middlewares: [routerMiddleware],
    devtoolOptions: {},
  },
  models,
});

export default store;
export const history = createReduxHistory(store);
export type RootState = RematchRootState<IRootModel>;
export type RootDispatch = RematchDispatch<IRootModel>;
