import React from 'react';
import EventActionsCard from 'entities/Actions/components/EventActionsCard';
import { EventReviewHistory } from 'entities/ReviewHistory/components/EventReviewHistory';

interface IComponentProps {
  eventId: number;
  autoStartTimer: boolean;
  isBillingSource: boolean;
}

const EventPanel: React.FC<IComponentProps> = ({ eventId, autoStartTimer, isBillingSource }) => {
  return (
    <>
      <EventActionsCard eventId={eventId} autoStartTimer={autoStartTimer} isBillingSource={isBillingSource} />

      <EventReviewHistory eventId={eventId} />
    </>
  );
};

export default EventPanel;
