import React from 'react';
import { connect } from 'react-redux';
import { Button, Modal, Spin } from 'antd';
import { DEFAULT_MODAL_WIDTH } from 'common/config';
import { RootState } from 'app/store';

type AllType = ReturnType<typeof mapState>;

const CancelModalComponent: React.FC<AllType> = ({ cancelModalParams, loading }) => {
  const { open, title, description, onYesClick, onNoClick } = cancelModalParams;

  return (
    <Modal title={title} open={open} footer={false} onCancel={onNoClick} width={DEFAULT_MODAL_WIDTH}>
      <Spin spinning={loading}>
        <div className="modal__body">
          <p>{description}</p>
        </div>

        <div className="modal__footer">
          <Button onClick={onNoClick}>No</Button>

          <Button className="btn-primary" onClick={onYesClick}>
            Yes
          </Button>
        </div>
      </Spin>
    </Modal>
  );
};

const mapState = (state: RootState) => ({
  cancelModalParams: state.modals.cancelModalParams,
  loading: state.enrollmentModel.loading,
});

export const CancelModal = connect(mapState)(CancelModalComponent);
