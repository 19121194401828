import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { ECCMTasksStatuses, ETaskTypes } from 'common/const/enums.const';
import { RootDispatch, RootState } from 'app/store';
import { Tasks } from 'entities/Tasks/components/Tasks';
import { ITaskItem } from 'entities/Tasks/Tasks.models';
import { getEnrollmentAvailableOperations } from 'entities/Enrollment/Enrollment.helper';

interface IComponentProps {
  enrollmentId: string;
  enrollmentStatus: string;
}

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch> & IComponentProps;

const CCMTasksComponent: React.FC<AllType> = (props) => {
  const {
    enrollmentId,
    enrollmentStatus,
    tasksCollection,
    diagnosesCollection,
    ccmPatientConsentModel,
    getCcmTasksCollection,
    changeCcmTaskStatus,
    setDiagnosesCollectionError,
    setCcmPatientConsentModelError,
  } = props;
  const { data: tasksData, loading } = tasksCollection;
  const { data: diagnosesData } = diagnosesCollection;
  const { data: patientConsentData } = ccmPatientConsentModel;
  const { canChangeTaskStatus } = getEnrollmentAvailableOperations(enrollmentStatus);

  const onChange = (task: ITaskItem, status: string) => {
    if (task.type === ETaskTypes.ChooseDiagnoses) {
      if (status === ECCMTasksStatuses.Completed && (!diagnosesData || !diagnosesData?.length)) {
        setDiagnosesCollectionError(true);
        return;
      } else {
        setDiagnosesCollectionError(false);
      }
    }

    if (task.type === ETaskTypes.ObtainPatientConsent) {
      if (status === ECCMTasksStatuses.Completed && !patientConsentData) {
        setCcmPatientConsentModelError(true);
        return;
      } else {
        setCcmPatientConsentModelError(false);
      }
    }

    changeCcmTaskStatus({ taskId: task.taskId, status });
  };

  useEffect(() => {
    getCcmTasksCollection({ enrollmentId });
  }, []);

  if (!tasksData.length) {
    return null;
  }

  return <Tasks tasks={tasksData} loading={loading} onChange={onChange} disabled={!canChangeTaskStatus} />;
};

const mapState = (state: RootState) => ({
  tasksCollection: state.tasksCollection,
  diagnosesCollection: state.diagnosesCollection,
  ccmPatientConsentModel: state.ccmPatientConsentModel,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  getCcmTasksCollection: dispatch.tasksCollection.getCcmTasksCollection,
  changeCcmTaskStatus: dispatch.tasksCollection.changeCcmTaskStatus,
  setDiagnosesCollectionError: dispatch.diagnosesCollection.setDiagnosesCollectionError,
  setCcmPatientConsentModelError: dispatch.ccmPatientConsentModel.setCcmPatientConsentModelError,
});

export const CCMTasks = connect(mapState, mapDispatch)(CCMTasksComponent);
