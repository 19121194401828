import { ECCMBillingStatuses, EStatuses } from 'common/const/enums.const';

export const billingMenuItems = [
  {
    label: 'Billable & Unsent',
    key: Object.keys(EStatuses).indexOf(EStatuses.Completed),
  },
  {
    label: EStatuses.Pending,
    key: Object.keys(EStatuses).indexOf(EStatuses.Pending),
  },
  {
    label: EStatuses.Closed,
    key: Object.keys(EStatuses).indexOf(EStatuses.Closed),
  },
  {
    label: EStatuses.All,
    key: Object.keys(EStatuses).indexOf(EStatuses.All),
  },
];

export const ccmBillingMenuItems = [
  {
    label: 'Billable & Unsent',
    key: ECCMBillingStatuses.Completed,
  },
  {
    label: EStatuses.Pending,
    key: ECCMBillingStatuses.Active,
  },
  {
    label: EStatuses.Closed,
    key: ECCMBillingStatuses.Closed,
  },
  {
    label: EStatuses.All,
    key: ECCMBillingStatuses.All,
  },
];
