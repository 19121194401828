import { createModel } from '@rematch/core';
import { IRootModel } from 'app/store';
import { filterTimingItems, mapMonthlyItems, mapOneTimeItems, updateTimingModel } from 'entities/Timings/Timings.helper';
import {
  ITimingCollection,
  ITimingCollectionQueryParams,
  ITimingModelCreateQueryParams,
  ITimingModelQueryParams,
  ITimingModelState,
} from 'entities/Timings/Timings.models';
import { timingsTransport } from 'entities/Timings/Timings.transport';

export const timingModel = createModel<IRootModel>()({
  state: {
    loading: false,
    createdAt: '',
    monthlyItems: [],
    oneTimeItems: [],
  } as ITimingModelState,
  reducers: {
    setTimingModelLoading: updateTimingModel.setTimingModelLoading,
    setTimingModelCreatedAt: updateTimingModel.setTimingModelCreatedAt,
    setMonthlyItems: updateTimingModel.setMonthlyItems,
    setOneTimeItems: updateTimingModel.setOneTimeItems,
    addOneTimeItem: updateTimingModel.addOneTimeItem,
    changeOneTimeItemCode: updateTimingModel.changeOneTimeItemCode,
    changeOneTimeItemFee: updateTimingModel.changeOneTimeItemFee,
    deleteOneTimeItem: updateTimingModel.deleteOneTimeItem,
  },
  effects: (dispatch) => ({
    async getTimingModel(params: ITimingModelQueryParams) {
      dispatch.timingModel.setTimingModelLoading(true);
      timingsTransport
        .getTimingModel(params)
        .then((response) => {
          const { monthlyItems, oneTimeItems } = filterTimingItems(response.items);
          const mappedMonthlyItems = mapMonthlyItems(monthlyItems);
          const mappedOneTimeItems = mapOneTimeItems(oneTimeItems);
          dispatch.timingModel.setMonthlyItems(mappedMonthlyItems);
          dispatch.timingModel.setOneTimeItems(mappedOneTimeItems);
          dispatch.timingModel.setTimingModelCreatedAt(response.createdAt);
        })
        .finally(() => {
          dispatch.timingModel.setTimingModelLoading(false);
        });
    },
    async createTimingModel(params: ITimingModelCreateQueryParams) {
      dispatch.timingModel.setTimingModelLoading(true);
      return timingsTransport.createTimingModel(params).finally(() => {
        dispatch.timingModel.setTimingModelLoading(false);
      });
    },
  }),
});

export const timingsHistory = createModel<IRootModel>()({
  state: {
    loading: false,
    data: null as ITimingCollection | null,
  },
  reducers: {
    setTimingsLoading: (state, payload: boolean) => ({ ...state, loading: payload }),
    setTimingsHistory: (state, payload: ITimingCollection) => ({ ...state, data: payload }),
  },
  effects: (dispatch) => ({
    async getTimingHistory(params: ITimingCollectionQueryParams) {
      dispatch.timingsHistory.setTimingsLoading(true);
      timingsTransport
        .getTimingHistory(params)
        .then((response) => {
          dispatch.timingsHistory.setTimingsHistory(response);
        })
        .finally(() => {
          dispatch.timingsHistory.setTimingsLoading(false);
        });
    },
  }),
});
