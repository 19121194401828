import React, { useEffect, useState } from 'react';
import { Button, Spin } from 'antd';
import { PlayCircleOutlined, PauseCircleOutlined, EditOutlined } from '@ant-design/icons';
import { useStopwatch } from 'react-timer-hook';
import { padTo2Digits } from 'common/helpers/time.helper';
import { getStopwatchOffset } from 'entities/Timer/Timer.helper';
import { ITimerState } from 'entities/Timer/Timer.models';

interface IComponentProps {
  autoStartTimer: boolean;
  canStart: boolean;
  timerModel: ITimerState;
  spentTimeForLastDays: string;
  billingPeriod: string | null;
  getTimerModel: () => Promise<number | undefined>;
  setTimerModelUntrackedTime: (value: number) => void;
  onStartClick: () => void;
  onStopClick: () => void;
  onEditClick: (
    hours: number,
    minutes: number,
    seconds: number,
    reset: (offsetTimestamp?: Date | undefined, autoStart?: boolean | undefined) => void,
    timeResultId?: string | number
  ) => void;
}

export const Timer: React.FC<IComponentProps> = (props) => {
  const {
    autoStartTimer,
    canStart,
    timerModel,
    spentTimeForLastDays,
    billingPeriod,
    getTimerModel,
    setTimerModelUntrackedTime,
    onStartClick,
    onStopClick,
    onEditClick,
  } = props;
  const { data, isRunning, loading } = timerModel;
  const [startTimeValue, setStartTimeValue] = useState<number>(0);
  const { days, hours, minutes, seconds, start, pause, reset } = useStopwatch({ autoStart: autoStartTimer });
  const currentHours = hours + days * 24;

  useEffect(() => {
    getTimerModel().then((response) => {
      reset(getStopwatchOffset(response || 0), autoStartTimer);

      autoStartTimer && canStart && onStartClick();
    });
  }, []);

  useEffect(() => {
    if (isRunning) {
      setStartTimeValue((currentHours * 3600 + minutes * 60 + seconds) * 1000);
      start();
    } else {
      pause();
    }
  }, [isRunning]);

  useEffect(() => {
    setTimerModelUntrackedTime((currentHours * 3600 + minutes * 60 + seconds) * 1000 - startTimeValue);
  }, [seconds]);

  return (
    <Spin spinning={!data || loading}>
      <div className="timer">
        <p className="title timer__title">
          <span>Your Timer:</span>
          <span className="timer__count">
            {`${padTo2Digits(currentHours)}:${padTo2Digits(minutes)}:${padTo2Digits(seconds)}`}
          </span>
        </p>

        <div className="timer__btns-wrapper">
          {canStart ? (
            isRunning ? (
              <Button className="btn-orange" onClick={onStopClick} icon={<PauseCircleOutlined />}>
                Stop
              </Button>
            ) : (
              <Button className="btn-primary" onClick={onStartClick} icon={<PlayCircleOutlined />}>
                Start
              </Button>
            )
          ) : null}
          <Button
            onClick={() => onEditClick(currentHours, minutes, seconds, reset, data?.time?.id)}
            icon={<EditOutlined />}
            disabled={isRunning}
          >
            Edit
          </Button>
        </div>

        <p className="timer__spent-time">{spentTimeForLastDays}</p>

        <p className="timer__week">{billingPeriod}</p>
      </div>
    </Spin>
  );
};
