import React from 'react';
import { connect } from 'react-redux';
import { RootDispatch, RootState } from 'app/store';
import { Timer } from 'entities/Timer/components/Timer';
import { getBillingCyclePeriod, getBillingCycleSpentTime, getStopwatchOffset } from 'entities/Timer/Timer.helper';

interface IComponentProps {
  eventId: number;
  autoStartTimer: boolean;
  canStart: boolean;
  canEdit: boolean;
  billingCycleStartDate?: string;
  billingCycleEndDate?: string;
}

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch> & IComponentProps;

const EventTimerComponent: React.FC<AllType> = (props) => {
  const {
    eventId,
    autoStartTimer,
    canStart,
    canEdit,
    billingCycleStartDate,
    billingCycleEndDate,
    eventTimerModel,
    getEventTimerModel,
    setEventTimerModelUntrackedTime,
    startEventTimer,
    stopEventTimer,
    setExportedEventModalParams,
    setTimeEditorModalParams,
    updateEventTimerModel,
  } = props;
  const { data } = eventTimerModel;

  const getTimerModel = () => {
    return getEventTimerModel(eventId);
  };

  const onStartClick = () => {
    startEventTimer(eventId);
  };

  const onStopClick = () => {
    stopEventTimer(eventId);
  };

  const onCancelEditClick = () => {
    setTimeEditorModalParams({ open: false, eventId: null, hours: null, minutes: null, seconds: null });
  };

  const onSaveEditClick = (
    milliseconds: number,
    reset: (offsetTimestamp?: Date | undefined, autoStart?: boolean | undefined) => void
  ) => {
    updateEventTimerModel({ eventId, time: milliseconds }).then(() => {
      reset(getStopwatchOffset(milliseconds), false);
      onCancelEditClick();
    });
  };

  const onEditClick = (
    hours: number,
    minutes: number,
    seconds: number,
    reset: (offsetTimestamp?: Date | undefined, autoStart?: boolean | undefined) => void
  ) => {
    if (!canEdit) {
      setExportedEventModalParams({ open: true });
    } else {
      setTimeEditorModalParams({
        open: true,
        eventId,
        hours,
        minutes,
        seconds,
        reset,
        onSaveEditClick,
        onCancelEditClick,
      });
    }
  };

  return (
    <Timer
      autoStartTimer={autoStartTimer}
      canStart={canStart}
      timerModel={eventTimerModel}
      spentTimeForLastDays={getBillingCycleSpentTime(billingCycleStartDate, data?.commonResults)}
      billingPeriod={getBillingCyclePeriod(billingCycleStartDate, billingCycleEndDate)}
      getTimerModel={getTimerModel}
      setTimerModelUntrackedTime={setEventTimerModelUntrackedTime}
      onStartClick={onStartClick}
      onStopClick={onStopClick}
      onEditClick={onEditClick}
    />
  );
};

const mapState = (state: RootState) => ({
  eventTimerModel: state.eventTimerModel,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  getEventTimerModel: dispatch.eventTimerModel.getEventTimerModel,
  setEventTimerModelUntrackedTime: dispatch.eventTimerModel.setEventTimerModelUntrackedTime,
  startEventTimer: dispatch.eventTimerModel.startEventTimer,
  stopEventTimer: dispatch.eventTimerModel.stopEventTimer,
  setExportedEventModalParams: dispatch.modals.setExportedEventModalParams,
  setTimeEditorModalParams: dispatch.modals.setTimeEditorModalParams,
  updateEventTimerModel: dispatch.eventTimerModel.updateEventTimerModel,
});

export const EventTimer = connect(mapState, mapDispatch)(EventTimerComponent);
