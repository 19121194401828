import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Button, Pagination, Space, Table } from 'antd';
import { useNavigate } from 'react-router-dom';
import { DEFAULT_PAGE_ID, DEFAULT_PAGE_SIZE } from 'common/config';
import { sort } from 'common/helpers/sorter.helper';
import { SearchInput } from 'common/components/SearchInput';
import { EEnrollmentsStatuses, ESearchParams } from 'common/const/enums.const';
import { DatePicker } from 'common/components/DatePicker';
import { Menu } from 'common/components/Menu';
import { useSocket } from 'common/hooks/useSocket';
import { useSearchParams } from 'common/hooks/useSearchParams';
import { RootDispatch, RootState, history } from 'app/store';
import { getEnrollmentsSearchParams, renderEnrollmentsRecords, toDataSourseMapper } from 'entities/Dashboard/Dashboard.helper';
import { enrollmentsQueryParamsTo } from 'entities/Dashboard/Dashboard.mappers';
import { enrollmentsMenuItems } from 'entities/Dashboard/Dashboard.const';
import { getEnrollmentAvailableOperations } from 'entities/Enrollment/Enrollment.helper';

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

const EnrollmentsBodyComponent: React.FC<AllType> = (props) => {
  const {
    enrollmentsCollection,
    enrollmentModelLoading,
    getEnrollmentsCollection,
    changeEnrollmentModelStatus,
    getEnrollmentModel,
  } = props;
  const { searchParams, setSearchParam, removeSearchParam, setSearchParamWithCondition } = useSearchParams();
  const [pageId, setPageId] = useState<number>(DEFAULT_PAGE_ID);
  const navigate = useNavigate();
  const { data: enrollmentsData, loading } = enrollmentsCollection;

  const params = getEnrollmentsSearchParams(searchParams);
  const dataSource = toDataSourseMapper(enrollmentsData?.data);
  const columns = renderEnrollmentsRecords();
  const paramsRef = React.useRef(enrollmentsQueryParamsTo(params, pageId));

  const { status, search, dateFrom, dateTo, orderField, orderDirection } = params;

  useSocket('enrollments', () => {
    getEnrollmentsCollection(paramsRef.current);
  });

  const searchValueChange = (value: string) => {
    setSearchParamWithCondition(ESearchParams.Search, value);
    setPageId(DEFAULT_PAGE_ID);
  };

  const dateRangeValueChange = (values: { [key: string]: string | undefined }[]) => {
    values.forEach((item) => {
      const [key, value] = Object.entries(item).flat() as [string, string | undefined];
      setSearchParamWithCondition(key, value);
    });
    setPageId(DEFAULT_PAGE_ID);
  };

  const menuTabChange = (value: string) => {
    removeSearchParam(ESearchParams.OrderField);
    removeSearchParam(ESearchParams.OrderDirection);
    setSearchParam(ESearchParams.Status, value);
    setPageId(DEFAULT_PAGE_ID);
  };

  const handleSort = (pagination: any, filters: any, sorter: any) => {
    sort(sorter, setSearchParam, removeSearchParam);
  };

  const onReviewClick = (enrollmentId: string) => {
    history.push(`${history.location.pathname}${history.location.search}`);
    getEnrollmentModel(enrollmentId).then((enrollmentModel) => {
      if (enrollmentModel) {
        const enrollmentStatus = enrollmentModel.status;

        const { canReview } = getEnrollmentAvailableOperations(enrollmentStatus);

        if (canReview) {
          changeEnrollmentModelStatus({ enrollmentId, status: EEnrollmentsStatuses.UnderReview }).then(() => {
            navigate(`/enrollment/${enrollmentId}`, { replace: true });
          });
        } else {
          navigate(`/enrollment/${enrollmentId}`, { replace: true });
        }
      }
    });
  };

  columns.push({
    key: 'action',
    render: (_, record) => {
      const enrollmentStatus = record.status;

      const { canReview } = getEnrollmentAvailableOperations(enrollmentStatus);

      return (
        <Space size="middle">
          <Button className="btn-link" onClick={() => onReviewClick(record.id)}>
            {canReview ? 'Review' : 'View'}
          </Button>
        </Space>
      );
    },
  });

  useEffect(() => {
    const queryParams = enrollmentsQueryParamsTo(params, pageId);

    getEnrollmentsCollection(queryParams);
  }, [status, search, dateFrom, dateTo, orderField, orderDirection, pageId]);

  useEffect(() => {
    paramsRef.current = enrollmentsQueryParamsTo(params, pageId);
  });

  return (
    <>
      <div className="filter">
        <div className="filter__row">
          <SearchInput value={search} onChange={searchValueChange} placeholder="Search by patient / physician / MRN" />

          <DatePicker dateFrom={dateFrom} dateTo={dateTo} onChange={dateRangeValueChange} />
        </div>

        <div className="filter__row">
          <Menu items={enrollmentsMenuItems} selectedKeys={[status.toString()]} onChange={menuTabChange} />
        </div>
      </div>

      <Table
        columns={columns}
        dataSource={dataSource}
        pagination={false}
        size="small"
        loading={loading || enrollmentModelLoading}
        onChange={handleSort}
      />

      <Pagination
        current={Number(pageId)}
        total={enrollmentsData?.meta.count}
        showSizeChanger={false}
        onChange={(pageId: number) => setPageId(pageId)}
        defaultPageSize={DEFAULT_PAGE_SIZE}
      />
    </>
  );
};

const mapState = (state: RootState) => ({
  enrollmentsCollection: state.enrollmentsCollection,
  enrollmentModelLoading: state.enrollmentModel.loading,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  getEnrollmentsCollection: dispatch.enrollmentsCollection.getEnrollmentsCollection,
  changeEnrollmentModelStatus: dispatch.enrollmentModel.changeEnrollmentModelStatus,
  getEnrollmentModel: dispatch.enrollmentModel.getEnrollmentModel,
});

export const EnrollmentsBody = connect(mapState, mapDispatch)(EnrollmentsBodyComponent);
