import axios from 'axios';
import {
  ICCMCommentModel,
  ICCMCommentPayload,
  IEnrollmentHistoryCollection,
  IEventHistoryCollection,
  IPatientHistoryCollection,
  IPatientHistoryItem,
  IPatientHistoryUpdatePayload,
} from 'entities/ReviewHistory/ReviewHistory.models';

const basePath = 'events';
const ccmPath = 'ccm/enrollments/history';
const patientPath = 'ccm/billing-cycles/history';

export const reviewHistoryTransport = {
  getEventReviewHistoryModel: (eventId: number): Promise<IEventHistoryCollection> =>
    axios.get(`${basePath}/${eventId}/histories`),
  postEventCallHistory: (eventId: number): Promise<void> => axios.post(`${basePath}/${eventId}/call`),
  getEnrollmentReviewHistoryModel: (enrollmentId: string): Promise<IEnrollmentHistoryCollection> =>
    axios.get(`${ccmPath}/${enrollmentId}`),
  addEnrollmentCommentHistory: ({ id, ...restPayload }: ICCMCommentPayload): Promise<ICCMCommentModel> =>
    axios.post(`${ccmPath}/comment/${id}`, restPayload),
  addEnrollmentCallHistory: (enrollmentId: string): Promise<void> => axios.post(`${ccmPath}/call/${enrollmentId}`),
  addPatientCallHistory: (billingCycleId: string): Promise<void> => axios.post(`${patientPath}/call/${billingCycleId}`),
  addPatientCommentHistory: ({ id, ...restPayload }: ICCMCommentPayload): Promise<ICCMCommentModel> =>
    axios.post(`${patientPath}/comment/${id}`, restPayload),
  getPatientReviewHistoryModel: (billingCycleId: string): Promise<IPatientHistoryCollection> =>
    axios.get(`${patientPath}/${billingCycleId}`),
  updatePatientCommentHistory: (payload: IPatientHistoryUpdatePayload): Promise<IPatientHistoryItem> =>
    axios.patch(`${patientPath}`, payload),
};
