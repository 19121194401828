import axios from 'axios';
import { ICcmBillingCycleModel } from 'entities/Billing/Billing.models';
import { ICcmAssessment } from 'entities/Assessment/Assessment.models';

const ccmPath = 'ccm';

export const assessmentTransport = {
  updateAssessmentModel: (billingCycleId: string, payload: ICcmAssessment): Promise<ICcmBillingCycleModel> =>
    axios.patch(`${ccmPath}/billing-cycles/assessment/${billingCycleId}`, payload),
};
