import React, { useEffect } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { Button } from 'antd';
import { EReviewHistoryActionTypes, EReviewHistoryActionTitles, EDateFormat } from 'common/const/enums.const';
import { convertToTimerFormat } from 'common/helpers/time.helper';
import { RootDispatch, RootState } from 'app/store';
import { ReactComponent as EditIcon } from 'app/assets/images/svg/editIcon.svg';
import { IPatientHistoryItem } from 'entities/ReviewHistory/ReviewHistory.models';
import { noCommentProvidedMessage } from 'entities/ReviewHistory/ReviewHistory.const';
import { ReviewHistory } from 'entities/ReviewHistory/components/ReviewHistory';

interface IComponentProps {
  billingCycleId: string;
}

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch> & IComponentProps;

const PatientReviewHistoryComponent: React.FC<AllType> = (props) => {
  const {
    billingCycleId,
    patientReviewHistoryModel,
    getPatientReviewHistoryModel,
    updatePatientCommentHistory,
    setCommentModalParams,
  } = props;
  const { data: reviewHistoryData, loading } = patientReviewHistoryModel;

  const handleCommentModalSend = (billingCycleHistoryId: string, comment: string) => {
    updatePatientCommentHistory({ billingCycleId, billingCycleHistoryId, comment }).then(() => {
      setCommentModalParams({ open: false, externalId: null });
    });
  };

  const handleCommentModalCancel = () => {
    setCommentModalParams({ open: false, externalId: null });
  };

  const onEditClick = (item: IPatientHistoryItem) => {
    setCommentModalParams({
      open: true,
      externalId: item.id,
      defaultValue: item.comment,
      handleSend: handleCommentModalSend,
      handleCancel: handleCommentModalCancel,
    });
  };

  const renderHistoryDescription = (item: IPatientHistoryItem) => {
    if (item.type === EReviewHistoryActionTypes.comment_is_left) {
      return <div className="history-card__item_description history-card__item_comment">{item.comment}</div>;
    }

    if (
      item.type === EReviewHistoryActionTypes.spent_time_record_is_added ||
      item.type === EReviewHistoryActionTypes.spent_time_record_is_edited ||
      item.type === EReviewHistoryActionTypes.spent_time_cancelled
    ) {
      return (
        <div className="history-card__item_description">
          <div>
            {EReviewHistoryActionTitles[item.type]}: {convertToTimerFormat(item.time)}
          </div>
          {item.comment && (
            <span
              className="history-card__item_comment"
              style={{ color: item.comment === noCommentProvidedMessage ? '#f24d4d' : '#6c7682' }}
            >
              {item.comment}
            </span>
          )}
          <Button className="btn-link history-card__item_btn-edit" icon={<EditIcon />} onClick={() => onEditClick(item)}>
            Edit
          </Button>
        </div>
      );
    }

    return (
      <div className="history-card__item_description">
        <div>{EReviewHistoryActionTitles[item.type]}</div>
        {item.comment && <div className="history-card__item_comment">{item.comment}</div>}
      </div>
    );
  };

  useEffect(() => {
    getPatientReviewHistoryModel(billingCycleId);
  }, []);

  if (!reviewHistoryData) {
    return null;
  }

  return (
    <ReviewHistory loading={loading}>
      {reviewHistoryData?.data.map((item) => {
        return (
          <div key={item.id} className="history-card__item">
            <div className="history-card__item_header">
              <span>{moment(item.createdAt).format(EDateFormat.FullYear12hm)}</span>
              <span>by: {item.user?.name}</span>
            </div>

            {renderHistoryDescription(item)}
          </div>
        );
      })}
    </ReviewHistory>
  );
};

const mapState = (state: RootState) => ({
  patientReviewHistoryModel: state.patientReviewHistoryModel,
});

const mapDispatch = (dispatch: RootDispatch) => ({
  getPatientReviewHistoryModel: dispatch.patientReviewHistoryModel.getPatientReviewHistoryModel,
  updatePatientCommentHistory: dispatch.patientReviewHistoryModel.updatePatientCommentHistory,
  setCommentModalParams: dispatch.modals.setCommentModalParams,
});

export const PatientReviewHistory = connect(mapState, mapDispatch)(PatientReviewHistoryComponent);
