import React from 'react';
import { ColumnsType } from 'antd/lib/table';
import { Button, Space } from 'antd';
import moment from 'moment';
import { EDateFormat } from 'common/const/enums.const';
import { assessmentNames } from 'common/const/assessment.const';
import { IAssessmentHistoryModel } from 'entities/AssessmentHistory/AssessmentHistory.models';

export const assessmentHistoryColumns: ColumnsType<IAssessmentHistoryModel> = [
  {
    title: 'Date',
    dataIndex: 'assessmentDate',
    key: 'assessmentDate',
    render: (text) => moment(text).format(EDateFormat.ShortYear),
  },
  {
    title: 'Type',
    dataIndex: 'assessmentTypeId',
    key: 'assessmentTypeId',
    render: (text) => assessmentNames[Number(text)],
  },
  {
    title: 'Actions',
    dataIndex: 'reportUrl',
    key: 'reportUrl',
    render: (text) => (
      <Space size="middle">
        <Button className="btn-link" onClick={() => window.open(text, '_blank')}>
          View
        </Button>
      </Space>
    ),
  },
];
