import axios from 'axios';
import {
  IDiagnosesCollectionPayload,
  IEventDiagnosesCollection,
  ICcmDiagnosesCollection,
} from 'entities/Diagnoses/Diagnoses.models';

const basePath = 'patient-diagnoses';
const ccmPath = 'ccm';

export const diagnosesTransport = {
  addEventDiagnosesCollection: (params: IDiagnosesCollectionPayload): Promise<IEventDiagnosesCollection> =>
    axios.post(`${basePath}`, params),
  addCcmDiagnosesCollection: (payload: IDiagnosesCollectionPayload): Promise<ICcmDiagnosesCollection> =>
    axios.patch(`${ccmPath}/diagnoses`, payload),
  getClinicalDiagnosesCollection: (value: string): Promise<(number | null | string[] | string[][])[]> =>
    axios.get(`https://clinicaltables.nlm.nih.gov/api/icd10cm/v3/search?sf=code,name&terms=${value}`),
};
