import React from 'react';
import type { MenuProps } from 'antd';
import { Menu, Dropdown, Space } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { EDashboardTabs, ENavOptions, ERoutes } from 'common/const/enums.const';
import { RootDispatch, RootState, history } from 'app/store';
import { getDashboardPathname } from 'entities/Dashboard/Dashboard.helper';
import { noCommentProvidedMessage } from 'entities/ReviewHistory/ReviewHistory.const';

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

const Header: React.FC<AllType> = (props) => {
  const {
    authorized,
    userName,
    eventId,
    billingCycleId,
    eventIsRunning,
    patientIsRunning,
    deleteAuthModel,
    stopEventTimer,
    stopPatientTimer,
  } = props;
  const location = useLocation();
  const autoStartTimer = new URLSearchParams(window.location.search).get('autoStartTimer') === 'true';

  const logout = () => {
    if (autoStartTimer && eventIsRunning) {
      eventId &&
        stopEventTimer(eventId).then(() => {
          history.push(ERoutes.Login);
          deleteAuthModel();
        });
      return;
    }

    if (autoStartTimer && patientIsRunning) {
      billingCycleId &&
        stopPatientTimer({ billingCycleId, comment: noCommentProvidedMessage }).then(() => {
          deleteAuthModel();
        });
      return;
    }

    history.push(getDashboardPathname(EDashboardTabs.Events));
    deleteAuthModel();
  };

  const navItems: MenuProps['items'] = [
    {
      label: <Link to={getDashboardPathname(EDashboardTabs.Events)}>{ENavOptions.Dashboard}</Link>,
      key: '/dashboard',
    },
    {
      label: ENavOptions.Billing,
      key: '/billing',
      children: [
        {
          label: <Link to={ERoutes.EMBilling}>{ENavOptions.EMBilling}</Link>,
          key: ERoutes.EMBilling,
        },
        {
          label: <Link to={ERoutes.CCMBilling}>{ENavOptions.CCMBilling}</Link>,
          key: ERoutes.CCMBilling,
        },
      ],
      popupClassName: 'header__submenu',
    },
    {
      label: <Link to={ERoutes.Operations}>{ENavOptions.Operations}</Link>,
      key: ERoutes.Operations,
    },
  ];

  const menu = (
    <Menu
      items={[
        {
          label: 'E&M CPT Codes',
          key: 'emCptCodes',
          onClick: () => history.push('/timings/enm'),
        },
        {
          label: 'CCM CPT Codes',
          key: 'ccmCptCodes',
          onClick: () => history.push('/timings/ccm'),
        },
        {
          label: (
            <div className="header__menu-item">
              Log out
              <div className="header__logout-icon" />
            </div>
          ),
          key: 'logout',
          onClick: logout,
        },
      ]}
    />
  );

  return (
    <div className="header">
      <div className="header__icon"></div>
      {authorized ? (
        <div className="header__nav">
          <Menu selectedKeys={[`/${location.pathname.split('/')[1]}`]} mode="horizontal" items={navItems} />
          <Dropdown overlay={menu} trigger={['click']}>
            <a onClick={(e) => e.preventDefault()}>
              <Space>
                {userName}
                <DownOutlined />
              </Space>
            </a>
          </Dropdown>
        </div>
      ) : (
        <div className="header__login-icon">Login</div>
      )}
    </div>
  );
};

const mapState = (state: RootState) => ({
  authorized: state.authModel.data?.access?.token,
  userName: state.authModel.data?.access?.id,
  eventId: state.eventModel?.eventId,
  billingCycleId: state.ccmPatientModel.billingCycleId,
  eventIsRunning: state.eventTimerModel.isRunning,
  patientIsRunning: state.patientTimerModel.isRunning,
});

const mapDispatch = (dispatch: RootDispatch) => ({
  deleteAuthModel: dispatch.authModel.deleteAuthModel,
  stopEventTimer: dispatch.eventTimerModel.stopEventTimer,
  stopPatientTimer: dispatch.patientTimerModel.stopPatientTimer,
});

export default connect(mapState, mapDispatch)(Header);
