import React from 'react';
import { connect } from 'react-redux';
import { Spin } from 'antd';
import { RootState } from 'app/store';
import { StatisticsItem } from 'entities/Statistics/components/StatisticsItem';

type AllType = ReturnType<typeof mapState>;

const BillingSubheaderComponent: React.FC<AllType> = ({ statistics, statisticsLoading }) => {
  return (
    <Spin spinning={!statistics || statisticsLoading}>
      <div className="statistics__container">
        <StatisticsItem title="Total number" subtitle="of patients" count={statistics?.patientsTotal} />
        <StatisticsItem
          title="Total billable events"
          subtitle="Last 30 days"
          count={statistics?.eventsTotal}
          highlighter="gray"
        />
        <StatisticsItem
          title="Total billable hours"
          subtitle="Last 30 days"
          count={statistics?.hoursTotal && Math.round(statistics?.hoursTotal)}
          highlighter="gray"
        />
      </div>
    </Spin>
  );
};

const mapState = (state: RootState) => ({
  statistics: state.statistics.billingCyclesStatisticsData,
  statisticsLoading: state.statistics.loading,
});

export const BillingSubheader = connect(mapState)(BillingSubheaderComponent);
