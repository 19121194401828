import { EAssessmentType } from 'common/const/assessment.const';
import { ECCMBillingStatuses, EEnrollmentsStatuses, EPromisSeverity, EStatuses } from 'common/const/enums.const';
import { EEventSeverity } from 'entities/Dashboard/Dashboard.const';

export const getStatusColor = (status: string) => {
  switch (true) {
    case status === EStatuses.Pending:
    case status === ECCMBillingStatuses.Active:
    case status === EEnrollmentsStatuses.Cancelled: {
      return 'red';
    }
    case status === EStatuses['Under review']:
    case status === EEnrollmentsStatuses.UnderReview: {
      return 'orange';
    }
    case status === EStatuses.Completed:
    case status === EEnrollmentsStatuses.Completed: {
      return 'green';
    }
    default: {
      return 'gray';
    }
  }
};

export const getPromisColor = (severity: string) => {
  switch (true) {
    case severity === EPromisSeverity.LowPain:
    case severity === EPromisSeverity.LowImpact:
    case severity === EPromisSeverity.Normal: {
      return 'green';
    }
    case severity === EPromisSeverity.ModeratePain:
    case severity === EPromisSeverity.ModerateImpact:
    case severity === EPromisSeverity.Mild: {
      return 'yellow';
    }
    case severity === EPromisSeverity.Moderate: {
      return 'orange';
    }
    case severity === EPromisSeverity.HighPain:
    case severity === EPromisSeverity.HighImpact:
    case severity === EPromisSeverity.Severe: {
      return 'red';
    }
    default: {
      return '';
    }
  }
};

export const getColor = {
  bySeverity: (severity: EEventSeverity) => {
    switch (severity) {
      case EEventSeverity.Severe: {
        return 'red';
      }
      case EEventSeverity.Moderate: {
        return 'orange';
      }
      case EEventSeverity.Mild: {
        return 'yellow';
      }
      case EEventSeverity.None: {
        return 'green';
      }
      default: {
        return 'gray';
      }
    }
  },
  byScore: (assessmentType: string, score: number) => {
    if (assessmentType === EAssessmentType.grmcdu) {
      switch (true) {
        case score >= 7: {
          return 'red';
        }
        case score >= 4 && score <= 6: {
          return 'orange';
        }
        default: {
          return 'green';
        }
      }
    }

    switch (true) {
      case score >= 15: {
        return 'red';
      }
      case score >= 8 && score <= 14: {
        return 'orange';
      }
      case score >= 1 && score <= 7: {
        return 'yellow';
      }
      default: {
        return 'green';
      }
    }
  },
};
