import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Row, Spin } from 'antd';
import { useNavigate } from 'react-router-dom';
import { RootDispatch, RootState, history } from 'app/store';
import { PatientDetails } from 'entities/Patient/components/PatientDetails';
import { ICCMPatientModel } from 'entities/Patient/Patient.models';
import { PatientVerbalConsent } from 'entities/Patient/components/PatientVerbalConsent';
import { PatientHistory } from 'entities/Patient/components/PatientHistory';
import { noCommentProvidedMessage } from 'entities/ReviewHistory/ReviewHistory.const';
import { Diagnoses } from 'entities/Diagnoses/components/Diagnoses';
import { IDiagnosesItem } from 'entities/Diagnoses/Diagnoses.models';

interface IComponentProps {
  billingCycleId?: string;
  ccmPatientModelData: ICCMPatientModel;
  patientEnrolled: boolean;
}

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch> & IComponentProps;

const CCMPatientDetailsContainerComponent: React.FC<AllType> = (props) => {
  const {
    billingCycleId,
    ccmPatientModelData: { id: patientId, externalId: patientExternalId, name: patientName },
    patientEnrolled,
    patientCcdaModel: { data: patientCcdaData },
    patientTimerModel: { isRunning },
    getPatientCcdaModel,
    addCcmDiagnosesCollection,
    setMultiselectDiagnosesModalParams,
    setUnenrollPatientModalParams,
    stopPatientTimer,
    unenrollCcmPatient,
    getCcmBillingCycleById,
  } = props;
  const navigate = useNavigate();

  const onSave = (diagnoses: IDiagnosesItem[]) => {
    addCcmDiagnosesCollection({
      patientId,
      diagnoses: diagnoses.map((item) => ({ diagnosis: item.displayName, code: item.code })),
      onSuccess: () => {
        setMultiselectDiagnosesModalParams({
          open: false,
          title: null,
          limit: null,
          defaultCcdaDiagnosesValue: null,
          defaultClinicalDiagnosesValue: null,
          defaultIsCcda: true,
          ccdaIsDisabled: false,
        });
      },
    });
  };

  const onCancelClick = () => {
    setUnenrollPatientModalParams({ open: false });
  };

  const unenroll = () => {
    setUnenrollPatientModalParams({ open: false });
    unenrollCcmPatient(patientExternalId).then(() => {
      navigate(history.location.pathname, { replace: true });

      if (billingCycleId) {
        getCcmBillingCycleById(billingCycleId);
      }
    });
  };

  const onUnenrollClick = () => {
    if (isRunning && billingCycleId) {
      stopPatientTimer({ billingCycleId, comment: noCommentProvidedMessage }).then(() => {
        unenroll();
      });
    } else {
      unenroll();
    }
  };

  const handleUnenrollClick = () => {
    setUnenrollPatientModalParams({ open: true, onUnenrollClick, onCancelClick });
  };

  useEffect(() => {
    getPatientCcdaModel(patientId);
  }, []);

  if (!patientCcdaData) {
    return (
      <Row justify="center" align="middle">
        <Spin size="large" />
      </Row>
    );
  }

  return (
    <div className="patient-details">
      <PatientDetails
        details={{
          gender: patientCcdaData.ccda?.gender,
          height: patientCcdaData.ccda?.height,
          weight: patientCcdaData.ccda?.weight,
          dob: patientCcdaData.ccda?.dob,
          ccdaLink: patientCcdaData.link,
        }}
      />

      <div className="patient-details__diagnosis-consent">
        <Diagnoses title="Add more than 1 diagnosis" limit={2} onSave={onSave} />

        <PatientVerbalConsent patientId={patientId} canEdit={patientEnrolled} onUnenrollClick={handleUnenrollClick} />

        <PatientHistory patientId={patientId} patientName={patientName} />
      </div>
    </div>
  );
};

const mapState = (state: RootState) => ({
  patientCcdaModel: state.patientCcdaModel,
  patientTimerModel: state.patientTimerModel,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  getPatientCcdaModel: dispatch.patientCcdaModel.getPatientCcdaModel,
  addCcmDiagnosesCollection: dispatch.diagnosesCollection.addCcmDiagnosesCollection,
  setMultiselectDiagnosesModalParams: dispatch.modals.setMultiselectDiagnosesModalParams,
  setUnenrollPatientModalParams: dispatch.modals.setUnenrollPatientModalParams,
  stopPatientTimer: dispatch.patientTimerModel.stopPatientTimer,
  unenrollCcmPatient: dispatch.ccmPatientConsentModel.unenrollCcmPatient,
  getCcmBillingCycleById: dispatch.ccmBillingCycleModel.getCcmBillingCycleById,
});

export const CCMPatientDetailsContainer = connect(mapState, mapDispatch)(CCMPatientDetailsContainerComponent);
