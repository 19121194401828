import React, { useState } from 'react';
import { Button, Input, Modal } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { DEFAULT_MODAL_WIDTH } from 'common/config';
import { useAutoFocus } from 'common/hooks/useAutoFocus';
import { RootDispatch, RootState } from 'app/store';

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

const CompleteModal: React.FC<AllType> = ({ completeModalParams, setCompleteModalParams }) => {
  const { open, externalId, onCompleteClick } = completeModalParams;
  const [text, setText] = useState<string>('');
  const textareaRef = useAutoFocus();

  const onCancelClick = () => {
    setText('');
    setCompleteModalParams({ open: false, externalId: null });
  };

  const handleCompleteClick = () => {
    if (externalId) {
      onCompleteClick?.(externalId, text);
      setText('');
      setCompleteModalParams({ open: false, externalId: null });
    }
  };

  return (
    <Modal title="Complete" open={open} footer={false} onCancel={onCancelClick} width={DEFAULT_MODAL_WIDTH}>
      <div className="modal__body">
        <Input.TextArea
          placeholder="Message"
          rows={6}
          onChange={(e) => setText(e.target.value)}
          value={text}
          ref={textareaRef}
          autoFocus
        />
      </div>

      <div className="modal__footer">
        <Button className="btn-green" onClick={handleCompleteClick} icon={<CheckCircleOutlined />} disabled={!text.trim().length}>
          Complete
        </Button>
        <Button onClick={onCancelClick}>Cancel</Button>
      </div>
    </Modal>
  );
};

const mapState = (state: RootState) => ({
  completeModalParams: state.modals.completeModalParams,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  setCompleteModalParams: dispatch.modals.setCompleteModalParams,
});

export default connect(mapState, mapDispatch)(CompleteModal);
