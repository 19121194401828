import moment from 'moment';

export function padTo2Digits(num: number) {
  return num.toString().padStart(2, '0');
}

export const convertToTimerFormat = (milliseconds?: number) => {
  const days = moment.duration(milliseconds).days();
  const hours = moment.duration(milliseconds).hours();
  const minutes = moment.duration(milliseconds).minutes();
  const seconds = moment.duration(milliseconds).seconds();

  return `${padTo2Digits(hours + days * 24)}:${padTo2Digits(minutes)}:${padTo2Digits(seconds)}`;
};

export const convertToMinutes = (milliseconds: number) => {
  return `${Math.round(milliseconds / 60000)} min`;
};
