import { useEffect } from 'react';
import { SocketIOService } from 'common/SocketIoService';

export const useSocket = (event: string, callback: () => void) => {
  const socket = SocketIOService.getSocket();

  useEffect(() => {
    socket?.on(event, () => {
      callback();
    });

    return () => {
      socket?.off(event);
    };
  }, []);
};
