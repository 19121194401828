export const modules = {
  toolbar: [
    [
      { header: '1' },
      { header: '2' },
      'bold',
      'italic',
      'strike',
      'blockquote',
      { align: [] },
      { list: 'bullet' },
      { list: 'ordered' },
      'link',
      'clean',
    ],
  ],
  clipboard: {
    matchVisual: false,
  },
};

export const formats = ['header', 'bold', 'italic', 'strike', 'blockquote', 'align', 'bullet', 'list', 'link'];
