import React from 'react';
import { Button, Modal } from 'antd';
import { connect } from 'react-redux';
import { DEFAULT_MODAL_WIDTH } from 'common/config';
import { RootDispatch, RootState } from 'app/store';

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

const EmptyTimeRecordModalComponent: React.FC<AllType> = ({ emptyTimeRecordModalParams, setEmptyTimeRecordModalParams }) => {
  const { open, physicianName, onLeaveClick } = emptyTimeRecordModalParams;

  const handleLeaveClick = () => {
    setEmptyTimeRecordModalParams({ open: false, physicianName: null });
    onLeaveClick?.();
  };

  const onCancelClick = () => {
    setEmptyTimeRecordModalParams({ open: false, physicianName: null });
  };

  return (
    <Modal title="Empty time record confirmation" open={open} footer={false} onCancel={onCancelClick} width={DEFAULT_MODAL_WIDTH}>
      <div className="modal__body">
        <p>
          {`${physicianName} didn’t track any time when the event was in the Escalated status.You can do this now.
          To do that, go back and add time record clicking the Edit Physician Time Spent button.`}
        </p>
      </div>

      <div className="modal__footer">
        <Button className="btn-red" onClick={handleLeaveClick}>
          Leave the record empty and proceed
        </Button>

        <Button onClick={onCancelClick}>Cancel</Button>
      </div>
    </Modal>
  );
};

const mapState = (state: RootState) => ({
  emptyTimeRecordModalParams: state.modals.emptyTimeRecordModalParams,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  setEmptyTimeRecordModalParams: dispatch.modals.setEmptyTimeRecordModalParams,
});

export const EmptyTimeRecordModal = connect(mapState, mapDispatch)(EmptyTimeRecordModalComponent);
