import React, { useEffect, useState } from 'react';
import { Button, Pagination, Space, Table } from 'antd';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { DEFAULT_PAGE_ID, DEFAULT_PAGE_SIZE } from 'common/config';
import { sort } from 'common/helpers/sorter.helper';
import { SearchInput } from 'common/components/SearchInput';
import { ESearchParams } from 'common/const/enums.const';
import { useSearchParams } from 'common/hooks/useSearchParams';
import { RootDispatch, RootState, history } from 'app/store';
import { getPatientsSearchParams, renderPatientsRecords, toDataSourseMapper } from 'entities/Dashboard/Dashboard.helper';
import { patientsQueryParamsTo } from 'entities/Dashboard/Dashboard.mappers';
import { PatientsFilter } from 'entities/Dashboard/components/Patients/PatientsFilter';

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

const PatientsBodyComponent: React.FC<AllType> = (props) => {
  const {
    patientsCollection: { data: patientsData, loading: patientsCollectionLoading },
    ccmBillingCycleModelLoading,
    ccmPatientModelLoading,
    getPatientsCollection,
  } = props;
  const { searchParams, setSearchParam, removeSearchParam, setSearchParamWithCondition } = useSearchParams();
  const [pageId, setPageId] = useState<number>(DEFAULT_PAGE_ID);
  const navigate = useNavigate();

  const params = getPatientsSearchParams(searchParams);
  const dataSource = toDataSourseMapper(patientsData?.data);
  const columns = renderPatientsRecords();

  const { search, orderField, orderDirection, daysLeftLessThan, timeLeftToNextThreshold } = params;

  const searchValueChange = (value: string) => {
    setSearchParamWithCondition(ESearchParams.Search, value);
    setPageId(DEFAULT_PAGE_ID);
  };

  const patientsFilterValuesChange = (values: { [key: string]: string | undefined }[]) => {
    values.forEach((item) => {
      const [key, value] = Object.entries(item).flat() as [string, string | undefined];
      setSearchParamWithCondition(key, value);
    });
    setPageId(DEFAULT_PAGE_ID);
  };

  const handleSort = (pagination: any, filters: any, sorter: any) => {
    sort(sorter, setSearchParam, removeSearchParam);
  };

  const onReviewClick = (billingCycleId: string) => {
    history.push(`${history.location.pathname}${history.location.search}`);
    navigate(`/patient/${billingCycleId}?autoStartTimer=true`, { replace: true });
  };

  const onViewClick = (billingCycleId: string) => {
    history.push(`${history.location.pathname}${history.location.search}`);
    navigate(`/patient/${billingCycleId}`, { replace: true });
  };

  columns.push({
    key: 'action',
    render: (_, record) => {
      return (
        <Space size="middle">
          {record.isPatientCcmEnrolled && (
            <Button className="btn-link" onClick={() => onReviewClick(record.id)}>
              Review
            </Button>
          )}
          <Button className="btn-view-link" onClick={() => onViewClick(record.id)}>
            View
          </Button>
        </Space>
      );
    },
  });

  useEffect(() => {
    const queryParams = patientsQueryParamsTo(params, pageId);

    getPatientsCollection(queryParams);
  }, [search, orderField, orderDirection, daysLeftLessThan, timeLeftToNextThreshold, pageId]);

  return (
    <>
      <div className="filter">
        <div className="filter__row">
          <SearchInput value={search} onChange={searchValueChange} placeholder="Search by patient / physician / MRN" />

          <PatientsFilter
            daysLeftLessThan={daysLeftLessThan}
            timeLeftToNextThreshold={timeLeftToNextThreshold}
            onChange={patientsFilterValuesChange}
          />
        </div>
      </div>

      <Table
        columns={columns}
        dataSource={dataSource}
        pagination={false}
        size="small"
        loading={patientsCollectionLoading || ccmPatientModelLoading || ccmBillingCycleModelLoading}
        onChange={handleSort}
      />

      <Pagination
        current={Number(pageId)}
        total={patientsData?.meta.count}
        showSizeChanger={false}
        onChange={(pageId: number) => setPageId(pageId)}
        defaultPageSize={DEFAULT_PAGE_SIZE}
      />
    </>
  );
};

const mapState = (state: RootState) => ({
  patientsCollection: state.patientsCollection,
  ccmBillingCycleModelLoading: state.ccmBillingCycleModel.loading,
  ccmPatientModelLoading: state.ccmPatientModel.loading,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  getPatientsCollection: dispatch.patientsCollection.getPatientsCollection,
});

export const PatientsBody = connect(mapState, mapDispatch)(PatientsBodyComponent);
