import { DEFAULT_PAGE_SIZE } from 'common/config';
import { EOrderDirection, EStatuses } from 'common/const/enums.const';
import {
  EnrollmentsCollectionOrderFieldType,
  EnrollmentsCollectionStatusType,
  IEnrollmentCollectionParams,
  IEnrollmentCollectionQueryParams,
  IPatientsCollectionParams,
  IPatientsCollectionQueryParams,
  PatientsCollectionOrderFieldType,
} from 'entities/Dashboard/Dashboard.models';

export const enrollmentsQueryParamsTo = (
  params: IEnrollmentCollectionParams,
  pageId: number
): IEnrollmentCollectionQueryParams => {
  return {
    ...params,
    status: params.status as EnrollmentsCollectionStatusType,
    orderField: params.orderField as EnrollmentsCollectionOrderFieldType,
    orderDirection: params.orderDirection as EOrderDirection,
    offset: DEFAULT_PAGE_SIZE * (pageId - 1),
    limit: DEFAULT_PAGE_SIZE,
  };
};

export const patientsQueryParamsTo = (params: IPatientsCollectionParams, pageId: number): IPatientsCollectionQueryParams => {
  return {
    ...params,
    status: EStatuses.Active,
    orderField: params.orderField as PatientsCollectionOrderFieldType,
    orderDirection: params.orderDirection as EOrderDirection,
    offset: DEFAULT_PAGE_SIZE * (pageId - 1),
    limit: DEFAULT_PAGE_SIZE,
  };
};
