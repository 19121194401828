import { createModel, RematchDispatch } from '@rematch/core';
import { IRootModel } from 'app/store';
import { diagnosesToDataMapper } from 'entities/Diagnoses/Diagnoses.helper';
import {
  ICcmPatientConsentModel,
  ICcmPatientConsentPayload,
  ICCMPatientModel,
  IPatientCcdaModel,
  IPatientHistoryCollection,
  IPatientHistoryQueryParams,
  IPatientModel,
} from 'entities/Patient/Patient.models';
import { patientTransport } from 'entities/Patient/Patient.transport';

type State = IPatientModel;

export const patientModel = createModel<IRootModel>()({
  state: null as State | null,
  reducers: {
    setPatientModel: (state, payload: State) => ({ ...state, ...payload }),
    clearPatientModel: () => null,
  },
  effects: (dispatch) => ({
    async getPatientModel(id: number) {
      patientTransport.getPatientModel(id).then((response) => {
        dispatch.patientModel.setPatientModel(response);

        if (response.diagnoses) {
          const diagnoses = response.diagnoses.map((item) => ({ displayName: item.diagnosis, code: item.code }));
          dispatch.diagnosesCollection.setDiagnosesCollection(diagnoses);
        }
      });
    },
  }),
});

export const patientCcdaModel = createModel<IRootModel>()({
  state: {
    data: null as IPatientCcdaModel | null,
    loading: false,
  },
  reducers: {
    setPatientCcdaModel: (state, payload: IPatientCcdaModel) => ({ ...state, data: payload }),
    setPatientCcdaModelLoading: (state, payload: boolean) => ({ ...state, loading: payload }),
    clearPatientCcdaModel: (state) => ({ ...state, data: null }),
  },
  effects: (dispatch) => ({
    async getPatientCcdaModel(patientId: string) {
      dispatch.patientCcdaModel.setPatientCcdaModelLoading(true);
      patientTransport
        .getPatientCcdaModel(patientId)
        .then((response) => {
          dispatch.patientCcdaModel.setPatientCcdaModel(response);

          if (response.ccda) {
            dispatch.ccdaDiagnosesCollection.setCcdaDiagnosesCollection(diagnosesToDataMapper(response.ccda.diagnoses));
          }
        })
        .finally(() => {
          dispatch.patientCcdaModel.setPatientCcdaModelLoading(false);
        });
    },
  }),
});

export const patientHistoryCollection = createModel<IRootModel>()({
  state: {
    data: null as IPatientHistoryCollection | null,
    loading: false,
  },
  reducers: {
    setPatientHistoryCollection: (state, payload: IPatientHistoryCollection) => ({ ...state, data: payload }),
    setPatientHistoryLoading: (state, payload: boolean) => ({ ...state, loading: payload }),
    clearPatientHistoryCollection: (state) => ({ ...state, data: null }),
  },
  effects: (dispatch) => ({
    async getPatientHistoryCollection(params: IPatientHistoryQueryParams) {
      dispatch.patientHistoryCollection.setPatientHistoryLoading(true);
      patientTransport
        .getPatientHistoryCollection(params)
        .then((response) => {
          dispatch.patientHistoryCollection.setPatientHistoryCollection(response);
        })
        .finally(() => {
          dispatch.patientHistoryCollection.setPatientHistoryLoading(false);
        });
    },
  }),
});

const postSuccess = (dispatch: RematchDispatch<IRootModel>, response: ICCMPatientModel) => {
  dispatch.ccmPatientModel.setCCMPatientModel(response);

  if (response.diagnoses) {
    const diagnosesData = response.diagnoses.map((item) => ({ displayName: item.diagnosis, code: item.code }));
    dispatch.diagnosesCollection.setDiagnosesCollection(diagnosesData);
  }

  if (response.consent) {
    dispatch.ccmPatientConsentModel.setCcmPatientConsentModel(response.consent);
  }

  if (response.carePlan) {
    dispatch.carePlanModel.setCarePlanModel({ description: response.carePlan });
  }
};

export const ccmPatientModel = createModel<IRootModel>()({
  state: {
    data: null as ICCMPatientModel | null,
    billingCycleId: null as string | null,
    loading: false,
  },
  reducers: {
    setCCMPatientModel: (state, payload: ICCMPatientModel) => ({ ...state, data: payload }),
    setCCMPatientModelBillingCycleId: (state, payload: string) => ({ ...state, billingCycleId: payload }),
    setCCMPatientModelLoading: (state, payload: boolean) => ({ ...state, loading: payload }),
    clearCcmPatientModel: (state) => ({ ...state, data: null }),
  },
  effects: (dispatch) => ({
    async getEnrollmentPatientModel(enrollmentId: string) {
      dispatch.ccmPatientModel.setCCMPatientModelLoading(true);
      return patientTransport
        .getEnrollmentPatientModel(enrollmentId)
        .then((response) => {
          postSuccess(dispatch, response);
        })
        .finally(() => {
          dispatch.ccmPatientModel.setCCMPatientModelLoading(false);
        });
    },
    async getBillingCyclesPatientModel(billingCycleId: string) {
      dispatch.ccmPatientModel.setCCMPatientModelLoading(true);
      return patientTransport
        .getBillingCyclesPatientModel(billingCycleId)
        .then((response) => {
          postSuccess(dispatch, response);
          dispatch.ccmPatientModel.setCCMPatientModelBillingCycleId(billingCycleId);
        })
        .finally(() => {
          dispatch.ccmPatientModel.setCCMPatientModelLoading(false);
        });
    },
    clearModels() {
      dispatch.modals.setInactiveModalParams({ open: false, title: null, description: null });
      dispatch.patientCcdaModel.clearPatientCcdaModel();
      dispatch.ccmPatientModel.clearCcmPatientModel();
      dispatch.ccmBillingCycleModel.clearCcmBillingCycleModel();
      dispatch.diagnosesCollection.clearDiagnosesCollection();
      dispatch.ccdaDiagnosesCollection.clearCcdaDiagnosesCollection();
      dispatch.clinicalDiagnosesCollection.clearClinicalDiagnosesCollection();
      dispatch.ccmPatientConsentModel.clearCcmPatientConsentModel();
      dispatch.promisCollection.clearPromisCollection();
      dispatch.assessmentHistoryCollection.clearAssessmentHistoryCollection();
      dispatch.patientReviewHistoryModel.clearPatientReviewHistoryModel();
      dispatch.patientTimerModel.clearPatientTimerModel();
      dispatch.carePlanModel.clearCarePlanModel();
      dispatch.patientHistoryCollection.clearPatientHistoryCollection();
    },
  }),
});

export const ccmPatientConsentModel = createModel<IRootModel>()({
  state: {
    data: null as ICcmPatientConsentModel | null,
    loading: false,
    error: false,
  },
  reducers: {
    setCcmPatientConsentModel: (state, payload: ICcmPatientConsentModel) => ({ ...state, data: payload }),
    setCcmPatientConsentModelLoading: (state, payload: boolean) => ({ ...state, loading: payload }),
    setCcmPatientConsentModelError: (state, payload: boolean) => ({ ...state, error: payload }),
    clearCcmPatientConsentModel: (state) => ({ ...state, data: null, error: false }),
  },
  effects: (dispatch) => ({
    async addCcmPatientConsentModel(payload: ICcmPatientConsentPayload) {
      dispatch.ccmPatientConsentModel.setCcmPatientConsentModelLoading(true);
      return patientTransport
        .addCcmPatientConsentModel(payload)
        .then((response) => {
          dispatch.ccmPatientConsentModel.setCcmPatientConsentModel(response);

          if (payload.onSuccess) {
            payload.onSuccess();
          }
        })
        .finally(() => {
          dispatch.ccmPatientConsentModel.setCcmPatientConsentModelLoading(false);
        });
    },
    async unenrollCcmPatient(patientExternalId: number) {
      dispatch.ccmPatientConsentModel.setCcmPatientConsentModelLoading(true);
      return patientTransport
        .unenrollCcmPatient(patientExternalId)
        .then(() => {
          dispatch.ccmPatientModel.clearCcmPatientModel();
        })
        .finally(() => {
          dispatch.ccmPatientConsentModel.setCcmPatientConsentModelLoading(false);
        });
    },
  }),
});
