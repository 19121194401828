import axios from 'axios';
import { ICcmTaskCollection, ICcmTaskItem, ICCMTasksCollectionQueryParams, ITaskPayload } from 'entities/Tasks/Tasks.models';

const taskPath = 'task/status';
const ccmTaskPath = 'ccm/tasks';

export const tasksTransport = {
  changeEventTaskStatus: ({ taskId, status }: ITaskPayload): Promise<void> => axios.patch(`${taskPath}/${taskId}/${status}`),
  getCcmTasksCollection: (params: ICCMTasksCollectionQueryParams): Promise<ICcmTaskCollection> =>
    axios.get(`${ccmTaskPath}`, { params }),
  changeCcmTaskStatus: (payload: ITaskPayload): Promise<ICcmTaskItem> => axios.patch(`${ccmTaskPath}/status`, payload),
};
