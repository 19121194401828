import React from 'react';
import { connect } from 'react-redux';
import { Button, Modal } from 'antd';
import { DEFAULT_MODAL_WIDTH } from 'common/config';
import { RootDispatch, RootState } from 'app/store';

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

const ExportedEventModal: React.FC<AllType> = ({ exportedEventModalParams, setExportedEventModalParams }) => {
  const { open } = exportedEventModalParams;

  const toggleModal = () => setExportedEventModalParams({ open: false });

  return (
    <Modal title="Notification" open={open} footer={false} onCancel={toggleModal} width={DEFAULT_MODAL_WIDTH}>
      <div className="modal__body">
        <p>Sorry! Event is completed and invoiced.</p>
      </div>

      <div className="modal__footer">
        <Button onClick={toggleModal}>Cancel</Button>
      </div>
    </Modal>
  );
};

const mapState = (state: RootState) => ({
  exportedEventModalParams: state.modals.exportedEventModalParams,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  setExportedEventModalParams: dispatch.modals.setExportedEventModalParams,
});

export default connect(mapState, mapDispatch)(ExportedEventModal);
