import axios from 'axios';
import {
  IHealthMetricsModel,
  IHealthMetricsQueryParams,
  IHealthMetricsScoresModel,
} from 'entities/HealthMetrics/HealthMetrics.models';

const basePath = 'patient-metrics';

export const healthMetricsTransport = {
  getHealthMetricsScores: (patientId: string): Promise<IHealthMetricsScoresModel[]> =>
    axios.get(`${basePath}/${patientId}/scores`),
  getHealthMetrics: ({ patientId, metric }: IHealthMetricsQueryParams): Promise<IHealthMetricsModel[]> =>
    axios.get(`${basePath}/${patientId}/health-metrics`, { params: { metric } }),
};
