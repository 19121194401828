import axios from 'axios';
import {
  IEventTimeModel,
  IEventTimerModel,
  IEventTimerModelUpdateParams,
  IPatientTimeModel,
  IPatientTimerModel,
  IPatientTimerPayload,
  IPatientTimerUpdatePayload,
} from 'entities/Timer/Timer.models';

const basePath = 'time';
const ccmPath = 'ccm/times';

export const timerTransport = {
  getEventTimerModel: (eventId: number): Promise<IEventTimerModel> => axios.get(`${basePath}/info/${eventId}`),
  startEventTimer: (eventId: number): Promise<void> => axios.post(`${basePath}/start`, { eventId }),
  stopEventTimer: (eventId: number, comment?: string): Promise<IEventTimeModel> =>
    axios.patch(`${basePath}/stop`, { eventId, comment }),
  pingEventTimer: (eventId: number): Promise<IEventTimeModel> => axios.patch(`${basePath}/ping`, { eventId }),
  updateEventTimerModel: (params: IEventTimerModelUpdateParams): Promise<any> => axios.patch(basePath, params),
  deleteEventTimerModel: (eventId: number, comment?: string): Promise<void> =>
    axios.patch(`${basePath}/cancel`, { eventId, comment }),
  getPatientTimerModel: (billingCycleId: string): Promise<IPatientTimerModel> => axios.get(`${ccmPath}/info/${billingCycleId}`),
  startPatientTimer: (billingCycleId: string): Promise<void> => axios.post(`${ccmPath}/start`, { billingCycleId }),
  stopPatientTimer: (payload: IPatientTimerPayload): Promise<IPatientTimeModel> => axios.patch(`${ccmPath}/stop`, payload),
  pingPatientTimer: (billingCycleId: string): Promise<void> => axios.patch(`${ccmPath}/ping`, { billingCycleId }),
  updatePatientTimerModel: (payload: IPatientTimerUpdatePayload): Promise<IPatientTimeModel> =>
    axios.patch(`${ccmPath}`, payload),
  deletePatientTimerModel: (payload: IPatientTimerPayload): Promise<IPatientTimeModel> =>
    axios.patch(`${ccmPath}/cancel`, payload),
};
