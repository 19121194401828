import React from 'react';
import { Badge } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import {
  EDashboardTabs,
  EDateFormat,
  EEnrollmentsStatuses,
  EEventStatus,
  EEventType,
  EOrderDirection,
  ERole,
  ESearchParams,
  ESort,
} from 'common/const/enums.const';
import { DiaryDots } from 'common/components/DiaryDots';
import { getColor } from 'common/helpers/color.helper';
import { convertToMinutes } from 'common/helpers/time.helper';
import {
  EventOrderField,
  IDashboardColumnsType,
  IDashboardInfoModel,
  IEnrollmentsCollectionColumnsType,
  IPatientsCollectionColumnsType,
} from 'entities/Dashboard/Dashboard.models';
import { EDashboardTableTabs, EEventSeverity } from 'entities/Dashboard/Dashboard.const';

const patientName = 'Patient Name';

export const getDashboardPathname = (tab: string) => `/dashboard/${tab}`;

export const toDataSourseMapper = (data?: any[]) => data?.map((item, index) => ({ key: item.id || index, ...item }));

const getBoolean = (param: string | null) => {
  switch (param) {
    case 'true':
      return true;
    case 'false':
      return false;
    default:
      return;
  }
};

export const getEventsSearchParams = (searchParams: URLSearchParams, role?: string) => {
  const statusParam = searchParams.get(ESearchParams.Status) as EEventStatus;
  const red = searchParams.get(ESearchParams.Red);
  const search = searchParams.get(ESearchParams.Search);
  const dateFrom = searchParams.get(ESearchParams.DateFrom);
  const dateTo = searchParams.get(ESearchParams.DateTo);
  const orderField = searchParams.get(ESearchParams.OrderField);
  const orderDirection = searchParams.get(ESearchParams.OrderDirection) as EOrderDirection;
  const currentPhysician = searchParams.get(ESearchParams.CurrentPhysician);

  const status = statusParam || EEventStatus.Inbox;
  let hasAssignee = searchParams.get(ESearchParams.HasAssignee);

  if (!hasAssignee && role === ERole.Staff && status === EEventStatus.Inbox) {
    hasAssignee = 'false';
  }

  return {
    status: status || EEventStatus.Inbox,
    hasAssignee: getBoolean(hasAssignee),
    red: red === 'true',
    search: search ? search : undefined,
    dateFrom: dateFrom ? dateFrom : undefined,
    dateTo: dateTo ? dateTo : undefined,
    orderField: (orderField ? orderField : ESort.Date) as EventOrderField,
    orderDirection: orderDirection ? orderDirection : EOrderDirection.Desc,
    currentPhysician: role === ERole.Physician && currentPhysician !== 'false',
  };
};

export const convertTabToStatus = (tab: EDashboardTableTabs): EEventStatus => {
  switch (tab) {
    case EDashboardTableTabs.Inbox:
      return EEventStatus.Inbox;
    case EDashboardTableTabs.UnderReview:
      return EEventStatus.UnderReview;
    case EDashboardTableTabs.Escalated:
      return EEventStatus.Inbox;
    case EDashboardTableTabs.Completed:
      return EEventStatus.Completed;
    default:
      return EEventStatus.Inbox;
  }
};

export const convertStatusToTab = (status: EEventStatus, hasAssignee = false): EDashboardTableTabs => {
  switch (status) {
    case EEventStatus.Inbox:
      return hasAssignee ? EDashboardTableTabs.Escalated : EDashboardTableTabs.Inbox;
    case EEventStatus.UnderReview:
      return EDashboardTableTabs.UnderReview;
    case EEventStatus.Completed:
      return EDashboardTableTabs.Completed;
    default:
      return EDashboardTableTabs.Inbox;
  }
};

export const getEnrollmentsSearchParams = (searchParams: URLSearchParams) => {
  const status = searchParams.get(ESearchParams.Status);
  const search = searchParams.get(ESearchParams.Search);
  const dateFrom = searchParams.get(ESearchParams.DateFrom);
  const dateTo = searchParams.get(ESearchParams.DateTo);
  const orderField = searchParams.get(ESearchParams.OrderField);
  const orderDirection = searchParams.get(ESearchParams.OrderDirection);

  return {
    status: status ? status : EEnrollmentsStatuses.Inbox,
    search: search ? search : undefined,
    dateFrom: dateFrom ? dateFrom : undefined,
    dateTo: dateTo ? dateTo : undefined,
    orderField: orderField ? orderField : ESort.CreatedAt,
    orderDirection: orderDirection ?? 'DESC',
  };
};

export const getPatientsSearchParams = (searchParams: URLSearchParams) => {
  const search = searchParams.get(ESearchParams.Search);
  const orderField = searchParams.get(ESearchParams.OrderField);
  const orderDirection = searchParams.get(ESearchParams.OrderDirection);
  const daysLeftLessThan = searchParams.get(ESearchParams.DaysLeftLessThan);
  const timeLeftToNextThreshold = searchParams.get(ESearchParams.TimeLeftToNextThreshold);

  return {
    search: search ? search : undefined,
    orderField: orderField ? orderField : ESort.CreatedAt,
    orderDirection: orderDirection ? orderDirection : 'DESC',
    daysLeftLessThan: daysLeftLessThan ? Number(daysLeftLessThan) : undefined,
    timeLeftToNextThreshold: timeLeftToNextThreshold ? Number(timeLeftToNextThreshold) : undefined,
  };
};

export const getMomentDifference = (firstDate: string, secondDate: string) => {
  return moment(firstDate).diff(moment(secondDate));
};

export const getFormattedMomentDifference = (milliseconds: number) => {
  const days = Math.floor(moment.duration(milliseconds).asDays());
  const hours = moment.duration(milliseconds).hours();
  const minutes = moment.duration(milliseconds).minutes();

  return days > 0 && hours > 0
    ? `${days}d ${hours}h`
    : days > 0 && hours <= 0
    ? `${days}d`
    : days <= 0 && hours > 0
    ? `${hours}h`
    : `${minutes}m`;
};

export const renderDashboardRecords = (role?: ERole, currentTab?: EDashboardTableTabs) => {
  return [
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      sorter: true,
      render: (text) => moment(text).format(EDateFormat.FullYear),
    },
    {
      title: patientName,
      dataIndex: 'patientName',
      key: 'patientName',
      sorter: true,
    },
    {
      title: 'Physician',
      dataIndex: 'physician',
      key: 'physician',
      sorter: true,
    },
    {
      title:
        currentTab === EDashboardTableTabs.UnderReview ||
        currentTab === EDashboardTableTabs.Escalated ||
        (role === ERole.Physician && currentTab === EDashboardTableTabs.Inbox)
          ? 'Assignee'
          : undefined,
      dataIndex:
        currentTab === EDashboardTableTabs.UnderReview ||
        currentTab === EDashboardTableTabs.Escalated ||
        (role === ERole.Physician && currentTab === EDashboardTableTabs.Inbox)
          ? 'assignee'
          : undefined,
      key: 'assignee',
    },
    {
      title: 'Event type',
      dataIndex: 'eventType',
      key: 'eventType',
      sorter: true,
      render: () => EEventType.DiarySubmission,
    },
    {
      title: currentTab === EDashboardTableTabs.Inbox ? 'Age' : 'Time spent',
      dataIndex: currentTab === EDashboardTableTabs.Inbox ? 'date' : 'timer',
      key: 'age',
      sorter: true,
      render: (text, record) => {
        const milliseconds =
          currentTab === EDashboardTableTabs.Inbox ? getMomentDifference(moment().toISOString(), record.date) : text;
        return getFormattedMomentDifference(milliseconds);
      },
    },
    {
      title: 'Score',
      dataIndex: 'score',
      key: 'score',
      sorter: true,
      onCell: (record) => ({
        className: `color__${getColor.byScore(record.assessmentType, record.score)}`,
      }),
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      sorter: true,
      onCell: (record) => ({
        className: `color__${getColor.byScore(record.assessmentType, record.score)}`,
      }),
    },
    {
      title: 'Last 5 diaries',
      dataIndex: 'lastDiaries',
      key: 'lastDiaries',
      render: (_, record) => {
        return <DiaryDots dots={record.last5Diaries as EEventSeverity[]} />;
      },
    },
  ] as ColumnsType<IDashboardColumnsType>;
};

export const renderEnrollmentsRecords = () => {
  return [
    {
      title: 'Date',
      dataIndex: 'createdAt',
      key: 'createdAt',
      sorter: true,
      render: (text) => moment(text).format(EDateFormat.FullYear),
    },
    {
      title: 'Patient MRN',
      dataIndex: 'patientMrn',
      key: 'patientMrn',
      sorter: true,
    },
    {
      title: patientName,
      dataIndex: 'patientName',
      key: 'patientName',
      sorter: true,
    },
    {
      title: 'Physician',
      dataIndex: 'physicianName',
      key: 'physicianName',
      sorter: true,
    },
    {
      title: 'Age',
      dataIndex: 'createdAt',
      key: 'age',
      sorter: true,
      render: (_, record) => {
        const milliseconds = getMomentDifference(moment().toISOString(), record.createdAt);
        return getFormattedMomentDifference(milliseconds);
      },
    },
  ] as ColumnsType<IEnrollmentsCollectionColumnsType>;
};

export const renderPatientsRecords = () => {
  return [
    {
      title: 'MRN',
      dataIndex: 'patientMrn',
      key: 'patientMrn',
    },
    {
      title: patientName,
      dataIndex: 'patientName',
      key: 'patientName',
    },
    {
      title: 'Physician Name',
      dataIndex: 'physicianName',
      key: 'physicianName',
    },
    {
      title: 'Start Date',
      dataIndex: 'startDate',
      key: 'startDate',
      sorter: true,
      render: (text) => (text ? moment(text).format(EDateFormat.ShortYear) : '-'),
    },
    {
      title: 'End Date',
      dataIndex: 'endDate',
      key: 'endDate',
      render: (text) => (text ? moment(text).format(EDateFormat.ShortYear) : '-'),
    },
    {
      title: 'Days Left',
      dataIndex: 'endDate',
      key: 'daysLeft',
      sorter: true,
      render: (_, record) => (record.endDate ? moment(record.endDate).diff(moment().toISOString(), 'days') : '-'),
    },
    {
      title: 'Total Minutes Spent',
      dataIndex: 'totalTimeSpent',
      key: 'totalTimeSpent',
      sorter: true,
      render: (text) => convertToMinutes(text),
    },
    {
      title: 'Last Record',
      dataIndex: 'lastRecordDate',
      key: 'lastRecordDate',
      render: (_, record) => {
        if (record.lastRecordDate) {
          const milliseconds = getMomentDifference(moment().toISOString(), record.lastRecordDate);
          return getFormattedMomentDifference(milliseconds);
        } else {
          return '-';
        }
      },
    },
    {
      title: 'CPT',
      dataIndex: 'cpt',
      key: 'cpt',
      render: (_, record) => (record.cpt?.length ? record.cpt : '-'),
    },
  ] as ColumnsType<IPatientsCollectionColumnsType>;
};

export const getDashboardTabs = (dashboardInfo: IDashboardInfoModel | null) => {
  return [
    {
      key: EDashboardTabs.Events,
      label: (
        <div className="tabs-item">
          <span>Diary Events</span>
          {!!dashboardInfo?.enmEvents && <Badge className="tabs-item__badge" count={dashboardInfo?.enmEvents} />}
        </div>
      ),
    },
    {
      key: EDashboardTabs.Enrollments,
      label: (
        <div className="tabs-item">
          <span>CCM Enrollments</span>
          {!!dashboardInfo?.ccmEnrollments && <Badge className="tabs-item__badge" count={dashboardInfo?.ccmEnrollments} />}
        </div>
      ),
    },
    {
      key: EDashboardTabs.Patients,
      label: `CCM Patients`,
    },
  ];
};
