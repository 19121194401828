import React, { useEffect, useState } from 'react';
import { Button, Checkbox, Pagination, Table } from 'antd';
import { SendOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { ECCMBillingStatuses, ESearchParams } from 'common/const/enums.const';
import { DEFAULT_PAGE_ID, DEFAULT_PAGE_SIZE } from 'common/config';
import { sort } from 'common/helpers/sorter.helper';
import { SearchInput } from 'common/components/SearchInput';
import { DatePicker } from 'common/components/DatePicker';
import { Menu } from 'common/components/Menu';
import { useSearchParams } from 'common/hooks/useSearchParams';
import { RootDispatch, RootState } from 'app/store';
import { ICcmBillingCycleCollectionQueryParams, ICcmBillingCycleItem } from 'entities/Billing/Billing.models';
import { ccmBillingQueryParamsTo } from 'entities/Billing/Billing.mappers';
import { toDataSourseMapper } from 'entities/Dashboard/Dashboard.helper';
import { getCcmBillingSearchParams, renderCcmBillingRecords, convertDateFormat } from 'entities/Billing/Billing.helper';
import { ccmBillingMenuItems } from 'entities/Billing/Billing.const';

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

const CCMBillingBodyComponent: React.FC<AllType> = (props) => {
  const {
    ccmBillingCollection,
    ccmBillingCycleModel,
    getCcmBillingCollection,
    getCcmBillingReport,
    sendCcmBillingCycleIds,
    rejectCcmBillingCycleIds,
  } = props;
  const { searchParams, setSearchParam, removeSearchParam, setSearchParamWithCondition } = useSearchParams();
  const [pageId, setPageId] = useState<number>(DEFAULT_PAGE_ID);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [collectionParams, setCollectionParams] = useState<ICcmBillingCycleCollectionQueryParams>();
  const { data: billingData, loading: billingCollectionLoading } = ccmBillingCollection;
  const { loading: billingCycleModelLoading } = ccmBillingCycleModel;

  const params = getCcmBillingSearchParams(searchParams);
  const dataSource = toDataSourseMapper(billingData?.data);

  const { status, search, dateFrom, dateTo, orderField, orderDirection, sendDate } = params;

  const searchValueChange = (value: string) => {
    setSearchParamWithCondition(ESearchParams.Search, value);
    setPageId(DEFAULT_PAGE_ID);
  };

  const dateRangeValueChange = (values: { [key: string]: string | undefined }[]) => {
    values.forEach((item) => {
      const [key, value] = Object.entries(item).flat() as [string, string | undefined];
      setSearchParamWithCondition(key, value);
    });
    setPageId(DEFAULT_PAGE_ID);
  };

  const menuTabChange = (value: string) => {
    removeSearchParam(ESearchParams.OrderField);
    removeSearchParam(ESearchParams.OrderDirection);
    setSearchParam(ESearchParams.Status, value);
    setPageId(DEFAULT_PAGE_ID);
  };

  const onExportClick = () => {
    const exportParams = ccmBillingQueryParamsTo(params);

    getCcmBillingReport(exportParams);
  };

  const sendToEMR = (billingCycleIds: string[]) => {
    sendCcmBillingCycleIds({
      billingCycleIds,
      params: collectionParams as ICcmBillingCycleCollectionQueryParams,
    }).then(() => {
      setSelectedRowKeys([]);
    });
  };

  const rejectCcmBillingCycles = () => {
    rejectCcmBillingCycleIds({
      billingCycleIds: selectedRowKeys.map((item) => item.toString()),
      params: collectionParams as ICcmBillingCycleCollectionQueryParams,
    }).then(() => {
      setSelectedRowKeys([]);
    });
  };

  const handleSort = (pagination: any, filters: any, sorter: any) => {
    sort(sorter, setSearchParam, removeSearchParam);
  };

  const onCell = (record: ICcmBillingCycleItem) => {
    return {
      onClick: () => window.open(`/patient/${record.id}?source=billing`, '_blank'),
    };
  };

  const columns = renderCcmBillingRecords(onCell);

  if (status === ECCMBillingStatuses.Completed) {
    columns.push({
      key: 'action',
      render: (_, record) => (
        <Button className="btn-link" onClick={() => sendToEMR([record.id])}>
          Send to EMR
        </Button>
      ),
    });
  }

  if (status === ECCMBillingStatuses.Closed) {
    columns.push({
      title: 'Sent to EMR',
      dataIndex: 'sendDate',
      key: 'sendDate',
      sorter: true,
      // @ts-ignore
      render: (_, record) => convertDateFormat(record.sendDate),
    });
  }

  useEffect(() => {
    const queryParams = ccmBillingQueryParamsTo(params, pageId);

    setCollectionParams(queryParams);
    getCcmBillingCollection(queryParams);
  }, [status, search, dateFrom, dateTo, orderField, orderDirection, sendDate, pageId]);

  useEffect(() => {
    setSelectedRowKeys([]);
  }, [status]);

  return (
    <>
      <div>
        <div className="filter">
          <div className="filter__row">
            <SearchInput value={search} onChange={searchValueChange} placeholder="Search by patient / MRN" />

            <DatePicker dateFrom={dateFrom} dateTo={dateTo} onChange={dateRangeValueChange} />
          </div>

          <div className="filter__row">
            <Menu items={ccmBillingMenuItems} selectedKeys={[status]} onChange={menuTabChange} />

            {(status === ECCMBillingStatuses.Completed || status === ECCMBillingStatuses.Closed) && (
              <div className="filter__btn_wrapper">
                <Button onClick={onExportClick}>Export to CSV</Button>
              </div>
            )}
          </div>
        </div>

        <Table
          className="billing-body__table"
          rowSelection={{
            selectedRowKeys,
            onChange: (newSelectedRowKeys) => setSelectedRowKeys(newSelectedRowKeys),
            renderCell: (checked, record, index, originNode) => {
              return status === ECCMBillingStatuses.Completed && originNode;
            },
            hideSelectAll: status === ECCMBillingStatuses.Completed ? false : true,
          }}
          columns={columns}
          dataSource={dataSource}
          pagination={false}
          size="small"
          loading={billingCollectionLoading || billingCycleModelLoading}
          onChange={handleSort}
        />

        <Pagination
          current={pageId}
          total={billingData?.meta.count}
          showSizeChanger={false}
          onChange={(page: number) => setPageId(page)}
          defaultPageSize={DEFAULT_PAGE_SIZE}
        />
      </div>

      {!!selectedRowKeys.length && (
        <div className="billing-body__footer">
          <div className="billing-body__footer_container">
            <Checkbox checked>Selected {selectedRowKeys.length} reports</Checkbox>

            <div className="billing-body__footer_btns-wrapper">
              <Button className="billing-body__footer_btn-reject" onClick={rejectCcmBillingCycles}>
                Reject
              </Button>

              <Button className="btn-primary" icon={<SendOutlined />} onClick={() => sendToEMR(selectedRowKeys as string[])}>
                Send selected to EMR
              </Button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const mapState = (state: RootState) => ({
  ccmBillingCollection: state.ccmBillingCollection,
  ccmBillingCycleModel: state.ccmBillingCycleModel,
});

const mapDispatch = (dispatch: RootDispatch) => ({
  getCcmBillingCollection: dispatch.ccmBillingCollection.getCcmBillingCollection,
  getCcmBillingReport: dispatch.ccmBillingCollection.getCcmBillingReport,
  sendCcmBillingCycleIds: dispatch.ccmBillingCollection.sendCcmBillingCycleIds,
  rejectCcmBillingCycleIds: dispatch.ccmBillingCollection.rejectCcmBillingCycleIds,
});

export const CCMBillingBody = connect(mapState, mapDispatch)(CCMBillingBodyComponent);
