import axios from 'axios';
import {
  ICcmPatientConsentModel,
  ICcmPatientConsentPayload,
  ICCMPatientModel,
  IPatientCcdaModel,
  IPatientHistoryCollection,
  IPatientHistoryQueryParams,
  IPatientModel,
} from 'entities/Patient/Patient.models';

const basePath = 'patients/by-event';
const patientHistoryPath = 'patient-history';
const ccmPath = 'ccm';

export const patientTransport = {
  getPatientModel: (id: number): Promise<IPatientModel> => axios.get(`${basePath}/${id}`),
  getPatientHistoryCollection: ({ patientId, ...rest }: IPatientHistoryQueryParams): Promise<IPatientHistoryCollection> =>
    axios.get(`${patientHistoryPath}/${patientId}`, { params: rest }),
  getEnrollmentPatientModel: (enrollmentId: string): Promise<ICCMPatientModel> =>
    axios.get(`${ccmPath}/enrollments/patient/${enrollmentId}`),
  getPatientCcdaModel: (patientId: string): Promise<IPatientCcdaModel> => axios.get(`ccda/by-patient/${patientId}`),
  addCcmPatientConsentModel: (payload: ICcmPatientConsentPayload): Promise<ICcmPatientConsentModel> =>
    axios.patch(`${ccmPath}/patient-consent`, payload),
  getBillingCyclesPatientModel: (billingCycleId: string): Promise<ICCMPatientModel> =>
    axios.get(`${ccmPath}/billing-cycles/patient/${billingCycleId}`),
  unenrollCcmPatient: (patientExternalId: number): Promise<void> => axios.post(`${ccmPath}/unenroll/${patientExternalId}`),
};
