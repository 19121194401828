import { createModel } from '@rematch/core';
import { IRootModel } from 'app/store';
import { reviewHistoryTransport } from 'entities/ReviewHistory/ReviewHistory.transport';
import {
  ICCMCommentPayload,
  IEnrollmentHistoryCollection,
  IEventHistoryCollection,
  IPatientHistoryCollection,
  IPatientHistoryUpdatePayload,
} from 'entities/ReviewHistory/ReviewHistory.models';

export const eventReviewHistoryModel = createModel<IRootModel>()({
  state: {
    data: null as IEventHistoryCollection | null,
    loading: false,
  },
  reducers: {
    setEventReviewHistoryModel: (state, payload: IEventHistoryCollection) => ({ ...state, data: payload }),
    setEventReviewHistoryModelLoading: (state, payload: boolean) => ({ ...state, loading: payload }),
    clearEventReviewHistoryModel: (state) => ({ ...state, data: null }),
  },
  effects: (dispatch) => ({
    async getEventReviewHistoryModel(eventId: number) {
      dispatch.eventReviewHistoryModel.setEventReviewHistoryModelLoading(true);
      reviewHistoryTransport
        .getEventReviewHistoryModel(eventId)
        .then((response) => {
          dispatch.eventReviewHistoryModel.setEventReviewHistoryModel(response);
        })
        .finally(() => {
          dispatch.eventReviewHistoryModel.setEventReviewHistoryModelLoading(false);
        });
    },
    async postEventCallHistory(eventId: number) {
      dispatch.eventReviewHistoryModel.setEventReviewHistoryModelLoading(true);
      reviewHistoryTransport
        .postEventCallHistory(eventId)
        .then(() => {
          dispatch.eventReviewHistoryModel.getEventReviewHistoryModel(eventId);
        })
        .finally(() => {
          dispatch.eventReviewHistoryModel.setEventReviewHistoryModelLoading(false);
        });
    },
  }),
});

export const enrollmentReviewHistoryModel = createModel<IRootModel>()({
  state: {
    data: null as IEnrollmentHistoryCollection | null,
    loading: false,
  },
  reducers: {
    setEnrollmentReviewHistoryModel: (state, payload: IEnrollmentHistoryCollection) => ({ ...state, data: payload }),
    setEnrollmentReviewHistoryModelLoading: (state, payload: boolean) => ({ ...state, loading: payload }),
    clearEnrollmentReviewHistoryModel: (state) => ({ ...state, data: null }),
  },
  effects: (dispatch) => ({
    async getEnrollmentReviewHistoryModel(enrollmentId: string) {
      dispatch.enrollmentReviewHistoryModel.setEnrollmentReviewHistoryModelLoading(true);
      return reviewHistoryTransport
        .getEnrollmentReviewHistoryModel(enrollmentId)
        .then((response) => {
          dispatch.enrollmentReviewHistoryModel.setEnrollmentReviewHistoryModel(response);
        })
        .finally(() => {
          dispatch.enrollmentReviewHistoryModel.setEnrollmentReviewHistoryModelLoading(false);
        });
    },
    async addEnrollmentCommentHistory(payload: ICCMCommentPayload) {
      dispatch.enrollmentReviewHistoryModel.setEnrollmentReviewHistoryModelLoading(true);
      return reviewHistoryTransport
        .addEnrollmentCommentHistory(payload)
        .then(() => {
          dispatch.enrollmentReviewHistoryModel.getEnrollmentReviewHistoryModel(payload.id);
        })
        .finally(() => {
          dispatch.enrollmentReviewHistoryModel.setEnrollmentReviewHistoryModelLoading(false);
        });
    },
    async addEnrollmentCallHistory(enrollmentId: string) {
      dispatch.enrollmentReviewHistoryModel.setEnrollmentReviewHistoryModelLoading(true);
      return reviewHistoryTransport
        .addEnrollmentCallHistory(enrollmentId)
        .then(() => {
          dispatch.enrollmentReviewHistoryModel.getEnrollmentReviewHistoryModel(enrollmentId);
        })
        .finally(() => {
          dispatch.enrollmentReviewHistoryModel.setEnrollmentReviewHistoryModelLoading(false);
        });
    },
  }),
});

export const patientReviewHistoryModel = createModel<IRootModel>()({
  state: {
    data: null as IPatientHistoryCollection | null,
    loading: false,
  },
  reducers: {
    setPatientReviewHistoryModel: (state, payload: IPatientHistoryCollection) => ({ ...state, data: payload }),
    setPatientReviewHistoryModelLoading: (state, payload: boolean) => ({ ...state, loading: payload }),
    clearPatientReviewHistoryModel: (state) => ({ ...state, data: null }),
  },
  effects: (dispatch) => ({
    async getPatientReviewHistoryModel(billingCycleId: string) {
      dispatch.patientReviewHistoryModel.setPatientReviewHistoryModelLoading(true);
      return reviewHistoryTransport
        .getPatientReviewHistoryModel(billingCycleId)
        .then((response) => {
          dispatch.patientReviewHistoryModel.setPatientReviewHistoryModel(response);
        })
        .finally(() => {
          dispatch.patientReviewHistoryModel.setPatientReviewHistoryModelLoading(false);
        });
    },
    async addPatientCommentHistory(payload: ICCMCommentPayload) {
      dispatch.patientReviewHistoryModel.setPatientReviewHistoryModelLoading(true);
      return reviewHistoryTransport
        .addPatientCommentHistory(payload)
        .then(() => {
          dispatch.patientReviewHistoryModel.getPatientReviewHistoryModel(payload.id);
        })
        .finally(() => {
          dispatch.patientReviewHistoryModel.setPatientReviewHistoryModelLoading(false);
        });
    },
    async addPatientCallHistory(billingCycleId: string) {
      dispatch.patientReviewHistoryModel.setPatientReviewHistoryModelLoading(true);
      return reviewHistoryTransport
        .addPatientCallHistory(billingCycleId)
        .then(() => {
          dispatch.patientReviewHistoryModel.getPatientReviewHistoryModel(billingCycleId);
        })
        .finally(() => {
          dispatch.patientReviewHistoryModel.setPatientReviewHistoryModelLoading(false);
        });
    },
    async updatePatientCommentHistory({ billingCycleId, ...restPayload }: IPatientHistoryUpdatePayload) {
      dispatch.patientReviewHistoryModel.setPatientReviewHistoryModelLoading(true);
      return reviewHistoryTransport
        .updatePatientCommentHistory(restPayload)
        .then(() => {
          if (billingCycleId) {
            dispatch.patientReviewHistoryModel.getPatientReviewHistoryModel(billingCycleId);
          }
        })
        .finally(() => {
          dispatch.patientReviewHistoryModel.setPatientReviewHistoryModelLoading(false);
        });
    },
  }),
});
