import React from 'react';
import { PatientActionsCard } from 'entities/Actions/components/PatientActionsCard';
import { PatientReviewHistory } from 'entities/ReviewHistory/components/PatientReviewHistory';

interface IComponentProps {
  billingCycleId?: string;
  autoStartTimer: boolean;
}

export const CCMPatientPanel: React.FC<IComponentProps> = ({ billingCycleId, autoStartTimer }) => {
  return (
    <>
      <PatientActionsCard billingCycleId={billingCycleId} autoStartTimer={autoStartTimer} />

      <PatientReviewHistory billingCycleId={billingCycleId} />
    </>
  );
};
